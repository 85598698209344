import React, { useState } from 'react';
import { formatDate } from '../../utilities';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useUserState } from '../../context/UserContext';
import { InputButton } from '../Global/InputButton';
import axios from 'axios';
import { ModalContainer } from '../Global/Modal';
import { toastNotification } from '../../utilities/toastMessage';
import { setUserInfo } from '../../context/actions/user';
import { useGlobalState } from '../../context/GlobalContext';

const UpcomingPayment = () => {
  const [userState, userDispatch] = useUserState();
  const [promoCodePreviewOpen, setPromoCodePreviewOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeDetails, setPromoCodeDetails] = useState(null);
  const {
    nextPaymentInfo,
    accountNumber,
    isPrimary,
    isEnrolledInDefaultProgram,
    defaultProgram,
    digitalProgram
  } = userState;
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  const location = useLocation();

  const addPromoCode = async promo => {
    try {
      if (!isEnrolledInDefaultProgram) return;
      let promotion = await axios.post(
        '/api/prime/v2/account/previewupdatesubscriptions',
        {
          loggedInAccountNumber: accountNumber,
          members: [
            {
              accountNumber: accountNumber,
              isPrimary: true,
              orderActions: [
                {
                  type: 'AddPromoCode',
                  CurrentSubscriptionNumber: defaultProgram?.futureSubscription
                    ? defaultProgram?.futureSubscription.subscriptionNumber
                    : defaultProgram?.currentSubscription?.subscriptionNumber,
                  PromoCode: promo
                }
              ]
            }
          ]
        }
      );
      await setPromoCode(promo);
      await setPromoCodeDetails(promotion.data.data.monthlyPaymentSummary);
      setPromoCodePreviewOpen(true);
    } catch (error) {
      toastNotification(`Promo code '${promo}' could not be applied.`, false);
      console.log(error);
      throw error;
    }
  };

  const submitAddPromoCode = async () => {
    try {
      if (!isEnrolledInDefaultProgram) return;
      await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
        loggedInAccountNumber: accountNumber,
        members: [
          {
            accountNumber: accountNumber,
            isPrimary: true,
            orderActions: [
              {
                type: 'AddPromoCode',
                CurrentSubscriptionNumber: defaultProgram?.futureSubscription
                  ? defaultProgram?.futureSubscription.subscriptionNumber
                  : defaultProgram?.currentSubscription?.subscriptionNumber,
                PromoCode: promoCode
              }
            ]
          }
        ]
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      await submitAddPromoCode();
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        `Your discount has been applied`,
        true,
        <p className="font-small m-0">
          A{' '}
          <b>{promoCodeDetails.detailedPromoCodeDescription[0].description}</b>{' '}
          has been applied to your account. View your discount summary in the{' '}
          <NavLink
            className="text-underline-primary"
            to="/account/billing-and-payment"
          >
            billing & payment
          </NavLink>{' '}
          section.
        </p>
      );
    } catch (error) {
      toastNotification('Your discount could not be applied.', false);
      console.log(error);
      throw error;
    } finally {
      setPromoCode('');
      setPromoCodeDetails(null);
      setPromoCodePreviewOpen(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="w-100 d-lg-block d-none">
        <div className="d-lg-flex d-none">
          <h5>Upcoming Payment</h5>
        </div>
        <div className="d-lg-flex d-none">
          <div className="card border-0 shadow flex-row px-4 py-3 w-100 justify-content-between">
            <div className="d-flex align-items-center">
              {nextPaymentInfo.nextPaymentDate && (
                <div>
                  <p className="h6 font-weight-bold text-uppercase text-secondary m-0">
                    Due Date
                  </p>
                  <h5 className="m-0">
                    {formatDate(nextPaymentInfo.nextPaymentDate)}
                  </h5>
                </div>
              )}
              <div className={nextPaymentInfo.nextPaymentDate && 'ml-5'}>
                <p className="h6 font-weight-bold text-uppercase text-secondary m-0">
                  Balance Due
                </p>
                <h5 className="m-0">
                  {Number(nextPaymentInfo.amount)
                    ? `$${Number(nextPaymentInfo.amount).toFixed(2)}`
                    : 'No payment due'}
                </h5>
              </div>
              {location.pathname !== '/account/billing-and-payment' && (
                <div className="text-primary ml-5">
                  <NavLink to="/account/billing-and-payment">
                    View Details
                  </NavLink>
                </div>
              )}
            </div>
            {isPrimary && isEnrolledInDefaultProgram && (
              <InputButton
                width="50"
                buttonText="Submit"
                placeholderText="Do you have a promo code?"
                submit={addPromoCode}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('d-lg-none flex-grow-1', {
          'd-none': location.pathname !== '/account',
          'my-3': location.pathname === '/account'
        })}
      >
        <div className="p-3 card shadow">
          <h5 className="m-0 mb-2">Upcoming Payment</h5>
          {nextPaymentInfo.nextPaymentDate && (
            <div className="d-flex align-items-center justify-content-between">
              <p className="h6 font-weight-bold text-uppercase text-secondary m-0">
                Due Date
              </p>
              <h5 className="m-0 upcoming-paymenttext">
                {formatDate(nextPaymentInfo.nextPaymentDate)}
              </h5>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between">
            <p className="h6 font-weight-bold text-uppercase text-secondary m-0">
              Balance Due
            </p>
            <h5 className="m-0">
              {Number(nextPaymentInfo.amount)
                ? `$${Number(nextPaymentInfo.amount).toFixed(2)}`
                : 'No payment due'}
            </h5>
          </div>
          <div className="my-2">
            {isPrimary && isEnrolledInDefaultProgram && (
              <InputButton
                buttonText="Submit"
                placeholderText="Do you have a promo code?"
                submit={addPromoCode}
              />
            )}
          </div>
          {location.pathname !== '/account/billing-and-payment' && (
            <NavLink
              to="/account/billing-and-payment"
              className="btn btn-outline-primary w-100 rounded-pill"
            >
              View Details
            </NavLink>
          )}
        </div>
      </div>
      <ModalContainer
        title={`Promo code '${promoCode}' was found`}
        isOpen={promoCodePreviewOpen}
        setIsOpen={setPromoCodePreviewOpen}
        isLoading={submitting}
        submit={submit}
        cancelText="No, Discard Promo Code"
        submitText="Yes, Apply Promo Code"
        selfClosing
      >
        {promoCodeDetails && (
          <>
            <p>
              This promo code applies a{' '}
              <b>
                {promoCodeDetails.detailedPromoCodeDescription[0].description}
              </b>{' '}
              to your account. Your next bill will be reduced to{' '}
              <b>
                $
                {Number(
                  promoCodeDetails.monthlyTotalPaymentAfterDiscountAmountWithTax
                ).toFixed(2)}
              </b>
              . Would you like to apply this code to your account?
            </p>
            <p className="m-0 font-small font-italic">
              Please note: this promo code can not be combined with subsequent
              codes during the time it has been applied.
            </p>
          </>
        )}
      </ModalContainer>
    </>
  );
};

export { UpcomingPayment };
