import React, { useState } from 'react';
import { PaymentMethodList } from './PaymentMethodList';
import { AddPaymentMethodModal } from './AddPaymentMethodModal';
import { EditPaymentModal } from './EditPaymentModal';
import { DeleteCardModal } from './DeleteCardModal';
import { setDefaultPaymentMethod } from '../../../api/payments';
import { setUserInfo } from '../../../context/actions/user';
import { useUserState } from '../../../context/UserContext';
import { toastNotification } from '../../../utilities/toastMessage';
import { LoadingSpinner } from '../../Global/LoadingSpinner';
import { useGlobalState } from '../../../context/GlobalContext';

let ManagePaymentMethods = ({ inModal }) => {
  const [userState, userDispatch] = useUserState();
  const { accountId } = userState;
  const [
    isAddPaymentMethodModalOpen,
    setIsAddPaymentMethodModalOpen
  ] = useState(false);
  const [showEditPayment, setShowEditPayment] = useState(false);
  const [showDeletePayment, setShowDeletePayment] = useState(false);
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  const changeDefaultPaymentMethod = async paymentId => {
    setIsLoading(true);
    try {
      await setDefaultPaymentMethod(client, accountId, paymentId);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your payment method has been successfully updated.',
        true
      );
    } catch (err) {
      toastNotification('Your payment method could not be updated.', false);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const openPaymentMethodEdit = paymentMethod => {
    setChosenPaymentMethod(paymentMethod);
    setShowEditPayment(true);
  };

  const openPaymentMethodDelete = paymentMethod => {
    setChosenPaymentMethod(paymentMethod);
    setShowDeletePayment(true);
  };

  return (
    <div className="order-1 order-lg-2 flex-shrink-1 w-75 w-lg-100">
      {!inModal && (
        <div className="d-flex flex-wrap justify-content-between align-items-baseline mb-3">
          <h5>Payment Options</h5>
        </div>
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <PaymentMethodList
          changeDefaultPaymentMethod={changeDefaultPaymentMethod}
          openPaymentMethodDelete={openPaymentMethodDelete}
          openPaymentMethodEdit={openPaymentMethodEdit}
          setChosenPaymentMethod={setChosenPaymentMethod}
          setShowEditPayment={setShowEditPayment}
        />
      )}
      <button
        type="button"
        className="btn btn-outline-primary my-2"
        onClick={() =>
          setIsAddPaymentMethodModalOpen(!isAddPaymentMethodModalOpen)
        }
      >
        Add New Payment Method
      </button>
      {isAddPaymentMethodModalOpen && (
        <AddPaymentMethodModal
          setIsAddPaymentMethodModalOpen={setIsAddPaymentMethodModalOpen}
          isAddPaymentMethodModalOpen={isAddPaymentMethodModalOpen}
        />
      )}
      <EditPaymentModal
        setShowEditPayment={setShowEditPayment}
        showEditPayment={showEditPayment}
        chosenPaymentMethod={chosenPaymentMethod}
        setChosenPaymentMethod={setChosenPaymentMethod}
        setIsLoading={setIsLoading}
      />
      <DeleteCardModal
        setShowDeletePayment={setShowDeletePayment}
        showDeletePayment={showDeletePayment}
        chosenPaymentMethod={chosenPaymentMethod}
        setChosenPaymentMethod={setChosenPaymentMethod}
      />
    </div>
  );
};

export { ManagePaymentMethods };
