import React, { useState, useEffect } from 'react';
import { Icon } from '../../../../assets/Icons';
import { useGlobalState } from '../../../../context/GlobalContext';
import { NavLink } from 'react-router-dom';
import { useUserState } from '../../../../context/UserContext';

const Confirmation = () => {
  const [{ tierState, configState }] = useGlobalState();
  const [userState] = useUserState();
  const { email, enrolledDependents } = userState;
  const { homeLocationTier } = tierState;
  const { defaultProgram, isEnrolledInDefaultProgram } = userState;

  const { strings } = configState;

  function isThereAEliteMember() {
    // Splitted the logic because it is getting too convoluted if sticked inside a series of logic gates.
    let isThereAEliteMemberBool;
    // checking if the primary member is a elite member based on esau's logic
    if (
      homeLocationTier &&
      defaultProgram?.tier == homeLocationTier.number &&
      !defaultProgram?.currentHomeGym
    ) {
      isThereAEliteMemberBool = true;
    } else {
      // primary member is not a elite member
      isThereAEliteMemberBool = false;
      // now check if their dependents have the elite tier
      if (enrolledDependents && enrolledDependents.length > 0) {
        enrolledDependents.forEach(dependent => {
          if (dependent?.defaultProgram?.tier == homeLocationTier.number) {
            isThereAEliteMemberBool = true;
          }
        });
      }
    }
    return isThereAEliteMemberBool;
  }

  const [showFamilyCard, setShowFamilyCard] = useState(false);

  useEffect(() => {
    const defaultProgramDependents =
      enrolledDependents &&
      enrolledDependents.length > 0 &&
      enrolledDependents.find(
        dependent => dependent.isEnrolledInDefaultProgram && dependent
      );
    if (defaultProgramDependents) {
      setShowFamilyCard(true);
    }
  }, []);

  return (
    <div className="platform-colors__bg mb-5 tb-spaceing confirmation">
      <section className="blue-gradient text-center">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-md-12 white">
              <div className="mb-2">
                <Icon
                  name="circle-check"
                  className="white"
                  width="70px"
                  height="70px"
                />
              </div>
              <h2 className="white">Congratulations!</h2>
              <p className="pt-3">
                A confirmation with more details has been emailed to {email}
              </p>
              <NavLink to="/account">
                <button className="white-btn font-weight-bold view-account__button">
                  View Account Overview{' '}
                  <Icon
                    name="chevron"
                    className="view-account__chevron"
                    width="20px"
                    height="20px"
                  />
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      {/* welcome message */}
      <section className="container text-center mt-5">
        <div className="col-md-9 mx-auto">
          <section
            dangerouslySetInnerHTML={{ __html: strings.welcome_message }}
          />
        </div>
      </section>

      <section className="container text-center mt-5">
        <h3 className="font-weight-bold">
          Where would you like to get started?
        </h3>
      </section>

      {/* member cards */}
      {(isEnrolledInDefaultProgram || showFamilyCard) && (
        <section className="container mt-5">
          <div className="col-md-12">
            <section
              dangerouslySetInnerHTML={{ __html: strings.member_cards }}
            />
            <div className="container w-btn-confirmation">
              <div className="text-center mt-4">
                {isEnrolledInDefaultProgram && (
                  <NavLink to="/account">
                    <button className="btn-outline-secondary w-75">
                      View My Member Card
                    </button>
                  </NavLink>
                )}
                {showFamilyCard && (
                  <div className="text-center mt-2">
                    <NavLink to="/account/manage-plans">
                      <button className="btn-outline-secondary mb-2 w-75">
                        View Family Member(s) Card
                      </button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      {isThereAEliteMember() ? (
        // primary has the highest tier
        <section className="container mt-5">
          <div className="col-md-12">
            <section
              dangerouslySetInnerHTML={{ __html: strings.elite_access }}
            />
          </div>
        </section>
      ) : null}

      {/* studio section  */}
      <section className="container mt-5">
        <div className="col-md-12">
          <section
            dangerouslySetInnerHTML={{ __html: strings.studio_access }}
          />
        </div>
      </section>
    </div>
  );
};

export { Confirmation };
