const getOrdinalNum = day => {
  return (
    day +
    (day > 0
      ? ['th', 'st', 'nd', 'rd'][
          (day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10
        ]
      : '')
  );
};

export const isToday = someDate => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const formatDate = date => {
  const monthAbbreviations = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const formattedDate = new Date(date);
  const day = formattedDate.getUTCDate();
  const month = formattedDate.getUTCMonth();
  return `${monthAbbreviations[month]} ${getOrdinalNum(day)}`;
};

export { formatDate };
