import React from 'react';
import { LocationListItem } from './';
import classNames from 'classnames';
import { useGlobalState } from '../../../context/GlobalContext';

const LocationList = ({
  headerText,
  scrollLocationList,
  setHoveredLocation,
  hoveredLocation,
  tierDescription,
  isModal
}) => {
  const [{ locationState }] = useGlobalState();
  const { visibleGyms, chosenLocationType } = locationState;

  return (
    <React.Fragment>
      <h5 className="mt-3">{headerText}</h5>
      <small className="text-secondary">{tierDescription}</small>
      <div
        className={classNames('mt-1', {
          'location-list-scroll': scrollLocationList
        })}
        style={{
          height: chosenLocationType === 'studio' ? '75vh' : isModal && '30vh'
        }}
      >
        {visibleGyms &&
          !!visibleGyms.length &&
          visibleGyms.map(item => {
            return (
              <LocationListItem
                setHoveredLocation={setHoveredLocation}
                locationHovered={hoveredLocation === item}
                key={item.id}
                item={item}
                name={item.name}
                address1={item.street}
                city={item.city}
                state={item.state}
                zipCode={item.zip}
                mileDistance={item.distance}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
};

export { LocationList };
