const getCurrentLocationFromNavigator = () => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          currentLocation: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        });
      },
      error => {
        reject({ currentLocation: null });
      },
      { timeout: 30000, enableHighAccuracy: true, maximumAge: 75000 }
    )
  );
};

export { getCurrentLocationFromNavigator };
