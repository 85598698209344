import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SelectPackageTierBox } from './SelectPackageTierBox';
import { PayAsYouGoBox } from './PayAsYouGoBox';
import { useGlobalState } from '../../../../context/GlobalContext';
import {
  setPrimarySelectedPackage,
  setDisableFooterButton
} from '../../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { DigitalTier } from './DigitalTier';

const SelectPackages = ({ getOrderPreview }) => {
  const [{ tierState }] = useGlobalState();
  const location = useLocation();
  const { tiers } = tierState;
  const [{ primarySelectedPackage }, enrollmentDispatch] = useEnrollmentState();

  useEffect(() => {
    if (location.selectedTier) {
      enrollmentDispatch(setPrimarySelectedPackage(location.selectedTier));
    }
  }, []);

  useEffect(() => {
    if (!!primarySelectedPackage) {
      getOrderPreview({ primarySelectedPackage });
      enrollmentDispatch(setDisableFooterButton(false));
    } else {
      enrollmentDispatch(setDisableFooterButton(true));
    }
  }, [primarySelectedPackage]);

  return (
    <>
      <form>
        <div className="container-fluid d-none d-md-flex flex-column p-0">
          <div className="row">
            {tiers && tiers.map(gym => <SelectPackageTierBox gym={gym} />)}
          </div>
        </div>
        <DigitalTier />
        <PayAsYouGoBox />
      </form>
      <form>
        <div className="d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-column">
          <div className="flex-cards">
            {tiers &&
              tiers.map(gym => {
                return <SelectPackageTierBox gym={gym} isMobile />;
              })}
          </div>
        </div>
        <DigitalTier isMobile />
        <PayAsYouGoBox isMobile />
      </form>
    </>
  );
};

export { SelectPackages };
