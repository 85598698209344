import React, { useEffect } from 'react';
import { HomeGymSection } from './HomeGymSection';
import { FitnessPackage } from './FitnessPackage';
import { Icon } from '../../assets/Icons';
import { useUserState } from '../../context/UserContext';
import { useGlobalState } from '../../context/GlobalContext';
import { StudioPackage } from './StudioPackage';
import { getPhoneNumber } from '../../utilities/getPhoneNumber';

const Plans = ({ setShowLocationSearchModal, showLocationSearchModal }) => {
  const [userState] = useUserState();
  const {
    isPrimary,
    isEnrolledInDefaultProgram,
    isLegacy,
    isStudioEnrolled,
    defaultProgram,
    isEnrolledInDigitalTier
  } = userState;
  const [{ configState }] = useGlobalState();
  const {
    com: {
      attributes: { studioDisplay }
    },
    cms
  } = configState;

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML =
      'var acc = document.getElementsByClassName("question"); var i; for (i = 0; i < acc.length; i++) {acc[i].addEventListener("click", function() {this.classList.toggle("active");var panel = this.nextElementSibling;if (panel.style.display === "block") {panel.style.display = "none";} else {panel.style.display = "block";}});}';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <React.Fragment>
      <div className="d-lg-flex d-none w-50">
        <div className="p-0 col mt-4">
          <h5 className="inline">My Plans</h5>
          {isLegacy ? (
            <p className="mb-1 m-0">
              You currently have access to the following fitness package(s).
            </p>
          ) : isPrimary ? (
            <p className="mb-1 m-0">
              You currently have access to the following fitness package(s).
            </p>
          ) : (
            (isEnrolledInDefaultProgram || isEnrolledInDigitalTier) && (
              <p className="mb-1 m-0">
                You currently have access to the following fitness package(s),
                as selected by the Primary Member in this family bundle.
              </p>
            )
          )}
          <div className="d-flex mt-2 w-100 wrapit">
            <FitnessPackage />
            {isPrimary &&
              (studioDisplay === 'Both' || studioDisplay === 'Web') &&
              isEnrolledInDefaultProgram &&
              !isStudioEnrolled && <StudioPackage />}
           
              <HomeGymSection
                setShowLocationSearchModal={setShowLocationSearchModal}
                showLocationSearchModal={showLocationSearchModal}
                primaryColor={cms.primary_color}
              />
          </div>
          {(isEnrolledInDefaultProgram || isEnrolledInDigitalTier) &&
            !isLegacy &&
            !isPrimary && (
              <div className="d-flex flex-column">
                <div className="mt-2 m-0 font-small d-flex font-primary question">
                  <p className="font-small primary">
                    I'd like to change my plan
                  </p>
                  <Icon
                    name="chevron"
                    width="15px"
                    height="15px"
                    className="fill-primary ml-1"
                    style={{ transform: 'rotate(90deg)' }}
                  ></Icon>
                </div>
                <div
                  className="panel p-0 bg-white border-0"
                  style={{ display: 'none' }}
                >
                  <p className="mt-2 m-0 font-small">
                    Only the Primary Member who created the family bundle is
                    allowed to make changes on your plan. If you'd like to
                    create your own individual account or family bundle, please
                    ask the current Primary Member to remove you from the
                    existing family bundle. For next steps in setting up your
                    own primary account, please call{' '}
                    <a href={`tel:${getPhoneNumber().number}`}>
                      {getPhoneNumber().text}
                    </a>{' '}
                    and speak with a customer service representative.
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export { Plans };
