import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Avatar } from './';
import { downloadCard, printCard } from '../../api/account';
import classNames from 'classnames';
import { useGlobalState } from '../../context/GlobalContext';
import { logOut } from '../../context/actions/user';
import { isUnderage } from '../../utilities/checkIfUnderage';
import { useUserState } from '../../context/UserContext';
import { Card } from '../Global/Card';
import { getImageURL } from '../../utilities/imageUrl';

const AccountNavigation = () => {
  const [{ tierState }] = useGlobalState();
  const [userState, userDispatch] = useUserState();
  const location = useLocation();
  const {
    isEnrolledInDefaultProgram,
    isFWEnrolled,
    firstName,
    lastName,
    dateOfBirth,
    defaultProgram,
    isPrimary,
    isStudioEnrolled,
    isFWEligible,
    consumerId,
    isEnrolledInDigitalTier
  } = userState;
  const [currentTier, setCurrentTier] = useState(null);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    async function fetchImageUrl() {
      setImageUrl(await getImageURL(consumerId));
    }
    fetchImageUrl();
  }, []);

  useEffect(() => {
    let currentUserTier;
    if (!currentTier)
      currentUserTier =
        defaultProgram?.tier &&
        tierState.tiers.find(item => item.tier == defaultProgram?.tier);
    setCurrentTier(currentUserTier);
  }, []);

  return (
    <React.Fragment>
      <div className="d-lg-flex d-none" style={{ minWidth: '250px' }}>
        <nav className="nav-bar d-flex flex-column side-nav">
          <div className="bg-white d-flex flex-column justify-content-between align-items-center">
            <div className="w-75 pl-4 mt-4">
              <div className="d-flex flex-column align-items-center">
                <Avatar />
                <h5 className="my-2 text-center w-100 text-capitalize">
                  {firstName} {lastName}
                </h5>
                <NavLink to="/account/edit-profile">
                  <button className="btn btn-outline-secondary">
                    Edit Profile
                  </button>
                </NavLink>
              </div>
              <ul className="navbar-nav mt-5">
                <li className="nav-item m-0 active mb-3">
                  <NavLink to="/account" exact className="account-nav-item">
                    Account Overview
                  </NavLink>
                </li>
                {(isEnrolledInDefaultProgram ||
                  defaultProgram?.currentSubscription ||
                  isStudioEnrolled ||
                  isEnrolledInDigitalTier) && (
                  <>
                    <li className="nav-item m-0 mb-3">
                      <NavLink
                        to="/account/manage-plans"
                        exact
                        className="account-nav-item"
                      >
                        Manage Plans
                      </NavLink>
                    </li>
                  </>
                )}
                {(isPrimary || !isUnderage(dateOfBirth)) &&
                  (isEnrolledInDefaultProgram ||
                    defaultProgram?.currentSubscription ||
                    isStudioEnrolled ||
                    isEnrolledInDigitalTier) && (
                    <li className="nav-item m-0 mb-3">
                      <NavLink
                        to="/account/billing-and-payment"
                        exact
                        className="account-nav-item"
                      >
                        Billing and Payment
                      </NavLink>
                    </li>
                  )}
                <li className="nav-item m-0 mb-3">
                  <NavLink
                    to="/account/activity"
                    exact
                    className="account-nav-item"
                  >
                    Activity Log
                  </NavLink>
                </li>
              </ul>
            </div>
            <ul className="navbar-nav mb-4 w-75 pl-4">
              <li className="nav-item m-0 mb-3">
                <a
                  href="/terms-of-use"
                  target="_blank"
                  className="account-nav-item"
                >
                  Terms of Use
                </a>
              </li>
              <li className="nav-item m-0 mb-3">
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="account-nav-item"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item m-0 mb-3">
                <a
                  href="/fitness-program-agreement"
                  target="_blank"
                  className="account-nav-item"
                >
                  Fitness Program Agreement
                </a>
              </li>

              <li className="nav-item m-0 mb-3">
                <NavLink to="/account/help" exact className="account-nav-item">
                  Help
                </NavLink>
              </li>
              <li className="nav-item m-0">
                <NavLink
                  to="/logout"
                  onClick={() => {
                    userDispatch(logOut());
                  }}
                  exact
                  className="account-nav-item font-weight-bold text-danger"
                >
                  Log Out
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div
        className={classNames('d-lg-none position-relative flex-column', {
          'd-none': location.pathname !== '/account',
          'd-sm-flex': location.pathname === '/account',
          'd-md-flex': location.pathname === '/account'
        })}
      >
        <div className="d-flex flex-column align-items-center py-5 bg-primary">
          <div className="avatar d-flex align-items-center justify-content-center bg-light shadow">
            <h3 className="m-0 text-primary">
              <Avatar />
            </h3>
          </div>
          <h5 className="mt-2 text-white">
            {firstName} {lastName}
          </h5>
          {isFWEnrolled && (
            <NavLink to="/account/edit-profile">
              <button className="btn-especial">Edit Profile</button>
            </NavLink>
          )}
        </div>
        <div style={{ marginTop: -50 }}>
          {((isFWEligible && isFWEnrolled) ||
            isEnrolledInDefaultProgram ||
            isStudioEnrolled ||
            isEnrolledInDigitalTier) && (
            <Card
              title="My Plan"
              titleIcon="clipboard"
              titleAction="/account/manage-plans"
              titleActionText="Manage Plan"
              submitButtonIcon="download"
              submitButtonText="Download"
              submitButtonAction={
                (isFWEligible && isFWEnrolled) || isEnrolledInDefaultProgram
                  ? () => {
                      downloadCard(consumerId);
                    }
                  : null
              }
            >
              {/* {currentSubscription &&
                currentTier &&
                currentSubscriptionStatus !== 'Cancelled' && (
                  <h3 className="font-weight-light">
                    <span className="text-uppercase">{currentTier.name} </span>
                    Fitness
                  </h3>
                )} */}
              <div className="card-text">
                {isEnrolledInDefaultProgram && currentTier && (
                  <p className="tier_description card-title text-uppercase text-secondary d-block m-0">
                    {currentTier.name.toUpperCase()} Fitness Package
                  </p>
                )}
                {isEnrolledInDigitalTier && (
                  <p className="tier_description card-title text-uppercase text-secondary d-block m-0">
                    Digital Content
                  </p>
                )}
                {isStudioEnrolled && (
                  <p className="tier_description card-title text-uppercase text-secondary d-block m-0">
                    Studio Class Loyalty Program
                  </p>
                )}
                {isFWEnrolled && (
                  <p className="tier_description card-title text-uppercase text-secondary d-block m-0">
                    Fitness Works Program
                  </p>
                )}
              </div>
              {((isFWEligible && isFWEnrolled) ||
                isEnrolledInDefaultProgram) && (
                <>
                  <img
                    src={imageUrl}
                    alt="Membership Card"
                    className="align-self-center p-2"
                    style={{ minWidth: '250px', maxWidth: '325px' }}
                  />
                  <div className="card-text">
                    <p className="card-title text-uppercase text-secondary">
                      How to use your card
                    </p>
                    <p className="card-text">
                      Show this card at the front desk of any gym in your
                      fitness package network.
                    </p>
                  </div>
                </>
              )}
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export { AccountNavigation };
