import { smartyStreetsValidateAddress } from './smartyStreetsAutoComplete';

async function checkIfAddressValid(values, formName = '') {
  if (!values) {
    return 'Invalid';
  }

  let res = await smartyStreetsValidateAddress(values);

  let errors = errorMaker(values, res, formName);
  // console.log(errors);
  if (errors) {
    return errors;
  }
  return res.status === 'Valid';
}

const errorMaker = (originalInput, BtoValues, formName) => {
  let errors = {};
  const { address1, city, state, zipCode, fullZipCode } = BtoValues;

  if (
    originalInput.address1 &&
    address1 &&
    originalInput.address1.toLowerCase() !== address1.toLowerCase()
  ) {
    errors[
      'address1'
    ] = `Did you mean to put in ${address1} as your ${formName} address1?`;
    if (
      originalInput.address2 &&
      address1 === `${originalInput.address1} ${originalInput.address2}`
    ) {
      delete errors.address1;
    }
  }

  if (
    originalInput.city &&
    city &&
    originalInput.city.toLowerCase() !== city.toLowerCase()
  ) {
    errors['city'] = `Did you mean to put in ${city} as your ${formName} city?`;
  }

  if (
    originalInput.state &&
    state &&
    originalInput.state.toLowerCase() !== state.toLowerCase()
  ) {
    errors[
      'state'
    ] = `Did you mean to put in ${state} as your ${formName} state?`;
  }

  if (originalInput.zip && zipCode && fullZipCode) {
    if (originalInput.zip.split('-').length === 2) {
      if (originalInput.zip !== fullZipCode) {
        errors[
          'zip'
        ] = `Did you mean to put in ${fullZipCode} as your ${formName} zip code?`;
      }
    } else {
      if (originalInput.zip !== zipCode) {
        errors[
          'zip'
        ] = `Did you mean to put in ${zipCode} as your ${formName} zip code?`;
      }
    }
  }

  if (Object.getOwnPropertyNames(errors).length) {
    return errors;
  } else {
    return false;
  }
};

export { checkIfAddressValid };
