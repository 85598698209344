import React from 'react';
import { Icon } from '../../../assets/Icons';
import { useGlobalState } from '../../../context/GlobalContext';
import { SuspensionWarning } from '../../Global/SuspensionWarning';
import { useUserState } from '../../../context/UserContext';
import { getPhoneNumber } from '../../../utilities/getPhoneNumber';

const ManageDigitalProgram = ({
  user,
  loggedInUser,
  isDigitalOnly,
  setDigitalDiscardModalOpen
}) => {
  const { firstName, lastName, digitalProgram } = user;
  const [{ tierState, configState }] = useGlobalState();
  const [userState] = useUserState();
  const { digitalTier } = tierState;
  const { isPrimary } = userState;
  const { cms } = configState;

  return (
    <>
      {!isDigitalOnly && !user.isUnderage ? (
        <div className="list-group-item w-100 d-xl-flex d-inline-block flex-xl-row flex-column">
          <div className="align-self-center d-flex flex-column pr-xl-3 border-xl-right">
            <Icon
              name="laptop"
              width="40px"
              height="40px"
              className="align-self-center"
              fill={cms.primary_color}
            />
            <p className="font-weight-bold font-small m-0 nowrap-responsive text-dark-gray">
              DIGITAL CONTENT
            </p>
          </div>
          <div className="align-self-center px-xl-3">
            <p className="m-0 font-small text-dark-gray">
              {`${
                loggedInUser ? 'You have' : 'This member has'
              } access to live virtual classes and thousands of on-demand videos! Your membership includes original Prime On-Demand workouts and access to our partner BurnAlong`}
              <sup>TM</sup>
            </p>
          </div>
        </div>
      ) : (
        <>
          <SuspensionWarning
            whereToShow="member"
            dependentStatus={digitalProgram?.currentSubscriptionStatus}
            startDate={
              digitalProgram?.currentSubscriptionPauseStartDate || null
            }
            endDate={digitalProgram?.currentSubscriptionPauseEndDate || null}
            user={user}
          />
          {!user.isUnderage && (
            <div className="d-flex flex-xl-row flex-column justify-content-between list-group-item">
              <div className=" w-100 d-xl-flex d-inline-block flex-xl-row flex-column">
                <div className="align-self-center pr-4">
                  <h5 className="m-0 font-weight-bold">{`${firstName}`}</h5>
                  <h5 className="m-0 font-weight-bold">{`${lastName}`}</h5>
                </div>
                <div className="align-self-center pr-4">
                  <div className="align-self-center px-xl-3 border-xl-left">
                    <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
                      Current Package
                    </p>
                    <h5 className="m-0 font-weight-bold nowrap-responsive">
                      Digital Content
                    </h5>
                    <p className="m-0 font-small text-dark-gray">{`$${Number(
                      digitalTier[0].recurringPrice
                    ).toFixed(2)}/month`}</p>
                  </div>
                </div>
              </div>
              {/* {isPrimary && !loggedInUser && digitalProgram?.isCancelling && (
              <div className="align-self-center px-lg-3">
                <p className="alert-danger border border-danger rounded p-2">
                  A subscription change is in progress for this member, no
                  further changes can be made at this time until the change has
                  been processed. Contact us at{' '}
                  <a href={`tel:${getPhoneNumber().number}`}>
                    {getPhoneNumber().text}
                  </a>{' '}
                  if you have any questions.
                </p>
              </div>
            )} */}
              {isPrimary && digitalProgram?.isCancelling && (
                <button
                  className="btn align-self-center text-danger pl-0 text-nowrap w-100 text-xl-right text-left mr-3"
                  onClick={() => {
                    setDigitalDiscardModalOpen(true);
                  }}
                >
                  <i className="fas fa-undo mr-2"></i>
                  Discard Cancellation
                </button>
              )}
            </div>
          )}

          {!user.isUnderage && (
            <div className="list-group-item w-100 d-xl-flex d-inline-block flex-xl-row flex-column">
              <div className="align-self-center d-flex flex-column pr-xl-3 border-xl-right">
                <Icon
                  name="laptop"
                  width="40px"
                  height="40px"
                  className="align-self-center"
                  fill={cms.primary_color}
                />
                <p className="font-weight-bold font-small m-0 nowrap-responsive text-dark-gray">
                  DIGITAL CONTENT
                </p>
              </div>
              <div className="align-self-center px-xl-3">
                <p className="m-0 font-small text-dark-gray">
                  {`${
                    loggedInUser ? 'You have' : 'This member has'
                  } access to live virtual classes and thousands of on-demand videos! Your membership includes original Prime On-Demand workouts and access to our partner BurnAlong`}
                  <sup>TM</sup>
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { ManageDigitalProgram };
