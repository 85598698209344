import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getActivities } from '../../../api/activity/GetActivities';
import { transformActivityData } from '../../../utilities';
import { useGlobalState } from '../../../context/GlobalContext';
import { ActivityFilters } from '../ActivityLog/ActivityFilters';
import { ActivityList } from '../ActivityLog/ActivityList';
import { ActivityPagination } from '../ActivityLog/ActivityPagination';
import { EmptyList } from '../EmptyList';
import { LoadingSpinner } from '../../Global/LoadingSpinner';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import { ActivityListExport } from '../ActivityLog/ActivityListExport';
import { Icon } from '../../../assets/Icons';

const ActivityLog = () => {
  const [activityFilterType, setActivityFilterType] = useState(null);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment()
      .add(-1, 'years')
      .format('l')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format('l'));
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loadingActivityLog, setLoadingActivityLog] = useState(true);
  const [
    {
      configState: { client }
    }
  ] = useGlobalState();

  useEffect(() => {
    doGetActivities();
    return () => setSelectedActivities([]);
  }, []);

  const doGetActivities = async (
    endDate = null,
    startDate = null,
    activityFilter = null
  ) => {
    try {
      setLoadingActivityLog(true);
      const activityList = await getActivities(
        client,
        endDate ? endDate : selectedEndDate,
        startDate ? startDate : selectedStartDate
      );
      const transformedActivities = await transformActivityData(activityList);
      if (activityFilter) {
        const filteredActivities = await filterActivities(
          transformedActivities
        );
        setSelectedActivities(filteredActivities);
      } else {
        setSelectedActivities(transformedActivities);
      }
      setActivities(transformedActivities);
    } catch (error) {
      console.log(error);
      setActivities([]);
      throw error;
    } finally {
      setLoadingActivityLog(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('currentActivityPage')) {
      const currentActivityPage = localStorage.getItem('currentActivityPage');
      setCurrentPageNum(Number(currentActivityPage));
    }
    return () => {
      localStorage.removeItem('currentActivityPage');
    };
  }, []);

  const clearFilter = () => {
    setActivityFilterType(null);
    setCurrentPageNum(1);
    setSelectedStartDate(
      moment()
        .add(-1, 'years')
        .format('l')
    );
    setSelectedEndDate(moment().format('l'));
    doGetActivities(
      moment().format('l'),
      moment()
        .add(-1, 'years')
        .format('l')
    );
  };

  const applyFilters = () => {
    doGetActivities(selectedEndDate, selectedStartDate, activityFilterType);
  };

  const filterActivities = activityList => {
    return activityList.filter(activity =>
      activityFilterType ? activity.type === activityFilterType : activity
    );
  };

  return (
    <>
      <div className="my-3 mx-4 d-flex p-4 bg-white rounded-sm shadow flex-grow-1 flex-column">
        <h5>Activity Log</h5>
        <span className="d-none d-sm-block border-bottom w-100 mb-2" />
        <ActivityFilters
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          applyFilters={applyFilters}
          clearFilter={clearFilter}
          activityFilterType={activityFilterType}
          setActivityFilterType={setActivityFilterType}
        />
        {loadingActivityLog ? (
          <LoadingSpinner additionalText="Fetching Your Activity Logs" />
        ) : !activities || activities.length == 0 ? (
          <EmptyList emptyActivityLog />
        ) : selectedActivities && selectedActivities.length > 0 ? (
          <>
            <ActivityList
              selectedActivities={selectedActivities}
              currentPageNum={currentPageNum}
            />
          </>
        ) : (
          <EmptyList emptySelectedActivities />
        )}
        {!loadingActivityLog && activities?.length > 0 && (
          <ActivityPagination
            activities={activities}
            currentPageNum={currentPageNum}
            setCurrentPageNum={setCurrentPageNum}
          />
        )}
      </div>
    </>
  );
};

export { ActivityLog };
