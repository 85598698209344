import React from 'react';
import classNames from 'classnames';
import { setPrimarySelectedPackage } from '../../../../context/actions/enrollment';
import { useGlobalState } from '../../../../context/GlobalContext';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';

const PayAsYouGoBox = ({ isMobile }) => {
  const [{ configState }] = useGlobalState();
  const [{ primarySelectedPackage }, dispatch] = useEnrollmentState();
  const {
    com: {
      attributes: { studioDisplay }
    }
  } = configState;
  return (
    <>
      {(studioDisplay === 'Both' || studioDisplay === 'Web') && (
        <div
          className={classNames('position-relative custom-radio-inf', {
            'd-none': !isMobile,
            'd-lg-flex': !isMobile,
            'd-lg-none d-sm-flex d-xs-flex mt-5': isMobile
          })}
        >
          <div className="p-1 w-100">
            <div className="row">
              <label
                className={classNames('col-md-12 line mt-3', {
                  'pb-4': isMobile
                })}
              >
                <input
                  className="radio-btn-fixed"
                  type="radio"
                  name="studiopackage"
                  id="studioPackageSelection"
                  value="option6"
                  checked={primarySelectedPackage === 'studio'}
                  onClick={() => dispatch(setPrimarySelectedPackage('studio'))}
                />
                <div className="col-sm-12 w-100">
                  <p>
                    I'm only interested in the Studio Class Rewards program and
                    get 30% off every 10th class. Pay-as-you-go, at no cost to
                    enroll.
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { PayAsYouGoBox };
