import React, { useEffect, useState } from 'react';
import { SetHomeGym } from './SetHomeGym';
import { UpdateHomeGym } from './UpdateHomeGym';
import { Confirmation } from './Confirmation';
import { useGlobalState } from '../../../context/GlobalContext';
import { setLoading, setUserInfo } from '../../../context/actions/user';
import { updateHomeGym } from '../../../api/account';
import { chooseLocationType } from '../../../context/actions/location';
import { useUserState } from '../../../context/UserContext';
import { ModalContainer } from '../../Global/Modal';

let HomeGymModal = ({
  showHomeGymModal,
  setShowHomeGymModal,
  location,
  title
}) => {
  const [
    {
      configState: {
        client,
        com: { programCode },
        cms
      }
    },
    dispatch
  ] = useGlobalState();
  const [userState, userDispatch] = useUserState();
  const {
    loadingAccountSummary,
    defaultProgram: { currentHomeGym }
  } = userState;
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showHomeGymModal) dispatch(chooseLocationType('gym'));
  }, []);

  const handleUpdateHomeGym = async () => {
    userDispatch(setLoading('loadingAccountSummary', true));

    try {
      await updateHomeGym(location.id);
      await setUserInfo(programCode, client, userDispatch);
      setShowConfirmation(true);
      setShowHomeGymModal(false);
    } catch (err) {
      return err;
    } finally {
      userDispatch(setLoading('loadingAccountSummary', false));
    }
  };

  return (
    <>
      <ModalContainer
        isOpen={showHomeGymModal}
        setIsOpen={setShowHomeGymModal}
        title={title}
        cancelText="Cancel"
        submitText="Confirm"
        submit={handleUpdateHomeGym}
        isLoading={loadingAccountSummary}
        loaderText="Changing your home gym"
      >
        {currentHomeGym ? (
          <UpdateHomeGym 
          location={location}
          fillColor={cms.primary_color} />
        ) : (
          <SetHomeGym 
          location={location}
          fillColor={cms.primary_color} />
        )}
      </ModalContainer>
      <ModalContainer
        isOpen={showConfirmation}
        setIsOpen={setShowConfirmation}
        title=""
        singleButton
        submitText="Confirm"
        submit={() => setShowConfirmation(false)}
      >
        <Confirmation fillColor={cms.primary_color}/>
      </ModalContainer>
    </>
  );
};

export { HomeGymModal };
