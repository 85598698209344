import React from 'react';
import { EnrollmentNavBarItem } from './EnrollmentNavBarItem';

const EnrollmentNavBar = ({ enrollmentSteps, step }) => (
  <React.Fragment>
    <div className="container-fluid d-lg-flex">
      <div className="col-xl-1 col-lg-1 d-none d-xl-block"></div>
      <div className="border-bottom col-xl-10 col-lg-12 p-0 py-3">
        {enrollmentSteps.map((enrollmentStep, i) => (
          <EnrollmentNavBarItem
            step={i + 1}
            currentStep={step}
            name={enrollmentStep.name}
            link={enrollmentStep.link}
            validation={enrollmentStep.validation}
          />
        ))}
      </div>
    </div>
  </React.Fragment>
);

export { EnrollmentNavBar };
