import React, { useEffect, useState } from 'react';
import { EditPlan } from '../EditPlan';
import axios from 'axios';
import { ManagePlanFitnessPackage } from '../ManagePlans/ManagePlanFitnessPackage';
import { ManageDependents } from '../ManagePlans/ManageDependents';
import { NavLink } from 'react-router-dom';
import { useUserState } from '../../../context/UserContext';
import { ManagePlanPaymentModal } from '../ManagePlanPaymentModal';
import { setUserInfo } from '../../../context/actions/user';
import { ManagePaymentMethodModal } from '../ManagePaymentMethods/ManagePaymentMethodModal';
import { EditAddressModal } from '../EditAddressModal';
import { toastNotification } from '../../../utilities/toastMessage';
import { useGlobalState } from '../../../context/GlobalContext';
import { getPhoneNumber } from '../../../utilities/getPhoneNumber';
import { setEnrollmentDefault } from '../../../context/actions/enrollment';
import { DeleteMemberModal } from '../DeleteMemberModal';
import { useLocation } from 'react-router-dom';

const ManagePlans = ({
  setShowLocationSearchModal,
  showLocationSearchModal,
  showEditPlan,
  setShowEditPlan,
}) => {
  const [userState, userDispatch] = useUserState();
  const {
    enrolledDependents,
    isPrimary,
    paymentMethods,
    nextPaymentInfo,
    defaultProgram,
    digitalProgram,
    isEnrolledInDigitalTier
  } = userState;
  const location = useLocation();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [showManagePlanPaymentModal, setShowManagePlanPaymentModal] = useState(
    false
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [
    isCancelSubscriptionModalOpen,
    setIsCancelSubscriptionModalOpen
  ] = useState(false);
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  useEffect(() => {
    setShowEditPlan(location.showEditPlan);
  }, [location]);

  //Missing logic to read pathname = 'learn-more'
  //then if it has payment methods set showeditplan(true)
  // useEffect(() => {
  //   (paymentMethods || paymentMethods.length === 0) &&
  //     setShowEditPlan(true)
  // })

  const payOffBalance = async () => {
    try {
      await axios.post('/api/prime/v2/account/createpayment');
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        `Your payment of $${Number(nextPaymentInfo.amount).toFixed(
          2
        )} has been accepted.`,
        true
      );
    } catch (err) {
      toastNotification(
        `Your payment could not be processed at this time.`,
        false
      );
      console.log(err);
      throw err;
    } finally {
      setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
    }
  };

  return (
    <>
      <div className="my-3 mx-4 d-flex p-4 bg-white rounded-sm shadow flex-grow-1 flex-column">
        {showEditPlan ? (
          <EditPlan setShowEditPlan={setShowEditPlan} />
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between align-items-baseline mb-3">
              <h5>Manage Plans</h5>
              <div className="d-flex flex-column">
                {isPrimary &&
                  (nextPaymentInfo && nextPaymentInfo.isBalanceOverdue ? (
                    <p className="m-0 align-self-end">
                      <span className="fas fa-exclamation-circle mr-1" />
                      <b>Note:</b> All past due balances must be paid prior to
                      editing your account.
                      <button
                        onClick={() => setShowManagePlanPaymentModal(true)}
                        className="btn text-primary text-underline-primary py-0 px-1"
                      >
                        Pay off your existing balance.
                      </button>
                    </p>
                  ) : (
                    <>
                      <button
                        className="btn btn-outline-secondary align-self-end"
                        disabled={
                          !paymentMethods ||
                          paymentMethods.length === 0 ||
                          ((defaultProgram?.isSuspended ||
                            defaultProgram?.isSuspending ||
                            (isEnrolledInDigitalTier &&
                              (digitalProgram?.isSuspended ||
                                digitalProgram?.isSuspending))) &&
                            (enrolledDependents?.length === 0 ||
                              enrolledDependents.every(
                                dependent =>
                                  dependent?.defaultProgram?.isSuspended ||
                                  dependent?.defaultProgram?.isSuspending ||
                                  (dependent.isEnrolledInDigitalTier &&
                                    (dependent?.digitalProgram?.isSuspended ||
                                      dependent?.digitalProgram?.isSuspending))
                              )))
                        }
                        onClick={() => setShowEditPlan(true)}
                      >
                        Edit Plan
                      </button>
                      {(!paymentMethods || paymentMethods.length === 0) && (
                        <p className="text-danger m-0 align-self-end">
                          It seems like you do not have a payment method on
                          file. Click
                          <NavLink
                            to="/account/billing-and-payment"
                            className="font-weight-bold text-danger"
                          >
                            {' '}
                            here{' '}
                          </NavLink>
                          to add one.
                        </p>
                      )}
                    </>
                  ))}
              </div>
            </div>
            {isPrimary && (
              <p className="h6 font-weight-bold text-uppercase text-secondary m-0">
                Primary member
              </p>
            )}
            <ManagePlanFitnessPackage
              setShowLocationSearchModal={setShowLocationSearchModal}
              showLocationSearchModal={showLocationSearchModal}
              setIsCancelSubscriptionModalOpen={
                setIsCancelSubscriptionModalOpen
              }
              user={userState}
              loggedInUser
              setSelectedUser={setSelectedUser}
            />
            <ManageDependents
              setSelectedUser={setSelectedUser}
              setShowEditPlan={setShowEditPlan}
              setIsCancelSubscriptionModalOpen={
                setIsCancelSubscriptionModalOpen
              }
            />
            {!isPrimary && (
              <div className="dependent-msg">
                <p>
                  Only the Primary Member who created the family bundle is
                  allowed to make changes on your plan. If you'd like to create
                  your own individual account or family bundle, please ask the
                  current Primary Member to remove you from the existing family
                  bundle. For next steps in setting up your own primary account,
                  please call{' '}
                  <a
                    className="text-underline-primary"
                    href={`tel:${getPhoneNumber().text}`}
                  >
                    {getPhoneNumber().text}
                  </a>
                  and speak with a customer service representative.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <ManagePlanPaymentModal
        showManagePlanPaymentModal={showManagePlanPaymentModal}
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
        setShowPaymentModal={setShowPaymentModal}
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
        submit={payOffBalance}
        title={
          nextPaymentInfo &&
          `Checkout - $${Number(nextPaymentInfo.amount).toFixed(2)}`
        }
      />
      <ManagePaymentMethodModal
        setIsManagePaymentMethodModalOpen={setShowPaymentModal}
        isManagePaymentMethodModalOpen={showPaymentModal}
      />
      <EditAddressModal
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
      />
      {isCancelSubscriptionModalOpen && (
        <DeleteMemberModal
          isOpen={isCancelSubscriptionModalOpen}
          setIsOpen={setIsCancelSubscriptionModalOpen}
          user={selectedUser}
        />
      )}
    </>
  );
};

export { ManagePlans };
