import React from 'react';
import PropTypes from 'prop-types';
import {
  DistanceFilter,
  AmenitiesFilter,
  StudioFilter,
  GenderSpecificFilter
} from '.';
import { useGlobalState } from '../../../context/GlobalContext';
import { clearFilters } from '../../../context/actions/location';

const MapFilters = ({
  studioFilter,
  distanceFilter,
  genderSpecificFilter,
  amenitiesFilter,
  showClearFilter
}) => {
  const [{}, dispatch] = useGlobalState();
  return (
    <div className="px-3 py-3 d-flex bg-white align-items-center">
      <p className="m-0 mr-3 text-body">
        <small>Filter by</small>
      </p>
      {distanceFilter ? <DistanceFilter /> : null}
      {amenitiesFilter ? <AmenitiesFilter /> : null}
      {genderSpecificFilter ? <GenderSpecificFilter /> : null}
      {studioFilter && <StudioFilter />}
      {showClearFilter ? (
        <button
          className="btn btn-link"
          onClick={() => dispatch(clearFilters())}
        >
          Clear Filter
        </button>
      ) : null}
    </div>
  );
};

MapFilters.propTypes = {
  amenitiesFilter: PropTypes.bool,
  chosenDistance: PropTypes.number,
  distanceFilter: PropTypes.bool,
  handleAfterDistanceChange: PropTypes.func,
  handleDistance: PropTypes.func,
  studioFilter: PropTypes.bool
};

export { MapFilters };
