import React from 'react';
import { AddressErrors } from './AddressForm/AddressErrors';
import { Select as SelectDropdown } from 'react-dropdown-select';
import { Field } from 'formik';

const Select = props => {
  return (
    <Field name={props.name} id={props.name} type={props.type}>
      {({ field: { value }, form: { setFieldValue } }) => {
        const valueList = props.options
          ? props.options.find(item => item[props.labelField] == value)
          : [];

        return (
          <div className="form-group col col-md-4">
            <label htmlFor={props.name} className="form-label">
              <p className=" h6 text-uppercase font-weight-bold text-secondary m-0">
                {props.title} <i className="m-0 text-danger">*</i>
              </p>
            </label>
            {/* <select
        className="form-control p-1"
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        onBlur={props.handleBlur}
        onChange={e => props.handleChange(e)}
        >
        {Object.entries(props.options).map(([key, value]) => {
          return (
            <option value={key} key={key}>
              {value}
            </option>
            );
          })}
        </select> */}
            <SelectDropdown
              style={{
                borderRadius: '5px',
                borderColor: '#0000001F',
                // zIndex: 1000,
                color: '#333'
              }}
              values={valueList ? [valueList] : []}
              options={props.options}
              name={props.name}
              labelField={props.labelField}
              valueField={props.labelField}
              dropdownGap={0}
              onChange={item => {
                setFieldValue(props.name, item[0][props.labelField]);
              }}
            />
            <AddressErrors
              touched={props.touched}
              errors={props.errors}
              name={props.name}
              addressType={props.addressType}
            />
          </div>
        );
      }}
    </Field>
  );
};

export { Select };
