import React, { useState } from 'react';
import { useGlobalState } from '../../../context/GlobalContext';
import { ManagePlanFitnessPackage } from './ManagePlanFitnessPackage';
import { useUserState } from '../../../context/UserContext';

const ManageDependents = ({
  setShowEditPlan,
  setSelectedUser,
  setIsCancelSubscriptionModalOpen
}) => {
  const [{ configState }] = useGlobalState();
  const [userState] = useUserState();
  const { com } = configState;
  const {
    enrolledDependents,
    isPrimary,
    paymentMethods,
    defaultProgram,
    digitalProgram,
    nextPaymentInfo
  } = userState;
  const { uiConfig } = com || {};
  const { canEnrollDependents } = uiConfig || {};

  return (
    <>
      {com.entitlements.familyMembership &&
        (enrolledDependents && enrolledDependents.length > 0 ? (
          <div className="d-flex flex-column mt-4 mb-2 overflow-hidden">
            <p className="h6 font-weight-bold text-uppercase text-secondary m-0 mb-2">
              Secondary Member(s)
            </p>
            {enrolledDependents.map(dependent => (
              <ManagePlanFitnessPackage
                key={dependent.consumerId}
                user={dependent}
                setIsCancelSubscriptionModalOpen={
                  setIsCancelSubscriptionModalOpen
                }
                setSelectedUser={setSelectedUser}
              />
            ))}
          </div>
        ) : (
          isPrimary &&
          !(
            !paymentMethods ||
            paymentMethods.length === 0 ||
            defaultProgram?.isSuspended ||
            defaultProgram?.isSuspending ||
            digitalProgram?.isSuspended ||
            digitalProgram?.isSuspending
          ) &&
          !(nextPaymentInfo && nextPaymentInfo.isBalanceOverdue) &&
          canEnrollDependents && (
            <p>
              Remember, family bundles unlock special discounts!{' '}
              <span
                className="text-underline-primary primary"
                onClick={() => setShowEditPlan(true)}
              >
                Edit your plan
              </span>{' '}
              to add family members.
            </p>
          )
        ))}
    </>
  );
};

export { ManageDependents };
