import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const EnrollmentNavBarItem = ({
  step,
  name,
  link,
  currentStep,
  validation
}) => {
  return (
    <NavLink
      className={classNames('mr-2 font-small', {
        'font-weight-bold': currentStep >= step,
        'text-primary': currentStep >= step,
        'text-secondary': currentStep < step
      })}
      to={validation ? `/enroll/${link}` : '#'}
      exact
    >
      {`${step}. ${name}`}
    </NavLink>
  );
};

export { EnrollmentNavBarItem };
