import React, { useEffect, useState } from 'react';
import { LocationSection, MapSection } from '../';
import { useGlobalState } from '../../context/GlobalContext';
import {
  getEliteGymList,
  chooseLocation,
  getCurrentMapLocation,
  renderLocations
} from '../../context/actions/location';
import { SET_GYMS } from '../../context/reducers/location';
import { ModalContainer } from '../Global/Modal';

const EliteLocationSearchModal = ({
  showLocationSearchModal,
  setShowLocationSearchModal,
  setShowCurrentHomeGym
}) => {
  const [
    {
      locationState,
      configState: {
        client,
        com: { programCode }
      }
    },
    dispatch
  ] = useGlobalState();
  const {
    currentUserLocation,
    currentMapLocation,
    gymList,
    chosenLocation,
    mapData,
    loadingLocations
  } = locationState;
  const [hoveredLocation, setHoveredLocation] = useState({});

  useEffect(() => {
    if (currentUserLocation) {
      dispatch(getCurrentMapLocation(currentUserLocation));
      getEliteGymList(programCode, client, currentUserLocation, dispatch);
    }
    return () => {
      dispatch({ type: SET_GYMS, gymList: null });
      dispatch(chooseLocation(null));
    };
  }, []);

  useEffect(() => {
    if (!loadingLocations) renderLocations(dispatch, mapData, gymList);
  }, [mapData, gymList]);

  useEffect(() => {
    if (currentMapLocation && !chosenLocation) {
      getEliteGymList(programCode, client, currentMapLocation, dispatch);
    }
  }, [currentMapLocation]);

  return (
    <ModalContainer
      size="lg"
      isOpen={showLocationSearchModal}
      setIsOpen={setShowLocationSearchModal}
      title="Update Home Gym"
      isDismissable={true}
    >
      <div className="d-flex">
        <LocationSection
          showTiers={false}
          setHoveredLocation={setHoveredLocation}
          hoveredLocation={hoveredLocation}
          isModal
        />
        <MapSection
          hoveredLocation={hoveredLocation}
          setHoveredLocation={setHoveredLocation}
          mapHeight="50vh"
          isModal
        />
      </div>
    </ModalContainer>
  );
};

export { EliteLocationSearchModal };
