import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useGlobalState } from '../../../context/GlobalContext';

const LocationOptionButton = ({ active, locationOption, selectGymOption }) => {
  const [{ configState }] = useGlobalState();
  const {
    com: {
      attributes: { studioDisplay }
    }
  } = configState;
  return (
    <React.Fragment>
      {(studioDisplay === 'Both' || studioDisplay === 'Web') && (
        <div
          className={classNames('col', 'p-2', {
            'border-primary': active,
            'border-bottom': active
          })}
        >
          <button
            type="button"
            className={classNames(
              'btn',
              'w-100',
              'text-uppercase',
              'font-small',
              {
                active: active,
                'text-primary': active,
                'text-secondary': !active
              }
            )}
            onClick={() => selectGymOption(locationOption)}
          >
            {`${locationOption.toUpperCase()}S`}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

LocationOptionButton.propTypes = {
  locationOption: PropTypes.string,
  active: PropTypes.bool,
  selectGymOption: PropTypes.func
};

export { LocationOptionButton };
