export const SET_TIERS = 'SET_TIERS';
export const CHOOSE_TIER = 'CHOOSE_TIER';
export const SET_LOADING = 'SET_LOADING';

const chooseTier = (chosenTier, state) => ({ ...state, chosenTier });

const setTiers = (tiers, homeLocationTier, digitalTier, state) => ({
  ...state,
  tiers,
  homeLocationTier,
  digitalTier,
  chosenTier: tiers && tiers.length > 0 ? tiers[tiers.length - 1] : null,
  loadingTiers: false
});

const setLoading = (loadingType, isLoading, state) => ({
  ...state,
  [loadingType]: isLoading
});

export const tiersReducer = (state, action) => {
  switch (action.type) {
    case CHOOSE_TIER:
      return chooseTier(action.tier, state);
    case SET_TIERS:
      return setTiers(
        action.tiers,
        action.homeLocationTier,
        action.digitalTier,
        state
      );
    case SET_LOADING:
      return setLoading(action.loadingType, action.isLoading, state);
    default:
      return state;
  }
};
