import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TwoColumnSummary } from '../components/Enrollment';

const Enrollment = () => (
  <Switch>
    <Route path="/enroll/(select-packages|cart-summary|billing-details|payment-method)">
      <TwoColumnSummary />
    </Route>
  </Switch>
);

export { Enrollment };
