import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const StudioPackageSection = () => (
  <div>
    <section className="extra-padding-bottom d-none d-sm-none d-md-none d-lg-block studio-classes">
      <div className="align-right extra-margin-top">
        <img
          src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2021/06/StudioClassRewards_right.png"
          className="img-fluid"
          alt="studio info-graphic"
        />
      </div>
      <div className="container row inline">
        <div className="col-md-12 extra-margin-top">
          <div className="row">
            <div className="col-lg-2 d-sm-none d-lg-block"></div>
            <div className="col-lg-7 extra-margin-top">
              <h1 className="font-weight-bold">
                Studio Class Rewards
                <a href="#legal" className="legal-sup">
                  <sup>1</sup>
                </a>
              </h1>
              <p>
                Want to do barre on Monday and yoga on Friday, and go to your
                favorite studio every other week? Try our studio class program
                to receive discounts the more classes you attend. It’s
                pay-as-you-go so there’s no obligation.
              </p>

              {window.location.href.includes('fyw') ? (
                <img
                  src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2020/04/punch-card-fyw.png"
                  className="punch-card img-fluid"
                  alt="studio punch card"
                />
              ) : (
                <img
                  src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2020/04/punch-card.png"
                  className="punch-card img-fluid"
                  alt="studio punch card"
                />
              )}

              <p className="mt-3">
                                Studio classes are separate from gym packages. Class fees are paid directly to the studios and vary based on studio rates and location.
              </p>
              <div>
                <NavLink
                  to={{
                    pathname: '/locations',
                    chosenLocationType: 'studio'
                  }}
                >
                  <button
                    type="button"
                    className="white-btn mt-3 font-weight-bold"
                  >
                    Explore The Studios{' '}
                    <FontAwesomeIcon icon={faChevronRight} />{' '}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export { StudioPackageSection };
