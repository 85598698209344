import React from 'react';
import { EditAddress } from './EditAddress';
import { EditEmail } from './EditEmail';
import { useUserState } from '../../context/UserContext';
import { useGlobalState } from '../../context/GlobalContext';

const EditProfile = () => {
  const [userState, userDispatch] = useUserState();
  const {
    firstName,
    lastName,
    defaultProgram,
    email,
    consumerId,
    isLegacy
  } = userState;
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  return (
    <div className="m-4 rounded-sm shadow d-flex flex-column flex-sm-row flex-grow-1">
      <div className="d-flex flex-column col p-4 bg-white rounded-sm">
        <div className="w-75 w-sm-100 d-flex flex-column">
          <h5>Personal Information</h5>
          <div className="form-group m-0 mx-3">
            <label for="firstName">
              <p className=" h6 text-uppercase font-weight-bold text-secondary m-0">
                First Name
              </p>
            </label>
            <input
              type="text"
              className="form-control"
              id="address1"
              placeholder="Type your address here"
              value={firstName}
              disabled={true}
            />

            <label for="lastName">
              <p className=" h6 text-uppercase font-weight-bold text-secondary m-0">
                Last Name
              </p>
            </label>
            <input
              type="text"
              className="form-control"
              id="address1"
              placeholder="Type your address here"
              value={lastName}
              disabled={true}
            />
          </div>

          {client === 'walmart'
            ? !defaultProgram?.isCancelled && (
                <>
                  <hr className="border border-light w-100" />
                  <EditAddress />
                  <hr className="border border-light w-100" />
                  <EditEmail
                    email={email}
                    userDispatch={userDispatch}
                    consumerId={consumerId}
                    programCode={programCode}
                    client={client}
                  />
                </>
              )
            : !(isLegacy && defaultProgram?.isCancelled) && (
                <>
                  <hr className="border border-light w-100" />
                  <EditAddress />
                  <hr className="border border-light w-100" />
                  <EditEmail
                    email={email}
                    userDispatch={userDispatch}
                    consumerId={consumerId}
                    programCode={programCode}
                    client={client}
                  />
                </>
              )}
        </div>
      </div>
    </div>
  );
};

export { EditProfile };
