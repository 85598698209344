import React, { useState, useRef, useEffect } from 'react';
import { FilterOptionButton } from './';
import classNames from 'classnames';
import { useGlobalState } from '../../../context/GlobalContext';
import { setFilter } from '../../../context/actions/location';

const DistanceFilter = () => {
  const [sliderOpen, setSliderOpen] = useState(false);
  const [filterChosen, setFilterChosen] = useState(false);
  const [checkedDistance, setCheckedDistance] = useState(20);
  const node = useRef();
  const [{ locationState }, dispatch] = useGlobalState();
  const { chosenDistance } = locationState;
  useEffect(() => {
    setCheckedDistance(chosenDistance);
  }, [chosenDistance]);

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setSliderOpen(false);
  };
  useEffect(() => {
    const distanceFilterCached = locationState.chosenDistance;
    if (distanceFilterCached) {
      setFilterChosen(true);
      dispatch(setFilter('chosenDistance', distanceFilterCached));
    }
  }, []);

  useEffect(() => {
    if (sliderOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sliderOpen]);

  const toggleDistanceSlider = () => {
    setSliderOpen(!sliderOpen);
    setCheckedDistance(chosenDistance);
    setFilterChosen(true);
  };

  const applyFilters = () => {
    dispatch(setFilter('chosenDistance', checkedDistance));
    toggleDistanceSlider();
  };

  return (
    <div ref={node} className="dropdown ml-2">
      <FilterOptionButton
        toggleFilter={toggleDistanceSlider}
        buttonText={
          filterChosen ? `Within ${chosenDistance} Miles` : 'Distance'
        }
        filterChosen={filterChosen}
      />
      <div
        className={classNames('dropdown-menu', 'shadow', 'px-4', 'py-4', {
          show: sliderOpen
        })}
      >
        <p
          onClick={() => setCheckedDistance(1)}
          className={classNames('dropdown-item', 'm-0', 'px-0', {
            active: checkedDistance === 1
          })}
        >
          {'< 1 Miles'}
        </p>
        <p
          onClick={() => setCheckedDistance(3)}
          className={classNames('dropdown-item', 'm-0', 'px-0', {
            active: checkedDistance === 3
          })}
        >
          {'< 3 Miles'}
        </p>
        <p
          onClick={() => setCheckedDistance(5)}
          className={classNames('dropdown-item', 'm-0', 'px-0', {
            active: checkedDistance === 5
          })}
        >
          {'< 5 Miles'}
        </p>
        <p
          onClick={() => setCheckedDistance(10)}
          className={classNames('dropdown-item', 'm-0', 'px-0', {
            active: checkedDistance === 10
          })}
        >
          {'< 10 Miles'}
        </p>
        <p
          onClick={() => setCheckedDistance(20)}
          className={classNames('dropdown-item', 'm-0', 'px-0', {
            active: checkedDistance === 20
          })}
        >
          {'< 20 Miles'}
        </p>
        <button
          onClick={() => applyFilters()}
          className="blue-btn mt-2 mb-2 w-100"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export { DistanceFilter };
