// import './styles/global/app.css';
// import './styles/hcsc/app.css';
import './styles/main.scss';
import React, { useEffect, Fragment, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { Header, Footer } from './components';
import {
  Account,
  Location,
  Home,
  Error,
  StaticContent,
  Iframe,
  Logout,
  HubSpot,
  Loading,
  Enrollment,
  Maintenance
} from './pages';
import { MobileHeader } from './components/Global/MobileHeader';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch,
  faAngleDown,
  faArrowLeft,
  faMapMarkerAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { useGlobalState } from './context/GlobalContext';
import { clearState, setIsRefreshingToken } from './context/actions/config';
import { getTiers } from './context/actions/tiers';
import {
  getAccessToken,
  refreshToken,
  getTokenExpiration,
  clearToken,
  setToken
} from './utilities/tokenHelpers';
import { Confirmation } from './components/Enrollment/EnrollmentSteps';
import { logOut, setUserInfo } from './context/actions/user';
import axios from 'axios';
import {
  loadSite,
  loadUser,
  loadWellKnownConfig,
  setCMSString
} from './utilities/getState';
import { subMinutes, isAfter } from 'date-fns';
import { SignUpFW } from './components/Enrollment';
import { useEnrollmentState } from './context/EnrollmentContext';
import { useUserState } from './context/UserContext';
import { initEvents } from '@tivityhealth/events-core';
import 'react-toastify/dist/ReactToastify.css';
import Helmet from 'react-helmet';
import { pauseCallRecording } from './utilities/inContactAPI';
import { LesMills } from './pages/LesMills';
import { Video } from './components/Video/Video';
import { LoadingSpinner } from './components/Global/LoadingSpinner';
import { Router } from 'react-router-dom/cjs/react-router-dom.min';

// createAuthRefreshInterceptor(axios, refreshToken);

library.add(
  faSearch,
  faAngleDown,
  faArrowLeft,
  faMapMarkerAlt,
  faCircle,
  faCheck
);

let App = () => {
  const [{ tierState, configState }, dispatch] = useGlobalState();
  const [userState, userDispatch] = useUserState();
  const [{}, enrollmentDispatch] = useEnrollmentState();
  const [loadingSite, setLoadingSite] = useState(true);

  const { tiers } = tierState;
  const {
    mobileNavbarVisible,
    isFWEligible,
    isFWEnrolled,
    isStudioEnrolled,
    isImpersonated,
    csrUsername,
    isEligibilityCheckFailed,
    isEnrolledInDefaultProgram,
    isPrimary,
    isEnrolledInDigitalTier,
    defaultProgram,
    paymentMethods
  } = userState;
  const {
    com,
    loadingCMS,
    cms,
    isRefreshingToken,
    client,
    inContact
  } = configState;
  const { uiConfig } = com || {};
  const { hasLandingPage } = uiConfig || {};
  const location = useLocation();
  const history = useHistory();
  const isMaintenance = false;
  const currentDate = new Date();
  const lesMillsLastDay = new Date('December 31, 2022 23:00:00 GMT-05:00');

  useEffect(() => {
    const informationWeCollect = document.getElementById(
      window.location.hash.slice(1)
    );
    if (informationWeCollect) {
      const navBarHeight = document.querySelector('nav').offsetHeight;
      const targetElement = document.querySelector(
        `#` + window.location.hash.slice(1)
      );
      const scrollDistance =
        targetElement.getBoundingClientRect().top +
        window.scrollY -
        navBarHeight;
      window.scrollTo({ top: scrollDistance });
    }
  }, [loadingSite]);

  useEffect(() => {
    window.addEventListener('storage', e => {
      const tokenCheck = sessionStorage.getItem('checkToken');
      if (tokenCheck && tokenCheck !== getAccessToken()) {
        window.location.reload();
      }
    });
  }, []);

  initEvents({
    serviceUrl: `/api/${client}/events/v1`,
    tokenSource: async () => getAccessToken()
  });

  axios.interceptors.request.use(async config => {
    const authToken = getAccessToken();
    const expiresAt = new Date(getTokenExpiration());

    if (authToken && expiresAt) {
      try {
        config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
        if (isAfter(new Date(), subMinutes(expiresAt, 5))) {
          if (!isRefreshingToken) {
            dispatch(setIsRefreshingToken(true));
            const token = await refreshToken();
            if (token.status !== 401) {
              setToken(token);
              dispatch(setIsRefreshingToken(false));

              return config;
            } else {
              throw Error;
            }
          }
        }
        return config;
      } catch (error) {
        throw new axios.Cancel('unauthenticated');
      }
    } else {
      if (!location.pathname.includes('/auth')) {
        clearState(userDispatch, enrollmentDispatch);
        if (
          location.pathname !== '/terms-of-use' &&
          location.pathname !== '/privacy-policy' &&
          location.pathname !== '/fitness-program-agreement' &&
          location.pathname !== '/logout' &&
          location.pathname !== '/error'
        ) {
          history.push('/');
        }

        return config;
      }
      return config;
    }
  });

  useEffect(() => {
    // document.documentElement.style.setProperty(
    //   '--primary',
    //   'orange',
    //   'important'
    // );
    if (!window.location.href.includes('iframe')) {
      firstLoadOfSite();
    }
  }, []);

  const firstLoadOfSite = async () => {
    if (location.pathname !== '' && location.pathname.includes('/auth')) {
      clearState(userDispatch, enrollmentDispatch);
      try {
        let { programCode, clientCode, cms, inContact } = await loadSite(
          dispatch
        );
        if (inContact) pauseCallRecording(inContact, false, () => {});
        await loadUser(userDispatch, clientCode, programCode, dispatch);
        setCMSString(dispatch, cms, true);
        await getTiers(clientCode, programCode, dispatch);
        history.push('/');
      } catch (error) {
        setCMSString(dispatch, cms, false);
        if (error.message && error.message == 'unauthenticated') {
          history.push('/logout');
          throw error;
        }
        history.push('/error', { error: 'generic' });
        throw error;
      } finally {
        setLoadingSite(false);
      }
    } else {
      if (!getAccessToken()) {
        if (location.pathname !== '/logout') {
          try {
            clearState(userDispatch, enrollmentDispatch);
            if (!cms) {
              let siteConfig = await loadWellKnownConfig(dispatch);
              if (siteConfig && siteConfig.cms) {
                setCMSString(dispatch, siteConfig.cms, false);
              } else {
                throw Error;
              }
            } else if (cms) {
              setCMSString(dispatch, cms, false);
            } else {
              throw Error;
            }
          } catch (error) {
            if (
              error?.message == 'unauthenticated' ||
              error.response?.status == 401
            ) {
              history.push('/logout');
              throw error;
            }
            history.push('/error', { error: 'generic' });
            throw error;
          } finally {
            setLoadingSite(false);
          }
        } else {
          setLoadingSite(false);
        }
      } else {
        try {
          let siteConfig;
          if (!com || !cms) {
            sessionStorage.setItem('checkToken', getAccessToken());
            siteConfig = await loadSite(dispatch);
          }
          setLoadingSite(true);
          await loadUser(
            userDispatch,
            siteConfig ? siteConfig.clientCode : com.client,
            siteConfig ? siteConfig.programCode : com.programCode,
            dispatch
          );
          setLoadingSite(false);
          setCMSString(dispatch, cms, true);

          if (!tiers) {
            await getTiers(
              siteConfig ? siteConfig.clientCode : com.client,
              siteConfig ? siteConfig.programCode : com.programCode,
              dispatch
            );
          }
        } catch (error) {
          setCMSString(dispatch, cms, false);
          if (
            error?.message == 'unauthenticated' ||
            error.response?.status == 401
          ) {
            history.push('/logout');
            throw error;
          }
          history.push('/error', { error: 'generic' });
          throw error;
        } finally {
          setLoadingSite(false);
        }
      }
    }
  };

  return (
    <>
      {cms && (
        <Helmet>
          <style>{`:root { --primary: ${cms.primary_color}!important; --secondary: ${cms.secondary_color}!important`}</style>
        </Helmet>
      )}
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <>
          {isEligibilityCheckFailed ? (
            <Error isNotEligible />
          ) : (
            <>
              {!!isImpersonated && (
                <h1
                  className="text-danger font-weight-bold position-fixed"
                  style={{ zIndex: 4000, top: '3%' }}
                >
                  Logged in as: {csrUsername}
                </h1>
              )}
              {!loadingSite && !loadingCMS ? (
                /* isImpersonated &&
            !inContact?.isCarenet &&
            (inContact?.baseURI === '' ||
              !inContact?.baseURI ||
              inContact?.sessionId === '' ||
              !inContact?.sessionId ||
              inContact?.contactId === '' ||
              !inContact?.contactId ||
              inContact?.inContactToken === '' ||
              !inContact?.inContactToken) ? (
              <Error type="generic" />
            ) : */
                getAccessToken() ? (
                  <Fragment>
                    {isFWEligible && !isFWEnrolled ? (
                      <Switch>
                        <Route exact path="/">
                          <SignUpFW />
                        </Route>
                        <Route
                          exact
                          path="/(fitness-program-agreement|privacy-policy|terms-of-use|privacy-policy-app)/"
                        >
                          <StaticContent />
                        </Route>
                        <Route exact path="/lesmills">
                          {currentDate < lesMillsLastDay ? (
                            <LesMills />
                          ) : (
                            <Redirect to="/account" />
                          )}
                        </Route>
                        <Route exact path="/lesmills/video">
                          {currentDate < lesMillsLastDay ? (
                            <Video />
                          ) : (
                            <Redirect to="/account" />
                          )}
                        </Route>
                        <Route>
                          <Error />
                        </Route>
                      </Switch>
                    ) : (
                      <>
                        {!(
                          location.pathname === '/logout' ||
                          location.pathname === '/enroll/' ||
                          location.pathname === '/error' ||
                          //remove next two lines, dec 1st
                          location.pathname === '/enroll/select-packages' ||
                          location.pathname === '/locations'
                        ) && <Header />}
                        {/* remove next section after dec 1st */}
                        {(location.pathname === '/enroll/select-packages' ||
                          location.pathname === '/locations') && (
                          <Header banner={true} />
                        )}
                        {location.pathname !== '/error' &&
                          mobileNavbarVisible &&
                          location.pathname !== '/lesmills' && <MobileHeader />}
                        <Switch>
                          <Route exact path="/">
                            {isEnrolledInDefaultProgram ||
                            defaultProgram?.currentSubscription ||
                            isStudioEnrolled ||
                            isFWEnrolled ||
                            isEnrolledInDigitalTier ? (
                              <Redirect to="/account" />
                            ) : !hasLandingPage ? (
                              <Redirect to="/enroll/select-packages" />
                            ) : !isFWEligible ? (
                              <Redirect to="/learn-more" />
                            ) : (
                              isFWEnrolled &&
                              isStudioEnrolled &&
                              isEnrolledInDigitalTier &&
                              !isEnrolledInDefaultProgram && (
                                <Redirect to="/learn-more" />
                              )
                            )}
                          </Route>
                          {hasLandingPage &&
                            isPrimary &&
                            !isEnrolledInDefaultProgram && (
                              <Route exact path="/learn-more">
                                <Home />
                              </Route>
                            )}
                          {(isEnrolledInDefaultProgram ||
                            defaultProgram?.currentSubscription ||
                            isStudioEnrolled ||
                            isEnrolledInDigitalTier ||
                            isFWEnrolled) && (
                            <Route path="/account">
                              <Account />
                            </Route>
                          )}
                          <Route exact path="/locations">
                            <Location />
                          </Route>
                          <Route path="/enroll">
                            {!isEnrolledInDefaultProgram &&
                            !isStudioEnrolled &&
                            !isEnrolledInDigitalTier ? (
                              <Enrollment />
                            ) : (
                              <Redirect
                                to={{
                                  pathname: '/account/manage-plans',
                                  showEditPlan: !(
                                    !paymentMethods ||
                                    paymentMethods.length == 0
                                  )
                                }}
                              />
                            )}
                          </Route>
                          {(isEnrolledInDefaultProgram ||
                            isStudioEnrolled ||
                            isEnrolledInDigitalTier) && (
                            <Route exact path="/confirmation">
                              <Confirmation fillColor={cms.primary_color} />
                            </Route>
                          )}
                          <Route
                            exact
                            path="/(faq|fitness-program-agreement|privacy-policy|terms-of-use|privacy-policy-app)/"
                          >
                            <StaticContent />
                          </Route>
                          <Route exact path="/lesmills">
                            {currentDate < lesMillsLastDay ? (
                              <LesMills />
                            ) : (
                              <Redirect to="/account" />
                            )}
                          </Route>
                          <Route exact path="/lesmills/video">
                            {currentDate < lesMillsLastDay ? (
                              <Video />
                            ) : (
                              <Redirect to="/account" />
                            )}
                          </Route>
                          <Route exact path="/logout">
                            <Logout />
                          </Route>
                          <Route exact path="/error">
                            <Error type="generic" />
                          </Route>
                          <Route>
                            <Error />
                          </Route>
                        </Switch>
                        {location.pathname !== '/logout' &&
                          location.pathname !== '/enroll/' && <Footer />}
                      </>
                    )}
                  </Fragment>
                ) : (
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route
                      exact
                      path="/(fitness-program-agreement|privacy-policy|terms-of-use|privacy-policy-app)/"
                    >
                      <StaticContent />
                    </Route>
                    <Route exact path="/logout">
                      <Logout />
                    </Route>
                    <Route exact path="/error">
                      <Error type="generic" />
                    </Route>
                    <Route>
                      <Error />
                    </Route>
                  </Switch>
                )
              ) : (
                <Route exact path="/">
                  <Loading />
                </Route>
              )}
            </>
          )}
        </>
      )}

      {location.pathname !== '/iframe' && <HubSpot />}
      <Route exact path="/iframe">
        <Iframe />
      </Route>
      {loadingSite && location.pathname === '/lesmills' && (
        <div className="d-flex align-items-center vh-100">
          <LoadingSpinner
            isLoading={loadingSite}
            additionalText={
              currentDate < lesMillsLastDay ? 'Loading Videos' : ''
            }
          />
        </div>
      )}
    </>
  );
};

export default App;
