import {
  SET_LOADING,
  SET_CART_SUMMARY,
  SET_CHOSEN_DEPENDENTS,
  SET_UNDERAGE_DEPENDENTS,
  SET_PRIMARY_SELECTED_PACKAGE,
  SET_CHECKED_DEPENDENTS,
  SET_DISABLE_FOOTER_BUTTON,
  SET_SELECTED_PAYMENT_METHOD,
  SET_POLICY,
  SET_PAYMENT_TOKEN,
  SET_PROMO_CODE,
  SET_ENROLLMENT_DEFAULT,
  SET_ADDRESS_VALIDATION_ERRORS,
  SET_IS_MAILING_SAME_AS_BILLING
} from '../reducers/enrollment';

export const setLoading = (loadingType, isLoading) => ({
  type: SET_LOADING,
  loadingType,
  isLoading
});

export const setPolicy = (policyType, isAccepted) => ({
  type: SET_POLICY,
  policyType,
  isAccepted
});

export const setCartSummary = cartSummary => ({
  type: SET_CART_SUMMARY,
  cartSummary
});

export const setPaymentToken = paymentToken => ({
  type: SET_PAYMENT_TOKEN,
  paymentToken
});

export const setPrimarySelectedPackage = primarySelectedPackage => ({
  type: SET_PRIMARY_SELECTED_PACKAGE,
  primarySelectedPackage
});

export const setChosenDependents = chosenDependents => ({
  type: SET_CHOSEN_DEPENDENTS,
  chosenDependents
});

export const setCheckedDependents = checkedDependents => ({
  type: SET_CHECKED_DEPENDENTS,
  checkedDependents
});

export const setUnderageDependents = underageDependents => ({
  type: SET_UNDERAGE_DEPENDENTS,
  underageDependents
});

export const setDisableFooterButton = disableFooterButton => ({
  type: SET_DISABLE_FOOTER_BUTTON,
  disableFooterButton
});

export const setSelectedPaymentMethod = selectedPaymentMethod => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  selectedPaymentMethod
});

export const setPromoCode = promoCode => ({
  type: SET_PROMO_CODE,
  promoCode
});

export const setEnrollmentDefault = () => ({
  type: SET_ENROLLMENT_DEFAULT
});

export const setAddressValidationErrors = addressValidationErrors => ({
  type: SET_ADDRESS_VALIDATION_ERRORS,
  addressValidationErrors
});

export const setIsMailingSameAsBilling = isMailingSameAsBilling => ({
  type: SET_IS_MAILING_SAME_AS_BILLING,
  isMailingSameAsBilling
});
