import React from 'react';
import { Icon } from '../../assets/Icons';
import { useGlobalState } from '../../context/GlobalContext';
import { useUserState } from '../../context/UserContext';

const HomeGymSection = ({
  setShowLocationSearchModal,
  showLocationSearchModal,
  primaryColor
}) => {
  const [{ tierState }] = useGlobalState();
  const [userState] = useUserState();
  const { homeLocationTier } = tierState;
  const { defaultProgram, isEnrolledInDefaultProgram } = userState;

  return (
    <>
      {isEnrolledInDefaultProgram &&
        homeLocationTier &&
        defaultProgram?.tier == homeLocationTier.number &&
        (!defaultProgram?.currentHomeGym ? (
          <div className="card d-flex align-items-center flex-column p-3 w-50 flex-card">
            <Icon name="home-gym" width="40px" height="40px" fill={primaryColor}/>
            <p className="m-0 font-small text-uppercase font-weight-bold text-secondary">
              Home Gym
              {/* {console.log("help ", JSON.stringify(defaultProgram))} */}
            </p>
            <p
              className="text-center text-secondary font-small my-2"
              style={{ maxWidth: '145px' }}
            >
              You have not set up a home location yet
            </p>
            <button
              onClick={() =>
                setShowLocationSearchModal(!showLocationSearchModal)
              }
              className="btn btn-outline-secondary btn-sm text-nowrap"
            >
              Set Home Location
            </button>
          </div>
        ) : (
          <div
            className="card d-flex align-items-center flex-column p-3 w-50 flex-card"
            style={{ maxWidth: '250px' }}
          >
            <Icon name="home-gym" width="40px" height="40px" fill={primaryColor}/>
            <p className="m-0 mb-2 font-small text-uppercase font-weight-bold text-secondary">
              Home Gym
            </p>
            <p className="font-weight-bold m-0 text-primary">
              {defaultProgram?.currentHomeGym.name}
            </p>
            <p className="m-0 text-center mb-2 w-100">{`${defaultProgram?.currentHomeGym.street}, ${defaultProgram?.currentHomeGym.city}, ${defaultProgram?.currentHomeGym.state} ${defaultProgram?.currentHomeGym.zip}`}</p>
            <button
              onClick={() =>
                setShowLocationSearchModal(!showLocationSearchModal)
              }
              className="btn btn-outline-primary rounded-pill "
            >
              Change
            </button>
          </div>
        ))}
    </>
  );
};

export { HomeGymSection };
