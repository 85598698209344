import React, { useEffect } from 'react';
import { useGlobalState } from '../context/GlobalContext';
import { clearToken } from '../utilities/tokenHelpers';
import { useEnrollmentState } from '../context/EnrollmentContext';
import { setEnrollmentDefault } from '../context/actions/enrollment';
import { Icon } from '../assets/Icons';
import { useUserState } from '../context/UserContext';
import { logOut } from '../context/actions/user';
import { setCMSString } from '../utilities/getState';

const Logout = () => {
  const [{ configState }, dispatch] = useGlobalState();
  const [{}, userDispatch] = useUserState();
  const [{}, enrollmentDispatch] = useEnrollmentState();
  const { com, cms, client } = configState;
  const { uiConfig } = com || {};
  const { hasMobileApp } = uiConfig || {};

  useEffect(() => {
    setCMSString(dispatch, cms, false);
    clearToken();
    enrollmentDispatch(setEnrollmentDefault());
    userDispatch(logOut());
  }, []);

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center flex-column mt-5">
          <Icon name="sign-out" width="80px" height="80px" />

          <h3 className="text-center py-3 font-weight-bold">
            You have been logged out of your account
          </h3>
          {client == 'walmart' && (
            <>
              <p className="text-center font-weight-bold">
                Please click the button below to return to your Walton Life
                Fitness Pass portal
              </p>
              <button
                onClick={() => {
                  window.location =
                    'https://pfedprod.wal-mart.com/idp/startSSO.ping?PartnerSpId=ExtWLFCMFA';
                }}
                className="btn btn-primary px-4 py-3"
              >
                Return to Walton Life Fitness Pass
                <span className="enroll-chevron">
                  <i className="fas fa-chevron-right" />
                </span>
              </button>
            </>
          )}
          {hasMobileApp && (
            <>
              <p className="text-center">
                Remember to download the app, if you haven't done so,
                <span>to take full advantage of the program</span>
              </p>

              <div className="apps d-flex flex-sm-row flex-column justify-content-center align-items-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tivityhealth.prime.wellontarget"
                  className="mr-3"
                >
                  <img
                    src="https://d1okn44hi4vvnd.cloudfront.net//wp-content/uploads/2020/04/google-play-badge.svg"
                    alt="google play store badge"
                  />
                </a>
                <a href="https://apps.apple.com/us/app/id1453242180">
                  <img
                    src="https://d1okn44hi4vvnd.cloudfront.net//wp-content/uploads/2020/04/apple-badge.svg"
                    alt="apple app store badge"
                  />
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { Logout };
