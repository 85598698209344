import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../../context/GlobalContext';
import axios from 'axios';
import { setUserInfo } from '../../context/actions/user';
import { useUserState } from '../../context/UserContext';
import { ModalContainer } from '../Global/Modal';
import { Icon } from '../../assets/Icons';
import { VideoModal } from '../Global/VideoModal';

export const LegacyPrompt = () => {
  const [{ configState }, dispatch] = useGlobalState();
  const [userState] = useUserState();
  const [showModal, setShowModal] = useState(false);
  const { defaultProgram, isLegacy, hasSeenLegacyAgreement } = userState;
  const [isLoading, setIsLoading] = useState(false);
  const [showVideoModal, setVideoModal] = useState(false);
  const {
    strings,
    client,
    com: { programCode },
    cms
  } = configState;

  useEffect(() => {
    if (isLegacy && !hasSeenLegacyAgreement) {
      if (defaultProgram?.isCancelled || defaultProgram?.isCancelling) return;
      setShowModal(true);
    }
  }, []);

  // const submitIsLegacy = async () => {
  //   setShowModal(false);
  //   await axios.put('/api/prime/v2/account/legacy/agreement');
  //   await setUserInfo(programCode, client, dispatch);
  // };

  const submitIsLegacy = async () => {
    setIsLoading(true);
    setShowModal(false);
    try {
      await axios.put('/api/prime/v2/account/legacy/agreement');
      await setUserInfo(programCode, client, dispatch);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalContainer
        isOpen={showModal}
        selfClosing
        singleButton
        submit={submitIsLegacy}
        submitText="Got it!"
        isLoading={isLoading}
      >
        <div className="container text-center p-4 mt-3 d-flex flex-column">
          <img
            src={strings?.path_to_image}
            className="align-self-center d-block mb-3"
            alt="logo"
          />
          <h4 className="text-dark font-weight-bold">
            A new look for a new program
          </h4>
          <p className="w-100 text-center package-locations font-weight-normal mt-1 mb-1">
            Well onTarget has upgraded its fitness program, here is what's new:
          </p>
          <a
            onClick={() => setVideoModal(true)}
            className="w-100 text-center mb-3"
          >
            <Icon
              width="16px"
              name="play-circle"
              height="26px"
              fill={cms.primary_color}
              style={{ marginTop: '-3px' }}
            />{' '}
            <u>See How It Works</u>
          </a>
          <div className="row-fluid mx-3 text-left">
            <div className="package-locations p-2">
              <div className="check">
                <i className="fas fa-check"></i>
              </div>
              <p className="package-total-locations">
                Flexibility to choose your gym package and price:
              </p>
              <p className="font-italic">
                BASE - $19, CORE - $29, POWER - $39, and ELITE - $129.
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: strings.legacy_members_pop_up_content
                }}
              />
            </div>
          </div>
        </div>
      </ModalContainer>
      <VideoModal isOpen={showVideoModal} setIsOpen={setVideoModal} />
    </>
  );
};
