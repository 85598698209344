import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { EmptyList } from './EmptyList';
import { LoadingSpinner } from '../Global/LoadingSpinner';
import { ActivityList } from './ActivityLog/ActivityList';
import { getActivities } from '../../api/activity/GetActivities';
import { useGlobalState } from '../../context/GlobalContext';
import { transformActivityData } from '../../utilities';

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [loadingActivityLog, setLoadingActivityLog] = useState(true);
  const [
    {
      configState: { client }
    }
  ] = useGlobalState();

  useEffect(() => {
    getAccountPageActivities();
  }, []);

  const getAccountPageActivities = async () => {
    try {
      setLoadingActivityLog(true);
      const activityList = await getActivities(client);
      const transformedActivities = await transformActivityData(activityList);
      setActivities(transformedActivities);
    } catch (error) {
      console.log(error);
      setActivities([]);
      throw error;
    } finally {
      setLoadingActivityLog(false);
    }
  };

  return (
    <div className="mt-4 d-flex flex-column">
      <div className="d-flex align-items-baseline">
        <h5>My Activities</h5>
        <div className="d-lg-flex d-none">
          <NavLink to="/account/activity" className="ml-5">
            View Activity Log
          </NavLink>
        </div>
      </div>
      {loadingActivityLog ? (
        <LoadingSpinner isLoading={loadingActivityLog} />
      ) : activities && activities.length > 0 ? (
        <>
          <span className="border-bottom w-100 my-2" />
          <ActivityList selectedActivities={activities} currentPageNum={1} />
          <div className="d-sm-flex mt-4 d-md-none">
            <NavLink
              to="/account/activity"
              className="btn btn-outline-primary mt-3 w-100"
            >
              View Activity Log
            </NavLink>
          </div>
        </>
      ) : (
        <EmptyList emptyActivityLog />
      )}
    </div>
  );
};

export { Activities };
