import React from 'react';
import { Icon } from '../../../assets/Icons';
import moment from 'moment';
import { useUserState } from '../../../context/UserContext';

const UpdateHomeGym = ({ location, fillColor }) => {
  const [userState] = useUserState();
  const {
    defaultProgram: { currentHomeGym }
  } = userState;
  const getNextMonthDate = () =>
    moment()
      .startOf('month')
      .add(1, 'month')
      .format('dddd, MMMM D, YYYY');

  return (
    <>
      <div className="card bg-light border-0">
        {currentHomeGym && (
          <div className="card-body text-center">
            <p className="font-weight-bold m-0">{currentHomeGym.name}</p>
            <p className="text-secondary font-small m-0">
              {`${currentHomeGym.street}, ${currentHomeGym.city}, ${currentHomeGym.state}, ${currentHomeGym.zip}`}
            </p>
          </div>
        )}
      </div>
      <div className="text-center my-2 p-0 w-100">
        <Icon
          name="down"
          width="20px"
          style={{ transform: 'rotate(-90deg)' }}
        />
      </div>
      <div className="card">
        <div className="card-body text-center">
          <Icon name="home-gym" width="40px" height="40px" fill={fillColor}/>
          <p className="font-weight-bold m-0">{location.name}</p>
          <p className="text-secondary font-small m-0 mb-2">
            {`${location.street}, ${
              location.address2 ? location.address2 + ', ' : ''
            }${location.city}, ${location.state}, ${location.zip}`}
          </p>
          <p className="text-secondary font-small font-weight-bold m-0">
            Access Date
          </p>
          <p className="font-small m-0 mb-2">{getNextMonthDate()}</p>
        </div>
      </div>
    </>
  );
};

export { UpdateHomeGym };
