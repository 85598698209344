import React, { useEffect, useState } from 'react';
import { BillingHistoryItem } from '.';
import { getBillingHistory } from '../../api/account';
import { EmptyList } from './EmptyList';
import { setLoading } from '../../context/actions/user';
import { useUserState } from '../../context/UserContext';
import { LoadingSpinner } from '../Global/LoadingSpinner';
import { useGlobalState } from '../../context/GlobalContext';

const BillingHistoryList = () => {
  const [userState, userDispatch] = useUserState();
  const { accountId, loadingBillingHistory } = userState;
  const [billingHistory, setBillingHistory] = useState(null);
  const [
    {
      configState: { client }
    }
  ] = useGlobalState();

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    userDispatch(setLoading('loadingBillingHistory', true));
    try {
      const history = await getBillingHistory(client, accountId);
      await setBillingHistory(history);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      userDispatch(setLoading('loadingBillingHistory', false));
    }
  };

  return (
    <div className="order-2 order-lg-1 w-100 mr-lg-4">
      {loadingBillingHistory ? (
        <LoadingSpinner isLoading={loadingBillingHistory} />
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between align-items-baseline">
            <h5>Billing & Discounts</h5>
          </div>
          <div className="d-flex flex-column">
            {billingHistory &&
              (billingHistory.length ? (
                <>
                  <span className="d-none d-sm-block border-bottom w-100 mb-2" />
                  <div className="d-none d-sm-flex mb-2 text-center">
                    <p className="p-0 col-2 h6 font-weight-bold text-uppercase text-secondary m-0">
                      Date
                    </p>
                    <p className="col-4 h6 p-0 font-weight-bold text-uppercase text-secondary m-0">
                      Description
                    </p>
                    <p className="col-2 h6 p-0 font-weight-bold text-uppercase text-secondary m-0">
                      Status
                    </p>
                    <p className="col-2 h6 p-0 font-weight-bold text-uppercase text-secondary m-0">
                      Amount
                    </p>
                    <p className="col-2 h6 p-0 font-weight-bold text-uppercase text-secondary m-0">
                      Invoice doc (PDF)
                    </p>
                  </div>
                  {billingHistory.map(history => (
                    <BillingHistoryItem history={history} />
                  ))}
                </>
              ) : (
                <EmptyList billingHistoryList />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export { BillingHistoryList };
