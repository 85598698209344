import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../../context/GlobalContext';
import { NavLink } from 'react-router-dom';
import { useUserState } from '../../context/UserContext';

const FitnessPackage = () => {
  const [{ tierState }] = useGlobalState();
  const [userState] = useUserState();
  const {
    defaultProgram,
    isEnrolledInDefaultProgram,
    isFWEnrolled,
    isStudioEnrolled,
    isPrimary,
    isEnrolledInDigitalTier
  } = userState;
  const [currentTier, setCurrentTier] = useState(null);

  useEffect(() => {
    if (isEnrolledInDefaultProgram) {
      let currentUserTier;
      if (!currentTier)
        currentUserTier =
          defaultProgram?.tier &&
          tierState.tiers.find(item => item.tier == defaultProgram?.tier);
      setCurrentTier(currentUserTier);
    }
  }, []);

  return (
    <>
      <div className="card d-flex p-3 mr-2 w-50 mb-2 flex-card">
        <div className="mb-2">
          <p className="m-0 mb-2 h6 font-weight-bold text-secondary">
            PACKAGES
          </p>
        </div>
        {isEnrolledInDefaultProgram && currentTier && (
          <p className="m-0 font-weight-bold font-small tier_description">
            {currentTier.name.toUpperCase()} Fitness Package
          </p>
        )}
        {isEnrolledInDigitalTier && (
          <p className="m-0 font-weight-bold font-small tier_description">
            Digital Content
          </p>
        )}
        {isStudioEnrolled && (
          <p className="m-0 font-weight-bold font-small tier_description">
            Studio Class Loyalty Program
          </p>
        )}
        {isFWEnrolled && (
          <p className="m-0 font-weight-bold font-small tier_description">
            Fitness Works Program
          </p>
        )}
        {isPrimary &&
          (isEnrolledInDefaultProgram ||
            isStudioEnrolled ||
            isEnrolledInDigitalTier) && (
            <NavLink
              className="align-self-center mt-4"
              to="/account/manage-plans"
            >
              <button className="btn btn-outline-primary rounded-pill">
                Manage Plan
              </button>
            </NavLink>
          )}
      </div>
    </>
  );
};

export { FitnessPackage };
