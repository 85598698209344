const daysDifference = termEndDate => {
  if (!termEndDate) {
    return { renderBanner: false, bannerText: null };
  }
  if (termEndDate === '9999-12-31T00:00:00') {
    return { renderBanner: false, bannerText: null };
  }
  //term end date = "2019/11/01"
  let date1 = new Date(Date.now());
  let date2 = new Date(termEndDate);
  if (date1 >= date2) {
    return { renderBanner: false, bannerText: null };
  }
  const differenceInTime = date2.getTime() - date1.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  const { hours, minutes, seconds } = endOfDayDifference();
  const returnObj = {
    renderBanner: true,
    bannerText: `${differenceInDays} days ${hours} hours ${minutes} minutes ${seconds} seconds`
  };
  return returnObj;
};

const endOfDayDifference = () => {
  let dateFuture = new Date(new Date().getFullYear() + 1, 0, 1);
  let dateNow = new Date();

  let seconds = Math.floor((dateFuture - dateNow) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  return { hours, minutes, seconds };
};

export { daysDifference };
