import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Plans, MembershipCard, Activities } from '..';
import { useUserState } from '../../../context/UserContext';
import { useGlobalState } from '../../../context/GlobalContext';
import { Helmet } from 'react-helmet';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Icon } from '../../../assets/Icons';

const AccountOverview = ({
  setShowLocationSearchModal,
  showLocationSearchModal
}) => {
  const [userState] = useUserState();
  const [{ configState }] = useGlobalState();
  const {
    isFWEnrolled,
    isFWEligible,
    isEnrolledInDefaultProgram,
    isEnrolledInDigitalTier,
    defaultProgram,
    digitalProgram
  } = userState;
  const { client, cms } = configState;
  const currentDate = new Date();
  const lesMillsLastDay = new Date('December 31, 2022 23:00:00 GMT-05:00');

  return (
    <>
      {
        <div className="my-3 mx-4 d-flex p-4 bg-white rounded-sm shadow flex-grow-1 flex-column">
          <h5 className="mb-1 d-lg-flex d-none">Account Overview</h5>
          {isEnrolledInDigitalTier && !digitalProgram?.isSuspended && (
            <>
              <p className="d-lg-flex d-none">
                You currently have access to the following digital/fitness
                content package(s).
              </p>
              <Helmet>
                <script
                  type="text/javascript"
                  src={configState.classesWidgetUrl}
                />
              </Helmet>
              {client && currentDate < lesMillsLastDay && (
                <div id="tivityClassesWidget" data-client={client}>
                  <style type="text/css">{`
                      .styles-module--button--16yHE, 
                      .styles-module--button--16yHE:active, 
                      .styles-module--button--16yHE:focus, 
                      .styles-module--button--16yHE:hover{
                        background-color: ${cms.primary_color};
                      }
                  `}</style>
                </div>
              )}
              {client && currentDate > lesMillsLastDay && (
                <div id="tivityClassesWidget" data-client={client}>
                  <style type="text/css">{`
                  .styles-module--button--16yHE, 
                  .styles-module--button--16yHE:active, 
                  .styles-module--button--16yHE:focus, 
                  .styles-module--button--16yHE:hover{
                    background-color: ${cms.primary_color};
                  }
                  .styles-module--card--lQy8I:first-child{
                    display: none !important;
                }
              `}</style>
                </div>
              )}
            </>
          )}
          <div className="d-flex flex-row">
            {((isFWEligible && isFWEnrolled) || isEnrolledInDefaultProgram) && (
              <MembershipCard />
            )}
            <Plans
              setShowLocationSearchModal={setShowLocationSearchModal}
              showLocationSearchModal={showLocationSearchModal}
            />
          </div>
          <Activities />
        </div>
      }
    </>
  );
};

export { AccountOverview };
