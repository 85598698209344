import React, { useState } from 'react';

const InputButton = ({
  buttonText = 'Submit',
  placeholderText = '',
  width = '100',
  submit = () => {}
}) => {
  const [inputText, setInputText] = useState('');

  const doSubmit = async () => {
    if (inputText === '') return;
    try {
      await submit(inputText);
      setInputText('');
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <div className={`input-group mb-3 w-${width}`}>
      <input
        value={inputText}
        type="text"
        className="form-control"
        placeholder={placeholderText}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        onChange={e => setInputText(e.target.value)}
      />
      <div className="input-group-append">
        <button className="btn btn-primary" type="button" onClick={doSubmit}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export { InputButton };
