import React, { useState } from 'react';
import { Icon } from '../../assets/Icons';
import { getPhoneNumber } from '../../utilities/getPhoneNumber';
import { ManagePaymentMethodModal } from '../Account/ManagePaymentMethods/ManagePaymentMethodModal';

const CreditCardExpiredAlertMessage = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  return (
    <>
      <div className="my-lg-3 mx-4 p-3 d-flex flex-column rounded danger-alert border shadow">
        <div className="d-flex flex-row">
          <span className="">
            <Icon name="exclamation-circle" width="19px" height="17px" />
          </span>
          <p
            className="font-weight-bold text-lg ml-1 m-0"
            style={{ color: '#a02020' }}
          >
            Notice
          </p>
        </div>
        <p className="font-small m-0">
          It appears something went wrong with your monthly payment and it could
          not get processed. Please verify the information on your payment
          method is correct to avoid interruption of your service. If all
          information is correct, please call us to look further into this
          issue:{' '}
          <a href={`tel:${getPhoneNumber().number}`}>{getPhoneNumber().text}</a>
        </p>
        <button
          onClick={() => setShowPaymentModal(true)}
          className="btn align-self-end text-primary text-underline-primary"
        >
          Update Payment Method
        </button>
      </div>
      <ManagePaymentMethodModal
        setIsManagePaymentMethodModalOpen={setShowPaymentModal}
        isManagePaymentMethodModalOpen={showPaymentModal}
      />
    </>
  );
};

export { CreditCardExpiredAlertMessage };
