import React, { useEffect, useState } from 'react';

const ProgressBar = ({
  title,
  subtitle,
  dynamicTextArray,
  textThatChanges
}) => {
  let [count, setCount] = useState(25);
  let [text, setText] = useState(0);

  useEffect(() => {
    let id = setInterval(() => {
      setCount(count + 25);
      setText(text + 1);
    }, 10000);

    if (count === 100) {
      clearInterval(id);
    }

    return () => clearInterval(id);
  });

  return (
    <React.Fragment>
      <h2 className="h2">{title}</h2>
      <p className="mb-4">{subtitle}</p>
      <img
        src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2020/04/map-luxury.svg"
        alt="loading"
      />
      <p className="mt-2">
        {(textThatChanges = dynamicTextArray[text % dynamicTextArray.length])}
      </p>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${count}%` }}
        />
      </div>
    </React.Fragment>
  );
};

export { ProgressBar };
