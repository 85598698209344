import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FilterOptionButton } from '.';
import { setFilter } from '../../../context/actions/location';
import { useGlobalState } from '../../../context/GlobalContext';

const GenderSpecificFilter = () => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [checkedGender, setCheckedGender] = useState('');
  const [{ locationState }, dispatch] = useGlobalState();
  const { genders, chosenGender } = locationState;

  useEffect(() => {
    setCheckedGender(chosenGender ? chosenGender : '');
  }, [chosenGender]);

  const node = useRef();

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setDropDownOpen(false);
  };

  const chooseGenderFilter = genderChosen => {
    if (checkedGender === genderChosen) {
      setCheckedGender('');
      return;
    }
    setCheckedGender(genderChosen);
  };

  useEffect(() => {
    if (dropDownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownOpen]);

  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
    setCheckedGender(chosenGender);
  };

  const applyFilters = () => {
    dispatch(setFilter('chosenGender', checkedGender));
    toggleDropDown();
  };

  return (
    <div ref={node} className="dropdown ml-2">
      <FilterOptionButton
        toggleFilter={toggleDropDown}
        buttonText={
          !chosenGender
            ? 'Gender Specific'
            : `${chosenGender === "Women's" ? 'Women' : chosenGender} Only`
        }
      />
      <div
        className={classNames('dropdown-menu', 'shadow', 'px-4', {
          show: dropDownOpen
        })}
      >
        {genders.map((gender, i) => (
          <p
            key={i}
            onClick={() => chooseGenderFilter(gender)}
            className={classNames('dropdown-item', 'm-0', 'px-0', 'd-block', {
              active: checkedGender === gender
            })}
          >
            {`${gender === "Women's" ? 'Women' : gender} Only`}
          </p>
        ))}
        <button
          onClick={() => applyFilters()}
          className="blue-btn mt-2 mb-2 w-100"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export { GenderSpecificFilter };
