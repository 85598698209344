import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { enrollmentReducer } from './reducers/enrollment';
import { initialEnrollmentState } from './state/initialEnrollmentState';
import Localbase from 'localbase';

let db = new Localbase('enrollment');

export const EnrollmentContext = createContext();

// const enrollmentState = db
//   .collection('enrollmentState')
//   .get()
//   .then(data => data)
//   .catch(error => error);

const enrollmentState = JSON.parse(localStorage.getItem('enrollmentState'));

export const EnrollmentProvider = ({ children }) => {
  const enrollment = useReducer(
    enrollmentReducer,
    enrollmentState || initialEnrollmentState
  );

  useEffect(() => {
    // db.collection('enrollmentState').set([enrollment[0]]);
    localStorage.setItem(
      'enrollmentState',
      JSON.stringify({
        ...enrollment[0]
      })
    );
  }, [enrollment[0]]);

  return (
    <EnrollmentContext.Provider value={enrollment}>
      {children}
    </EnrollmentContext.Provider>
  );
};

export const useEnrollmentState = () => useContext(EnrollmentContext);
