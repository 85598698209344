import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { FilterOptionButton } from './';
import { useGlobalState } from '../../../context/GlobalContext';
import { setFilter } from '../../../context/actions/location';

const StudioFilter = () => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [checkedStudioType, setCheckedStudioType] = useState('');
  const node = useRef();
  const [{ locationState }, dispatch] = useGlobalState();
  const { studioTypes, chosenStudioType } = locationState;

  useEffect(() => {
    setCheckedStudioType(chosenStudioType);
  }, [chosenStudioType]);

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setDropDownOpen(false);
  };

  useEffect(() => {
    if (dropDownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownOpen]);

  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
    setCheckedStudioType(chosenStudioType);
  };

  const chooseStudioType = studioType => {
    if (checkedStudioType === studioType) {
      setCheckedStudioType('');
      return;
    }
    setCheckedStudioType(studioType);
  };

  const applyFilters = () => {
    dispatch(setFilter('chosenStudioType', checkedStudioType));
    toggleDropDown();
  };

  return (
    <div ref={node} className="dropdown ml-2">
      <FilterOptionButton
        toggleFilter={toggleDropDown}
        buttonText={!chosenStudioType ? 'Studios' : chosenStudioType}
      />
      <div
        className={classNames('dropdown-menu', 'shadow', 'px-4', {
          show: dropDownOpen
        })}
      >
        <p className="text-secondary h6 py-2">
          Studio types of result locations
        </p>
        <div className="studio-list-scroll">
          {studioTypes.map((studioType, i) => (
            <p
              key={i}
              onClick={() => chooseStudioType(studioType)}
              className={classNames('dropdown-item', 'm-0', 'px-0', {
                active: checkedStudioType === studioType
              })}
            >
              {studioType}
            </p>
          ))}
        </div>
        <button
          onClick={() => applyFilters()}
          className="blue-btn mt-2 mb-2 w-100"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export { StudioFilter };
