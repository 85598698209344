import React, { useEffect, useState } from 'react';
import { setPromoCode } from '../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import { PromoCodes } from './PromoCodes';
import { PromoCodePill } from './PromoCodePill';
import { Icon } from '../../../assets/Icons';
import moment from 'moment';
import { useUserState } from '../../../context/UserContext';
import { mapCartSummaryDetails } from '../../../utilities/getOrderInformation';
import { useGlobalState } from '../../../context/GlobalContext';

const CartSummarySection = ({
  isEditingPlan,
  getOrderPreview,
  details,
  promoCodeErrors,
  dueNow
}) => {
  const [userState] = useUserState();
  const [{ configState }] = useGlobalState();
  const { cms } = configState;
  const [enrollmentState, dispatch] = useEnrollmentState();
  const [accordionState, setAccordionState] = useState({
    showPromoCodes: false,
    showPackages: true,
    showDiscounts: false
  });
  const [summaryDetails, setSummaryDetails] = useState(null);

  const { shippingAddress } = userState;
  const { promoCode } = enrollmentState;

  useEffect(() => {
    let cartSummaryDetails = {};
    if (dueNow) {
      cartSummaryDetails = {
        paymentDetails:
          details?.immediatePaymentSummary.immediatePaymentDetails,
        totalPaymentRecurringFee:
          details?.immediatePaymentSummary.immediateTotalPaymentRecurringFee,
        totalPaymentSetUpFee:
          details?.immediatePaymentSummary.immediateTotalPaymentSetUpFee,
        creditAmountWithTax:
          details?.immediatePaymentSummary.creditAmountWithTax,
        paymentDiscountAmountWithoutTax:
          details?.immediatePaymentSummary
            .immediatePaymentDiscountAmountWithoutTax,
        paymentDiscountTaxAmount:
          details?.immediatePaymentSummary.immediatePaymentDiscountTaxAmount,
        detailedPromoCodeDescription:
          details?.immediatePaymentSummary.detailedPromoCodeDescription,
        totalPaymentAfterDiscountTaxAmount:
          details?.immediatePaymentSummary
            .immediateTotalPaymenAfterDiscountTaxAmount,
        totalPaymentAfterDiscountAmountWithTax:
          details?.immediatePaymentSummary
            .immediateTotalPaymentAfterDiscountAmountWithTax,
        totalMonthlyPaymentAfterDiscountAmountWithTax:
          details?.monthlyPaymentSummary
            .monthlyTotalPaymentAfterDiscountAmountWithTax
      };
    } else {
      cartSummaryDetails = {
        paymentDetails: details?.monthlyPaymentDetails,
        totalPaymentRecurringFee: details?.monthlyTotalPaymentRecurringFee,
        totalPaymentSetUpFee: details?.monthlyTotalPaymentSetUpFee,
        creditAmountWithTax: details?.creditAmountWithTax,
        paymentDiscountAmountWithoutTax:
          details?.monthlyPaymentDiscountAmountWithoutTax,
        paymentDiscountTaxAmount: details?.monthlyPaymentDiscountTaxAmount,
        detailedPromoCodeDescription: details?.detailedPromoCodeDescription,
        totalPaymentAfterDiscountTaxAmount:
          details?.monthlyTotalPaymentAfterDiscountTaxAmount,
        totalPaymentAfterDiscountAmountWithTax:
          details?.monthlyTotalPaymentAfterDiscountAmountWithTax
      };
    }
    setSummaryDetails(cartSummaryDetails);

    return () => setSummaryDetails(null);
  }, []);

  const removePromoCode = promotion => {
    let updatedPromoCode = null;
    if (!promotion.promoCodeUsed) {
      let promoError = promoCode.indexOf(promotion.description);
      if (promoError) {
        updatedPromoCode = promoCode.filter(
          code => code.toUpperCase() !== promoCode[promoError].toUpperCase()
        );
      }
    } else {
      updatedPromoCode = promoCode.filter(
        code => code.toUpperCase() !== promotion.promoCodeUsed.toUpperCase()
      );
    }
    if (updatedPromoCode) {
      getOrderPreview({ promoCode: updatedPromoCode });
      dispatch(setPromoCode(updatedPromoCode));
    }
  };

  return (
    <>
      {summaryDetails && (
        <>
          {details && (
            <>
              <div className="border border-light p-4">
                <h4 className="font-weight-bold text-secondary">
                  {isEditingPlan
                    ? dueNow
                      ? 'DUE NOW'
                      : 'New Plan (per changes)'
                    : 'SUMMARY'}
                </h4>
                <div className="ml-2 w-100">
                  <div className="mb-3">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        setAccordionState({
                          ...accordionState,
                          showPackages: !accordionState.showPackages
                        })
                      }
                    >
                      <p className="m-0 font-weight-bold text-primary">
                        Package Selection(s) Total{' '}
                        <Icon
                          name="chevron"
                          width="15px"
                          height="15px"
                          className="fill-primary ml-1"
                          fill={cms.primary_color}
                          style={{
                            transform: !accordionState.showPackages
                              ? 'rotate(90deg)'
                              : 'rotate(270deg)'
                          }}
                        ></Icon>{' '}
                      </p>
                      <p className="m-0 ml-1">{`$${summaryDetails?.totalPaymentRecurringFee &&
                        summaryDetails?.totalPaymentRecurringFee.toFixed(
                          2
                        )}`}</p>
                    </div>
                    <div
                      style={{
                        display: !accordionState.showPackages ? 'none' : 'block'
                      }}
                    >
                      {mapCartSummaryDetails(summaryDetails?.paymentDetails)}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <p className="m-0 font-weight-bold text-primary">
                        Enrollment Fees
                      </p>
                      <p className="m-0">
                        {!!summaryDetails?.totalPaymentSetUpFee
                          ? `$${summaryDetails?.totalPaymentSetUpFee.toFixed(
                              2
                            )}`
                          : '$0.00'}
                      </p>
                    </div>
                  </div>
                  {isEditingPlan && (
                    <>
                      {!!summaryDetails?.creditAmountWithTax && (
                        <div className="d-flex justify-content-between">
                          <p className="m-0 font-weight-bold text-primary">
                            Total Credits
                          </p>
                          <p>
                            <p className="m-0">
                              {`-$${summaryDetails?.creditAmountWithTax
                                .toFixed(2)
                                .toString()
                                .replace('-', '')}`}
                            </p>
                          </p>
                        </div>
                      )}
                    </>
                  )}

                  {!!summaryDetails?.paymentDiscountAmountWithoutTax && (
                    <>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <p
                            className="m-0 font-weight-bold text-primary"
                            onClick={() =>
                              setAccordionState({
                                ...accordionState,
                                showPromoCodes: !accordionState.showPromoCodes
                              })
                            }
                          >
                            Discount(s) Total{' '}
                            <Icon
                              name="chevron"
                              width="15px"
                              height="15px"
                              className="fill-primary ml-1"
                              fill={cms.primary_color}
                              style={{
                                transform: !accordionState.showPromoCodes
                                  ? 'rotate(90deg)'
                                  : 'rotate(270deg)'
                              }}
                            ></Icon>
                          </p>
                          <p className="m-0">{`-$${(
                            summaryDetails?.paymentDiscountAmountWithoutTax +
                            summaryDetails?.paymentDiscountTaxAmount
                          )
                            .toFixed(2)
                            .toString()
                            .replace('-', '')}`}</p>
                        </div>
                        <div
                          className="m-0 mt-2 ml-2"
                          style={{
                            display: !accordionState.showPromoCodes
                              ? 'none'
                              : 'block'
                          }}
                        >
                          {summaryDetails?.detailedPromoCodeDescription
                            ?.length > 0 &&
                            summaryDetails?.detailedPromoCodeDescription.map(
                              (code, index) => {
                                if (code.isSystemApplied) {
                                  return (
                                    <p className="m-0">{code.description}</p>
                                  );
                                } else {
                                  return (
                                    <PromoCodePill
                                      promoCode={code}
                                      index={index}
                                      error={false}
                                      removePromoCode={removePromoCode}
                                    />
                                  );
                                }
                              }
                            )}
                        </div>
                      </div>
                    </>
                  )}
                  {promoCodeErrors?.length > 0 &&
                    promoCodeErrors.map(promoCode => (
                      <PromoCodePill
                        promoCode={{ description: promoCode }}
                        error
                        removePromoCode={removePromoCode}
                      />
                    ))}
                  {dueNow && <PromoCodes getOrderPreview={getOrderPreview} />}
                  <div className="d-flex justify-content-between">
                    <p className="m-0">Taxes</p>
                    <p className="m-0">
                      {summaryDetails?.totalPaymentAfterDiscountTaxAmount !==
                        0 ||
                      (shippingAddress && shippingAddress.zip !== '')
                        ? '$' +
                          summaryDetails?.totalPaymentAfterDiscountTaxAmount.toFixed(
                            2
                          )
                        : 'Determined at Billing'}
                    </p>
                  </div>
                  <div className="my-2 d-flex justify-content-between">
                    <p className="m-0 font-weight-bold">
                      {dueNow ? 'TOTAL DUE NOW' : 'NEW MONTHLY TOTAL'}
                    </p>
                    <p className="m-0 font-weight-bold">
                      {summaryDetails?.totalPaymentAfterDiscountAmountWithTax &&
                        `$${Number(
                          summaryDetails?.totalPaymentAfterDiscountAmountWithTax
                        ).toFixed(2)}`}
                    </p>
                  </div>
                  {isEditingPlan && !dueNow && (
                    <div className="d-flex justify-content-between">
                      <i className="m-0">
                        Next due date:
                        {moment(
                          summaryDetails?.paymentDetails &&
                            summaryDetails?.paymentDetails[0].serviceStartDate
                        ).format('MMMM D')}
                      </i>
                    </div>
                  )}
                </div>
              </div>
              {!isEditingPlan && (
                <div className="border border-light p-4">
                  <div className="ml-2 d-flex justify-content-between">
                    <div className="m-0 font-weight-bold">
                      MONTHLY TOTAL
                      <div className="font-italic font-weight-normal">
                        {`Starting ${moment(
                          summaryDetails?.paymentDetails &&
                            summaryDetails?.paymentDetails[0].serviceStartDate
                        ).format('MMMM D')}`}
                      </div>
                    </div>
                    <div className="m-0 font-weight-bold">
                      {summaryDetails?.totalPaymentAfterDiscountAmountWithTax &&
                        `$${Number(
                          summaryDetails?.totalMonthlyPaymentAfterDiscountAmountWithTax
                        ).toFixed(2)}`}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { CartSummarySection };
