import axios from 'axios';
import {
  setClient,
  setAuthUrl,
  setCom,
  getCMS,
  setIdpClientId,
  setClassesWidgetUrl,
  setAnalytics,
  setStrings,
  setInContact
} from '../context/actions/config';
import { getCurrentUserLocation } from '../context/actions/location';
import { setUserInfo } from '../context/actions/user';
import { setToken } from './tokenHelpers';

const mapUIConfig = config => {
  if (config.length === 0) return;

  let mappedConfigs = {};

  config.map(item => {
    mappedConfigs[item.name] =
      item.value === '1' || item.value === '0'
        ? item.value === '0'
          ? false
          : true
        : item.value;
  });

  return mappedConfigs;
};

const getState = async dispatch => {
  try {
    const state = await axios.get('/api/prime/v2/state');
    await setToken(state.data.data.authToken);
    sessionStorage.setItem('checkToken', state.data.data.authToken.accessToken);
    const authUrl = state.data.data.idpUrl.replace('/oauth', '');
    const idpClientId = state.data.data.idpClientId;
    const inContact = state.data.data.inContact;
    const classesWidgetUrl = state.data.data.classesWidgetUrl;
    dispatch(setAuthUrl(authUrl));
    dispatch(setIdpClientId(idpClientId));
    dispatch(setClassesWidgetUrl(classesWidgetUrl));
    dispatch(setInContact(inContact));
    const configurations = state.data.data.clientConfiguration;
    const userFields = configurations.userInputFieldConfigs;
    const uiConfig = configurations.userInterfaceConfigurations
      ? mapUIConfig(configurations.userInterfaceConfigurations)
      : null;
    const productConfigurations = configurations.productConfigurations.find(
      item => item.isDefault
    );

    dispatch(
      setCom({
        entitlements: configurations.entitlements,
        statesAllowingSelfCancellation: configurations?.memberCancellations[0]?.valueList,
        ...productConfigurations,
        userFields,
        uiConfig
      })
    );
    return {
      programCode: productConfigurations.programCode,
      inContact
    };
  } catch (error) {
    throw error;
  }
};

const setCMSString = (dispatch, cms, authenticated) => {
  let strings = authenticated
    ? cms.authenticated_user_fields
    : cms.unauthenticated_user_fields;
  dispatch(setStrings(strings));
};

const loadUser = async (userDispatch, clientCode, programCode, dispatch) => {
  try {
    await getCurrentUserLocation(dispatch);
    await setUserInfo(programCode, clientCode, userDispatch);
  } catch (error) {
    throw error;
  }
};

const loadSite = async dispatch => {
  try {
    const wellKnownConfig = await loadWellKnownConfig(dispatch);
    if (wellKnownConfig) {
      const { clientCode, cms } = wellKnownConfig;
      const state = await getState(dispatch);
      return {
        programCode: state.programCode,
        clientCode,
        cms,
        inContact: state.inContact
      };
    }
  } catch (error) {
    throw error;
  }
};

const loadWellKnownConfig = async dispatch => {
  try {
    const wellKnownConfig = await axios.get('/.well-known/site-config/');
    const {
      clientCode,
      config: { cmsUrl, googleAnalyticsEnabled, googleAnalyticsKey }
    } = wellKnownConfig.data;
    dispatch(setClient(clientCode));
    dispatch(setAnalytics({ googleAnalyticsEnabled, googleAnalyticsKey }));
    let { cms } = await getCMS(cmsUrl, clientCode, dispatch);
    return { clientCode, cms };
  } catch (error) {
    throw error;
  }
};

export { loadSite, loadUser, setCMSString, loadWellKnownConfig };
