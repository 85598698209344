import React, { useRef, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ActivityListItem } from './ActivityListItem';

const ActivityList = ({ selectedActivities, currentPageNum }) => {
  return (
    <div className="d-flex flex-column">
      <span className="border-bottom w-100 my-2" />

      <div className="d-md-flex d-none justify-content-between p-1">
        <p className="text-uppercase font-weight-bold text-secondary h6 p-0 ml-5 col">
          Location
        </p>
        <p className="text-uppercase font-weight-bold text-secondary h6 p-0  col">
          Class
        </p>
        <p className="text-uppercase font-weight-bold text-secondary h6 p-0  col">
          Date
        </p>
        <p className="text-uppercase font-weight-bold text-secondary h6 p-0  col">
          Time
        </p>
        <p className="text-uppercase font-weight-bold text-secondary h6 p-0  col">
          Type
        </p>
        <span style={{ width: 40 }} />
      </div>
      {selectedActivities
        .slice(10 * (currentPageNum - 1), 10 * currentPageNum)
        .map(activity => (
          <ActivityListItem activity={activity} key={activity?.id} />
        ))}
    </div>
  );
};
export { ActivityList };
