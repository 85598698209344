import React from 'react';
import { Icon } from '../../../assets/Icons';

const SetHomeGym = ({ location, fillColor }) => (
  <>
    <div className="card mb-3 d-block">
      <div className="card-body text-center">
        <Icon name="home-gym" width="40px" height="40px" fill={fillColor} />
        <p className="font-weight-bold m-0">{location.name}</p>
        <p className="text-secondary font-small m-0">
          {`${location.street}, ${
            location.address2 ? location.address2 + ', ' : ''
          }${location.city}, ${location.state}, ${location.zip}`}
        </p>
      </div>
    </div>
    <p className="m-0">
      You're about to select the above location as your home location.
      Confirming this selection means you'll be able to begin accessing this
      Elite location effective immediately. You can also continue to enjoy
      access to all other locations in the Power, Core, and Base. If you'd like
      to access another Elite location, you'll need to update this selection in
      your profile and access will be granted to your new location the 1st of
      the following month.
    </p>
  </>
);

export { SetHomeGym };
