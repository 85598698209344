import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useGlobalState } from '../../../context/GlobalContext';
import { chooseTier } from '../../../context/actions/tiers';

const TierList = () => {
  const [{ tierState }, dispatch] = useGlobalState();
  const { tiers, chosenTier } = tierState;
  return (
    <div className="btn-group btn-group-toggle w-100">
      {tiers.map((tier, i) => {
        return (
          <label
            key={i}
            className={classNames('btn', 'btn-primary-locations', 'p-0', {
              active: chosenTier && chosenTier.tier == tier.tier
            })}
          >
            <input
              type="radio"
              name="options"
              autoComplete="off"
              checked={chosenTier && chosenTier.tier == tier.tier}
              onChange={() => dispatch(chooseTier(tier))}
            />
            <p className="m-0">{tier.name.toUpperCase()}</p>
          </label>
        );
      })}
    </div>
  );
};

TierList.propTypes = {
  chosenTier: PropTypes.object,
  chooseTier: PropTypes.func,
  tiers: PropTypes.array
};

export { TierList };
