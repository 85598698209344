import React, { useState, useEffect } from 'react';
import { MapFilters, Map } from '.';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { haversineDistance } from '../../utilities';
import { getLocationCount } from '../../../src/utilities/getNewLocations';
import { NavLink } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalContext';
import { getCurrentMapLocation } from '../../context/actions/location';
import { useUserState } from '../../context/UserContext';
import { Icon } from '../../assets/Icons';

const MapSection = ({
  showStudioFilter,
  hoveredLocation,
  showAmenitiesFilter,
  showGenderSpecificFilter,
  showClearFilter,
  mapHeight,
  isModal,
  studioLimitReached,
  getLocations
}) => {
  const [locationCount, setLocationCount] = useState(0);
  const [loadingLocationCount, setLoadingLocationCount] = useState(false);
  const [
    {
      locationState,
      tierState,
      configState: {
        client,
        com: { programCode }
      }
    },
    dispatch
  ] = useGlobalState();
  const [userState] = useUserState();
  const { chosenTier, loadingTiers } = tierState;
  const {
    currentMapLocation,
    loadingLocations,
    mapData,
    chosenLocationType
  } = locationState;

  const {
    isEnrolledInDefaultProgram,
    defaultProgram,
    isPrimary,
    isStudioEnrolled,
    paymentMethods,
    nextPaymentInfo
  } = userState;

  useEffect(() => {
    getLocationCount(programCode, client)
      .then(count => setLocationCount(count))
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    setLoadingLocationCount(true);
    if (chosenTier) {
      getLocationCount(programCode, client, chosenTier.tier)
        .then(count => {
          setLocationCount(count);
          setLoadingLocationCount(false);
        })
        .catch(error => {
          console.log(error);
          setLoadingLocationCount(false);
        });
    }
  }, [chosenTier]);

  const redoSearch = () => {
    dispatch(getCurrentMapLocation(mapData.center));
  };

  return (
    <div
      className={classNames(
        'location-search__map flex-fill p-0 d-none d-lg-block',
        { 'col-lg-7': isModal, 'col-xl-7': isModal }
      )}
    >
      {showClearFilter ||
      showGenderSpecificFilter ||
      showAmenitiesFilter ||
      showStudioFilter ? (
        <MapFilters
          showClearFilter={showClearFilter}
          distanceFilter
          genderSpecificFilter={showGenderSpecificFilter}
          amenitiesFilter={showAmenitiesFilter}
          studioFilter={showStudioFilter}
        />
      ) : null}
      {mapData &&
        !loadingLocations &&
        currentMapLocation &&
        haversineDistance(
          [mapData.center.lat, mapData.center.lng],
          [currentMapLocation.lat, currentMapLocation.lng],
          true
        ) > 30 && (
          <div
            style={{ zIndex: 2 }}
            className={classNames(
              { 'w-75': !isModal, 'w-100': isModal },
              'px-3 py-3 d-flex position-absolute justify-content-center'
            )}
          >
            <button
              style={{ boxShadow: '0 1px 5px #999' }}
              onClick={() => redoSearch()}
              className="btn btn-primary"
            >
              Search This Area
            </button>
          </div>
        )}

      {studioLimitReached ? (
        <div
          className="d-flex text-center flex-column align-items-center justify-content-center"
          style={{ height: mapHeight ? mapHeight : 'calc(100% - 70px)' }}
        >
          <Icon name="studio-search-error" width="318.514" height="229.798" />
          <p className="m-0 my-2 font-weight-bold">
            Sorry, we encountered an error trying to locate nearby fitness
            studios. Please try again.
          </p>
          <button
            onClick={() => getLocations()}
            className="d-flex align-items-center btn bg-none border-0 text-primary"
          >
            <Icon name="re-do" width="12px" height="12px" />
            Try again
          </button>
        </div>
      ) : (
        <>
          {!isModal &&
            !loadingTiers &&
            chosenLocationType &&
            chosenLocationType !== 'studio' &&
            chosenTier && (
              <div
                className="card d-flex flex-column align-items-center justify-content-between p-2 z-5 position-absolute map-fields__bottom"
                style={{ marginTop: '3.5rem', width: '220px' }}
              >
                <div className="package-name-locations">
                  <div className="text-uppercase white font-weight-bold">
                    {chosenTier.name}
                  </div>
                </div>
                <div className="row">
                  <div className="align-items-center d-flex flex-column col location-count">
                    <p className="m-0 h4 font-weight-normal just-location-number">
                      {loadingLocationCount ? 'Loading...' : locationCount}
                    </p>
                    <p className="m-0 text-uppercase text-secondary h6">
                      {chosenLocationType}
                      {'S '}
                    </p>
                    <p className="m-0 text-uppercase text-secondary h6">
                      {' '}
                      Nationwide
                    </p>
                    {isPrimary &&
                     (nextPaymentInfo &&
                     !nextPaymentInfo.isBalanceOverdue) &&
                      (isEnrolledInDefaultProgram ? (
                        !defaultProgram?.isCancelled ? (
                          defaultProgram?.tier < chosenTier.tier && (
                            <NavLink
                              to={{
                                pathname: '/account/manage-plans',
                                showEditPlan:
                                  !paymentMethods ||
                                  paymentMethods.length !== 0,
                                selectedTier: chosenTier
                              }}
                            >
                              <button
                                type="button"
                                className="my-2 font-weight-bold btn btn-primary"
                              >
                                Upgrade Package
                              </button>
                            </NavLink>
                          )
                        ) : (
                          <NavLink
                            to={{
                              pathname: '/account/manage-plans',
                              showEditPlan:
                                !paymentMethods || paymentMethods.length !== 0,
                              selectedTier: chosenTier
                            }}
                          >
                            <button
                              type="button"
                              className="my-2 font-weight-bold btn btn-primary"
                            >
                              Select Package
                            </button>
                          </NavLink>
                        )
                      ) : (
                        <NavLink
                          to={{
                            pathname:
                              isStudioEnrolled ||
                              defaultProgram?.currentSubscription
                                ? '/account/manage-plans'
                                : '/enroll/select-packages',
                            showEditPlan:
                              isStudioEnrolled &&
                              (!paymentMethods || paymentMethods.length !== 0),
                            selectedTier: chosenTier
                          }}
                        >
                          <button
                            type="button"
                            className="my-2 font-weight-bold btn btn-primary"
                          >
                            Select Package
                          </button>
                        </NavLink>
                      ))}
                  </div>
                </div>
              </div>
            )}
          <Map
            hoveredLocation={hoveredLocation}
            height={mapHeight ? mapHeight : 'calc(100% - 70px)'}
            showName
          />
        </>
      )}
    </div>
  );
};

MapSection.propTypes = {
  currentMapLocation: PropTypes.object,
  handleAfterDistanceChange: PropTypes.func,
  gymList: PropTypes.array,
  showStudioFilter: PropTypes.bool
};

export { MapSection };
