import React from 'react';
import { useGlobalState } from '../context/GlobalContext';
import { Helmet } from 'react-helmet';

const HubSpot = () => {
  const [
    {
      configState: 
        { googleAnalyticsEnabled,
          googleAnalyticsKey
        }
    }
  ] = useGlobalState();


  return (
    <>
    {googleAnalyticsEnabled === "true" && (
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`}></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${googleAnalyticsKey}");
        `}
      </script>
      </Helmet>
    )}
    </>
  );
};

export { HubSpot };
