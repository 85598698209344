import { apiWrapper } from './ApiWrapper';
import axios from 'axios';
import { getOrderInformation } from '../utilities/getOrderInformation';
import { setLoading } from '../context/actions/enrollment';
const CancelToken = axios.CancelToken;
let cancel;

const getPaymentMethods = async () => {
  return apiWrapper('/api/prime/v2/enrollment/payment-types', '', 'GET')
    .then(data => data.data.paymentTypes)
    .catch(error => error);
};

const getIframe = async (pageId, headers) => {
  return axios({
    url: `/api/prime/v1/enrollment/payment-types/${pageId}`,
    method: 'get',
    headers: { ...(headers && headers) }
  })
    .then(data => {
      return data.data.data.selectedPaymentType;
    })
    .catch(error => error);
};

const processOrder = async (
  isDependentAccessLinked,
  programCode,
  dispatch,
  userData,
  enrollmentData,
  submitOrder,
  isEditingPlan,
  paymentToken = ''
) => {
  if (cancel !== undefined) {
    cancel();
  }

  const url = `/api/prime/${
    isEditingPlan
      ? `v2/account/${
          submitOrder
            ? 'submitupdatesubscriptions'
            : 'previewupdatesubscriptions'
        }`
      : `${
          !submitOrder ? 'v2/enrollment/order/preview' : 'v2/enrollment/order'
        }`
  }`;

  const requestBody = getOrderInformation(
    isDependentAccessLinked,
    programCode,
    userData,
    enrollmentData,
    isEditingPlan,
    paymentToken
  );

  try {
    const orderResponse = await axios.post(url, requestBody, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    });
    if (orderResponse.status === 200) {
      return orderResponse.data.data;
    } else {
      throw new Error(orderResponse);
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      return;
    }
    throw error;
  }
};

const setDefaultPaymentMethod = async (client, accountId, paymentId) => {
  return axios
    .put(`/api/${client}/commerce/v2/accounts/${accountId}`, {
      defaultPaymentMethodId: paymentId
    })
    .then(data => {
      return;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const updatePaymentMethod = async (paymentMethod, updatedFields) => {
  return axios
    .put(
      `/api/prime/v2/account/updatepaymentmethod/${paymentMethod.id}`,
      updatedFields
    )
    .then(data => {
      return;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const deletePaymentMethod = async paymentMethod => {
  return axios
    .delete(`/api/prime/v2/account/deletepaymentmethod/${paymentMethod}`)
    .then(data => {
      return;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

export const reinstatePrimary = async (userState, user) => {
  const dependent = !user.isPrimary
    ? userState.enrolledDependents.find(
        dep => dep.consumerId === user.consumerId
      )
    : null;
  try {
    await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
      loggedInAccountNumber: userState.accountNumber,
      members: [
        ...(dependent
          ? [
              {
                accountNumber: dependent.subscriptionAccount.accountNumber,
                consumerId: dependent.consumerId,
                isPrimary: false,
                orderActions: [
                  {
                    type: 'SubscriptionNew',
                    ratePlanChargeId:
                      dependent?.defaultProgram?.currentSubscription
                        .ratePlanChargeId,
                    subscriptionNumber:
                      dependent?.defaultProgram?.currentSubscription
                        .subscriptionNumber
                  }
                ]
              }
            ]
          : []),
        {
          accountNumber: userState.accountNumber,
          consumerId: userState.consumerId,
          isPrimary: true,
          orderActions: [
            ...(!dependent
              ? [
                  {
                    ratePlanChargeId:
                      user?.defaultProgram?.currentSubscription
                        .ratePlanChargeId,
                    subscriptionNumber:
                      user?.defaultProgram?.currentSubscription
                        .subscriptionNumber,
                    type: 'SubscriptionNew'
                  }
                ]
              : [
                  {
                    type: 'NoAction'
                  }
                ])
          ]
        }
      ]
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const reinstateDigitalPrimary = async (userState, user) => {
  try {
    const dependent = !user.isPrimary
      ? userState.enrolledDependents.find(
          dep => dep.consumerId === user.consumerId
        )
      : null;
    if (
      dependent
        ? !dependent.isEnrolledInDigitalTier
        : !userState.isEnrolledInDigitalTier
    )
      throw Error;
    await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
      loggedInAccountNumber: userState.accountNumber,
      members: [
        ...(dependent
          ? [
              {
                accountNumber: dependent.subscriptionAccount.accountNumber,
                consumerId: dependent.consumerId,
                isPrimary: false,
                orderActions: [
                  {
                    type: 'SubscriptionNew',
                    ratePlanChargeId:
                      dependent?.digitalProgram?.currentSubscription
                        .ratePlanChargeId,
                    subscriptionNumber:
                      dependent?.digitalProgram?.currentSubscription
                        .subscriptionNumber
                  }
                ]
              }
            ]
          : []),
        {
          accountNumber: userState.accountNumber,
          consumerId: userState.consumerId,
          isPrimary: true,
          orderActions: [
            ...(!dependent
              ? [
                  {
                    type: 'SubscriptionNew',
                    ratePlanChargeId:
                      userState?.digitalProgram?.currentSubscription
                        .ratePlanChargeId,
                    subscriptionNumber:
                      userState?.digitalProgram?.currentSubscription
                        .subscriptionNumber
                  }
                ]
              : [
                  {
                    type: 'NoAction'
                  }
                ])
          ]
        }
      ]
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const cancelSubscription = async (userState, dependent) => {
  if (dependent.isPrimary) {
    try {
      await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
        loggedInAccountNumber: userState.accountNumber,
        members: [
          {
            accountNumber: userState.accountNumber,
            consumerId: userState.consumerId,
            isPrimary: true,
            orderActions: [
              {
                subscriptionNumber:
                  userState?.defaultProgram?.isActive ||
                  userState?.defaultProgram?.isTransitioning
                    ? userState?.defaultProgram?.futureSubscription
                      ? userState?.defaultProgram?.futureSubscription
                          .subscriptionNumber
                      : userState?.defaultProgram?.currentSubscription &&
                        userState?.defaultProgram?.currentSubscription
                          .subscriptionNumber
                    : userState?.defaultProgram?.isSuspended
                    ? userState?.defaultProgram?.currentSubscription
                        .subscriptionNumber
                    : userState?.defaultProgram?.isSuspending
                    ? userState?.defaultProgram?.currentSubscription
                        .subscriptionNumber
                    : userState?.digitalProgram?.isSuspended ||
                      userState?.digitalProgram?.isSuspending
                    ? userState?.digitalProgram?.currentSubscription
                        .subscriptionNumber
                    : userState?.digitalProgram?.isActive &&
                      userState?.digitalProgram?.currentSubscription
                        .subscriptionNumber,

                type: 'Cancel',
                keepDigital: true
              }
            ]
          }
        ]
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  } else {
    const deletedDependent = userState.enrolledDependents.find(
      dep => dep.consumerId === dependent.consumerId
    );
    try {
      await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
        loggedInAccountNumber: userState.accountNumber,
        members: [
          ...(deletedDependent && [
            {
              accountNumber: deletedDependent.subscriptionAccount.accountNumber,
              consumerId: deletedDependent.consumerId,
              orderActions: [
                {
                  subscriptionNumber:
                    deletedDependent?.defaultProgram?.isActive ||
                    deletedDependent?.defaultProgram?.isTransitioning
                      ? deletedDependent?.defaultProgram?.futureSubscription
                        ? deletedDependent?.defaultProgram?.futureSubscription
                            .subscriptionNumber
                        : deletedDependent?.defaultProgram
                            ?.currentSubscription &&
                          deletedDependent?.defaultProgram?.currentSubscription
                            .subscriptionNumber
                      : deletedDependent?.defaultProgram?.isSuspended
                      ? deletedDependent?.defaultProgram?.currentSubscription
                          .subscriptionNumber
                      : deletedDependent?.defaultProgram?.isSuspending
                      ? deletedDependent?.defaultProgram?.currentSubscription
                          .subscriptionNumber
                      : deletedDependent?.digitalProgram?.isSuspended ||
                        deletedDependent?.digitalProgram?.isSuspending
                      ? deletedDependent?.digitalProgram?.currentSubscription
                          .subscriptionNumber
                      : deletedDependent?.digitalProgram?.isActive &&
                        deletedDependent?.digitalProgram?.currentSubscription
                          .subscriptionNumber,
                  type: 'Cancel', 
                  keepDigital: true,
                }
              ]
            }
          ]),
          {
            accountNumber: userState.accountNumber,
            consumerId: userState.consumerId,
            isPrimary: true,
            orderActions: [
              {
                type: 'NoAction'
              }
            ]
          }
        ]
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};
const fullCancelSubscription = async (userState, dependent) => {
  if (dependent.isPrimary) {
    try {
      await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
        loggedInAccountNumber: userState.accountNumber,
        members: [
          {
            accountNumber: userState.accountNumber,
            consumerId: userState.consumerId,
            isPrimary: true,
            orderActions: [
              {
                subscriptionNumber:
                  userState?.defaultProgram?.isActive ||
                  userState?.defaultProgram?.isTransitioning
                    ? userState?.defaultProgram?.futureSubscription
                      ? userState?.defaultProgram?.futureSubscription
                          .subscriptionNumber
                      : userState?.defaultProgram?.currentSubscription &&
                        userState?.defaultProgram?.currentSubscription
                          .subscriptionNumber
                    : userState?.defaultProgram?.isSuspended
                    ? userState?.defaultProgram?.currentSubscription
                        .subscriptionNumber
                    : userState?.defaultProgram?.isSuspending
                    ? userState?.defaultProgram?.currentSubscription
                        .subscriptionNumber
                    : userState?.digitalProgram?.isSuspended ||
                      userState?.digitalProgram?.isSuspending
                    ? userState?.digitalProgram?.currentSubscription
                        .subscriptionNumber
                    : userState?.digitalProgram?.isActive &&
                      userState?.digitalProgram?.currentSubscription
                        .subscriptionNumber,

                type: 'Cancel',
                keepDigital: false
              }
            ]
          }
        ]
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  } else {
    const deletedDependent = userState.enrolledDependents.find(
      dep => dep.consumerId === dependent.consumerId
    );
    try {
      await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
        loggedInAccountNumber: userState.accountNumber,
        members: [
          ...(deletedDependent && [
            {
              accountNumber: deletedDependent.subscriptionAccount.accountNumber,
              consumerId: deletedDependent.consumerId,
              orderActions: [
                {
                  subscriptionNumber:
                    deletedDependent?.defaultProgram?.isActive ||
                    deletedDependent?.defaultProgram?.isTransitioning
                      ? deletedDependent?.defaultProgram?.futureSubscription
                        ? deletedDependent?.defaultProgram?.futureSubscription
                            .subscriptionNumber
                        : deletedDependent?.defaultProgram
                            ?.currentSubscription &&
                          deletedDependent?.defaultProgram?.currentSubscription
                            .subscriptionNumber
                      : deletedDependent?.defaultProgram?.isSuspended
                      ? deletedDependent?.defaultProgram?.currentSubscription
                          .subscriptionNumber
                      : deletedDependent?.defaultProgram?.isSuspending
                      ? deletedDependent?.defaultProgram?.currentSubscription
                          .subscriptionNumber
                      : deletedDependent?.digitalProgram?.isSuspended ||
                        deletedDependent?.digitalProgram?.isSuspending
                      ? deletedDependent?.digitalProgram?.currentSubscription
                          .subscriptionNumber
                      : deletedDependent?.digitalProgram?.isActive &&
                        deletedDependent?.digitalProgram?.currentSubscription
                          .subscriptionNumber,
                  type: 'Cancel', 
                  keepDigital: false,
                }
              ]
            }
          ]),
          {
            accountNumber: userState.accountNumber,
            consumerId: userState.consumerId,
            isPrimary: true,
            orderActions: [
              {
                type: 'NoAction'
              }
            ]
          }
        ]
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};

const setAutoPayMethod = async (client, accountId) => {
  await axios.put(`/api/${client}/commerce/v2/accounts/${accountId}/auto-pay`, {
    autoPay: 'true'
  });
};

export {
  setDefaultPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  getIframe,
  getPaymentMethods,
  processOrder,
  cancelSubscription,
  fullCancelSubscription,
  setAutoPayMethod
};
