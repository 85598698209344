import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../../context/GlobalContext';
import { useUserState } from '../../context/UserContext';
import { Icon } from '../../assets/Icons';
import { LoadingScreen } from '../Global';
import axios from 'axios';
import { toastNotification } from '../../utilities/toastMessage';
import { setUserInfo } from '../../context/actions/user';
import { ManagePlanPaymentModal } from './ManagePlanPaymentModal';
import { ManagePaymentMethodModal } from './ManagePaymentMethods/ManagePaymentMethodModal';
import { EditAddressModal } from './EditAddressModal';
import Background from '../../assets/images/studio_upsell.png';
import { ModalContainer } from '../Global/Modal';
import { StudioInformationModal } from './StudioInformationModal';

const StudioPackage = () => {
  const [
    {
      tierState,
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();
  const [userState, userDispatch] = useUserState();
  const { defaultProgram, isEnrolledInDefaultProgram } = userState;
  const [currentTier, setCurrentTier] = useState(null);
  const [isStudioModalOpen, setIsStudioModalOpen] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [showManagePlanPaymentModal, setShowManagePlanPaymentModal] = useState(
    false
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);

  useEffect(() => {
    if (isEnrolledInDefaultProgram) {
      let currentUserTier;
      if (!currentTier)
        currentUserTier =
          defaultProgram?.tier &&
          tierState.tiers.find(item => item.tier == defaultProgram?.tier);
      setCurrentTier(currentUserTier);
    }
  }, []);

  const enrollInStudio = async () => {
    try {
      await axios.post('/api/prime/v2/enrollment/order', {
        loggedInAccountNumber: userState.accountNumber,
        members: [
          {
            accountNumber: userState.accountNumber,
            consumerId: userState.consumerId,
            isPrimary: true,
            orderActions: [
              {
                type: 'SubscriptionNewStudio',
                ratePlanChargeId: null
              }
            ]
          }
        ]
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const doStudioEnrollment = async () => {
    setIsEnrolling(true);
    try {
      await enrollInStudio();
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(`You have successfully enrolled in studios.`, true);
    } catch (error) {
      toastNotification(
        `Your enrollment in studios cannot be completed.`,
        false
      );
    } finally {
      setIsStudioModalOpen(false);
      setIsEnrolling(false);
    }
  };

  return (
    <>
      <div
        className="card d-flex p-3 mr-2 w-50 mb-2 text-center align-items-center flex-card"
        style={{
          minWidth: '253px',
          backgroundImage: `linear-gradient(0deg, #2070A0b3, #2070A0b3), url(${Background})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
          backgroundPosition: 'center',
          height: 'auto !important'
        }}
      >
        <h2 className="m-0 mb-4 font-weight-bold text-white">
          Introducing Studio Class Rewards
        </h2>
        <button
          className="d-flex btn bg-0 font-weight-normal font-italic p-0 align-items-center text-white my-2"
          onClick={() => setIsStudioModalOpen(true)}
        >
          <u>
            <Icon
              className="mr-1 mb-1"
              name="info-circle"
              width="13px"
              height="13px"
            />
            How does it work?
          </u>
        </button>
        <button
          onClick={() => setShowManagePlanPaymentModal(true)}
          className="btn border border-white rounded-pill text-white"
        >
          Add to Plan Free of Charge
        </button>
      </div>
      <StudioInformationModal
        setIsStudioModalOpen={setIsStudioModalOpen}
        isStudioModalOpen={isStudioModalOpen}
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
      />
      <ManagePlanPaymentModal
        showManagePlanPaymentModal={showManagePlanPaymentModal}
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
        setShowPaymentModal={setShowPaymentModal}
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
        submit={doStudioEnrollment}
        title={`Checkout - $0.00`}
      />
      <ManagePaymentMethodModal
        setIsManagePaymentMethodModalOpen={setShowPaymentModal}
        isManagePaymentMethodModalOpen={showPaymentModal}
      />
      <EditAddressModal
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
      />
      <LoadingScreen isLoading={isEnrolling} />
    </>
  );
};

export { StudioPackage };
