import React, { useState, useEffect } from 'react';
import { downloadCard, printCard } from '../../api/account';
import { getImageURL } from '../../utilities/imageUrl';
import classNames from 'classnames';
import { useUserState } from '../../context/UserContext';

const MembershipCard = () => {
  const [userState] = useUserState();
  const { consumerId } = userState;
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    async function fetchImageUrl() {
      setImageUrl(await getImageURL(consumerId));
    }
    fetchImageUrl();
  }, []);
  return (
    <React.Fragment>
      <div className="d-lg-flex d-none w-50" style={{ maxHeight: 350 }}>
        <div className="col p-0 mt-4">
          <div className="col-md-12 w-100">
            <h5 className="mb-1">Gym Membership Card</h5>
            <p className="m-0 mb-2">
              Show this card at the front desk of any gym in your fitness
              package(s) network.
            </p>
            <div className="w-50">
              <img
                src={imageUrl}
                alt="Membership Card"
                className="w-50"
                style={{ minWidth: '275px', maxWidth: '350px' }}
              />
              <div
                className="d-flex w-100 mt-2"
                style={{ minWidth: '275px', maxWidth: '350px' }}
              >
                <button
                  onClick={() => printCard(consumerId)}
                  className={classNames('btn w-50 mr-1', {
                    'btn-outline-primary': consumerId,
                    'btn-secondary': !consumerId
                  })}
                  disabled={!consumerId}
                >
                  Print
                </button>
                <button
                  onClick={() => downloadCard(consumerId)}
                  className={classNames('btn w-50', {
                    'btn-outline-primary': consumerId,
                    'btn-secondary': !consumerId
                  })}
                  disabled={!consumerId}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-sm-flex d-none" />
    </React.Fragment>
  );
};

export { MembershipCard };
