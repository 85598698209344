import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { useGlobalState } from '../../context/GlobalContext';
import { validateZipCode } from '../../utilities/validateZipCode';
import axios from 'axios';
import { Input, Select, LoadingScreen } from '../Global';
import { checkIfAddressValid } from '../../utilities/checkIfAddressValid';
import { setUserInfo } from '../../context/actions/user';
import { useEnrollmentState } from '../../context/EnrollmentContext';
import { setAddressValidationErrors } from '../../context/actions/enrollment';
import { AddressInput } from '../Global/AddressForm/AddressInput';
import { useHistory, Redirect } from 'react-router-dom';
import { useUserState } from '../../context/UserContext';
import { validateEmail } from '../../utilities/validateEmail';

let SignUpFW = ({
  values,
  handleBlur,
  handleChange,
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  isValid,
  setErrors,
  setValues
}) => {
  const [
    { address1, address2, city, state, zip, personId },
    userDispatch
  ] = useUserState();
  const [address, setAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: ''
  });
  const [{ configState }] = useGlobalState();
  const [addressError, setAddressError] = useState({});
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [emailValue, setEmailValue] = useState('');

  const {
    strings: { states },
    client,
    com: { programCode }
  } = configState;

  useEffect(() => {
    if (
      values.zip &&
      !errors['zip'] &&
      values.zip.length === 9 &&
      values.zip.split('-').length === 1
    ) {
      setAddress({
        ...values,
        zip: values.zip.slice(0, 5) + '-' + values.zip.slice(5)
      });
    }
  }, [values, errors]);

  const submitFitnessWorks = async e => {
    e.preventDefault();
    if (Object.getOwnPropertyNames(errors).length === 0) {
      const response = await checkIfAddressValid(values);

      if (typeof response === 'object') {
        setAddressError({ ...response });
      } else if (response === true) {
        setAddressError({});
        setErrors({});
        setSubmittingOrder(true);
        try {
          await axios.post('/api/prime/v2/enrollment/registerhpsprimemember', {
            ...{ ...values, email: values.email.trim() },
            acceptedTerms: true
          });
          await setUserInfo(programCode, client, userDispatch);
          history.push('/');
        } catch (error) {
          console.log(error);
          setError(true);
          throw error;
        } finally {
          setSubmittingOrder(false);
        }
      } else {
        setAddressError({
          invalid: { shippingAddress: 'Your address is invalid' }
        });
      }
    }
  };

  const returnStates = () => {
    let stateOptions = [];
    Object.entries(JSON.parse(states)).map(([key, value]) => {
      stateOptions = [...stateOptions, { state: key }];
    });
    return stateOptions;
  };

  return (
    <>
      {error ? (
        <Redirect
          to={{ pathname: '/error', state: { error: 'fwEnrollmentFailed' } }}
        />
      ) : (
        <div
          className="bg-light d-flex flex-column align-items-center justify-content-center"
          style={{ height: '100vh' }}
        >
          <img
            src={configState.strings.path_to_image}
            className="mt-5 p-4"
            alt="logo"
          />
          <div
            className="bg-white rounded-sm shadow p-4 m-3 pb-5"
            style={{ maxWidth: '500px' }}
          >
            <form onSubmit={e => submitFitnessWorks(e)}>
              {addressError &&
                addressError['invalid'] &&
                addressError['invalid']['shippingAddress'] && (
                  <p style={{ color: 'red' }} className="text-center">
                    {addressError['invalid']['shippingAddress']}
                  </p>
                )}
              <h3 className="h3 font-weight-bold">
                Welcome Fitness Works Member!
              </h3>
              <p>
                We found that you have access to use this program to track
                visits to your employers onsite fitness location at no charge to
                you.
              </p>
              <p>
                Before we take you to the rest of the program including the
                Location Finder, please complete the information below, agree to
                the policy & terms of services to begin tracking those visits.
              </p>
              <AddressInput
                type={'text'}
                title={'Address'}
                name={'address1'}
                value={values.address1}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                addressType="shippingAddress"
                setValues={setValues}
              />
              <Input
                type={'text'}
                title={'Apt, Unit, ETC.'}
                name={'address2'}
                value={values.address2}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                addressType="shippingAddress"
                optional
              />

              <div className="d-flex w-100">
                <Input
                  type={'text'}
                  title={'City'}
                  name={'city'}
                  value={values.city}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  addressType="shippingAddress"
                  errors={errors}
                  touched={touched}
                />
                <Select
                  type={'select'}
                  title={'State'}
                  name={'state'}
                  value={values.state}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  addressType="shippingAddress"
                  options={returnStates()}
                  labelField="state"
                  // options={[
                  //   { accountType: 'Saving' },
                  //   { accountType: 'Checking' },
                  //   { accountType: 'Business Checking' }
                  // ]}
                />
                <Input
                  type={'text'}
                  title={'Zip Code'}
                  name={'zip'}
                  value={values.zip}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="w-100">
                <Input
                  type={'email'}
                  title={'Email'}
                  name={'email'}
                  value={values.email}
                  handleBlur={handleBlur}
                  addressType="shippingAddress"
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div>
                <p className="my-4">
                  By providing your email address and/or any other personal
                  information, as defined under applicable law, you acknowledge
                  that you are agreeing to our use of your information as
                  provided in our <a href="/terms-of-use">Terms of Use</a> and{' '}
                  {''}
                  <a href="/privacy-policy">Privacy Policy.</a>
                  {/* <span className="text-danger">*</span> */}
                </p>
              </div>
              <button
                className="btn btn-primary mt-3 w-50 center-btn"
                type="submit"
                disabled={submittingOrder || !isValid}
              >
                Submit
              </button>
            </form>
          </div>
          <LoadingScreen isLoading={submittingOrder} />
        </div>
      )}{' '}
    </>
  );
};

SignUpFW = withFormik({
  validate: values => {
    let errors = {};
    if (!values.address1) errors['address1'] = 'Address is required';
    if (!values.city) errors['city'] = 'City is required';
    if (!values.zip) errors['zip'] = 'Zipcode is required';
    if (!validateEmail(values.email))
      errors['email'] =
        'The email you have entered is not a valid email address';
    if (!values.email) errors['email'] = 'Email is required';
    if (values.state === 'Select State' || !values.state)
      errors['state'] = 'State is Required';
    if (values.zip && !validateZipCode(values.zip)) {
      errors['zip'] = 'Zipcode is not valid';
    }
    // if (!values.acceptedTerms) {
    //   errors['acceptedTerms'] = 'Terms and Policy needs to be accepted';
    // }
    return errors;
  },
  handleSubmit: async (props, { props: { userDispatch } }) => {
    return props;
  }
})(SignUpFW);

export { SignUpFW };
