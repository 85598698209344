import React from 'react';
import { EditAddress } from './EditAddress';
import { ModalContainer } from '../Global/Modal';

const EditAddressModal = ({
  setIsEditAddressModalOpen,
  isEditAddressModalOpen
}) => {
  return (
    <ModalContainer
      setIsOpen={setIsEditAddressModalOpen}
      title="Change Shipping Address"
      isOpen={isEditAddressModalOpen}
      submit={() => setIsEditAddressModalOpen(false)}
      submitText="Close"
      singleButton
    >
      <EditAddress />
    </ModalContainer>
  );
};

export { EditAddressModal };
