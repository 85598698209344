import React from 'react';
import classNames from 'classnames';
import { validateEmail } from '../../utilities/validateEmail';
import axios from 'axios';
import { withFormik } from 'formik';
import { Input } from '../Global';
import { setUserInfo } from '../../context/actions/user';
import { toastNotification } from '../../utilities/toastMessage';
import { LoadingSpinner } from '../Global/LoadingSpinner';

let EditEmail = ({
  values,
  errors,
  setErrors,
  touched,
  handleChange,
  handleBlur,
  email,
  status,
  handleSubmit
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <h5>Change Email Address</h5>
      <p>
        We use this email address to notify you about changes to your account.
      </p>
      {status && status === 'pending' ? (
        <LoadingSpinner additionalText="Saving your changes" />
      ) : (
        <>
          <div className="form-group m-0 col">
            <label for="currentEmail">
              <p className=" h6 text-uppercase font-weight-bold text-secondary m-0">
                Current Email
              </p>
            </label>
            <p className="mb-0 pb-2">{email}</p>
          </div>
          <div className="form-group m-0">
            <Input
              type="email"
              title="NEW EMAIL"
              name="newEmail"
              placeholder="Enter new email here"
              value={values.newEmail || ''}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />

            <Input
              type="email"
              title="CONFIRM NEW EMAIL"
              name="confirmEmail"
              placeholder="Enter email here again"
              value={values.confirmEmail || ''}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
          </div>
          <button
            className={classNames('align-self-end', 'btn', 'mt-3', 'ml-3', {
              'btn-secondary': Object.getOwnPropertyNames(errors).length,
              'btn-primary': !Object.getOwnPropertyNames(errors).length
            })}
            type="submit"
          >
            Apply Email Change
          </button>
        </>
      )}
    </form>
  );
};

EditEmail = withFormik({
  validate: values => {
    let errors = {};

    if (!validateEmail(values.newEmail))
      errors['newEmail'] =
        'The email you have entered is not a valid email address';
    if (!validateEmail(values.confirmEmail))
      errors['confirmEmail'] =
        'The email you have entered is not a valid email address';
    if (values.newEmail !== values.confirmEmail)
      errors['confirmEmail'] = 'Thie email you have entered is not the same';
    if (!values.newEmail) errors['newEmail'] = 'Please provide a new email';
    if (!values.confirmEmail)
      errors['confirmEmail'] = 'Please confirm your entered email';
    return errors;
  },

  handleSubmit: async (
    payload,
    { props, setErrors, setStatus, setValues, resetForm }
  ) => {
    if (props.email === payload.newEmail) {
      setErrors({
        editError: 'The email you typed in is the same as your current email'
      });
    }
    let response;
    try {
      setStatus('pending');
      response = await axios.put(
        `/api/prime/v2/account/updateemailaddress?consumerId=${props.consumerId}&email=${payload.newEmail.trim()}`
      );
      if (!response.data.success) throw new Error();
      await setUserInfo(props.programCode, props.client, props.userDispatch);
      resetForm({});
      toastNotification(
        'Your email address has been successfully updated.',
        true
      );
      setStatus('success');
    } catch (err) {
      resetForm({});
      toastNotification('Your email address could not be updated.', false);
      setStatus('success');
      setErrors({
        editError:
          'Whoops! There seems to have been an issue processing your request. Please try again.'
      });
    }
  },

  validateOnBlur: true
})(EditEmail);

export { EditEmail };
