import React, { useRef } from 'react';
import { useGlobalState } from '../../../../context/GlobalContext';
import {
  setChosenDependents,
  setCheckedDependents
} from '../../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { ModalDisclaimer } from './ModalDisclaimer';
import { AddMemberCheckbox } from './AddMemberCheckbox';
import { useUserState } from '../../../../context/UserContext';
import { ModalContainer } from '../../../Global/Modal';
import { AddMemberForm } from './AddMemberForm';

const AddMemberModal = ({
  showFamilyModal,
  setShowFamilyModal,
  getOrderPreview
}) => {
  const [{ tierState, configState }] = useGlobalState();
  const [userState] = useUserState();
  const [
    { primarySelectedPackage, checkedDependents, chosenDependents },
    enrollmentDispatch
  ] = useEnrollmentState();
  const { dependents, enrolledDependents, defaultProgram } = userState;
  const {
    com: {
      ageThresholds: { familyMember },
      entitlements: { allowFamilyMemberManualAdd }
    }
  } = configState;

  const enrolledContainsDependent = dependent => {
    let isDependentEnrolled = enrolledDependents.filter(
      member => member.consumerId === dependent.consumerId
    );
    if (isDependentEnrolled) {
      return isDependentEnrolled;
    }
    return false;
  };

  const submitAddFamilyMember = () => {
    let addedChosenDependents = chosenDependents
      ? chosenDependents.filter(member =>
          checkedDependents.includes(member.consumerId)
        )
      : [];
    let checked = checkedDependents;

    if (addedChosenDependents) {
      addedChosenDependents.map(member => {
        if (checked.includes(member.consumerId)) {
          checked.splice(checked.indexOf(member.consumerId), 1);
        }
      });
    }

    let addedDependents = dependents.filter(member => {
      if (checked.includes(member.consumerId) && member.canBeEnrolled) {
        const isDependentEnrolled = enrolledContainsDependent(member);

        if (
          isDependentEnrolled &&
          member?.defaultProgram?.currentSubscriptionStatus !==
            'ActiveFutureTerm'
        ) {
          return isDependentEnrolled;
        }
        return member;
      }
    });

    const updatedDependents = addedDependents.map(member => {
      member.selectedGymPackage = primarySelectedPackage
        ? primarySelectedPackage === 'studio'
          ? member.isUnderage
            ? tierState.tiers.find(item => item.tier == 1)
            : member.isStudioEnrolled
            ? tierState.tiers.find(item => item.tier == 1)
            : 'studio'
          : primarySelectedPackage.name.toLowerCase() === 'elite' &&
            member.isUnderage
          ? tierState.tiers.find(item => item.tier == 3)
          : primarySelectedPackage
        : defaultProgram?.tier &&
          tierState.tiers.find(item => item.tier == defaultProgram?.tier);
      return member;
    });

    let newChosenDependents = [...addedChosenDependents, ...updatedDependents];
    if (newChosenDependents) {
      enrollmentDispatch(setChosenDependents(newChosenDependents));
      enrollmentDispatch(
        setCheckedDependents(
          newChosenDependents.map(dependent => dependent.consumerId)
        )
      );
      getOrderPreview({ chosenDependents: newChosenDependents });
    }
    setShowFamilyModal();
  };

  const submitManuallyAddedFamilyMember = members => {
    if (!members) return;
    enrollmentDispatch(setChosenDependents(members.members));
    setShowFamilyModal();
  };

  const handleCheckboxChange = (checkedDependents, checkedDependentId) => {
    let eligibleFamilyMembers = !checkedDependents ? [] : checkedDependents;
    if (!eligibleFamilyMembers.includes(checkedDependentId)) {
      eligibleFamilyMembers.push(checkedDependentId);
      enrollmentDispatch(setCheckedDependents(eligibleFamilyMembers));
    } else {
      eligibleFamilyMembers = eligibleFamilyMembers.filter(
        memberId => memberId !== checkedDependentId
      );
      enrollmentDispatch(setCheckedDependents(eligibleFamilyMembers));
    }
  };

  const formRef = useRef();

  return (
    <ModalContainer
      isOpen={showFamilyModal}
      setIsOpen={setShowFamilyModal}
      title="Add Family"
      titleSubText={
        allowFamilyMemberManualAdd
          ? `Please enter the names of the family member(s) (${familyMember} years or older*) you would like to add. You may add up to (X) members to your plan.`
          : `Select the family member(s) (${familyMember} years or older) you
      would like to add.`
      }
      cancelText="Cancel"
      submitText={
        allowFamilyMemberManualAdd ? 'Confirm Addition(s)' : 'Add Member'
      }
      submit={() =>
        allowFamilyMemberManualAdd
          ? formRef.current && formRef.current.handleSubmit()
          : submitAddFamilyMember()
      }
      disclaimer={!allowFamilyMemberManualAdd && <ModalDisclaimer />}
    >
      {allowFamilyMemberManualAdd ? (
        <AddMemberForm
          formRef={formRef}
          submit={submitManuallyAddedFamilyMember}
        />
      ) : (
        <AddMemberCheckbox handleCheckboxChange={handleCheckboxChange} />
      )}
    </ModalContainer>
  );
};

export { AddMemberModal };
