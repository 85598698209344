import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const smartyStreetsAutoComplete = async value => {
  if (!value) return false;

  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
  // US Autocomplete Pro only supports using Website Keys
  let websiteKey = 8945708894654578;
  const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);

  let client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

  // Documentation for input fields can be found at:
  // https://smartystreets.com/docs/cloud/us-autocomplete-api#pro-http-request-input-fields
  let inputValue = `${value ? value : ''}`;
  // *** Simple Lookup ***
  let lookup = new Lookup(inputValue);

  lookup.maxResults = 4;
  try {
    let res = await client.send(lookup);
    return res.result;
  } catch (err) {
    return false;
  }
};

const smartyStreetsValidateAddress = async values => {
  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const Lookup = SmartyStreetsSDK.usStreet.Lookup;

  let websiteKey = 8945708894654578;
  const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);

  let client = SmartyStreetsCore.buildClient.usStreet(credentials);

  // Documentation for input fields can be found at:
  // https://smartystreets.com/docs/us-street-api#input-fields

  let lookup1 = new Lookup();
  lookup1.street = values.address1;
  lookup1.secondary = values.address2 ? values.address2 : '';
  lookup1.city = values.city;
  lookup1.state = values.state;
  lookup1.zipCode = values.zip;
  lookup1.maxCandidates = 3;
  lookup1.match = 'invalid'; // "invalid" is the most permissive match,
  // this will always return at least one result even if the address is invalid.
  // Refer to the documentation for additional MatchStrategy options.

  try {
    let res = await client.send(lookup1);
    return handleSuccess(res);
  } catch (err) {
    values.status = 'Invalid';
    return values;
  }

  function handleSuccess(response) {
    const address = response.lookups[0].result[0];
    if (!address.metadata.rdi) {
      values.status = 'Invalid';
      return values;
    } else {
      const data = {};
      data.status = 'Valid';
      data.address1 = address.deliveryLine1;
      data.zipCode = address.components.zipCode;
      data.state = address.components.state;
      data.city = address.components.cityName;
      data.fullZipCode = `${address.components.zipCode}-${address.components.plus4Code}`;
      return data;
    }
  }
};

export { smartyStreetsAutoComplete, smartyStreetsValidateAddress };
