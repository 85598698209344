import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationSection, MapSection } from '../components';
import { useGlobalState } from '../context/GlobalContext';
import {
  chooseLocationType,
  clearFilters,
  getCurrentMapLocation,
  renderLocations,
  setVisibleGyms,
  setVisibleStudios,
  chooseLocation,
  setLoading,
  getGyms
} from '../context/actions/location';
import { SET_GYMS, SET_STUDIOS } from '../context/reducers/location';
import { GetLocationModal } from '../components/LocationSearch/GetLocationModal';
import { chooseTier } from '../context/actions/tiers';
import { useUserState } from '../context/UserContext';

const Location = () => {
  const [
    {
      locationState,
      tierState,
      configState: {
        client,
        com: { programCode }
      }
    },
    dispatch
  ] = useGlobalState();
  const [userState] = useUserState();
  const {
    chosenAmenities,
    chosenDistance,
    chosenGender,
    chosenStudioType,
    locationTypes,
    chosenLocationType,
    gymList,
    currentUserLocation,
    chosenLocation,
    currentMapLocation,
    mapData
  } = locationState;
  const { chosenTier, tiers } = tierState;
  const { defaultProgram } = userState;

  const [hoveredLocation, setHoveredLocation] = useState({});
  const [studioLimitReached, setStudioLimitReached] = useState(false);
  const [showGetLocationModal, setShowGetLocationModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: SET_GYMS, gymList: null });
    dispatch(setVisibleGyms(null));
    if (location.selectedTier) {
      dispatch(chooseTier(location.selectedTier));
    } else if (defaultProgram?.tier) {
      dispatch(
        chooseTier(tiers.find(item => item.tier == defaultProgram?.tier))
      );
    }
    if (location.chosenLocationType) {
      dispatch(chooseLocationType(location.chosenLocationType));
    } else {
      dispatch(chooseLocationType(locationTypes[0]));
    }
    if (currentUserLocation) {
      dispatch(getCurrentMapLocation(currentUserLocation));
    } else {
      // added this if statement so it wont pop back up when the user selects their location
      if (!currentMapLocation) {
        setShowGetLocationModal(true);
      }
    }

    return () => {
      dispatch(chooseLocationType(null));
      dispatch(clearFilters());
      setStudioLimitReached(false);
    };
  }, []);

  useEffect(() => {
    dispatch({ type: SET_GYMS, gymList: null });
    dispatch({ type: SET_STUDIOS, studioList: null });
    dispatch(setVisibleGyms(null));
    dispatch(setVisibleStudios(null));
    dispatch(chooseLocation(null));
    return () => {
      dispatch({ type: SET_GYMS, gymList: null });
      dispatch({ type: SET_STUDIOS, studioList: null });
      dispatch(setVisibleGyms(null));
      dispatch(setVisibleStudios(null));
      dispatch(chooseLocation(null));
    };
  }, []);

  useEffect(() => {
    if (chosenLocationType === 'studio') {
      renderLocations(
        dispatch,
        mapData,
        gymList,
        null,
        null,
        chosenDistance,
        null,
        null,
        chosenLocationType
      );
    } else {
      if (studioLimitReached) setStudioLimitReached(false);
      renderLocations(
        dispatch,
        mapData,
        gymList,
        null,
        chosenAmenities,
        chosenDistance,
        chosenGender,
        chosenTier,
        chosenLocationType
      );
    }
  }, [
    mapData,
    gymList,
    chosenAmenities,
    chosenDistance,
    chosenGender,
    chosenTier
  ]);

  const getLocations = async () => {
    dispatch(setLoading('loadingLocations', true));
    try {
      const locations = await getGyms(
        programCode,
        client,
        chosenLocationType,
        currentMapLocation,
        chosenStudioType ? chosenStudioType : ''
      );
      dispatch({ type: SET_GYMS, gymList: locations });
    } catch (error) {
      console.log(error);
      if (chosenLocationType === 'studio') {
        if (error.response) {
          if (error.response.status === 429) {
            setStudioLimitReached(true);
          }
        }
      }
      dispatch({ type: SET_GYMS, gymList: [] });
    } finally {
      dispatch(setLoading('loadingLocations', false));
    }
  };

  useEffect(() => {
    if (chosenLocationType) {
      if (currentMapLocation && !chosenLocation) {
        getLocations();
      }
    }
  }, [currentMapLocation, chosenLocationType, chosenStudioType]);

  return (
    <div className="d-flex location-search">
      <LocationSection
        showTiers={chosenLocationType === 'gym'}
        setHoveredLocation={setHoveredLocation}
        hoveredLocation={hoveredLocation}
        studioLimitReached={studioLimitReached}
        getLocations={getLocations}
      />
      <MapSection
        showClearFilter={
          chosenGender ||
          chosenAmenities ||
          chosenStudioType !== '' ||
          chosenDistance !== 20
        }
        showStudioFilter={chosenLocationType === 'studio'}
        showAmenitiesFilter={chosenLocationType === 'gym'}
        showGenderSpecificFilter={chosenLocationType === 'gym'}
        hoveredLocation={hoveredLocation}
        studioLimitReached={studioLimitReached}
        getLocations={getLocations}
      />
      <GetLocationModal
        showGetLocationModal={showGetLocationModal}
        setShowGetLocationModal={setShowGetLocationModal}
      />
    </div>
  );
};

export { Location };
