
import React, { useState } from 'react';
import { Icon } from '../../../assets/Icons';


let SelfHelpPanel = ({
  isVisible,
  category,
  bodyText,
  primaryColor
}) => {
  
  return isVisible && bodyText && (
    <div className="alert alert-primary p-3 rounded w-75 mb-3 hqa">
      <Icon
        name="info-circle"
        width="20px"
        height="20px"
        className="ml-3 icon-alignment"
        fill="#004085"
      />
      <h5 className="alert m-0 mb-1 d-inline-block pl-2">
        Looking for help with {category}?
      </h5>
      <p className="m-0 ml-3 mb-1" dangerouslySetInnerHTML={{
          __html: bodyText
        }}>
      </p>
      <p className="m-0 ml-3 mb-1">
        <span className="font-weight-bold">
          Still need help? Please explain your issue below and send us an email.
        </span>
      </p>
    </div>
  );
}


export { SelfHelpPanel }