import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { version } from '../package.json';

import 'font-awesome/css/font-awesome.min.css';
import { GlobalProvider } from './context/GlobalContext';
import { EnrollmentProvider } from './context/EnrollmentContext';
import { UserProvider } from './context/UserContext';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

//for now, this will be our entry point in to the app.
ReactDOM.render(
  <GlobalProvider>
    <UserProvider>
      <EnrollmentProvider>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </EnrollmentProvider>
    </UserProvider>
  </GlobalProvider>,
  document.getElementById('root')
);

console.info(
  `%cPrime Build: v${version}`,
  'font-weight: bold; color: #023047; background-color: #caf0f8; padding: 4px;'
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
