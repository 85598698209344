import { parse } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import Fragment from 'react';
import spinner from '../../assets/spinner.gif';

const LoadingSpinner = ({ 
    isLoading, 
    additionalText = 'Processing....\nThis may take a minute.\nPlease do not refresh the page.', //default
  }) => {

const additionalTextLines = additionalText.split(/(?:\r\n|\r|\n)/g).map(str => <p>{str}</p>);

  return (
    <div style={{ width: '100%' }}>
      <img
        className="align-self-center loading-spinner-left"
        alt="loader"
        src={spinner}
      />{' '}
      <div className="loadingSpinner additionalText text-center">{additionalTextLines}</div>
    </div>
  );
};

export { LoadingSpinner };
