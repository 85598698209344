import React, { useState } from 'react';
import { useUserState } from '../../../../../context/UserContext';
import { DiscardModal } from '../../../../Account/DiscardChangeModal';
import { DiscardOption } from './DiscardOption';
import { EmailField } from './EmailField';
import { PackageDropdown } from './PackageDropdown';

const MemberPackageBox = ({
  user,
  isEditingPlan,
  getOrderPreview,
  selectedGymPackage,
  loggedInMember
}) => {
  const [userState] = useUserState();
  const { isPrimary } = userState;
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(true);
  return (
    <>
      <div className="list-group mb-3 d-flex bg-white flex-column">
        <div className="list-group-item">
          {!loggedInMember && user.isUnderage && (
            <div className="w-100 alert-warning rounded mb-2">
              <p className="font-small font-weight-bold pl-2 py-2 m-0">
                This member is under the age of 18 and some restrictions apply
              </p>
              <ul className="pl-4 m-0">
                <li
                  className="font-small pr-2 pb-1"
                  style={{ listStyle: 'disc', lineHeight: '1.5' }}
                >
                  A parent or guardian who is also a Fitness Program member will
                  need to accompany this member to the location on the first
                  visit to sign the legal waiver(s)
                </li>
                <li
                  className="font-small pr-2 py-1"
                  style={{ listStyle: 'disc', lineHeight: '1.5' }}
                >
                  Access to locations for members under age 18 is at each
                  location's discretion. Not all locations allow members under
                  18, and some may require those members to be accompanied by a
                  parent or guardian on each visit.
                </li>
              </ul>
            </div>
          )}
          <div className="d-flex flex-column">
            {user.isPossibleTwin && user.canBeEnrolled && (
              <div className="w-100 alert-warning rounded mb-2 pl-2 pb-2 pt-1">
                <p className="font-small font-weight-bold pl-0 py-2 m-0">
                  Prevent Duplicate Charges
                </p>
                <p className="m-0">
                  To prevent duplicate charges, please confirm that there are no
                  identical family members added to your plan. We noticed that{' '}
                  {''}
                  <b>
                    {user.firstName} {user.lastName}
                    {"'s "}{' '}
                  </b>
                  information is similar to another family member on your plan.
                </p>
              </div>
            )}
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div className="align-self-md-center d-flex flex-column mr-3">
                <p className="m-0 h6 text-uppercase font-weight-bold text-secondary pb-1">
                  {loggedInMember ? 'Primary Member' : 'Family Member'}
                </p>
                <p className="m-0 text-capitalize">
                  {user.firstName + ' ' + user.lastName}
                </p>
              </div>
              {!loggedInMember &&
                user.isUnderage &&
                selectedGymPackage.name === 'Elite' && (
                  <div className="form-group flex-fill">
                    <p className="font-italic">
                      <b>** Please Note: </b>
                      Family members under the age of 18 may only register for
                      ELITE tier access if the primary plan member has ELITE
                      tier access
                    </p>
                  </div>
                )}
              <EmailField
                loggedInMember={loggedInMember}
                user={user}
                isEditingPlan={isEditingPlan}
              />
            </div>
            {loggedInMember && (
              <p>
                By providing your email address and/or any other personal
                information, as defined under applicable law, you acknowledge
                that you are agreeing to our use of your information as provided
                in our <a href="/terms-of-use">Terms of Use</a> and{' '}
                <a href="/privacy-policy">Privacy Policy.</a>
                {/* <span className="text-danger">*</span> */}
              </p>
            )}
          </div>
        </div>
        {isPrimary &&
          (user?.isEnrolledInDefaultProgram ? (
            user?.defaultProgram?.isCancelling ? (
              user?.isEnrolledInDigitalTier &&
              !user?.digitalProgram?.isCancelling ? (
                isShowing && (
                  <DiscardOption
                    type="Change"
                    setDiscardModalOpen={setDiscardModalOpen}
                  />
                )
              ) : (
                <DiscardOption
                  type="Cancellation"
                  setDiscardModalOpen={setDiscardModalOpen}
                />
              )
            ) : user?.defaultProgram?.isTransitioning ? (
              <DiscardOption
                type="Change"
                setDiscardModalOpen={setDiscardModalOpen}
              />
            ) : (
              <PackageDropdown
                loggedInMember={loggedInMember}
                user={user}
                isEditingPlan={isEditingPlan}
                getOrderPreview={getOrderPreview}
                selectedGymPackage={selectedGymPackage}
              />
            )
          ) : user?.isEnrolledInDigitalTier ? (
            user?.digitalProgram?.isCancelling ? (
              <DiscardOption
                type="Cancellation"
                setDiscardModalOpen={setDiscardModalOpen}
              />
            ) : user?.digitalProgram?.isTransitioning ? (
              <DiscardOption
                type="Change"
                setDiscardModalOpen={setDiscardModalOpen}
              />
            ) : (
              <PackageDropdown
                loggedInMember={loggedInMember}
                user={user}
                isEditingPlan={isEditingPlan}
                getOrderPreview={getOrderPreview}
                selectedGymPackage={selectedGymPackage}
              />
            )
          ) : (
            <PackageDropdown
              loggedInMember={loggedInMember}
              user={user}
              isEditingPlan={isEditingPlan}
              getOrderPreview={getOrderPreview}
              selectedGymPackage={selectedGymPackage}
            />
          ))}
      </div>
      {isDiscardModalOpen && user?.defaultProgram?.isCancelling ? (
        user?.isEnrolledInDigitalTier && !user?.digitalProgram?.isCancelling ? (
          <DiscardModal
            setDiscardModalOpen={setDiscardModalOpen}
            isDiscardModalOpen={isDiscardModalOpen}
            type={'Downgrade Digital Discard Change'}
            user={user}
            isShowing={setIsShowing}
          />
        ) : (
          <DiscardModal
            setDiscardModalOpen={setDiscardModalOpen}
            isDiscardModalOpen={isDiscardModalOpen}
            type={'Discard Cancellation'}
            user={user}
          />
        )
      ) : isDiscardModalOpen &&
        user?.defaultProgram.isCancelling &&
        !user?.defaultProgram.isTransitioning ? (
        <DiscardModal
          setDiscardModalOpen={setDiscardModalOpen}
          isDiscardModalOpen={isDiscardModalOpen}
          type={'Discard Cancellation'}
          user={user}
        />
      ) : isDiscardModalOpen &&
        !user?.defaultProgram.isCancelling &&
        user?.defaultProgram.isTransitioning ? (
        <DiscardModal
          setDiscardModalOpen={setDiscardModalOpen}
          isDiscardModalOpen={isDiscardModalOpen}
          type={'Downgrade Discard Change'}
          user={user}
        />
      ) : (
        isDiscardModalOpen &&
        user?.digitalProgram?.isCancelling &&
        !user?.digitalProgram?.isTransitioning && (
          <DiscardModal
            setDiscardModalOpen={setDiscardModalOpen}
            isDiscardModalOpen={isDiscardModalOpen}
            type={'Discard Digital Cancellation'}
            user={user}
          />
        )
      )}
    </>
  );
};

export { MemberPackageBox };
