import React from 'react';
import { Modal } from 'reactstrap';
import Helmet from 'react-helmet';

let VideoModal = ({
  isOpen,
  setIsOpen,
  isLoading = false,
  selfClosing = false,
  size = 'lg'
}) => {
  return (
    <>
      <Helmet>
        <script
          src="https://fast.wistia.com/embed/medias/bkz54h9304.jsonp"
          async
        ></script>
        <script
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
        ></script>
        {/* <style type="text/css">{`
          .modal-content 
          {
            background: none !important;
            border: none !important;
          }
      `}</style> */}
      </Helmet>
      <Modal
        //size={size}
        toggle={!selfClosing && (() => setIsOpen(!isOpen))}
        centered
        isOpen={isOpen}
        className="videoModal"
      >
        <iframe
          src="//fast.wistia.net/embed/iframe/bkz54h9304"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          width="640"
          height="360"
          style={{ marginLeft: '-75px' }}
        />
      </Modal>
    </>
  );
};
export { VideoModal };
