import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ClusterMarker = ({
  item,
  name,
  $hover,
  zoomIntoCluster,
  studioColor,
  primaryColor
}) => (
  <div
    className={classNames('cluster-marker', {
      'cluster-marker-hovered': $hover,
      primary: studioColor,
      'border-primary': studioColor
    })}
    style={{
      backgroundColor: studioColor ? '#fff' : primaryColor
    }}
    onClick={() => zoomIntoCluster(item)}
  >
    {name}
  </div>
);

ClusterMarker.propTypes = {
  name: PropTypes.string
};

export { ClusterMarker };
