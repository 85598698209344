import {
  setDisableFooterButton,
  setAddressErrors
} from '../context/actions/enrollment';
import { checkIfAddressValid } from './checkIfAddressValid';

export const validateAddress = async (
  isMailingSameAsBilling,
  setValidatingAddress,
  enrollmentDispatch,
  shippingAddress,
  billingAddress,
  setStep,
  history,
  getOrderPreview,
  setIsAddressValidationErrorOpen
) => {
  setValidatingAddress(true);

  try {
    if (isMailingSameAsBilling) {
      const shippingAddressIsValid = await checkIfAddressValid(
        shippingAddress,
        'Shipping Address'
      );
      if (shippingAddressIsValid) {
        getOrderPreview();
        setStep(prevState => prevState++);
        history.push('/enroll/payment-method');
      } else {
        throw new Error();
      }
    } else {
      const shippingAddressIsValid = await checkIfAddressValid(
        shippingAddress,
        'Shipping Address'
      );

      const billingAddressIsValid = await checkIfAddressValid(
        billingAddress,
        'Billing Address'
      );
      if (shippingAddressIsValid && billingAddressIsValid) {
        getOrderPreview();
        setStep(prevState => prevState++);
        history.push('/enroll/payment-method');
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    console.log(error);
    setIsAddressValidationErrorOpen(true);
    enrollmentDispatch(setDisableFooterButton(true));
    throw error;
  } finally {
    setValidatingAddress(false);
  }
};
