import React, { useState, useEffect } from 'react';
import { MemberSummary } from '../Enrollment/EnrollmentSteps';
import { CartSummary } from '../Enrollment/CartSummary';
import { ManagePlanPaymentModal } from './ManagePlanPaymentModal';
import { Icon } from '../../assets/Icons';
import { useEnrollmentState } from '../../context/EnrollmentContext';
import {
  setPrimarySelectedPackage,
  setEnrollmentDefault,
  setPromoCode,
  setCartSummary,
  setDisableFooterButton,
  setLoading
} from '../../context/actions/enrollment';
import { useGlobalState } from '../../context/GlobalContext';
import { EnrollmentStep } from '../Enrollment/EnrollmentStep';
import { EnrollmentFooter } from '../Enrollment/EnrollmentNav';
import { LoadingScreen } from '../Global';
import { isPast } from 'date-fns';
import { ManagePaymentMethodModal } from './ManagePaymentMethods/ManagePaymentMethodModal';
import { EditAddressModal } from './EditAddressModal';
import { processOrder } from '../../api/payments';
import { useLocation, useHistory } from 'react-router-dom';
import { useUserState } from '../../context/UserContext';
import { setUserInfo } from '../../context/actions/user';
import { toastNotification } from '../../utilities/toastMessage';
import { checkEligibility } from '../../utilities/checkEligibility';
import { SET_CMS } from '../../context/reducers/config';

let EditPlan = ({ setShowEditPlan }) => {
  const [
    {
      tierState,
      configState: {
        client,
        cms,
        com: {
          programCode,
          entitlements: { isDependentAccessLinked },
          uiConfig: { canEnrollDependents }
        }
      }
    }
  ] = useGlobalState();
  const [userState, userDispatch] = useUserState();
  const [enrollmentState, enrollmentDispatch] = useEnrollmentState();
  const { primarySelectedPackage, promoCode } = enrollmentState;
  const [showManagePlanPaymentModal, setShowManagePlanPaymentModal] = useState(
    false
  );
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    defaultProgram,
    digitalProgram,
    isEnrolledInDigitalTier,
    isStudioEligible,
    isEnrolledInDefaultProgram,
    isStudioEnrolled
  } = userState;
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.selectedTier) {
      enrollmentDispatch(setPrimarySelectedPackage(location.selectedTier));
    } else {
      if (!isEnrolledInDefaultProgram) {
        if (isEnrolledInDigitalTier) {
          enrollmentDispatch(
            setPrimarySelectedPackage(tierState.digitalTier[0])
          );
        } else if (defaultProgram?.tier) {
          let currentUserTier = tierState.tiers.find(
            item => item.tier == defaultProgram?.tier
          );
          if (currentUserTier) {
            enrollmentDispatch(setPrimarySelectedPackage(currentUserTier));
            getOrderPreview({ primarySelectedPackage: currentUserTier });
          }
        } else if (isStudioEligible && isStudioEnrolled) {
          enrollmentDispatch(setPrimarySelectedPackage('studio'));
        }
      } else {
        let currentUserTier = tierState.tiers.find(
          item => item.tier == defaultProgram?.tier
        );
        if (currentUserTier) {
          enrollmentDispatch(setPrimarySelectedPackage(currentUserTier));
        }
      }
    }
    return () => {
      enrollmentDispatch(setEnrollmentDefault());
    };
  }, []);

  const getOrderPreview = async (itemChanged = null) => {
    try {
      enrollmentDispatch(setLoading('loadingCartSummary', true));
      const cartSummaryData = await processOrder(
        isDependentAccessLinked,
        programCode,
        enrollmentDispatch,
        userState,
        itemChanged ? { ...enrollmentState, ...itemChanged } : enrollmentState,
        false,
        true,
        ''
      );
      if (cartSummaryData) {
        if (cartSummaryData.cartSummary) {
          enrollmentDispatch(setCartSummary(cartSummaryData.cartSummary));
          enrollmentDispatch(setLoading('loadingCartSummary', false));
        } else {
          enrollmentDispatch(setCartSummary(cartSummaryData));
          enrollmentDispatch(setLoading('loadingCartSummary', false));
        }
      }
    } catch (error) {
      enrollmentDispatch(setCartSummary(null));
      enrollmentDispatch(setLoading('loadingCartSummary', false));
      console.log(error);
      throw error;
    }
  };

  const submitOrder = async () => {
    setIsLoading(true);
    setShowManagePlanPaymentModal(!showManagePlanPaymentModal);

    try {
      await processOrder(
        isDependentAccessLinked,
        programCode,
        enrollmentDispatch,
        userState,
        enrollmentState,
        true,
        true,
        ''
      );
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your subscription has been successfully updated.',
        true
      );
      setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
    } catch (err) {
      history.push('/error', { error: 'orderSubmitFail' });
      console.log(err);
    } finally {
      setIsLoading(false);
      if (setShowEditPlan) setShowEditPlan(false);
      enrollmentDispatch(setEnrollmentDefault());
    }
  };

  return (
    <>
      <div className="px-lg-4 px-2 pt-3">
        <p
          className="go-back d-flex m-0"
          onClick={() => {
            enrollmentDispatch(setEnrollmentDefault());
            setShowEditPlan(false);
          }}
          style={{ height: 25 }}
        >
          <Icon name="back" width="24px" fill={cms.primary_color}/> Back
        </p>
      </div>
      <div className="container-fluid d-lg-flex">
        <div className="col-xl-1 col-lg-1 d-none d-xl-block" />
        <EnrollmentStep
          title="Edit Plan"
          description={
            <>
              Please confirm the email address on file and add family members as
              necessary.
              {canEnrollDependents &&
                ' Family bundles unlock special discounts'}
            </>
          }
        >
          <MemberSummary isEditingPlan getOrderPreview={getOrderPreview} />
        </EnrollmentStep>
        <CartSummary isEditingPlan getOrderPreview={getOrderPreview} />
        <div className="col-xl-1 d-none d-xl-block" />
      </div>
      <ManagePlanPaymentModal
        showManagePlanPaymentModal={showManagePlanPaymentModal}
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
        setShowEditPlan={setShowEditPlan}
        setShowPaymentModal={setShowPaymentModal}
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
        title={
          enrollmentState.cartSummary &&
          enrollmentState.cartSummary.immediatePaymentSummary &&
          enrollmentState.cartSummary.immediatePaymentSummary
            .immediateTotalPaymentAfterDiscountAmountWithTax
            ? `Checkout - $
            ${enrollmentState.cartSummary.immediatePaymentSummary.immediateTotalPaymentAfterDiscountAmountWithTax.toFixed(
              2
            )}`
            : 'Checkout - $0.00'
        }
        submit={submitOrder}
      />
      {showPaymentModal && (
        <ManagePaymentMethodModal
          setIsManagePaymentMethodModalOpen={setShowPaymentModal}
          isManagePaymentMethodModalOpen={showPaymentModal}
        />
      )}
      {isEditAddressModalOpen && (
        <EditAddressModal
          isEditAddressModalOpen={isEditAddressModalOpen}
          setIsEditAddressModalOpen={setIsEditAddressModalOpen}
        />
      )}
      <EnrollmentFooter
        isEditingPlan
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
        setShowEditPlan={setShowEditPlan}
      />
      <LoadingScreen isLoading={isLoading} />
    </>
  );
};

export { EditPlan };
