import React, { useEffect } from 'react';
import { FormikConsumer } from 'formik';

const FormikPersistor = formikProps => {
  const storageKey = () => `formik.form.${formikProps.name}`;

  useEffect(() => {
    const { setValues, setErrors } = formikProps;
    const data = sessionStorage.getItem(storageKey());

    if (data) {
      const { values, errors } = JSON.parse(data);
      setValues(values);
      setErrors(errors);
    }
  }, []);

  useEffect(() => {
    const { values, errors } = formikProps;
    sessionStorage.setItem(storageKey(), JSON.stringify({ values, errors }));
  }, [formikProps.values, formikProps.errors]);

  return null;
};

const FormikPersist = ({ name }) => (
  <FormikConsumer>
    {({ values, errors, setValues, setErrors }) => (
      <FormikPersistor
        name={name}
        setValues={setValues}
        setErrors={setErrors}
        values={values}
        errors={errors}
      />
    )}
  </FormikConsumer>
);

export default FormikPersist;
