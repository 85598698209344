import axios from 'axios';

export const getImageURL = (consumerId = null) => {
  return new Promise((resolve, reject) => {
    const cardImage = `/api/prime/v2/account/getcardimage${
      consumerId ? `?consumerId=${consumerId}` : ''
    }`;
    axios({
      url: cardImage,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'image/png' })
        );
        resolve(url);
      })
      .catch(err => reject(err));
  });
};
