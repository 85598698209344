export const SET_FILTER = 'SET_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CHOOSE_LOCATION = 'CHOOSE_LOCATION';
export const CHOOSE_TIER = 'CHOOSE_TIER';
export const CHOOSE_LOCATION_TYPE = 'CHOOSE_LOCATION_TYPE';
export const SET_LOADING = 'SET_LOADING';
export const SET_GYMS = 'SET_GYMS';
export const SET_STUDIOS = 'SET_STUDIOS';
export const SET_CURRENT_USER_LOCATION = 'SET_CURRENT_USER_LOCATION';
export const SET_CURRENT_MAP_LOCATION = 'SET_CURRENT_MAP_LOCATION';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const SET_VISIBLE_GYMS = 'SET_VISIBLE_GYMS';
export const SET_VISIBLE_STUDIOS = 'SET_VISIBLE_STUDIOS';

const setFilter = (filterType, filterValue, state) => {
  return {
    ...state,
    [filterType]: filterValue
  };
};

const clearFilters = state => ({
  ...state,
  chosenStudioType: null,
  chosenGender: null,
  chosenAmenities: null,
  chosenDistance: 20
});

const chooseLocationType = (chosenLocationType, state) => ({
  ...state,
  chosenLocationType
});

const chooseLocation = (chosenLocation, state) => ({
  ...state,
  chosenLocation
});

const chooseTier = (chosenTier, state) => ({ ...state, chosenTier });

const setLoading = (loadingType, isLoading, state) => ({
  ...state,
  [loadingType]: isLoading
});

const setGyms = (gymList, state) => ({
  ...state,
  gymList
});

const setStudios = (studioList, state) => ({
  ...state,
  studioList
});

const setCurrentUserLocation = (currentUserLocation, state) => ({
  ...state,
  currentUserLocation
});

const setCurrentMapLocation = (currentMapLocation, state) => ({
  ...state,
  currentMapLocation
});

const setMapData = (mapData, state) => ({ ...state, mapData });

const setVisibleGyms = (visibleGyms, state) => ({
  ...state,
  visibleGyms
});

const setVisibleStudios = (visibleStudios, state) => ({
  ...state,
  visibleStudios
});

export const locationReducer = (state, action) => {
  switch (action.type) {
    case SET_FILTER:
      return setFilter(action.filterType, action.filterValue, state);
    case CLEAR_FILTERS:
      return clearFilters(state);
    case CHOOSE_LOCATION:
      return chooseLocation(action.location, state);
    case CHOOSE_TIER:
      return chooseTier(action.tier, state);
    case CHOOSE_LOCATION_TYPE:
      return chooseLocationType(action.chosenLocationType, state);
    case SET_GYMS:
      return setGyms(action.gymList, state);
    case SET_STUDIOS:
      return setStudios(action.studioList, state);
    case SET_LOADING:
      return setLoading(action.loadingType, action.isLoading, state);
    case SET_CURRENT_MAP_LOCATION:
      return setCurrentMapLocation(action.location, state);
    case SET_CURRENT_USER_LOCATION:
      return setCurrentUserLocation(action.location, state);
    case SET_MAP_DATA:
      return setMapData(action.mapData, state);
    case SET_VISIBLE_GYMS:
      return setVisibleGyms(action.visibleGyms, state);
    case SET_VISIBLE_STUDIOS:
      return setVisibleStudios(action.visibleStudios, state);
    default:
      return state;
  }
};
