import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import { Input, Select } from '../../Global';
import { useGlobalState } from '../../../context/GlobalContext';
import { setAddress, setAddressError } from '../../../context/actions/user';
import { setDisableFooterButton } from '../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import { validateZipCode } from '../../../utilities/validateZipCode';
import { AddressInput } from './AddressInput';
import { useUserState } from '../../../context/UserContext';
import FormikPersist from '../../../utilities/FormikPersist';

let AddressForm = ({
  persist,
  addressType,
  values,
  errors,
  onAddressChange,
  touched,
  handleChange,
  handleSubmit,
  handleBlur,
  setValues,
  addressErrors,
  prevState
}) => {
  const [{ configState }] = useGlobalState();
  const {
    strings: { states }
  } = configState;

  useEffect(() => {
    onAddressChange({ values, touched, errors, addressType });
  }, [values, touched, errors]);

  const returnStates = () => {
    let stateOptions = [];
    Object.entries(JSON.parse(states)).map(([key, value]) => {
      stateOptions = [...stateOptions, { state: key }];
    });
    return stateOptions;
  };

  return (
    <form onSubmit={handleSubmit}>
      {addressErrors &&
        addressErrors['invalid'] &&
        addressErrors['invalid'][addressType] && (
          <p className="text-danger text-center">
            {addressErrors['invalid'][addressType]}
          </p>
        )}
      <AddressInput
        type={'text'}
        title={'Address'}
        name={'address1'}
        value={values.address1}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setValues={setValues}
        addressType={addressType}
      />
      <Input
        type={'text'}
        title={'Apt, Unit, ETC.'}
        name={'address2'}
        value={values.address2}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        addressType={addressType}
        optional
      />
      <div className="d-flex flex-sm-row flex-column w-100">
        <Input
          type={'text'}
          title={'City'}
          name={'city'}
          value={values.city}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          addressType={addressType}
        />
        <Select
          type={'select'}
          title={'State'}
          name={'state'}
          value={values.state}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          options={returnStates()}
          labelField="state"
          addressType={addressType}
        />
        <Input
          type={'text'}
          title={'Zip Code'}
          name={'zip'}
          value={values.zip}
          handleBlur={handleBlur}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          addressType={addressType}
        />
      </div>
      {persist && <FormikPersist name={addressType} />}
    </form>
  );
};

AddressForm = withFormik({
  validate: values => {
    let errors = {};

    if (!values.address1) errors['address1'] = 'Address1 is required';

    if (!values.city) errors['city'] = 'City is required';

    if (!values.state) errors['state'] = 'State is required';

    if (!validateZipCode(values.zip)) errors['zip'] = 'Zipcode is invalid';

    if (!values.zip) errors['zip'] = 'Zip is required';

    return errors;
  },
  mapPropsToValues: props => ({ ...props.initialValues }),
  // enableReinitialize: true,

  displayName: 'BasicForm'
})(AddressForm);

export { AddressForm };

// const AddyForm = ({type})
