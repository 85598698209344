import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useGlobalState } from '../../../../context/GlobalContext';
import { calculateAge } from '../../../../utilities/checkIfUnderage';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { EmptyList } from '../../../Account/EmptyList';
import { Checkbox } from '../../../Global/Checkbox';
import { useUserState } from '../../../../context/UserContext';

const AddMemberCheckbox = ({ handleCheckboxChange }) => {
  const [{ configState }] = useGlobalState();
  const [userState] = useUserState();
  const [eligibleDependents, setEligibleDependents] = useState([]);
  const [{ chosenDependents, checkedDependents }] = useEnrollmentState();
  let { dependents, enrolledDependents } = userState;
  const {
    com: {
      ageThresholds: { familyMember }
    }
  } = configState;

  useEffect(() => {
    dependents &&
      setEligibleDependents(
        dependents.filter(
          dependent => calculateAge(dependent.dateOfBirth) >= familyMember
        )
      );
  }, []);

  return (
    <>
      {eligibleDependents.length > 0 ? (
        eligibleDependents.map((member, i) => (
          <>
            <div
              key={member.consumerId}
              className={classNames(
                'justify-content-between',

                'py-3',
                'px-2',
                {
                  'd-none':
                    chosenDependents &&
                    chosenDependents.filter(
                      dependent => dependent.consumerId === member.consumerId
                    ).length > 0,
                  'd-flex':
                    chosenDependents &&
                    chosenDependents.filter(
                      dependent => dependent.consumerId === member.consumerId
                    ).length === 0,
                  'border-bottom': i > 0 && i < eligibleDependents.length
                }
              )}
              style={{
                opacity:
                  !member.canBeEnrolled ||
                  member.eligibilities.length === 0 ||
                  enrolledDependents.filter(
                    dependent => dependent.consumerId === member.consumerId
                  ).length > 0
                    ? 0.5
                    : 1
              }}
            >
              <Checkbox
                value={member.consumerId}
                text={`${member.firstName} ${member.lastName}`}
                optional
                htmlFor={`${member.firstName} ${member.lastName}`}
                disabled={
                  !member.canBeEnrolled ||
                  member.eligibilities.length === 0 ||
                  enrolledDependents.filter(
                    dependent => dependent.consumerId === member.consumerId
                  ).length > 0
                }
                checked={
                  checkedDependents &&
                  checkedDependents.includes(member.consumerId)
                }
                onChange={() =>
                  handleCheckboxChange(checkedDependents, member.consumerId)
                }
              />
            </div>
            {!member.canBeEnrolled &&
              enrolledDependents.filter(
                dependent => dependent.consumerId === member.consumerId
              ).length === 0 && (
                <p className="blue small ml-4">
                  This member is already part of another fitness program gym
                  package.
                </p>
              )}
          </>
        ))
      ) : (
        <EmptyList addMemberModal />
      )}
    </>
  );
};

export { AddMemberCheckbox };
