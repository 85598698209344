import { checkEligibility } from './checkEligibility';
import React from 'react';

const setAddress = address => ({
  address1: address ? address.address1 : '',
  city: address ? address.city : '',
  postalCode: address ? address.zip : '',
  state: address ? address.state : null
});

const createOrder = (
  isDependentAccessLinked,
  programCode,
  memberInfo,
  isEditingPlan,
  selectedPaymentMethod = null,
  isGettingCurrentPlan = false
) => {
  const { primaryMember, dependents } = memberInfo;
  let orderInfo = {};
  const memberOrders = dependents
    ? dependents
        .filter(member => {
          if (
            !member.isDependentToMember ||
            (member?.isEnrolledInDefaultProgram &&
              !member?.digitalProgram?.isCancelling) ||
            !(
              member?.isEnrolledInDigitalTier &&
              member?.digitalProgram?.isCancelling
            )
          ) {
            return member;
          }
        })
        .map(member => {
          let order = createDependentOrder(
            isDependentAccessLinked,
            programCode,
            member,
            primaryMember,
            isEditingPlan
          );
          return order;
        })
    : [];
  const primaryOrder = createPrimaryOrder(
    programCode,
    primaryMember,
    isEditingPlan,
    selectedPaymentMethod,
    isGettingCurrentPlan
  );
  orderInfo = {
    members: [primaryOrder, ...memberOrders]
  };
  return orderInfo;
};

const createPrimaryOrder = (
  programCode,
  member,
  isEditingPlan,
  selectedPaymentMethod = null,
  isGettingCurrentPlan = false
) => {
  const {
    firstName,
    lastName,
    email,
    consumerId,
    billingAddress,
    shippingAddress,
    accountNumber
  } = member;
  return {
    consumerId,
    ...(!isEditingPlan && {
      billToContact: {
        firstName,
        lastName,
        ...(billingAddress && setAddress(billingAddress))
      }
    }),
    soldToContact: {
      email,
      firstName,
      lastName,
      ...(shippingAddress && setAddress(shippingAddress))
    },
    ...(isEditingPlan &&
      member?.defaultProgram?.currentSubscription &&
      member.isLegacy && {
        soldToContact: {
          email,
          firstName,
          lastName,
          ...(shippingAddress && setAddress(shippingAddress))
        }
      }),
    firstName,
    lastName,
    isPrimary: true,
    ...((isEditingPlan ||
      (!isEditingPlan &&
        (member.isLegacy || member?.defaultProgram?.currentSubscription))) && {
      accountNumber
    }),
    orderActions: createOrderActions(
      member,
      isEditingPlan,
      selectedPaymentMethod,
      isGettingCurrentPlan
    )
  };
};

const createDependentOrder = (
  isDependentAccessLinked,
  programCode,
  member,
  primaryMember,
  isEditingPlan,
  selectedPaymentMethod = null
) => {
  const { shippingAddress } = primaryMember;
  const {
    firstName,
    lastName,
    email,
    consumerId,
    dob,
    subscriptionAccount
  } = member;
  if (isDependentAccessLinked) {
    return { firstName: firstName, lastName: lastName, dob: dob };
  }
  return {
    ...(subscriptionAccount && {
      accountNumber: subscriptionAccount.accountNumber
    }),
    firstName: member.firstName,
    lastName: member.lastName,
    soldToContact: {
      email: member.isUnderage ? primaryMember.email : email,
      firstName: firstName,
      lastName: lastName,
      ...(member.isPossibleTwin && {
        isMultipleBirth: true
      }),
      ...(shippingAddress && setAddress(shippingAddress))
    },
    orderActions: createOrderActions(
      member,
      isEditingPlan,
      selectedPaymentMethod
    ),
    consumerId: consumerId
  };
};

const getOrderActionType = (member, isEditingPlan, isGettingCurrentPlan) => {
  if (!isEditingPlan) {
    // Is in initial enrollment
    if (member?.defaultProgram?.currentSubscription) {
      // If member's subscription exists
      return getOrderTypeInfo('SubscriptionNew', member); // Legacy member
    } else {
      return getOrderTypeInfo('NewAccount', member); // New Enrollments
    }
  } else {
    // Is in edit plans
    if (isGettingCurrentPlan) {
      // Current Plan section displaying their current subscription
      return getOrderTypeInfo('NoAction', member);
    }

    // If member is cancelling, transitioning, or active
    if (member.isEnrolledInDefaultProgram || member.isEnrolledInDigitalTier) {
      if (
        (member.isEnrolledInDefaultProgram &&
          (member?.defaultProgram?.isCancelling ||
            member?.defaultProgram?.isTransitioning)) ||
        (!member.isEnrolledInDefaultProgram &&
          member.isEnrolledInDigitalTier &&
          member?.digitalProgram?.isCancelling)
      ) {
        return getOrderTypeInfo('NoAction', member);
      }
      // If legacy member chooses own tier
      else if (
        member.isLegacy &&
        member?.selectedGymPackage?.tier ==
          member?.defaultProgram?.currentSubscription.tier
      ) {
        if (
          member.isEnrolledInDefaultProgram ||
          member?.defaultProgram?.isActive
        ) {
          return getOrderTypeInfo('NoAction', member);
        } else {
          return getOrderTypeInfo('SubscriptionNew', member);
        }
      } else if (
        member.isEnrolledInDefaultProgram &&
        member.isEnrolledInDigitalTier &&
        member?.selectedGymPackage?.name === 'Digital'
      ) {
        // If downgrading to digital
        return getOrderTypeInfo('DigitalDowngrade', member);
      } else if (
        (member?.defaultProgram?.futureSubscription &&
          member?.defaultProgram?.futureSubscription?.ratePlanChargeId !==
            member?.selectedGymPackage?.recurringRatePlanChargeId) ||
        (!member.isEnrolledInDefaultProgram &&
          member.isEnrolledInDigitalTier &&
          member?.digitalProgram?.currentSubscription?.ratePlanChargeId !==
            member?.selectedGymPackage?.recurringRatePlanChargeId)
      ) {
        // If transitioning
        return getOrderTypeInfo('SubscriptionNew', member);
      } else if (
        member?.defaultProgram?.currentSubscription?.ratePlanChargeId ===
          member?.selectedGymPackage?.recurringRatePlanChargeId ||
        (!member.isEnrolledInDefaultProgram &&
          member.isEnrolledInDigitalTier &&
          member?.digitalProgram?.currentSubscription?.ratePlanChargeId ===
            member?.selectedGymPackage?.recurringRatePlanChargeId)
      ) {
        return getOrderTypeInfo('NoAction', member);
      } else {
        // LOOKHERE
        // if (
        //   member.defaultProgram?.currentSubscriptionStatus ==
        //     'ActiveFutureTerm' &&
        //   member.defaultProgram?.memberTermDate == '9999-12-31T23:59:00' &&
        //   !member.defaultProgram?.futureSubscription
        // ) {
        //   return getOrderTypeInfo('SubscriptionNew', member);
        // }
        // Basic update
        return getOrderTypeInfo('SubscriptionUpdate', member);
      }
    } else {
      if (checkEligibility(member.eligibilities, 'studio')) {
        if (member.selectedGymPackage !== 'studio') {
          return getOrderTypeInfo('SubscriptionNew', member);
        } else {
          return getOrderTypeInfo('NoAction', member);
        }
      } else {
        // If member is cancelled or not enrolled
        if (member?.defaultProgram?.isCancelled) {
          return getOrderTypeInfo('SubscriptionNew', member);
        } else if (!member?.defaultProgram?.currentSubscription) {
          return getOrderTypeInfo('NewAccount', member);
        } else {
          return getOrderTypeInfo('NoAction', member);
        }
      }
    }
  }
};

const getOrderTypeInfo = (type, member) => {
  switch (type) {
    case 'NewAccount':
      return {
        type: 'NewAccount',
        ratePlanChargeId:
          member.selectedGymPackage === 'studio'
            ? null
            : member.selectedGymPackage.recurringRatePlanChargeId
      };
    case 'NoAction':
      return { type: 'NoAction' };
    case 'SubscriptionNew':
      return {
        type: 'SubscriptionNew',
        ratePlanChargeId: member.selectedGymPackage.recurringRatePlanChargeId
      };
    case 'SubscriptionUpdate':
      return {
        type: 'SubscriptionUpdate',
        keepDigital: true,
        newRatePlanChargeId:
          member.selectedGymPackage.recurringRatePlanChargeId,
        currentRatePlanChargeId: member?.defaultProgram?.futureSubscription
          ? member?.defaultProgram?.futureSubscription.ratePlanChargeId
          : member?.defaultProgram?.currentSubscription?.ratePlanChargeId,
        currentSubscriptionNumber: member?.defaultProgram?.futureSubscription
          ? member?.defaultProgram?.futureSubscription.subscriptionNumber
          : member?.defaultProgram?.currentSubscription?.subscriptionNumber
      };
    case 'DigitalDowngrade':
      return {
        type: 'Cancel',
        keepDigital: true,
        subscriptionNumber: member?.defaultProgram?.futureSubscription
          ? member?.defaultProgram?.futureSubscription.subscriptionNumber
          : member?.defaultProgram?.currentSubscription?.subscriptionNumber
      };
    default:
      return { type: 'NoAction' };
  }
};

const createOrderActions = (
  member,
  isEditingPlan,
  selectedPaymentMethod = null,
  isGettingCurrentPlan = false
) => {
  let orderAction = {
    ...getOrderActionType(member, isEditingPlan, isGettingCurrentPlan),
    ...(selectedPaymentMethod && { paymentMethodPageId: selectedPaymentMethod })
  };
  return [orderAction];
};

export const getOrderInformation = (
  isDependentAccessLinked,
  programCode,
  userData,
  enrollmentData,
  isEditingPlan,
  paymentToken = '',
  isGettingCurrentPlan
) => {
  const {
    primarySelectedPackage,
    selectedPaymentMethod,
    promoCode,
    chosenDependents
  } = enrollmentData;
  const { accountNumber } = userData;

  let orderInfo = createOrder(
    isDependentAccessLinked,
    programCode,
    {
      primaryMember: {
        ...userData,
        selectedGymPackage: primarySelectedPackage
      },
      dependents: chosenDependents && chosenDependents
    },
    isEditingPlan,
    selectedPaymentMethod,
    isGettingCurrentPlan
  );
  orderInfo = {
    ...(accountNumber && {
      loggedInAccountNumber: accountNumber
    }),
    ...(!isEditingPlan && { paymentMethodId: paymentToken }),
    ...orderInfo,
    promoCodes: promoCode
  };
  return orderInfo;
};

export const mapCartSummaryDetails = details => {
  if (!details) return;
  let updatedDetails = [];

  details.forEach(item => {
    let itemDetail = updatedDetails.find(
      detail => detail.chargeName === item.chargeName
    );
    if (itemDetail) {
      itemDetail['count'] += 1;
      // updatedDetails.push(itemDetail);
    } else {
      item['count'] = 1;
      updatedDetails.push(item);
    }
  });

  return updatedDetails.map(
    (invoice, i) =>
      invoice.chargeType === 'RECURRING' && (
        <div className="ml-1 mr-2 d-flex justify-content-between">
          <p className="m-0" key={i}>
            {invoice.count > 1 && `(${invoice.count}) `}
            <span className="text-uppercase font-italic">{`${
              invoice.chargeName.split('Package')[0]
            }`}</span>{' '}
            Fitness Package
          </p>
          <p className="m-0">
            $
            {invoice.count
              ? (invoice.count * invoice.amountWithoutTax).toFixed(2)
              : invoice.amountWithoutTax.toFixed(2)}
          </p>
        </div>
      )
  );
};
