import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../assets/Icons';

const Card = ({
  title,
  titleAction,
  titleActionText,
  titleIcon,
  children,
  submitButtonText,
  submitButtonAction,
  submitButtonIcon,
  cancelButtonText,
  cancelButtonAction,
  cancelButtonIcon
}) => {
  return (
    <>
      <div className="card m-4 shadow">
        <div className="card-header d-flex flex-row align-items-center">
          {titleIcon && (
            <Icon
              width="16px"
              name={titleIcon}
              height="26px"
              className="mr-2"
            />
          )}
          <h5 className="text-uppercase font-weight-bold m-0">{title}</h5>
          {titleAction && (
            <NavLink to={titleAction} className="ml-auto btn-link__underline">
              {titleActionText}
            </NavLink>
          )}
        </div>
        <div className="card-body d-flex flex-column">
          {children}
          <div className="my-3">
            {cancelButtonAction && (
              <button
                className="btn btn-outline-secondary align-self-center mr-4 px-4"
                onClick={cancelButtonAction}
              >
                {cancelButtonIcon && (
                  <span className="fill-primary">
                    <Icon
                      width="16px"
                      name={cancelButtonIcon}
                      height="16px"
                      className="mr-2"
                    />
                  </span>
                )}
                {cancelButtonText}
              </button>
            )}
            {submitButtonAction && (
              <button
                className="btn btn-primary rounded-pill align-self-center mr-4 px-4"
                onClick={submitButtonAction}
              >
                {submitButtonIcon && (
                  <Icon
                    width="16px"
                    name={submitButtonIcon}
                    height="16px"
                    className="mr-2"
                    fill="#fff"
                  />
                )}
                {submitButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Card };
