export const SET_COM = 'SET_COM';
export const SET_CMS = 'SET_CMS';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLASSESWIDGETURL = 'SET_CLASSESWIDGETURL';
export const SET_AUTH_URL = 'SET_AUTH_URL';
export const SET_LOADING = 'SET_LOADING';
export const SET_IDP_CLIENT_ID = 'SET_IDP_CLIENT_ID';
export const IS_REFRESHING_TOKEN = 'IS_REFRESHING_TOKEN';
export const SET_ANALYTICS = 'SET_ANALYTICS';
export const SET_STRINGS = 'SET_STRINGS';
export const SET_IN_CONTACT = 'SET_IN_CONTACT';

const setCom = (com, state) => ({ ...state, com });

const setCMS = (cms, state) => ({ ...state, cms });

const setStrings = (strings, state) => ({ ...state, strings });

const setInContact = (inContact, state) => ({ ...state, inContact });

const setClient = (client, state) => ({ ...state, client });

const setClassesWidgetUrl = (classesWidgetUrl, state) => ({
  ...state,
  classesWidgetUrl
});

const setAuthUrl = (authUrl, state) => ({ ...state, authUrl });

const setIdpClientId = (idpClientId, state) => ({ ...state, idpClientId });

const setLoading = (loadingType, isLoading, state) => ({
  ...state,
  [loadingType]: isLoading
});

export const configReducer = (state, action) => {
  switch (action.type) {
    case SET_COM:
      return setCom(action.com, state);
    case SET_CMS:
      return setCMS(action.cms, state);
    case SET_STRINGS:
      return setStrings(action.strings, state);
    case SET_IN_CONTACT:
      return setInContact(action.inContact, state);
    case SET_ANALYTICS:
      return { ...state, ...action.analytics };
    case SET_CLIENT:
      return setClient(action.client, state);
    case SET_CLASSESWIDGETURL:
      return setClassesWidgetUrl(action.classesWidgetUrl, state);
    case SET_AUTH_URL:
      return setAuthUrl(action.authUrl, state);
    case SET_IDP_CLIENT_ID:
      return setIdpClientId(action.idpClientId, state);
    case SET_LOADING:
      return setLoading(action.loadingType, action.isLoading, state);
    case IS_REFRESHING_TOKEN:
      return { ...state, isRefreshingToken: action.isRefreshingToken };
    default:
      return state;
  }
};
