import React from 'react';
import { useGlobalState } from '../../context/GlobalContext';

const Footer = () => {
  const [{ configState }] = useGlobalState();
  const { strings } = configState;
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: strings.footer }} />
    </React.Fragment>
  );
};

export { Footer };
