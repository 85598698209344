import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { LoadingSpinner } from '../Global/LoadingSpinner';
import { useEnrollmentState } from '../../context/EnrollmentContext';
import { useUserState } from '../../context/UserContext';
import { setUserInfo } from '../../context/actions/user';
import { useGlobalState } from '../../context/GlobalContext';
import { toastNotification } from '../../utilities/toastMessage';
import { ManagePlanPaymentModal } from './ManagePlanPaymentModal';
import { set } from 'date-fns';
import { EditAddressModal } from './EditAddressModal';
import { useLocation, useHistory } from 'react-router-dom';
import { ManagePaymentMethodModal } from './ManagePaymentMethods/ManagePaymentMethodModal';

const ResumeAccountModal = ({
  isOpen,
  setIsOpen,
  isPrimary,
  disabled,
  submitText,
  loaderText = '',
  selfClosing = false,
  submit = null,
  cancelAction = null,
  size = 'md',
  user,
  isDismissable = false, //default
}) => {
  const [userState, userDispatch] = useUserState();
  const { accountNumber, consumerId } = userState;
  const [enrollmentState, enrollmentDispatch] = useEnrollmentState();
  const [isLoading, setIsLoading] = useState(false);
  const [immediatePayment, setImmediatePayment] = useState('');
  const [showManagePlanPaymentModal, setShowManagePlanPaymentModal] = useState(
    false
  );
  const { digitalProgram, defaultProgram } = user;
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [
    {
      tierState,
      configState: {
        client,
        com: {
          programCode,
          entitlements: { isDependentAccessLinked },
          uiConfig: { canEnrollDependents }
        }
      }
    }
  ] = useGlobalState();
  const history = useHistory();

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const todaysDate = year + '-' + month + '-' + day;

  useEffect(() => {
    processOrderPreview();
  }, []);

  const processOrderPreview = async () => {
    setIsLoading(true);
    try {
      if (user.isPrimary) {
        let resume = await axios.post(
          '/api/prime/v2/account/previewupdatesubscriptions',
          {
            loggedInAccountNumber: accountNumber,
            members: [
              {
                accountNumber: accountNumber,
                consumerId: consumerId,
                isPrimary: true,
                orderActions: [
                  {
                    type: 'SubscriptionResume',
                    resumeDate: todaysDate,
                    SubscriptionNumber: userState?.defaultProgram?.isSuspended
                      ? userState?.defaultProgram?.futureSubscription
                        ? userState?.defaultProgram?.futureSubscription
                            .subscriptionNumber
                        : userState?.defaultProgram?.currentSubscription &&
                          userState?.defaultProgram?.currentSubscription
                            .subscriptionNumber
                      : userState?.digitalProgram?.isSuspended &&
                        userState?.digitalProgram?.currentSubscription
                          .subscriptionNumber
                  }
                ]
              }
            ]
          }
        );
        await setImmediatePayment(resume.data.data.immediatePaymentSummary);
        setIsLoading(false);
      } else {
        let resume = await axios.post(
          '/api/prime/v2/account/previewupdatesubscriptions',
          {
            loggedInAccountNumber: accountNumber,
            members: [
              {
                accountNumber: user.accountNumber,
                consumerId: user.consumerId,
                isPrimary: false,
                orderActions: [
                  {
                    type: 'SubscriptionResume',
                    resumeDate: todaysDate,
                    SubscriptionNumber: user?.defaultProgram?.isSuspended
                      ? user?.defaultProgram?.futureSubscription
                        ? user?.defaultProgram?.futureSubscription
                            .subscriptionNumber
                        : user?.defaultProgram?.currentSubscription &&
                          user?.defaultProgram?.currentSubscription
                            .subscriptionNumber
                      : user?.digitalProgram?.isSuspended &&
                        user?.digitalProgram?.currentSubscription
                          .subscriptionNumber
                  }
                ]
              },
              {
                accountNumber: userState.accountNumber,
                consumerId: userState.consumerId,
                isPrimary: true,
                orderActions: [
                  {
                    type: 'NoAction'
                  }
                ]
              }
            ]
          }
        );
        await setImmediatePayment(resume.data.data.immediatePaymentSummary);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setIsOpen(!isOpen);
      console.log(err);
      throw err;
    }
  };

  const submitOrder = async () => {
    setIsLoading(true);
    setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
    try {
      if (user.isPrimary) {
        let resume = await axios.post(
          '/api/prime/v2/account/submitupdatesubscriptions',
          {
            loggedInAccountNumber: accountNumber,
            members: [
              {
                accountNumber: accountNumber,
                consumerId: userState?.consumerId,
                isPrimary: true,
                orderActions: [
                  {
                    type: 'SubscriptionResume',
                    resumeDate: todaysDate,
                    SubscriptionNumber: userState?.defaultProgram?.isSuspended
                      ? userState?.defaultProgram?.futureSubscription
                        ? userState?.defaultProgram?.futureSubscription
                            .subscriptionNumber
                        : userState?.defaultProgram?.currentSubscription &&
                          userState?.defaultProgram?.currentSubscription
                            .subscriptionNumber
                      : userState?.digitalProgram?.isSuspended &&
                        userState?.digitalProgram?.currentSubscription
                          .subscriptionNumber
                  }
                ]
              }
            ]
          }
        );
        await setUserInfo(programCode, client, userDispatch);
        toastNotification(
          'Your plan account has been successfully resumed',
          true
        );
        setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
      } else {
        let resume = await axios.post(
          '/api/prime/v2/account/submitupdatesubscriptions',
          {
            loggedInAccountNumber: user.accountNumber,
            members: [
              {
                accountNumber: user.accountNumber,
                isPrimary: false,
                consumerId: user?.consumerId,
                orderActions: [
                  {
                    type: 'SubscriptionResume',
                    resumeDate: todaysDate,
                    SubscriptionNumber: user?.defaultProgram?.isSuspended
                      ? user?.defaultProgram?.futureSubscription
                        ? user?.defaultProgram?.futureSubscription
                            .subscriptionNumber
                        : user?.defaultProgram?.currentSubscription &&
                          user?.defaultProgram?.currentSubscription
                            .subscriptionNumber
                      : user?.digitalProgram?.isSuspended &&
                        user?.digitalProgram?.currentSubscription
                          .subscriptionNumber
                  }
                ]
              },
              {
                accountNumber: userState.accountNumber,
                consumerId: userState.consumerId,
                isPrimary: true,
                orderActions: [
                  {
                    type: 'NoAction'
                  }
                ]
              }
            ]
          }
        );
        await setUserInfo(programCode, client, userDispatch);
        toastNotification(
          'Your plan account has been successfully resumed',
          true
        );
        setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
      }
    } catch (err) {
      setIsLoading(false);
      setIsOpen(!isOpen);
      history.push('/error', { error: 'orderSubmitFail' });
      console.log(err);
    } finally {
      setIsLoading(false);
      setIsOpen(!isOpen);
    }
  };


  const setBackdrop = (val) => {
    return isDismissable ? val : 'static';
  }


  return (
    <>
      <Modal
        size={size}
        toggle={!selfClosing && (() => setIsOpen(!isOpen))}
        centered
        isOpen={isOpen}
        backdrop={setBackdrop(true)}
      >
        <ModalHeader
          className="ml-4"
          toggle={!selfClosing && isDismissable ? () => setIsOpen(!isOpen) : null}
        >
          {user.isPrimary ? 'Resume Your Account?' : 'Resume Account?'}
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <LoadingSpinner additionalText={loaderText} />
          ) : (
            <>
              <p className="mx-4">
                Are you sure you want to resume this account today,{' '}
                <b>{moment().format('MM/DD/YY')}</b>?
              </p>
              <p className="mx-4 mb-0">
                Please note: a prorated fee of{' '}
                {immediatePayment && (
                  <b>
                    <i>
                      $
                      {Number(
                        immediatePayment.immediateTotalPaymentAfterDiscountAmountWithTax
                      ).toFixed(2)}{' '}
                    </i>
                  </b>
                )}{' '}
                will be charged for the remainder of the current billing cycle.
              </p>
            </>
          )}
        </ModalBody>

        {!isLoading && (<ModalFooter>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            <Button
              onClick={
                !cancelAction ? () => setIsOpen(!isOpen) : () => cancelAction()
              }
              className="btn bg-white text-primary col"
            >
              Cancel
            </Button>
            <Button
              onClick={() => setShowManagePlanPaymentModal(true)}
              className="btn btn-primary col"
              disabled={isLoading}
            >
              {user.isPrimary ? 'Resume My Account' : 'Resume Account'}
            </Button>
          </div>
        </ModalFooter>)}
      </Modal>
      <ManagePlanPaymentModal
        showManagePlanPaymentModal={showManagePlanPaymentModal}
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
        setShowPaymentModal={setShowPaymentModal}
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
        title={
          immediatePayment &&
          immediatePayment.immediateTotalPaymentAfterDiscountAmountWithTax > 0
            ? `Checkout - $
            ${immediatePayment.immediateTotalPaymentAfterDiscountAmountWithTax.toFixed(
              2
            )}`
            : 'Checkout'
        }
        submit={submitOrder}
      />
      <EditAddressModal
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
      />
      <ManagePaymentMethodModal
        setIsManagePaymentMethodModalOpen={setShowPaymentModal}
        isManagePaymentMethodModalOpen={showPaymentModal}
      />
    </>
  );
};

export { ResumeAccountModal };
