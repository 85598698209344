import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';

const FilterOptionButton = ({ buttonText, filterChosen, toggleFilter }) => (
  <button
    className={classNames(
      'btn',
      'btn-primary',
      'btn-sm',
      'px-md-3',
      'px-5',
      'border',
      'text-nowrap',
      filterChosen ? 'active' : null
    )}
    onClick={() => toggleFilter()}
  >
    {buttonText}
    <FontAwesomeIcon icon="angle-down" className="arrow-icon" />
  </button>
);

FilterOptionButton.propTypes = {
  buttonText: PropTypes.string,
  filterChosen: PropTypes.bool,
  toggleFilter: PropTypes.func
};

export { FilterOptionButton };
