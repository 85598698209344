import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { LoadingSpinner } from './LoadingSpinner';

let ModalContainer = ({
  isOpen,
  setIsOpen,
  children,
  disabled,
  cancelText,
  submitText,
  isLoading = false,
  loaderText = 'Processing.... This may take a minute.\r\nPlease do not refresh the page.', //default
  selfClosing = false,
  submit = null,
  cancelAction = null,
  title = '',
  size = 'md',
  singleButton = false,
  titleSubText = null,
  disclaimer = null,
  isDismissable = false, //default
  zuora = true
}) => {
  const handleCloseButtonClick = () => {
    if (isDismissable && !selfClosing) {
      setIsOpen(!isOpen);
    }
  };

  const closeButton = isDismissable && (
    <button
      type="button"
      class="close"
      aria-label="Close"
      onClick={handleCloseButtonClick}
    >
      {isDismissable && <span aria-hidden="true">×</span>}
    </button>
  );

  const setBackdrop = val => {
    return isDismissable ? val : 'static';
  };

  const setKeyboard = val => {
    return isDismissable ? val : false;
  };

  return (
    <Modal
      size={size}
      centered
      isOpen={isOpen}
      backdrop={setBackdrop(true)}
      keyboard={setKeyboard(true)}
      toggle={!selfClosing && (() => setIsOpen(!isOpen))} //!selfClosing && (() => setIsOpen(!isOpen))
    >
      {zuora && (
        <ModalHeader close={closeButton}>
          {title}
          {titleSubText && <p className="font-small m-0">{titleSubText}</p>}
        </ModalHeader>
      )}
      <ModalBody>
        {isLoading ? <LoadingSpinner additionalText={loaderText} /> : children}
      </ModalBody>
      {submit && !isLoading && (
        <ModalFooter>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            {!isLoading && singleButton ? (
              <Button
                disabled={isLoading}
                onClick={() => submit()}
                disabled={disabled}
                className="btn btn-primary col"
              >
                {submitText}
              </Button>
            ) : (
              <>
                {!isLoading && (
                  <Button
                    disabled={isLoading}
                    onClick={
                      !cancelAction
                        ? () => setIsOpen(!isOpen)
                        : () => cancelAction()
                    }
                    className="btn bg-white text-primary col"
                  >
                    {cancelText}
                  </Button>
                )}

                {!isLoading && (
                  <Button
                    disabled={isLoading}
                    onClick={() => submit()}
                    disabled={disabled}
                    className="btn btn-primary col"
                  >
                    {submitText}
                  </Button>
                )}
              </>
            )}
          </div>
        </ModalFooter>
      )}
      {disclaimer}
    </Modal>
  );
};
export { ModalContainer };
