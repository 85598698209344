import axios from 'axios';

const getNewGymsAndStudios = async (programCode, client, currentLocation) => {
  try {
    const gymList = await getNewLocations(programCode, client, currentLocation);
    const studioList = await getNewStudios(client, currentLocation);
    return { gymList, studioList };
  } catch (error) {
    return error;
  }
};

const getNewLocationsWithElite = async (
  programCode,
  client,
  currentLocation
) => {
  return axios
    .get(
      `/api/${client}/network/locations/search?programCode=${programCode}&latitude=${
        currentLocation.lat
      }&longitude=${currentLocation.lng}&maxResults=200&radius=${50}`
    )
    .then(data => {
      const locationData = data.data.data.list.map(location => {
        const { distance } = location;
        const {
          id,
          amenities,
          city,
          isHomeLocationTier,
          name,
          latitude,
          longitude,
          phone,
          street,
          state,
          zip,
          genderSpecific,
          marketingNotes,
          tierName,
          tierNumber,
          website
        } = location.location;
        let amenityCategories = location.location.amenities
          .map(amenity => amenity.categoryName)
          .filter(category => category != null);
        amenityCategories = amenityCategories.filter(
          (name, i) => amenityCategories.indexOf(name) >= i
        );
        return {
          id,
          amenities,
          amenityCategories,
          isHomeLocationTier,
          city,
          name,
          latitude,
          longitude,
          genderSpecific,
          phone,
          marketingNotes,
          street,
          state,
          zip,
          tierName,
          tierNumber,
          distance,
          website
        };
      });
      const eliteLocations = [];
      for (let i = 0; i < locationData.length; i++) {
        if (locationData[i].isHomeLocationTier) {
          eliteLocations.push(locationData[i]);
          locationData.splice(i, 1);
        }
      }
      const sortedEliteLocations = eliteLocations.sort((a, b) =>
        a.distance > b.distance ? 1 : -1
      );
      return [
        ...sortedEliteLocations,
        ...locationData.sort((a, b) => (a.distance > b.distance ? 1 : -1))
      ];
    });
};

const getEliteGyms = async (programCode, client, currentLocation) => {
  return axios
    .get(
      `/api/${client}/network/locations/search?programCode=${programCode}&latitude=${
        currentLocation.lat
      }&longitude=${currentLocation.lng}&maxResults=200&radius=${50}`
    )
    .then(data => {
      const locationData = data.data.data.list.map(location => {
        const { distance } = location;
        const {
          id,
          amenities,
          city,
          isHomeLocationTier,
          name,
          latitude,
          longitude,
          phone,
          street,
          state,
          zip,
          marketingNotes,
          genderSpecific,
          tierName,
          tierNumber,
          website
        } = location.location;
        let amenityCategories = location.location.amenities
          .map(amenity => amenity.categoryName)
          .filter(category => category != null);
        amenityCategories = amenityCategories.filter(
          (name, i) => amenityCategories.indexOf(name) >= i
        );
        return {
          id,
          amenities,
          amenityCategories,
          isHomeLocationTier,
          city,
          name,
          latitude,
          longitude,
          genderSpecific,
          phone,
          street,
          state,
          zip,
          marketingNotes,
          tierName,
          tierNumber,
          distance,
          website
        };
      });
      const eliteGyms = [];
      for (let i = 0; i < locationData.length; i++) {
        if (locationData[i].isHomeLocationTier) {
          eliteGyms.push(locationData[i]);
          locationData.splice(i, 1);
        }
      }
      const sortedEliteGyms = eliteGyms.sort((a, b) =>
        a.distance > b.distance ? 1 : -1
      );
      return sortedEliteGyms;
    });
};

const getNewLocations = async (programCode, client, currentLocation) => {
  try {
    const locations = await axios.get(
      `/api/${client}/network/locations/search?programCode=${programCode}&latitude=${
        currentLocation.lat
      }&longitude=${currentLocation.lng}&maxResults=200&radius=${50}`
    );
    const locationList = locations.data.data.list;
    return locationList.map(location => {
      const { distance } = location;
      const {
        id,
        amenities,
        city,
        name,
        latitude,
        longitude,
        phone,
        street,
        state,
        zip,
        marketingNotes,
        genderSpecific,
        isHomeLocationTier,
        tierName,
        tierNumber,
        website
      } = location.location;
      return {
        id,
        amenities,
        city,
        name,
        latitude,
        longitude,
        genderSpecific,
        isHomeLocationTier,
        phone,
        street,
        state,
        zip,
        marketingNotes,
        tierName,
        tierNumber,
        distance,
        website
      };
    });
  } catch (error) {
    return error;
  }
};

const getNewStudios = async (
  client,
  currentLocation,
  chosenStudioType = null
) => {
  try {
    let studioEndpoint = `/api/${client}/network/studios/search?latitude=${
      currentLocation.lat
    }&longitude=${currentLocation.lng}&maxResults=200&radius=${50}${
      chosenStudioType ? `&searchText=${chosenStudioType}` : ''
    }`;
    const studios = await axios.get(studioEndpoint);
    const studioList = studios.data.data.list;
    return studioList.map(studio => {
      const { distance } = studio;
      const { id, name } = studio.locationViewModel;
      const { website } = studio.locationViewModel.subscriberViewModel;
      const {
        phone,
        street,
        city,
        stateCode,
        postalCode,
        latitude,
        longitude,
        marketingNotes
      } = studio.locationViewModel.contactInfoViewModel;
      const zip = postalCode;
      const state = stateCode;
      const isStudio = true;
      return {
        distance,
        id,
        name,
        website,
        phone,
        street,
        city,
        state,
        zip,
        latitude,
        longitude,
        isStudio,
        marketingNotes
      };
    });
  } catch (error) {
    throw error;
  }
};

const getLocationCount = async (programCode, client, tierNumber = 0) => {
  return axios
    .get(
      `/api/${client}/network/location-summary?programCode=${programCode}&tierNumber=${tierNumber}`
    )
    .then(data => data.data.data.activeLocationCount);
};

const getStudioCount = async client => {
  return axios
    .get(`/api/${client}/network/location-summary?programCode=studio`)
    .then(data => data.data.data.activeLocationCount);
};

const getLocationByType = async (
  programCode,
  client,
  location,
  locationType,
  chosenStudioType
) => {
  try {
    if (locationType === 'studio') {
      const studios = await getNewStudios(client, location, chosenStudioType);
      return {
        list: studios,
        location
      };
    } else {
      const gyms = await getNewLocationsWithElite(
        programCode,
        client,
        location
      );
      return {
        list: gyms,
        location
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLocations = async (
  programCode,
  client,
  locationType,
  location,
  chosenStudioType
) => {
  let locations;

  try {
    locations = await getLocationByType(
      programCode,
      client,
      location,
      locationType,
      ''
    );
    return locations;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export {
  getEliteGyms,
  getNewLocations,
  getNewGymsAndStudios,
  getLocationCount,
  getStudioCount,
  getLocations
};
