import React, { useState, Fragment } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { chooseLocation } from '../../context/actions/location';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { useGlobalState } from '../../context/GlobalContext';
import { getCurrentMapLocation } from '../../context/actions/location';

const LocationSearchInput = ({ setShowGetLocationModal }) => {
  const [{ locationState }, dispatch] = useGlobalState();
  const { currentUserLocation } = locationState;
  const [address, setAddress] = useState('');
  const [modifiedSuggestions, setModifiedSuggestions] = useState([]);

  const handleSelect = address => {
    if (locationState.chosenLocation) {
      dispatch(chooseLocation(null));
    }
    if (
      address.props &&
      address.props.children[1] === '  Use Current Location'
    ) {
      dispatch(getCurrentMapLocation(currentUserLocation));
    } else {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          dispatch(getCurrentMapLocation(latLng));
          if (setShowGetLocationModal) setShowGetLocationModal(false);
        })
        .catch(error => console.error('Error', error));
    }
  };

  const trimUSA = passedAddress => {
    return typeof passedAddress === 'string' && passedAddress.endsWith(', USA')
      ? passedAddress.slice(0, passedAddress.length - 5)
      : passedAddress;
  };

  const addCurrentLocationButton = suggestions => {
    if (
      navigator.geolocation &&
      (suggestions.length === 0 ||
        (suggestions.length > 0 && suggestions[0].id !== 'currentLocationBtn'))
    ) {
      const currentLocationBtn = {
        id: 'currentLocationBtn',
        placeId: 'currentLocationBtn',
        index: 0,
        description: (
          <div>
            <FontAwesomeIcon color="#006dc6" icon={faLocationArrow} />
            {'  Use Current Location'}
          </div>
        )
      };
      suggestions.forEach(suggestion => (suggestion.index += 1));
      suggestions.unshift(currentLocationBtn);
    }
    setModifiedSuggestions(suggestions);
  };

  const handleInputChange = passedAddress => {
    setAddress(
      passedAddress.props &&
        passedAddress.props.children[1] === '  Use Current Location'
        ? 'Current Location'
        : trimUSA(passedAddress)
    );
    addCurrentLocationButton(modifiedSuggestions);
  };

  return (
    <div className="input-group-prepend">
      <PlacesAutocomplete
        value={address}
        onChange={handleInputChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: {
            country: 'us'
          }
        }}
        shouldFetchSuggestions={address.length >= 4}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Fragment>
            <span
              className="search-icon input-group-text"
              onClick={() => handleSelect(address)}
            >
              <FontAwesomeIcon icon="search" className="search-icon" />
            </span>
            <input
              {...getInputProps({
                placeholder: !address.length
                  ? 'Search by address, city, or zip code'
                  : `Searching within ${address}`,
                className: 'location-search-input form-control'
              })}
            />
            <div className="autocomplete-dropdown-container shadow">
              {loading && <div>Loading...</div>}
              {suggestions.length > 0 && addCurrentLocationButton(suggestions)}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{trimUSA(suggestion.description)}</span>
                  </div>
                );
              })}
            </div>
          </Fragment>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export { LocationSearchInput };
