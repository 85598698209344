import React, { useEffect, useState } from 'react';
import { AddressForm } from '../Global';
import axios from 'axios';
import { checkIfAddressValid } from '../../utilities/checkIfAddressValid';
import { setUserInfo } from '../../context/actions/user';
import { useUserState } from '../../context/UserContext';
import { toastNotification } from '../../utilities/toastMessage';
import { LoadingSpinner } from '../Global/LoadingSpinner';
import { useGlobalState } from '../../context/GlobalContext';

function EditAddress() {
  const [
    { address1, address2, city, state, zip, personId },
    userDispatch
  ] = useUserState();
  const [address, setAddress] = useState(null);

  const [addressError, setAddressError] = useState({});

  useEffect(() => {
    if (address1 || address2 || city || state || zip) {
      setAddress({ address1, address2, city, state, zip });
    } else {
      setAddress({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      });
    }
  }, []);
  const [loading, setLoading] = useState(false);
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  const handleSubmit = async () => {
    const addressIsValid = await checkIfAddressValid(address);

    if (typeof addressIsValid === 'object') {
      setAddressError({ ...addressIsValid });
    } else if (addressIsValid) {
      setAddressError({});
      setLoading(true);
      let response;
      try {
        response = await axios.put(`/api/prime/v2/account/updateaddress`, {
          personId,
          address1: address.address1,
          city: address.city,
          zipCode: address.zip,
          state: address.state
        });
        if (!response.data.success) throw new Error();
        await setUserInfo(programCode, client, userDispatch);
        toastNotification(
          'Your shipping address has been successfully updated.',
          true
        );
      } catch (error) {
        toastNotification('Your shipping address could not be updated.', false);
        throw error;
      } finally {
        setLoading(false);
      }
    } else {
      setAddressError({
        shippingAddress: 'The address you entered is invalid'
      });
    }
  };

  const onAddressChange = addressData => {
    const { values, touched, errors, addressType } = addressData;

    if (addressType === 'shippingAddress') {
      if (values) {
        setAddress(values);
      }
      if (errors) {
        setAddressError(errors);
      }
    }
  };

  return (
    <div>
      <h5>Shipping (sold to) Address</h5>
      <p>
        This is the address that will show on your invoices and is used to
        calculate tax rates
      </p>
      {loading ? (
        <LoadingSpinner isLoading={loading} />
      ) : (
        <>
          {/* {status && status === 'success' && (
            <p className="text-center text-success">
              The address on file has been updated.
            </p>
          )} */}
          {address && (
            <AddressForm
              addressType="shippingAddress"
              initialValues={address}
              onAddressChange={onAddressChange}
              addressErrors={addressError}
              persist={false}
            />
          )}
          <button
            className="btn btn-primary px-3 py-2 ml-3"
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </>
      )}
    </div>
  );
}

export { EditAddress };
