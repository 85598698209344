import axios from 'axios';
import { getImageURL } from '../utilities/imageUrl';

const getBillingHistory = async (
  client,
  accountId,
  pageNumber = 1,
  pageSize = 40,
  allData = []
) => {
  try {
    const endPoint = `/api/${client}/commerce/invoices/${accountId}`;
    const response = await axios.get(endPoint, {
      params: { pageNumber, pageSize }
    });
    const data = response.data.data.invoices;
    allData.push(...data);
    if (response.data.data.moreInvoices) {
      return await getBillingHistory(
        client,
        accountId,
        pageNumber + 1,
        (pageSize = 40),
        allData
      );
    } else {
      return allData;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const downloadInvoice = async (client, fileId) => {
  return axios
    .get(`/api/${client}/commerce/v2/invoices/file/${fileId}`, {
      responseType: 'blob'
    })
    .then(data => {
      const downloadLink = document.createElement('a');
      const fileName = `invoice-${fileId}.pdf`;

      const file = new Blob([data.data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        const fileName = `invoice-${fileId}.pdf`;
        window.navigator.msSaveOrOpenBlob(file, fileName);
      }

      downloadLink.href = URL.createObjectURL(file);
      downloadLink.download = fileName;
      document.body.append(downloadLink);
      downloadLink.click();
      downloadLink.remove();
      // window.addEventListener('focus', e=>URL.revokeObjectURL(downloadLink.href), {once:true});
    })
    .catch(error => error);
};

const downloadCard = async personId => {
  const url = await getImageURL(personId);
  const fileName = `membership-card.png`;
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.append(link);
  link.click();
};

const updateHomeGym = async homeGymCode => {
  return axios
    .put(`/api/prime/v2/account/sethomegym?homeGymCode=${homeGymCode}`)
    .then(data => {
      return data;
    });
};

const printCard = async personId => {
  const url = await getImageURL(personId);
  let myWindow = window.open('');
  myWindow.document.write(`<img src=${url} alt="card" />`);
  setTimeout(() => {
    myWindow.focus();
    myWindow.print();
  }, 10);
  myWindow.document.close();
};

export {
  getBillingHistory,
  downloadInvoice,
  downloadCard,
  printCard,
  updateHomeGym
};
