import React, { useState } from 'react';
import { useGlobalState } from '../../../context/GlobalContext';
import { Icon } from '../../../assets/Icons';
import moment from 'moment';
import { useUserState } from '../../../context/UserContext';
import { DiscardModal } from '../DiscardChangeModal';
import { isUnderage } from '../../../utilities/checkIfUnderage';

const ManageHomeGym = ({
  setShowLocationSearchModal,
  showLocationSearchModal,
  user
}) => {
  const [{ tierState, configState }] = useGlobalState();
  const [userState] = useUserState();
  const {
    defaultProgram: { currentHomeGym, futureHomeGym, tier }
  } = userState;
  const { homeLocationTier } = tierState;
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);
  const { cms } = configState;
  const currentDate = new Date(Date.now());
  const formattedHomeGymEndDate = new Date(currentHomeGym?.homeGymEndDate);

  return (
    <>
      {homeLocationTier && (
        <div className="d-flex flex-xl-row flex-column justify-content-between list-group-item">
          <div className=" w-100 d-xl-flex d-inline-block flex-lg-row flex-column">
            <div className="align-self-center pr-4 text-sm-center">
              <Icon
                name="home-gym"
                width="40px"
                height="40px"
                className="ml-3"
                fill={cms.primary_color}
              />
              <p className="font-weight-bold font-small m-0 nowrap-responsive">
                HOME GYM
              </p>
            </div>
            <div className="align-self-center pl-xl-4 border-xl-left">
              {!user.isDependentToMember ||
              (user.isDependentToMember &&
                user.defaultProgram.currentHomeGym) ||
              (isUnderage(user.dateOfBirth) && currentHomeGym) ? (
                <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
                  Current
                </p>
              ) : (
                ''
              )}
              {(user.defaultProgram.currentHomeGym || user.isUnderage) &&
              tier == homeLocationTier.number &&
              currentDate < formattedHomeGymEndDate ? (
                <>
                  <h5 className="m-0 font-weight-bold text-uppercase">
                    {`${currentHomeGym.name} - ${currentHomeGym.city}`}
                  </h5>
                  <p className="m-0 my-2 font-small text-dark-gray">
                    {`${currentHomeGym.street}, ${currentHomeGym.city}, ${currentHomeGym.state}, ${currentHomeGym.zip}`}
                  </p>
                </>
              ) : !isUnderage(user.dateOfBirth) && !user.isDependentToMember ? (
                <p className="m-0 my-2 font-small text-dark-gray">
                  Looks like you haven't set a home gym yet. Set one now to take
                  full advantage of your{' '}
                  <span className="font-italic">ELITE</span> access.{' '}
                </p>
              ) : (
                ''
              )}
            </div>
            {user.isDependentToMember && user.isUnderage && !currentHomeGym && (
              <div className="align-self-center pl-xl-4 border-xl-left">
                <p className="m-0 my-2 font-small text-dark-gray">
                  Dependents under 18 must have the same home gym as the
                  primary. Looks like you haven't set a home gym yet. Set one
                  now to take full advantage of the{' '}
                  <span className="font-italic">ELITE</span> access.{' '}
                </p>
              </div>
            )}
            {user.isDependentToMember && !user.isUnderage && (
              <div className="align-self-center pl-xl-4 border-xl-left">
                <p className="m-0 my-2 font-small text-dark-gray">
                  Dependents over 18 manage their own home gym settings.
                </p>
              </div>
            )}
            {((futureHomeGym && user.isPrimary) ||
              (futureHomeGym && user.isUnderage) ||
              user.defaultProgram.futureHomeGym) && (
              <>
                <div className="align-self-center">
                  <Icon
                    width="24px"
                    height="24px"
                    name="back-secondary"
                    className="d-xl-flex d-none mx-3"
                    fill="blue"
                    style={{ transform: 'rotate(180deg)' }}
                  />
                  <Icon
                    width="24px"
                    height="24px"
                    name="back-secondary"
                    className="d-flex d-xl-none m-2"
                    style={{ transform: 'rotate(270deg)' }}
                  />
                </div>
                <div className="align-self-center font-small px-xl-4 h-100">
                  <p className="font-weight-bold text-uppercase text-secondary m-0">
                    New
                  </p>
                  <h5 className="m-0 font-weight-bold">
                    {`${futureHomeGym.name} - ${futureHomeGym.city}`}
                  </h5>
                  <p className="m-0 font-small text-dark-gray">{`${futureHomeGym.street}, ${futureHomeGym.city}, ${futureHomeGym.state}, ${futureHomeGym.zip}`}</p>
                </div>
                <div className="align-self-center font-small px-xl-4 h-100">
                  <p className="font-weight-bold text-uppercase text-secondary m-0">
                    Effective Date
                  </p>
                  <h5 className="m-0 font-weight-bold">
                    {`${moment(futureHomeGym.homeGymStartDate).format(
                      'MMMM Do'
                    )}`}
                  </h5>
                  <p className="m-0 font-small text-dark-gray">
                    {moment(futureHomeGym.homeGymStartDate).format('YYYY')}
                  </p>
                </div>
              </>
            )}
          </div>
          {(user.isPrimary && !currentHomeGym) ||
          (user.isPrimary && currentHomeGym && !futureHomeGym) ||
          (!isUnderage(user.dateOfBirth) &&
            !user.isDependentToMember &&
            !currentHomeGym) ||
          (!isUnderage(user.dateOfBirth) &&
            !user.isDependentToMember &&
            currentHomeGym &&
            !futureHomeGym) ? (
            <div className="align-self-center px-xl-3">
              <button
                onClick={() =>
                  setShowLocationSearchModal(!showLocationSearchModal)
                }
                className="btn btn-outline-secondary"
              >
                {currentHomeGym ? 'Change' : 'Set Home Gym'}
              </button>
            </div>
          ) : (
            futureHomeGym &&
            !user.isDependentToMember && (
              <div className="d-flex justify-content-end">
                <button
                  className="btn align-self-center text-danger pl-0 w-xl-100 text-xl-right text-left"
                  onClick={() => setDiscardModalOpen(true)}
                >
                  <Icon
                    width="16px"
                    height="16px"
                    name="undo"
                    className="mr-2 mb-1"
                  />
                  Discard Change
                </button>
              </div>
            )
          )}
        </div>
      )}
      {isDiscardModalOpen && (
        <DiscardModal
          setDiscardModalOpen={setDiscardModalOpen}
          isDiscardModalOpen={isDiscardModalOpen}
          type={'Home Gym Change'}
          user={userState}
        />
      )}
    </>
  );
};

export { ManageHomeGym };
