import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useLocation } from 'react-router-dom';
import { Marker, ClusterMarker } from '.';
import Supercluster from 'supercluster';
import { fitBounds } from 'google-map-react';
import { useGlobalState } from '../../context/GlobalContext';
import {
  chooseLocation,
  getCurrentMapLocation,
  setMapData
} from '../../context/actions/location';
import { LoadingSpinner } from '../Global/LoadingSpinner';

const Map = ({ hoveredLocation, showName, height }) => {
  const location = useLocation();
  const [{ locationState, configState }, dispatch] = useGlobalState();
  const {
    chosenLocation,
    chosenLocationType,
    currentUserLocation,
    currentMapLocation,
    mapData,
    loadingLocations,
    gymList,
    studioList,
    visibleGyms,
    visibleStudios
  } = locationState;
  const [mapZoom, setMapZoom] = useState(11);
  const [clusters, setClusters] = useState([]);
  const [studioClusters, setStudioClusters] = useState([]);
  const [loadingClusters, setLoadingClusters] = useState(false);
  const [superCluster, setSuperCluster] = useState({});
  const [studioSuperCluster, setStudioSuperCluster] = useState({});

  useEffect(() => {
    if (!currentUserLocation) setMapZoom(5);
  }, [currentUserLocation]);

  useEffect(() => {
    if (mapData && gymList && !loadingLocations) createClusters();
  }, [
    mapData,
    gymList,
    visibleGyms,
    visibleStudios,
    chosenLocationType,
    loadingLocations
  ]);

  const handleChooseLocation = location => {
    dispatch(chooseLocation(location));
    if (location) {
      dispatch(
        getCurrentMapLocation({
          lat: location.latitude,
          lng: location.longitude
        })
      );
    }
  };

  const createStudioClusters = async () => {
    let locations = visibleGyms ? visibleGyms : gymList;
    let studios = visibleStudios ? visibleStudios : studioList;
    let list = chosenLocationType === 'studio' ? locations : studios;
    let updatedStudioList = [];
    const studioSuperClusterObject = new Supercluster({
      radius: 125
    });
    updatedStudioList = list.map(location => {
      location.type = 'Feature';
      location.geometry = {
        type: 'Point',
        coordinates: [location.longitude, location.latitude]
      };
      return location;
    });
    studioSuperClusterObject.load(updatedStudioList);
    setStudioClusters(
      studioSuperClusterObject.getClusters(
        [
          mapData.bounds.nw.lng,
          mapData.bounds.sw.lat,
          mapData.bounds.se.lng,
          mapData.bounds.ne.lat
        ],
        mapData.zoom
      )
    );
    setStudioSuperCluster(studioSuperClusterObject);
    return;
  };

  const createClusters = () => {
    let gyms = visibleGyms ? visibleGyms : gymList;
    setLoadingClusters(true);
    let updatedGymsList = [];
    const superClusterObject = new Supercluster({
      radius: 125
    });
    updatedGymsList =
      gyms &&
      gyms.length &&
      gyms.map(gym => {
        gym.type = 'Feature';
        gym.geometry = {
          type: 'Point',
          coordinates: [gym.longitude, gym.latitude]
        };
        return gym;
      });
    if (updatedGymsList) {
      superClusterObject.load(updatedGymsList);
      setClusters(
        superClusterObject.getClusters(
          [
            mapData.bounds.nw.lng,
            mapData.bounds.sw.lat,
            mapData.bounds.se.lng,
            mapData.bounds.ne.lat
          ],
          mapData.zoom
        )
      );
      setSuperCluster(superClusterObject);
      if (
        (studioList && studioList.length) ||
        (chosenLocationType && chosenLocationType === 'studio')
      ) {
        createStudioClusters().then(() => setLoadingClusters(false));
        return;
      }
    }
    setLoadingClusters(false);
  };

  const handleStudioClusterOnClick = cluster => {
    const clusterZoomLevel = studioSuperCluster.getClusterExpansionZoom(
      cluster.properties.cluster_id
    );
    setMapZoom(clusterZoomLevel);
    dispatch(
      getCurrentMapLocation({
        lat: cluster.geometry.coordinates[1],
        lng: cluster.geometry.coordinates[0]
      })
    );
  };

  const handleClusterOnClick = cluster => {
    const clusterZoomLevel = superCluster.getClusterExpansionZoom(
      cluster.properties.cluster_id
    );
    setMapZoom(clusterZoomLevel);
    dispatch(
      getCurrentMapLocation({
        lat: cluster.geometry.coordinates[1],
        lng: cluster.geometry.coordinates[0]
      })
    );
  };

  const handleMapChange = map => {
    dispatch(setMapData(map));
    const { zoom } = fitBounds(map.bounds, map.size);
    if (!currentUserLocation) {
      setMapZoom(5);
    } else {
      setMapZoom(zoom || map.zoom);
    }
  };

  const {
    com: {
      attributes: { studioDisplay }
    }, 
    cms
  } = configState;

  return (
    <div className="bg-light" style={{ height: height ? height : '100%' }}>
      {loadingLocations && location.pathname === '/enroll/select-packages' && (
        <div
          className="h-100 position-absolute"
          style={{
            zIndex: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            width: '99%'
          }}
        >
          <LoadingSpinner />{' '}
        </div>
      )}
      <GoogleMapReact
        defaultCenter={
          currentUserLocation ? currentUserLocation : { lat: 39, lng: -98 }
        }
        hoverDistance={45}
        options={{ fullscreenControl: false }}
        center={
          chosenLocation
            ? {
                lat: chosenLocation.latitude,
                lng: chosenLocation.longitude
              }
            : currentMapLocation
        }
        defaultZoom={11}
        zoom={chosenLocation ? 11 : mapZoom}
        resetBoundsOnResize
        zoomControl
        onChange={map => {
          handleMapChange(map);
        }}
      >
        {chosenLocation && (
          <Marker
            item={chosenLocation}
            key={chosenLocation.id}
            chooseLocation={handleChooseLocation}
            isChosenLocationItem
            studioColor={chosenLocation.isStudio}
            chosenLocationType={chosenLocationType}
            lat={chosenLocation.latitude}
            lng={chosenLocation.longitude}
            name={chosenLocation.name}
            showName={showName}
          />
        )}
        {!loadingLocations &&
          !loadingClusters &&
          !chosenLocation &&
          clusters.map(item =>
            item.hasOwnProperty('properties') && item.properties.cluster ? (
              <ClusterMarker
                item={item}
                key={item.id}
                zoomIntoCluster={handleClusterOnClick}
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                name={`${item.properties.point_count}`}
                studioColor={false}
                primaryColor={cms.primary_color}
              />
            ) : (
              <Marker
                item={item}
                key={item.id}
                studioColor={item.isStudio}
                chooseLocation={handleChooseLocation}
                locationHovered={hoveredLocation === item}
                isChosenLocationItem={chosenLocation === item}
                lat={item.latitude}
                lng={item.longitude}
                name={item.name}
                showName={showName}
                primaryColor={cms.primary_color}
              />
            )
          )}
        {!loadingLocations &&
          !loadingClusters &&
          !chosenLocation &&
          (studioDisplay === 'Both' || studioDisplay === 'Web') &&
          ((studioList && studioList.length) ||
            (chosenLocationType && chosenLocationType === 'studio')) &&
          studioClusters.map(item =>
            item.hasOwnProperty('properties') && item.properties.cluster ? (
              <ClusterMarker
                item={item}
                key={item.id}
                studioColor
                zoomIntoCluster={handleStudioClusterOnClick}
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                name={`${item.properties.point_count}`}
                primaryColor={cms.primary_color}
              />
            ) : (
              <Marker
                item={item}
                key={item.id}
                studioColor
                chooseLocation={handleChooseLocation}
                locationHovered={hoveredLocation === item}
                isChosenLocationItem={chosenLocation === item}
                lat={item.latitude}
                lng={item.longitude}
                name={item.name}
                showName={showName}
                primaryColor={cms.primary_color}
              />
            )
          )}
      </GoogleMapReact>
    </div>
  );
};

export { Map };
