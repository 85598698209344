import React, { useEffect, useState } from 'react';
import { Icon } from '../../assets/Icons';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { useUserState } from '../../context/UserContext';
import { useGlobalState } from '../../context/GlobalContext';
import { getPhoneNumber } from '../../utilities/getPhoneNumber';
import { ResumeAccountModal } from '../Account/ResumeAccountModal';
import { ModalContainer } from '../Global/Modal';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomerServiceHours } from './CustomerServiceHours';

const SuspensionWarning = ({
  loggedInUser,
  whereToShow,
  dependentStatus,
  startDate,
  endDate, 
  user
}) => {
  const location = useLocation();
  const [userState] = useUserState();
  const [
    {
      configState: {
        com: {
          entitlements: { isDependentAccessLinked }
        }
      }
    }
  ] = useGlobalState();
  const [subscription, setSubscription] = useState(null);
  const {
    isPrimary,
    isEnrolledInDefaultProgram,
    isEnrolledInDigitalTier,
    enrolledDependents,
    defaultProgram,
    digitalProgram
  } = userState;
  const [showResumeAccountModal, setResumeAccountModal ] = useState(false);

  useEffect(() => {
    if (
      isEnrolledInDefaultProgram &&
      (defaultProgram?.isSuspending || defaultProgram?.isSuspended)
    ) {
      setSubscription({
        ...defaultProgram,
        currentSubscriptionPauseStartDate:
          defaultProgram?.currentSubscriptionPauseStartDate,
        ...(defaultProgram?.currentSubscriptionPauseEndDate && {
          currentSubscriptionPauseEndDate:
            defaultProgram?.currentSubscriptionPauseEndDate
        })
      });
    } else if (
      isEnrolledInDigitalTier &&
      (digitalProgram?.isSuspending || digitalProgram?.isSuspended)
    ) {
      setSubscription({
        ...digitalProgram,
        currentSubscriptionPauseStartDate:
          digitalProgram?.currentSubscriptionPauseStartDate,
        ...(digitalProgram?.currentSubscriptionPauseEndDate && {
          currentSubscriptionPauseEndDate:
            digitalProgram?.currentSubscriptionPauseEndDate
        })
      });
    } else if (dependentStatus) {
      setSubscription({
        ...digitalProgram,
        currentSubscriptionPauseStartDate: startDate,
        ...(endDate && {
          currentSubscriptionPauseEndDate: endDate
        })
      });
    }

    return () => {
      setSubscription(null);
    };
  }, []);

  const getSuspensionWarningText = type => {
    switch (type) {
      case 'primary-future-suspend':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">
              Our records indicate that you have placed a temporary pause on
              your account, which will take effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              .{' '}
              {subscription?.currentSubscriptionPauseEndDate &&
                subscription?.currentSubscriptionPauseEndDate !==
                  '9999-12-31T23:59:00' && (
                  <>
                    This account is scheduled to resume on
                    {` ${format(
                      new Date(subscription?.currentSubscriptionPauseEndDate),
                      'P'
                    )}`}
                    .
                  </>
                )}
              You will not have access to the program for this period of time.
              If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'primary-suspended':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">             
              Our records indicate that you placed a temporary pause on this
              account, which took effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              .{' '}
              {subscription?.currentSubscriptionPauseEndDate &&
                subscription?.currentSubscriptionPauseEndDate !==
                  '9999-12-31T23:59:00' && (
                  <>
                    This account is scheduled to resume on
                    {` ${format(
                      new Date(subscription?.currentSubscriptionPauseEndDate),
                      'P'
                    )}`}
                    .{' '}
                  </>
                )}
              Alternatively, you can resume your account
              today by clicking the link below. Please note: a prorated fee will
              be charged for the remainder of the current billing cycle.
            </p>
            <p className="mt-3 ml-auto pr-4">
              <a onClick={() => setResumeAccountModal(true)} className="font-weight-bold cursor-pointer">
                <u>Resume Account</u>
              </a>
            </p>
          </div>
        );
      case 'dependent-future-suspend':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">
              Our records indicate that the primary account holder of this
              family bundle has placed a temporary pause on your account, which
              will take effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              .{' '}
              {subscription?.currentSubscriptionPauseEndDate &&
                subscription?.currentSubscriptionPauseEndDate !==
                  '9999-12-31T23:59:00' && (
                  <>
                    This account is scheduled to resume on
                    {` ${format(
                      new Date(subscription?.currentSubscriptionPauseEndDate),
                      'P'
                    )}`}
                    .{' '}
                  </>
                )}
              You will not have access to the program for this period of time.
              If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'dependent-suspended':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">
              Our records indicate that the primary account holder of this
              family bundle has placed a temporary pause on your account, which
              took effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              .{' '}
              {subscription?.currentSubscriptionPauseEndDate &&
                subscription?.currentSubscriptionPauseEndDate !==
                  '9999-12-31T23:59:00' && (
                  <>
                    This account is scheduled to resume on
                    {` ${format(
                      new Date(subscription?.currentSubscriptionPauseEndDate),
                      'P'
                    )}`}
                    .{' '}
                  </>
                )}
              You will not have access to the program for this period of time.
              If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'family-future-suspend':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">
              Our records indicate that you have placed a temporary pause on
              this family bundle account, which will take effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              . You and your family may not have access to the program during a
              period of time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'family-suspended':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            {/* <p className="font-small m-0 px-4">
              Our records indicate that you have placed a temporary pause on
              this family bundle account, which took effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              . You and your family may not have access to the program during a
              period of time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p> */}
            <p className="font-small m-0 px-4">
              Our records indicate that you have placed a temporary pause on 
              this family bundle account, which took effect on 
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              . You and your family may not access your program during a 
              period of time. To resume your accounts, go to the {' '}
              <NavLink
                className="align-self-center mt-4"
                to="/account/manage-plans"
              >
                <u>Manage Plans</u>
              </NavLink> page and select <i>Resume Account </i> under each member.
            </p>
          </div>
        );
      case 'manage-page-future-suspend':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              Our records indicate that you have placed a temporary pause on
              this member's account, which will take effect on
              {startDate && ` ${format(new Date(startDate), 'P')}`}.{' '}
              {endDate && endDate !== '9999-12-31T23:59:00' && (
                <>
                  This account is scheduled to resume on
                  {` ${format(new Date(endDate), 'P')}`}.{' '}
                </>
              )}
              This member will not have access to the program for this period of
              time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'manage-page-suspended':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              You have placed a temporary pause on this member’s account. {' '}
              {endDate && endDate !== '9999-12-31T23:59:00' && (
                <>
                  The account is scheduled to resume on  
                  {` ${format(new Date(endDate), 'P')}`}.{' '}
                </>
              )}
               Alternatively, you can resume this account today by clicking 
               the link below. Please note: a prorated fee will be charged 
               for the remainder of the current billing cycle.
            </p>
            <p className="mt-3 ml-auto pr-4">
              <a onClick={() => setResumeAccountModal(true)} className="font-weight-bold cursor-pointer">
                <u>Resume Account</u>
              </a>
            </p>
          </div>
        );
      case 'resume-suspension':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              Our records indicate that you placed a temporary pause on this
              account, which took effect on 2/2/2020. This account is scheduled
              to resume on 2/10/2020. Alternatively, you can resume your account
              today by clicking the link below. Please note: a prorated fee will
              be charged for the remainder of the current billing cycle.
            </p>
            <p className="mt-3 ml-auto pr-4">
              <a onClick={() => setResumeAccountModal(true)} className="font-weight-bold cursor-pointer">
                <u>Resume Account</u>
              </a>
            </p>
          </div>
        );
      case 'manage-page-walmart-future-suspend':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              Our records indicate that you have placed a temporary pause on
              this member's account, which will take effect on
              {startDate && ` ${format(new Date(startDate), 'P')}`}.{' '}
              {endDate && endDate !== '9999-12-31T23:59:00' && (
                <>
                  This account is scheduled to resume on
                  {` ${format(new Date(endDate), 'P')}`}.{' '}
                </>
              )}
              This member will not have access to the program for this period of
              time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'manage-page-walmart-suspended':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              Our records indicate that you have placed a temporary pause on
              this member's account, which took effect on
              {startDate && ` ${format(new Date(startDate), 'P')}`}.{' '}
              {endDate && endDate !== '9999-12-31T23:59:00' && (
                <>
                  This account is scheduled to resume on
                  {` ${format(new Date(endDate), 'P')}`}.{' '}
                </>
              )}
              This member will not have access to the program for this period of
              time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'walmart-future-suspend':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              Our records indicate that you have placed a temporary pause on
              this account, which will take effect on
              {startDate && ` ${format(new Date(startDate), 'P')}`}.{' '}
              {endDate && endDate !== '9999-12-31T23:59:00' && (
                <>
                  This account is scheduled to resume on
                  {` ${format(new Date(endDate), 'P')}`}.{' '}
                </>
              )}
              You and your family will not have access to the program for this
              period of time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'walmart-suspend':
        return (
          <div className="d-flex flex-column p-3 justify-content-between suspended-warning py-3 border-bottom-0 list-group-item">
            <p className="suspended-warning__title">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0">
              Our records indicate that you have placed a temporary pause on
              this account, which took effect on
              {startDate && ` ${format(new Date(startDate), 'P')}`}.{' '}
              {endDate && endDate !== '9999-12-31T23:59:00' && (
                <>
                  This account is scheduled to resume on
                  {` ${format(new Date(endDate), 'P')}`}.{' '}
                </>
              )}
              You and your family will not have access to the program for this
              period of time. If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'walmart-primary-future-suspend':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">
              Our records indicate that you have placed a temporary pause on
              your account, which will take effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              .{' '}
              {subscription?.currentSubscriptionPauseEndDate &&
                subscription?.currentSubscriptionPauseEndDate !==
                  '9999-12-31T23:59:00' && (
                  <>
                    This account is scheduled to resume on
                    {` ${format(
                      new Date(subscription?.currentSubscriptionPauseEndDate),
                      'P'
                    )}`}
                    .
                  </>
                )}
              You will not have access to the program for this period of time.
              If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      case 'walmart-primary-suspended':
        return (
          <div
            className={
              'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
            }
          >
            <p className="suspended-warning__title px-4">
              <span className="suspended-warning__icon">
                <Icon
                  name="exclamation-triangle"
                  width="19px"
                  height="17px"
                  className="suspended-warning__icon"
                />
              </span>
              Attention
            </p>
            <p className="font-small m-0 px-4">
              Our records indicate that you have placed a temporary pause on
              your account, which took effect on
              {` ${format(
                new Date(subscription?.currentSubscriptionPauseStartDate),
                'P'
              )}`}
              .{' '}
              {subscription?.currentSubscriptionPauseEndDate &&
                subscription?.currentSubscriptionPauseEndDate !==
                  '9999-12-31T23:59:00' && (
                  <>
                    This account is scheduled to resume on
                    {` ${format(
                      new Date(subscription?.currentSubscriptionPauseEndDate),
                      'P'
                    )}`}
                    .{' '}
                  </>
                )}
              You will not have access to the program for this period of time.
              If you have any questions please contact us at{' '}
              <a href={`tel:${getPhoneNumber().number}`}>
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />.
            </p>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      {subscription && (
        <>
          {isDependentAccessLinked ? (
            <>
              {whereToShow === 'banner' &&
              isPrimary &&
              subscription.isSuspending &&
              enrolledDependents?.length > 0
                ? getSuspensionWarningText('walmart-future-suspend')
                : whereToShow === 'banner' &&
                  isPrimary &&
                  subscription.isSuspending
                ? getSuspensionWarningText('walmart-primary-future-suspend')
                : whereToShow === 'banner' &&
                  subscription.isSuspended &&
                  enrolledDependents?.length > 0
                ? getSuspensionWarningText('walmart-suspend')
                : whereToShow === 'banner' &&
                  isPrimary &&
                  subscription.isSuspended &&
                  getSuspensionWarningText('walmart-primary-suspended')}

              {whereToShow === 'member' &&
              isPrimary &&
              !loggedInUser &&
              location.pathname === '/account/manage-plans' &&
              dependentStatus === 'ActiveFutureSuspend'
                ? getSuspensionWarningText('manage-page-walmart-future-suspend')
                : whereToShow === 'member' &&
                  isPrimary &&
                  !loggedInUser &&
                  location.pathname === '/account/manage-plans' &&
                  dependentStatus === 'Suspended' &&
                  getSuspensionWarningText('manage-page-walmart-suspended')}
            </>
          ) : (
            <>
              {whereToShow === 'banner' &&
                (isPrimary
                  ? subscription.isSuspending
                    ? enrolledDependents?.length > 0 &&
                      enrolledDependents.every(
                        dependent =>
                          dependent.defaultProgram.isSuspending ||
                          (dependent.isEnrolledInDigitalTier &&
                            dependent.digitalProgram.isSuspending)
                      )
                      ? getSuspensionWarningText('family-future-suspend')
                      : getSuspensionWarningText('primary-future-suspend')
                    : subscription.isSuspended &&
                      (enrolledDependents?.length > 0 &&
                      enrolledDependents.every(
                        dependent =>
                          dependent.defaultProgram.isSuspended ||
                          (dependent.isEnrolledInDigitalTier &&
                            dependent.digitalProgram.isSuspended)
                      )
                        ? getSuspensionWarningText('family-suspended')
                        : getSuspensionWarningText('primary-suspended'))
                  : !isPrimary &&
                    (subscription.isSuspended
                      ? getSuspensionWarningText('dependent-suspended')
                      : subscription.isSuspending &&
                        getSuspensionWarningText('dependent-future-suspend')))}

              {whereToShow === 'member' &&
              isPrimary &&
              !loggedInUser &&
              location.pathname === '/account/manage-plans' &&
              dependentStatus === 'ActiveFutureSuspend'
                ? getSuspensionWarningText('manage-page-future-suspend')
                : whereToShow === 'member' &&
                  isPrimary &&
                  !loggedInUser &&
                  location.pathname === '/account/manage-plans' &&
                  dependentStatus === 'Suspended' &&
                  getSuspensionWarningText('manage-page-suspended')}
            </>
          )}
        </>
      )}
      {showResumeAccountModal &&
        <ResumeAccountModal
          isOpen={showResumeAccountModal}
          setIsOpen={setResumeAccountModal}
          user={user}
        />
      }
    </>
  );
};



export { SuspensionWarning };
