import React from 'react';
import { deletePaymentMethod } from '../../../api/payments';
import { setUserInfo } from '../../../context/actions/user';
import { useUserState } from '../../../context/UserContext';
import { toastNotification } from '../../../utilities/toastMessage';
import { ModalContainer } from '../../Global/Modal';
import { useGlobalState } from '../../../context/GlobalContext';

let DeleteCardModal = ({
  setShowDeletePayment,
  showDeletePayment,
  chosenPaymentMethod,
  setChosenPaymentMethod
}) => {
  const [{}, userDispatch] = useUserState();
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  const closeDeleteCardModal = () => {
    setShowDeletePayment(!showDeletePayment);
    setChosenPaymentMethod(null);
  };

  const doDeletePaymentMethod = async () => {
    closeDeleteCardModal();
    try {
      await deletePaymentMethod(chosenPaymentMethod.id);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your payment method has been successfully updated.',
        true
      );
    } catch (err) {
      toastNotification('Your payment method could not be updated.', false);
      throw err;
    }
  };

  return (
    <ModalContainer
      setIsOpen={closeDeleteCardModal}
      isOpen={showDeletePayment}
      title="Delete Payment Method"
      cancelText="Do Not Delete"
      submitText="Delete Payment Method"
      submit={doDeletePaymentMethod}
    >
      <p>
        Are you sure you want to delete this payment method? By doing so we will
        no longer be able to issue refunds to this payment method.
      </p>
    </ModalContainer>
  );
};

export { DeleteCardModal };
