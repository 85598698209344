import React from 'react';
import { Icon } from '../../../assets/Icons';
import moment from 'moment';
import { useGlobalState } from '../../../context/GlobalContext';
import classNames from 'classnames';
import { useUserState } from '../../../context/UserContext';
import { SuspensionWarning } from '../../Global/SuspensionWarning';
import { getPhoneNumber } from '../../../utilities/getPhoneNumber';

const ManageDefaultProgram = ({
  user,
  loggedInUser,
  setDowngradeDiscardModalOpen,
  setDiscardModalOpen, 
  setDigitalDiscardModalOpen
}) => {
  const { firstName, lastName, defaultProgram, digitalProgram } = user;
  const [{ tierState }] = useGlobalState();
  const [userState] = useUserState();
  const { digitalTier } = tierState;
  const { isPrimary } = userState;

  return (
    <>
      <SuspensionWarning
        whereToShow="member"
        dependentStatus={defaultProgram?.currentSubscriptionStatus}
        startDate={defaultProgram?.currentSubscriptionPauseStartDate || null}
        endDate={defaultProgram?.currentSubscriptionPauseEndDate || null}
        user={user}
      />
      <div
        className={classNames(
          'd-flex flex-xl-row flex-column justify-content-between list-group-item',
          {
            'suspended-warning':
              (isPrimary || !loggedInUser) &&
              (defaultProgram?.isSuspending ||
                defaultProgram?.isSuspended ||
                digitalProgram?.isSuspending ||
                digitalProgram?.isSuspended)
          }
        )}
      >
        <div className=" w-100 d-xl-flex d-inline-block flex-xl-row flex-column">
          <div className="align-self-center pr-3">
            <h5 className="m-0 font-weight-bold">{`${firstName}`}</h5>
            <h5 className="m-0 font-weight-bold">{`${lastName}`}</h5>
          </div>
          <div className="align-self-center pl-xl-3 border-xl-left">
            <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
              Current Package
            </p>
            <h5 className="m-0 font-weight-bold text-uppercase">
              <i>{defaultProgram?.currentSubscription?.ratePlanName}</i>
            </h5>
            <p className="m-0 font-small text-dark-gray">{`$${Number(
              defaultProgram?.currentSubscription?.price
            ).toFixed(2)}/month`}</p>
          </div>
          {/* {isPrimary &&
            !loggedInUser &&
            defaultProgram?.isCancelling && (
              <div className="align-self-center px-lg-3">
                <p className="alert-danger border border-danger rounded p-2">
                  A subscription change is in progress for this member, no
                  further changes can be made at this time until the change has
                  been processed. Contact us at{' '}
                  <a href={`tel:${getPhoneNumber().number}`}>
                    {getPhoneNumber().text}
                  </a>{' '}
                  if you have any questions.
                </p>
              </div>
            )} */}
          {defaultProgram?.isCancelling &&
            !defaultProgram?.futureSubscription &&
            digitalProgram?.isActive && (
              <>
                <div className="align-self-center">
                  <Icon
                    width="24px"
                    height="24px"
                    name="back-secondary"
                    className="d-xl-flex d-none mx-3"
                    fill="blue"
                    style={{ transform: 'rotate(180deg)' }}
                  />
                  <Icon
                    width="24px"
                    height="24px"
                    name="back-secondary"
                    className="d-flex d-xl-none px-xl-3"
                    style={{ transform: 'rotate(270deg)' }}
                  />
                </div>
                <div className="align-self-center px-xl-3">
                  <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
                    New Package
                  </p>
                  <h5 className="m-0 font-weight-bold text-uppercase nowrap-responsive">
                    Digital Content Only
                  </h5>
                  <p className="m-0 font-small text-dark-gray">{`$${Number(
                    digitalTier[0].recurringPrice
                  ).toFixed(2)}/month`}</p>
                </div>
                <div className="align-self-center px-xl-3">
                  <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
                    Effective Date
                  </p>
                  <h5 className="m-0 font-weight-bold nowrap-responsive">
                    {`${moment(
                      defaultProgram?.defaultProgramEligibility
                        ?.currentSubscription.cancelDate
                    ).format('MMMM Do')}`}
                  </h5>
                  <p className="m-0 font-small text-dark-gray">
                    {moment(
                      defaultProgram?.defaultProgramEligibility
                        ?.currentSubscription.cancelDate
                    ).format('YYYY')}
                  </p>
                </div>
              </>
            )}
          {defaultProgram?.isTransitioning && (
            <>
              <div className="align-self-center">
                <Icon
                  width="24px"
                  height="24px"
                  name="back-secondary"
                  className="d-xl-flex d-none mx-3"
                  fill="blue"
                  style={{ transform: 'rotate(180deg)' }}
                />
                <Icon
                  width="24px"
                  height="24px"
                  name="back-secondary"
                  className="d-flex d-xl-none px-xl-3"
                  style={{ transform: 'rotate(270deg)' }}
                />
              </div>
              <div className="align-self-center px-xl-3">
                <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
                  New Package
                </p>
                <h5 className="m-0 font-weight-bold  text-uppercase nowrap-responsive">
                  {defaultProgram?.futureSubscription.ratePlanName}
                </h5>
                <p className="m-0 font-small text-dark-gray">{`$${Number(
                  defaultProgram?.futureSubscription.price
                ).toFixed(2)}/month`}</p>
              </div>
              <div className="align-self-center px-xl-3">
                <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
                  Effective Date
                </p>
                <h5 className="m-0 font-weight-bold nowrap-responsive">
                  {`${moment(
                    defaultProgram?.futureSubscription.startDate
                  ).format('MMMM Do')}`}
                </h5>
                <p className="m-0 font-small text-dark-gray">
                  {moment(defaultProgram?.futureSubscription.startDate).format(
                    'YYYY'
                  )}
                </p>
              </div>
              {isPrimary && (
                <button
                  className="btn align-self-center text-danger pl-0 text-nowrap w-xl-100 text-xl-right text-left"
                  onClick={() => {
                    setDowngradeDiscardModalOpen(true);
                  }}
                >
                  <i className="fas fa-undo mr-2"></i>
                  Discard Change
                </button>
              )}
            </>
          )}
        </div>
        {isPrimary && defaultProgram?.isCancelling && (
          <button
            className="btn align-self-center text-danger pl-0 text-nowrap w-100 text-xl-right text-left mr-3"
            onClick={() => {
               setDiscardModalOpen(true)
            }}
          >
            <i className="fas fa-undo mr-2"></i>
            {!defaultProgram?.futureSubscription && digitalProgram?.isActive
              ? 'Discard Change'
              : 'Discard Cancellation'}
          </button>
        )}
      </div>
    </>
  );
};

export { ManageDefaultProgram };
