import React from 'react';

function Checkbox({
  text,
  onChange,
  hyperLink,
  hyperLink2,
  hyperLinkValue,
  hyperLinkValue2,
  htmlFor = '',
  checked,
  name,
  optional,
  classes,
  disabled = false,
  onBlur = () => {
    return;
  },
  bold = false
}) {
  return (
    <div className="form-group m-0 p-0 d-flex align-items-start mt-2 ml-3 checkbox-wrapper">
      <input
        type="checkbox"
        className="border-primary border"
        onChange={onChange}
        checked={checked}
        id={htmlFor}
        name={name}
        hidden
        onBlur={onBlur}
        disabled={disabled}
      />
      <label for={htmlFor} className={`m-0 p-0 mr-2 checkmark ${classes}`} />
      <label className="w-100 m-0" for={htmlFor}>
        <div className={`d-inline ${bold ? 'font-weight-bold' : ''}`}>
          {text} {!optional && !hyperLink && <i className="text-danger">*</i>}
        </div>
        {hyperLink && hyperLinkValue && (
          <a href={hyperLinkValue} className="d-inline" target="_blank">
            {hyperLink}
          </a>
        )}
        {hyperLink2 && hyperLinkValue2 && ' and '}
        {hyperLink2 && hyperLinkValue2 && (
          <a href={hyperLinkValue2} className="d-inline" target="_blank">
            {hyperLink2}
          </a>
        )}
        {!optional && hyperLink && <i className="text-danger">*</i>}
      </label>
    </div>
  );
}

export { Checkbox };
