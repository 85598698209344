import React from 'react';

const DiscardOption = ({ type, setDiscardModalOpen }) => {
  return (
    <>
      <div className="list-group-item">
        <div className="m-0 alert alert-primary d-flex flex-row justify-content-between align-items-center">
          <p className="m-0">
            A change is in progress for this member in order to make any further
            adjustments, current changes have to be discarded first.
          </p>
          <button
            className="btn btn-danger bg-white border-0 text-danger"
            onClick={() => setDiscardModalOpen(true)}
          >
            <i className="fas fa-undo mr-2"></i>
            Discard {type}
          </button>
        </div>
      </div>
    </>
  );
};

export { DiscardOption };
