import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setMobileNavbarVisible } from '../../context/actions/user';
import { isUnderage } from '../../utilities/checkIfUnderage';
import { useUserState } from '../../context/UserContext';
import { useGlobalState } from '../../context/GlobalContext';

const MobileHeader = () => {
  const [userState, userDispatch] = useUserState();
  const {
    isPrimary,
    personId,
    isEligibleForDefaultProgram,
    isEnrolledInDefaultProgram,
    dateOfBirth,
    isStudioEligible,
    isStudioEnrolled,
    isFWEnrolled,
    isEnrolledInDigitalTier,
    defaultProgram
  } = userState;
  const location = useLocation();
  const [
    {
      configState,
      tierState: { digitalTier }
    }
  ] = useGlobalState();
  const {
    com: { uiConfig }
  } = configState;
  const { hasLandingPage } = uiConfig || {};

  useEffect(() => {
    function closeNavbar(e) {
      const w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (w >= 991) {
        userDispatch(setMobileNavbarVisible(false));
      }
    }
    // on init run closeNavbar to check if the client has the right device width
    closeNavbar();
    window.addEventListener('resize', closeNavbar);
    return () => {
      window.removeEventListener('resize', closeNavbar);
    };
  }, []);

  return (
    <>
      <div
        className="overlay"
        // handles click outside/overlay
        onClick={() => {
          userDispatch(setMobileNavbarVisible(false));
        }}
      ></div>
      <nav
        className="nav-bar d-flex flex-column side-nav fixed-mobile-navbar vh-100"
        style={{
          position: 'sticky'
        }}
      >
        <div className="h-100 bg-white d-flex flex-column">
          <ul className="w-75 mt-4 pt-4 navbar-nav align-self-center">
            {(isEnrolledInDefaultProgram ||
              defaultProgram?.currentSubscription ||
              isStudioEnrolled ||
              isFWEnrolled ||
              isEnrolledInDigitalTier) && (
              <li className="nav-item m-0">
                <NavLink
                  to="/account"
                  exact
                  className="nav-link"
                  onClick={() => userDispatch(setMobileNavbarVisible(false))}
                >
                  My Account
                </NavLink>
              </li>
            )}
            {hasLandingPage && isPrimary && !isEnrolledInDefaultProgram && (
              <li className="nav-item m-0">
                <NavLink
                  to="/learn-more"
                  exact
                  className="nav-link"
                  onClick={() => userDispatch(setMobileNavbarVisible(false))}
                >
                  Learn More
                </NavLink>
              </li>
            )}
            <li className="nav-item m-0">
              <NavLink
                to="/locations"
                exact
                className="nav-link"
                onClick={() => userDispatch(setMobileNavbarVisible(false))}
              >
                Locations
              </NavLink>
            </li>
            <li className="nav-item m-0">
              <NavLink
                to="/faq"
                exact
                className="nav-link"
                onClick={() => userDispatch(setMobileNavbarVisible(false))}
              >
                FAQs
              </NavLink>
            </li>
            {personId && (
              <>
                <li
                  className={classNames('nav-link', 'm-0', {
                    'font-weight-bold': location.pathname === '/account'
                  })}
                  onClick={() => {
                    userDispatch(setMobileNavbarVisible(false));
                  }}
                >
                  <NavLink
                    onClick={() => userDispatch(setMobileNavbarVisible(false))}
                    to="/account"
                    exact
                  >
                    Account Overview
                  </NavLink>
                </li>
                {isPrimary &&
                  ((isEligibleForDefaultProgram &&
                    isEnrolledInDefaultProgram) ||
                    (isStudioEligible && isStudioEnrolled)) && (
                    <>
                      <li
                        className={classNames('nav-link', 'm-0', {
                          'font-weight-bold':
                            location.pathname === '/account/manage-plans'
                        })}
                        onClick={() => {
                          userDispatch(setMobileNavbarVisible(false));
                        }}
                      >
                        <NavLink
                          onClick={() =>
                            userDispatch(setMobileNavbarVisible(false))
                          }
                          to="/account/manage-plans"
                          exact
                        >
                          Manage Plans
                        </NavLink>
                      </li>
                    </>
                  )}
                {(isPrimary || !isUnderage(dateOfBirth)) &&
                  ((isEligibleForDefaultProgram &&
                    isEnrolledInDefaultProgram) ||
                    (isStudioEligible && isStudioEnrolled)) && (
                    <li
                      className={classNames('nav-link', 'm-0', {
                        'font-weight-bold':
                          location.pathname === '/account/billing-and-payment'
                      })}
                      onClick={() => {
                        userDispatch(setMobileNavbarVisible(false));
                      }}
                    >
                      <NavLink
                        onClick={() =>
                          userDispatch(setMobileNavbarVisible(false))
                        }
                        to="/account/billing-and-payment"
                        exact
                      >
                        Billing and Payment
                      </NavLink>
                    </li>
                  )}
                <li
                  className={classNames('nav-link', 'm-0', {
                    'font-weight-bold':
                      location.pathname === '/account/activity'
                  })}
                  onClick={() => {
                    userDispatch(setMobileNavbarVisible(false));
                  }}
                >
                  <NavLink
                    onClick={() => userDispatch(setMobileNavbarVisible(false))}
                    to="/account/activity"
                    exact
                  >
                    Activity Log
                  </NavLink>
                </li>
              </>
            )}
            <li
              className={classNames('nav-link', 'm-0', {
                'font-weight-bold': location.pathname === '/terms-of-use'
              })}
              onClick={() => {
                userDispatch(setMobileNavbarVisible(false));
              }}
            >
              <a href="/terms-of-use" target="_blank">
                Terms of use
              </a>
            </li>
            <li
              className={classNames('nav-link', 'm-0', {
                'font-weight-bold': location.pathname === '/privacy-policy'
              })}
              onClick={() => {
                userDispatch(setMobileNavbarVisible(false));
              }}
            >
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li
              className={classNames('nav-link', 'm-0', {
                'font-weight-bold': location.pathname === '/privacy-policy'
              })}
              onClick={() => {
                userDispatch(setMobileNavbarVisible(false));
              }}
            >
              <a href="/fitness-program-agreement" target="_blank">
                Fitness Program Agreement
              </a>
            </li>
            {personId && (
              <>
                <li
                  className={classNames('nav-link', 'm-0', {
                    'font-weight-bold': location.pathname === '/help'
                  })}
                  onClick={() => {
                    userDispatch(setMobileNavbarVisible(false));
                  }}
                >
                  <NavLink
                    onClick={() => userDispatch(setMobileNavbarVisible(false))}
                    to="/account/help"
                    exact
                    className="account-nav-item blue"
                  >
                    Help
                  </NavLink>
                </li>
                <li
                  className={classNames('nav-link mt-2 m-0', {
                    'font-weight-bold': location.pathname === '/logout'
                  })}
                  onClick={() => {
                    userDispatch(setMobileNavbarVisible(false));
                  }}
                >
                  <NavLink
                    onClick={() => userDispatch(setMobileNavbarVisible(false))}
                    to="/logout"
                    exact
                    className="account-nav-item text-danger"
                  >
                    Log Out
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export { MobileHeader };
