import React from 'react';
import { BillingHistoryList } from '../';
import { ManagePaymentMethods } from '../ManagePaymentMethods/ManagePaymentMethods';

const BillingAndPayment = () => (
  <>
    <div className="my-3 mx-4 d-flex p-4 bg-white rounded-sm shadow flex-column flex-lg-row">
      <BillingHistoryList />
      <ManagePaymentMethods />
    </div>
  </>
);

export { BillingAndPayment };
