import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { processOrder } from '../../../../api/payments';
import { PaymentMethod } from './';
import { PaymentIframe, LoadingScreen } from '../.././../Global';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import {
  setDisableFooterButton,
  setEnrollmentDefault
} from '../../../../context/actions/enrollment';
import { setUserInfo } from '../../../../context/actions/user';
import { useUserState } from '../../../../context/UserContext';
import { useGlobalState } from '../../../../context/GlobalContext';
import { pauseCallRecording } from '../../../../utilities/inContactAPI';
import { setEmail } from '../../../../context/actions/user';

const Payment = ({
  shippingAddress,
  billingAddress,
  setShippingAddress,
  setBillingAddress
}) => {
  const [userState, userDispatch] = useUserState();
  const { isImpersonated } = userState;
  const [enrollmentState, enrollmentDispatch] = useEnrollmentState();
  const { selectedPaymentMethod } = enrollmentState;
  const [showPaymentIframe, toggleShowPaymentIframe] = useState(false);

  const [inContactError, setInContactError] = useState(false);
  const [processingPayment, toggleProcessingPayment] = useState(false);

  const [errors, setErrors] = useState({});

  const [paymentToken, setPaymentToken] = useState(null);
  const history = useHistory();
  const [
    {
      configState: {
        client,
        inContact,
        com: {
          programCode,
          entitlements: { isDependentAccessLinked }
        }
      }
    }
  ] = useGlobalState();

  const [{ address1, address2, city, state, zip }] = useUserState();

  useEffect(() => {
    if (shippingAddress === null && billingAddress === null) {
      const shippingKey = () => `formik.form.shippingAddress`;
      const storageShippingAddress = JSON.parse(
        sessionStorage.getItem(shippingKey())
      );
      const billingKey = () => `formik.form.billingAddress`;
      const storageBillingAddress = JSON.parse(
        sessionStorage.getItem(billingKey())
      );
      if (address1 || address2 || city || state || zip) {
        setShippingAddress({ address1, address2, city, state, zip });
        setBillingAddress({ address1, address2, city, state, zip });
      } else {
        setShippingAddress({
          address1: storageShippingAddress.values.address1,
          address2: storageShippingAddress.values.address2,
          city: storageShippingAddress.values.city,
          state: storageShippingAddress.values.state,
          zip: storageShippingAddress.values.zip
        });
      }
      if (storageBillingAddress === null) {
        setBillingAddress({
          address1: storageShippingAddress.values.address1,
          address2: storageShippingAddress.values.address2,
          city: storageShippingAddress.values.city,
          state: storageShippingAddress.values.state,
          zip: storageShippingAddress.values.zip
        });
      } else {
        setBillingAddress({
          address1: storageBillingAddress.values.address1,
          address2: storageBillingAddress.values.address2,
          city: storageBillingAddress.values.city,
          state: storageBillingAddress.values.state,
          zip: storageBillingAddress.values.zip
        });
      }
    }
  }, []);

  useEffect(() => {
    if (userState.email === undefined) {
      const userKey = () => `persist.user`;
      const userEmail = JSON.parse(sessionStorage.getItem(userKey()));
      Object.entries(userEmail).forEach(([key, value]) => {
        if (value.name === userState.firstName) {
          userDispatch(setEmail(value.email));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (paymentToken) {
      submitOrder();
    }
  }, [paymentToken]);

  useEffect(() => {
    if (isImpersonated && !inContact?.isCarenet) {
      pauseCallRecording(inContact, true, setInContactError);
    }
    return () => {
      if (isImpersonated && !inContact?.isCarenet) {
        pauseCallRecording(inContact, false, setInContactError);
      }
    };
  }, []);

  const submitOrder = async () => {
    try {
      await processOrder(
        isDependentAccessLinked,
        programCode,
        enrollmentDispatch,
        { ...userState, shippingAddress, billingAddress },
        enrollmentState,
        true,
        false,
        paymentToken
      );
      await setUserInfo(programCode, client, userDispatch);
      await enrollmentDispatch(setEnrollmentDefault());
      history.push('/confirmation');
    } catch (error) {
      history.push('/error', { error: 'orderSubmitFail' });
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedPaymentMethod && !inContactError) toggleShowPaymentIframe(true);
  }, [selectedPaymentMethod, inContactError]);

  useEffect(() => {
    if (!selectedPaymentMethod) {
      enrollmentDispatch(setDisableFooterButton(true));
    } else {
      enrollmentDispatch(setDisableFooterButton(false));
    }
  }, [selectedPaymentMethod]);

  const handleBlur = e => {
    const targetName = e.target.name;
    if (!e.target.checked) {
      setErrors(prevState => ({
        ...prevState,
        [targetName]: `${targetName} needs to read and accepted`
      }));
    } else {
      let errorsCopy = { ...errors };
      delete errorsCopy[targetName];
      setErrors(errorsCopy);
    }
  };

  return (
    <>
      <div className="card shadow p-4 w-100">
        <PaymentMethod />
        {inContactError ? (
          <p className="font-weight-bold text-danger">
            Unable to load page due to call recording error.
          </p>
        ) : (
          showPaymentIframe && (
            <React.Fragment>
              <PaymentIframe
                showPaymentIframe={showPaymentIframe}
                toggleShowPaymentIframe={toggleShowPaymentIframe}
                toggleProcessingPayment={toggleProcessingPayment}
                setPaymentToken={setPaymentToken}
                billingAddress={billingAddress}
              />
            </React.Fragment>
          )
        )}
      </div>
      <LoadingScreen isLoading={processingPayment} />
    </>
  );
};

export { Payment };
