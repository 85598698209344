import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '../../assets/Icons';

const Marker = ({
  item,
  name,
  $hover,
  locationHovered,
  chooseLocation,
  studioColor,
  showName,
  isChosenLocationItem,
  primaryColor
}) => (
  <div
    className={classNames('location-marker-container', {
      'location-marker-hovered': $hover || locationHovered
    })}
    onClick={() =>
      isChosenLocationItem ? chooseLocation(null) : chooseLocation(item)
    }
  >
    {showName ? (
      <div
        className={classNames('location-marker-name', {
          'location-marker-name-hovered':
            $hover || locationHovered || isChosenLocationItem
        })}
      >
        {name}
      </div>
    ) : (
      <div
        style={{
          maxWidth: 74,
          height: 24,
          marginBottom: 5,
          padding: '0 7'
        }}
      />
    )}
    {!showName && isChosenLocationItem && (
      <Fragment>
        <div className="package-selection-location-marker-info mb-3">
          <h4 className="font-weight-bold m-0">{item.name}</h4>
          <p className="font-small m-0">
            {item.street}
            <br />
            {item.city}, {item.state} {item.zip}
          </p>
        </div>
        <div className="package-selection-location-marker-arrow" />
      </Fragment>
    )}
    {item.isHomeLocationTier ? (
      <Icon name="elite-pin" width="18px" height="26px" />
    ) : item.isStudio ? (
      <Icon name="studio-pin" width="18px" height="26px" fill={primaryColor}></Icon>
    ) : (
      <Icon name="pin" width="18px" height="26px" fill={primaryColor}></Icon>
    )}
  </div>
);

Marker.propTypes = {
  name: PropTypes.string
};

export { Marker };
