import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { LoadingSpinner } from '../components/Global/LoadingSpinner';
import { ModalContainer } from '../components/Global/Modal';

const Iframe = () => {
  const location = useLocation();
  const [paymentToken, setPaymentToken] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [showPaymentIframe, toggleShowPaymentIframe] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const query = QueryString.parse(location.search);

  let props = {
    tenantId: 30222,
    id: null,
    token: null,
    signature: null,
    style: 'inline',
    key: null,
    submitEnabled: 'true',
    locale: 'English (en)',
    param_supportedTypes: 'AmericanExpress,Visa,MasterCard,Discover',
    url: null,
    paymentGateway: null,
    field_passthrough1: 'Consumerid-1',
    field_accountId: query.accountId ? query.accountId : null
  };

  let prePopulatedFields = {
    creditCardPostalCode: (query.postalCode && query.postalCode) || '',
    achPostalCode: (query.postalCode && query.postalCode) || '',
    creditCardHolderName:
      (query.firstName &&
        query.lastName &&
        `${query.firstName} ${query.lastName}`) ||
      ''
  };

  useEffect(() => {
    if (!iframeLoading) {
      setIframeLoading(true);
    }
    if (query.paymentType) {
      let data = JSON.parse(query.paymentType);
      let newProps = { ...props, ...data };
      window.Z.render(newProps, prePopulatedFields, callback);
      if (!showPaymentIframe) toggleShowPaymentIframe(true);
      setIframeLoading(false);
    }
  }, []);

  const forwardCallbackURL = response => {
    var callbackUrl = window.location.origin;
    for (var id in response) {
      callbackUrl =
        callbackUrl + id + '=' + encodeURIComponent(response[id]) + '&';
    }
    window.location.replace(callbackUrl);
  };

  const callback = response => {
    if (!response.success) {
      forwardCallbackURL(response);
    } else {
      console.log('response.refId' + response.refId);
      setPaymentToken(response.refId);
    }
  };

  useEffect(() => {
    if (paymentToken) {
      window.location.href = `${query.uri}/?token=${paymentToken}`;
    }
  }, [paymentToken]);

  const ZuoraSumbit = () => {
    window.open('https://www.zuora.com/', '_blank');
    setOpenModal(false);
  };

  return (
    <>
      <div className="container">
        <div className="mx-4 my-0">
          <div
            style={iframeLoading ? { display: 'block' } : { display: 'none' }}
          >
            <LoadingSpinner />
          </div>
          <div
            style={iframeLoading ? { display: 'none' } : { display: 'block' }}
          >
            <div id="zuora_payment" style={{ paddingBottom: '3px' }}></div>
            <div
              style={{
                width: '100vw',
                'background-color': '#fff',
                position: 'fixed',
                bottom: 0,
                'z-index': 998,
                height: 'auto',
                padding: '16px 12px',
                margin: '0 auto',
                left: 0
              }}
            >
              <button
                type="button"
                className="btn btn-primary col mb-2"
                onClick={e => {
                  e.preventDefault();
                  window.Z.submit();
                }}
              >
                Save
              </button>
            </div>
            <p className="m-0 p-0" style={{ minHeight: '100px' }}>
              Powered by{' '}
              <button
                className="zuoraButton"
                onClick={() => setOpenModal(true)}
              >
                Zuora
              </button>
            </p>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalContainer
          title="You’re heading to Zuora.com"
          setIsOpen={setOpenModal}
          isOpen={openModal}
          cancelText="Stay Here"
          submitText="Visit Zuora"
          submit={ZuoraSumbit}
          zuora={false}
        >
          <h3 className="zuoraTitle">
            <b>You're heading to Zuora.com</b>{' '}
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setOpenModal(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </h3>
          <div className="zuoraBorderTop"></div>
          <p className="zuoraBody">
            You’re leaving Well onTarget Fitness Program and being redirected to
            our friends at Zuora.com
          </p>
          <div />
          <p className="zuoraLegal">
            Please note that Zuora.com is not owned, operated or controlled by
            Tivity Health, inc. Or it’s affiliates. By visiting Zuora.com, you
            will be subject to their terms of use and privacy policy
          </p>
          <div className="zuoraBorderBottom"></div>
        </ModalContainer>
      )}
    </>
  );
};

export { Iframe };
