import React from 'react';
import { ManagePaymentMethods } from './ManagePaymentMethods';
import { ModalContainer } from '../../Global/Modal';

const ManagePaymentMethodModal = ({
  setIsManagePaymentMethodModalOpen,
  isManagePaymentMethodModalOpen
}) => {
  return (
    <ModalContainer
      title="Change Payment Method"
      setIsOpen={setIsManagePaymentMethodModalOpen}
      isOpen={isManagePaymentMethodModalOpen}
      singleButton
      submitText="Close"
      submit={setIsManagePaymentMethodModalOpen}
    >
      <ManagePaymentMethods inModal />
    </ModalContainer>
  );
};

export { ManagePaymentMethodModal };
