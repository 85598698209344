import React from 'react';
import moment from 'moment';

const WarningSection = ({ type }) => {
  const getWarningText = () => {
    switch (type) {
      case 'underage':
        return (
          <>
            <h4 className="font-weight-bold text-dark">
              This following member(s) are under the age of 18 and some
              restrictions apply
            </h4>
            <ul className="pl-4 m-0">
              <li style={{ listStyle: 'disc' }}>
                Access to locations for this member is based on each individual
                location, not all locations allow less than 18 and some may
                require them to be accompanied by a parent/guardian.
              </li>
              <li style={{ listStyle: 'disc' }}>
                Studio scheduling is not available for individuals under the age
                of 18.
              </li>
            </ul>
          </>
        );
      case 'home location not set':
        return (
          <p className="font-small m-0 px-4">
            It looks like you have not yet set a Home Location. In order to
            access this Elite Location, you must first designate this location
            as your Home Location by clicking the Set as Home Location button
            below.
          </p>
        );
      case 'not users current home location':
        return (
          <p className="font-small m-0 px-4">
            {`You currently do not have access to this Elite Location.
          Update to this location in order to access this location
          starting 
          ${moment()
            .startOf('month')
            .add(1, 'month')
            .format('MMMM Do')}
          by clicking the Set as Home Location button below.`}
          </p>
        );
      case 'tier upsell':
        return (
          <p className="font-small m-0 px-4">
            You currently do not have access to this Location. If you wish to
            upgrade your package selection, you may do so by clicking the
            Upgrade Package button below.
          </p>
        );
      default:
        break;
    }
  };

  return (
    <div className="d-flex shadow card mb-2 p-3 border-0 rounded-0 bg-warning">
      {getWarningText()}
    </div>
  );
};

export { WarningSection };
