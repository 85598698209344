const transformActivityData = async data => {
  if (!data || data?.length === 0) return data;
  const sortedActivities = data.sort((a, b) => {
    if (
      Date.parse(b.activityDate, b.activityTime) >
      Date.parse(a.activityDate, a.activityTime)
    ) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  });
  return sortedActivities;
};

export { transformActivityData };
