import React, { useState, useEffect, useRef } from 'react';
import { AddressForm } from '../../../Global';
import { Checkbox } from '../../../Global/Checkbox';
import { setDisableFooterButton } from '../../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { useUserState } from '../../../../context/UserContext';
import { LoadingSpinner } from '../../../Global/LoadingSpinner';
import { ModalContainer } from '../../../Global/Modal';

let BillingDetails = ({
  validatingAddress,
  isAddressValidationErrorOpen,
  setIsAddressValidationErrorOpen,
  skipValidation,
  shippingAddress,
  billingAddress,
  setBillingAddress,
  setShippingAddress,
  shippingAddressError,
  billingAddressError,
  isMailingSameAsBilling,
  setIsMailingSameAsBilling,
  onAddressChange
}) => {
  const [{ address1, address2, city, state, zip }] = useUserState();
  const [{}, enrollmentDispatch] = useEnrollmentState();

  useEffect(() => {
    if (address1 || address2 || city || state || zip) {
      setShippingAddress({ address1, address2, city, state, zip });
      setBillingAddress({ address1, address2, city, state, zip });
      enrollmentDispatch(setDisableFooterButton(false));
    } else {
      setShippingAddress({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      });
      setBillingAddress({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      });
      enrollmentDispatch(setDisableFooterButton(true));
    }
  }, []);

  const switchMailingSameAsBilling = () => {
    if (isMailingSameAsBilling) {
      setBillingAddress({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      });
      enrollmentDispatch(setDisableFooterButton(true));
      setIsMailingSameAsBilling(false);
    } else {
      setBillingAddress(shippingAddress);
      setIsMailingSameAsBilling(true);
    }
  };

  return (
    <>
      {validatingAddress ? (
        <LoadingSpinner additionalText="Validating your address" />
      ) : (
        <>
          <div className="card shadow p-4 w-100">
            {shippingAddress && (
              <AddressForm
                addressType="shippingAddress"
                initialValues={shippingAddress}
                onAddressChange={onAddressChange}
                addressErrors={shippingAddressError}
                persist
              />
            )}
            <Checkbox
              checked={isMailingSameAsBilling}
              onChange={() => switchMailingSameAsBilling()}
              text="Use this address for billing as well."
              name="isMailingSameAsBilling"
              htmlFor="isMailingSameAsBilling"
              bold
              optional
            />
          </div>
          {!isMailingSameAsBilling && (
            <>
              <h3 className="font-weight-bold pt-2 mt-2">Billing Address</h3>
              <div className="card shadow p-4 w-100">
                <AddressForm
                  addressType="billingAddress"
                  initialValues={billingAddress}
                  onAddressChange={onAddressChange}
                  addressErrors={billingAddressError}
                  persist
                />
              </div>
            </>
          )}
        </>
      )}
      <ModalContainer
        title="Attention"
        titleSubText="We could not find your address in our database. Please double-check your address info and confirm that you would like to continue with the address you entered below."
        isOpen={isAddressValidationErrorOpen}
        setIsOpen={setIsAddressValidationErrorOpen}
        cancelText="Cancel"
        submitText="Confirm Address"
        submit={skipValidation}
      >
        <p className="m-0 h6 text-uppercase font-weight-bold text-secondary py-2">
          {isMailingSameAsBilling ? 'CURRENT ADDRESS' : 'SHIPPING ADDRESS'}
        </p>
        {shippingAddress && (
          <div className="list-group-item d-flex justify-content-between flex-row">
            <div className="d-flex flex-column">
              <p className="m-0 font-weight-bold">{`${shippingAddress.address1}`}</p>
              {shippingAddress.address2 && (
                <p className="m-0 font-weight-bold">{`${shippingAddress.address2}`}</p>
              )}
              <p className="m-0 font-weight-bold">{`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zip}`}</p>
            </div>
            <button
              onClick={() => setIsAddressValidationErrorOpen(false)}
              className="btn text-primary align-self-center"
            >
              <span className="pr-2">
                <i className="fas fa-pen" />
              </span>
              Edit
            </button>
          </div>
        )}
        {!isMailingSameAsBilling && billingAddress && (
          <>
            <p className="m-0 h6 text-uppercase font-weight-bold text-secondary py-2">
              BILLING ADDRESS
            </p>
            <div className="list-group-item d-flex justify-content-between flex-row">
              <div className="d-flex flex-column">
                <p className="m-0 font-weight-bold">{`${billingAddress.address1}`}</p>
                {billingAddress.address2 && (
                  <p className="m-0 font-weight-bold">{`${billingAddress.address2}`}</p>
                )}
                <p className="m-0 font-weight-bold">{`${billingAddress.city}, ${billingAddress.state} ${billingAddress.zip}`}</p>
              </div>
              <button
                onClick={() => setIsAddressValidationErrorOpen(false)}
                className="btn text-primary align-self-center"
              >
                <span className="pr-2">
                  <i className="fas fa-pen" />
                </span>
                Edit
              </button>
            </div>
          </>
        )}
      </ModalContainer>
    </>
  );
};

export { BillingDetails };
