import React, { useEffect, useState } from 'react';
import { PaymentMethodListItem } from './ManagePaymentMethods/PaymentMethodListItem';
import { useEnrollmentState } from '../../context/EnrollmentContext';
import { Checkbox } from '../Global/Checkbox';
import { setEnrollmentDefault } from '../../context/actions/enrollment';
import { useUserState } from '../../context/UserContext';
import { ModalContainer } from '../Global/Modal';

let ManagePlanPaymentModal = ({
  showManagePlanPaymentModal,
  setShowManagePlanPaymentModal,
  setShowEditPlan,
  setShowPaymentModal,
  setIsEditAddressModalOpen,
  title,
  submit
}) => {
  const [userState] = useUserState();
  const { paymentMethods, shippingAddress } = userState;
  const [enrollmentState, enrollmentDispatch] = useEnrollmentState();
  const [errors, setErrors] = useState({});
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState(null);
  const [acceptedProgramPolicy, setAcceptedProgramPolicy] = useState(false);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length) {
      const primaryMethod = paymentMethods.find(
        paymentMethod => paymentMethod.isDefault
      );
      setPrimaryPaymentMethod(primaryMethod);
    }
  }, [paymentMethods]);

  const handleBlur = e => {
    const targetName = e.target.name;
    if (!e.target.checked) {
      setErrors(prevState => ({
        ...prevState,
        [targetName]: `${targetName} needs to read and accepted`
      }));
    } else {
      let errorsCopy = { ...errors };
      delete errorsCopy[targetName];
      setErrors(errorsCopy);
    }
  };

  const cancel = () => {
    if (setShowEditPlan) {
      setShowEditPlan(false);
    }
    setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
    enrollmentDispatch(setEnrollmentDefault());
  };

  return (
    <ModalContainer
      setIsOpen={setShowManagePlanPaymentModal}
      isOpen={showManagePlanPaymentModal}
      title={title}
      cancelAction={cancel}
      cancelText="Cancel"
      submitText="Complete Checkout"
      disabled={!(acceptedProgramPolicy && primaryPaymentMethod)}
      submit={submit}
      isDismissable={false}
    >
      <>
        <p className="m-0 h6 text-uppercase font-weight-bold text-secondary py-2">
          PAYMENT METHOD
        </p>
        <PaymentMethodListItem
          isLastItem
          showChangeOption
          paymentMethod={primaryPaymentMethod}
          isInModal
          showEditPaymentOptions={false}
          openPaymentMethodEdit={() => {}}
          changeDefaultPaymentMethod={() => {}}
          changeOption={setShowPaymentModal}
        />
        <p className="m-0 h6 text-uppercase font-weight-bold text-secondary py-2">
          SHIPPING ADDRESS
        </p>
        <div className="list-group-item d-flex justify-content-between flex-row">
          <div className="d-flex flex-column">
            <p className="m-0">{`${shippingAddress.address1}`}</p>
            {shippingAddress.address2 && (
              <p className="m-0">{`${shippingAddress.address2}`}</p>
            )}
            <p className="m-0">{`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zip}`}</p>
          </div>
          <button
            onClick={() => setIsEditAddressModalOpen(true)}
            className="btn text-primary align-self-center"
          >
            <span className="pr-2">
              <i className="fas fa-pen" />
            </span>
            Edit
          </button>
        </div>
      </>

      <p className="m-0 h6 text-uppercase font-weight-bold text-secondary py-2">
        AGREEMENTS
      </p>
      <Checkbox
        onChange={e => {
          handleBlur(e);
          setAcceptedProgramPolicy(e.target.checked);
        }}
        checked={acceptedProgramPolicy}
        text="By checking this box, I acknowledge that I have read and agree to comply with the terms and conditions of the"
        hyperLink="Fitness Program Agreement"
        hyperLinkValue="/fitness-program-agreement"
        name="Fitness Program Agreement"
        htmlFor="Fitness Program Agreement"
        classes="m-neg"
      />
      {errors && errors['Fitness Program Agreement'] && (
        <p style={{ color: 'red' }} className="m-0 pl-4">
          {errors['Fitness Program Agreement']}
        </p>
      )}
    </ModalContainer>
  );
};
export { ManagePlanPaymentModal };
