import React, { useEffect, useState } from 'react';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import { PaymentMethod } from '../../Enrollment/EnrollmentSteps/Payment';
import { PaymentIframe } from '../../Global';
import { Checkbox } from '../../Global/Checkbox';
import {
  setDefaultPaymentMethod,
  setAutoPayMethod
} from '../../../api/payments';
import { setUserInfo } from '../../../context/actions/user';
import { useUserState } from '../../../context/UserContext';
import { ModalContainer } from '../../Global/Modal';
import { useGlobalState } from '../../../context/GlobalContext';
import { pauseCallRecording } from '../../../utilities/inContactAPI';

const AddPaymentMethodModal = ({
  setIsAddPaymentMethodModalOpen,
  isAddPaymentMethodModalOpen
}) => {
  const [userState, userDispatch] = useUserState();
  const { accountId, paymentMethods, isImpersonated } = userState;
  const [{ selectedPaymentMethod }] = useEnrollmentState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [submitFunc, setSubmitFunc] = useState('');
  const [showPaymentIframe, toggleShowPaymentIframe] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);
  const [isSetAsDefault, setIsSetAsDefault] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [inContactError, setInContactError] = useState(false);
  const [
    {
      configState: {
        client,
        inContact,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  useEffect(() => {
    if (isImpersonated && !inContact?.isCarenet) {
      pauseCallRecording(inContact, true, setInContactError);
    }
    setSubmitFunc(() => handlePaymentSubmission);
    return () => {
      setExecuting(false);
      if (isImpersonated && !inContact?.isCarenet) {
        pauseCallRecording(inContact, false, setInContactError);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedPaymentMethod && !inContactError) toggleShowPaymentIframe(true);
  }, [selectedPaymentMethod, inContactError]);

  useEffect(() => {
    if (paymentToken) {
      updatePaymentMethods(paymentToken);
    }
  }, [paymentToken]);

  const updatePaymentMethods = async paymentToken => {
    try {
      setExecuting(true);
      if (isSetAsDefault || (paymentMethods && paymentMethods.length === 0)) {
        await setDefaultPaymentMethod(client, accountId, paymentToken);
        if (paymentMethods && paymentMethods.length === 0)
          await setAutoPayMethod(client, accountId);
      }
      await setUserInfo(programCode, client, userDispatch);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setExecuting(false);
      setIsProcessing(false);
      setIsAddPaymentMethodModalOpen(false);
    }
  };

  const ProcessSubmitClick = async e => submitFunc(e);

  const handlePaymentSubmission = () => {
    window.Z.submit();
  };

  return (
    <ModalContainer
      isOpen={isAddPaymentMethodModalOpen}
      setIsOpen={setIsAddPaymentMethodModalOpen}
      title="Add a New Payment Method"
      disabled={executing}
      submit={submitFunc === '' ? null : e => ProcessSubmitClick(e)}
      submitText={
        userState.loadingAccountSummary ? 'Processing...' : 'Confirm Update'
      }
      singleButton
      isDismissable={true}
    >
      <PaymentMethod />
      {inContactError ? (
        <p className="font-weight-bold text-danger">
          Unable to load page due to call recording error.
        </p>
      ) : (
        showPaymentIframe && (
          <>
            <PaymentIframe
              showPaymentIframe={showPaymentIframe}
              toggleShowPaymentIframe={toggleShowPaymentIframe}
              setPaymentToken={setPaymentToken}
              toggleProcessPayment={setIsProcessing}
              isProcessing={isProcessing}
            />
            <Checkbox
              disabled={!paymentMethods || paymentMethods.length === 0}
              value={
                !paymentMethods || paymentMethods.length === 0
                  ? true
                  : isSetAsDefault
              }
              text="Set As Default Payment Method"
              optional
              htmlFor="Set As Default Payment Method"
              checked={
                !paymentMethods || paymentMethods.length === 0
                  ? true
                  : isSetAsDefault
              }
              onChange={() => setIsSetAsDefault(!isSetAsDefault)}
            />
          </>
        )
      )}
    </ModalContainer>
  );
};

export { AddPaymentMethodModal };
