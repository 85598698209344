import React from 'react';
import classNames from 'classnames';

const PaymentMethodListItem = ({
  paymentMethod,
  changeDefaultPaymentMethod,
  openPaymentMethodDelete,
  showEditPaymentOptions,
  setChosenPaymentMethod,
  setShowEditPayment,
  showChangeOption,
  changeOption
}) => (
  <div
    className={classNames('list-group mb-2', {
      'rounded border border-primary':
        !showChangeOption && paymentMethod.isDefault
    })}
  >
    <div className="list-group-item d-flex justify-content-between flex-row">
      {paymentMethod ? (
        paymentMethod.paymentType === 'CreditCard' ? (
          <div className="d-flex flex-column">
            <p
              className={classNames('m-0 text-capitalize', {
                'font-weight-bold': !showChangeOption && paymentMethod.isDefault
              })}
            >{`${paymentMethod.name}`}</p>
            <p
              className={classNames('m-0', {
                'font-weight-bold': !showChangeOption && paymentMethod.isDefault
              })}
            >
              {`${paymentMethod.creditCardType} ${paymentMethod.maskedCardNumber}`}
            </p>
            <div className="d-flex flex-row">
              <p
                className={classNames('m-0', {
                  'font-weight-bold':
                    !showChangeOption && paymentMethod.isDefault
                })}
              >
                {`Exp: ${
                  paymentMethod.expirationMonth < 10
                    ? `0${paymentMethod.expirationMonth}`
                    : paymentMethod.expirationMonth
                }/${paymentMethod.expirationYear}${
                  !!paymentMethod.zip ? `, Zip Code: ${paymentMethod.zip}` : ''
                }`}
                ,
              </p>
              <p
                className={classNames('m-0', 'ml-1', {
                  'font-weight-bold':
                    !showChangeOption && paymentMethod.isDefault
                })}
              >
                Zip Code: {paymentMethod.creditCardPostalCode}
              </p>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <p
              className={classNames('m-0', {
                'font-weight-bold': !showChangeOption && paymentMethod.isDefault
              })}
            >{`${paymentMethod.name}`}</p>
            <p
              className={classNames('m-0', {
                'font-weight-bold': !showChangeOption && paymentMethod.isDefault
              })}
            >
              {`${paymentMethod.eCheckBankName}, ${paymentMethod.eCheckType} Account ${paymentMethod.accountNumber}`}
            </p>
          </div>
        )
      ) : (
        <div className="d-flex flex-column">
          <p
            className={classNames('m-0', {
              'font-weight-bold': !showChangeOption && paymentMethod.isDefault
            })}
          >
            Please add a payment method.
          </p>
        </div>
      )}
      {showChangeOption && (
        <button
          onClick={() => changeOption(true)}
          className="btn text-primary align-self-center"
        >
          <span className="pr-2">
            <i className="fas fa-sync-alt" />
          </span>
          Change
        </button>
      )}
    </div>
    {showEditPaymentOptions && (
      <div className="list-group-item w-100 bg-light d-flex d-inline-block flex-row justify-content-between">
        {paymentMethod.isDefault ? (
          <p className="m-0 font-small font-italic text-success tier_description pt-1">
            This is your default
          </p>
        ) : (
          <button
            onClick={() => changeDefaultPaymentMethod(paymentMethod.id)}
            className="bg-transparent border-0 m-0 font-small font-weight-bold text-primary pointer"
          >
            Set as Default
          </button>
        )}
        <div className="d-xl-flex flex-lg-row flex-column justify-content-between">
          <button
            onClick={() => {
              setChosenPaymentMethod(paymentMethod);
              setShowEditPayment(true);
            }}
            className="bg-transparent border-0 m-0 mr-4 font-small font-weight-bold text-primary"
          >
            <i className="fas fa-pencil-alt"></i> Edit
          </button>
          <button
            onClick={() => openPaymentMethodDelete(paymentMethod)}
            className={classNames(
              'bg-transparent border-0 m-0 font-small font-weight-bold',
              {
                'text-danger': !paymentMethod.isDefault,
                'text-secondary': paymentMethod.isDefault
              }
            )}
            disabled={paymentMethod.isDefault}
          >
            <i className="fas fa-times"></i> Remove
          </button>
        </div>
      </div>
    )}
  </div>
);

export { PaymentMethodListItem };
