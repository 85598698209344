import React from 'react';
import { Icon } from '../../assets/Icons';

const SpecialPricingBanner = () => {
  return (
    <div
      className={
        'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
      }
    >
      <p className="suspended-warning__title px-4">
        <span className="suspended-warning__icon">
          <Icon
            name="exclamation-triangle"
            width="19px"
            height="17px"
            className="suspended-warning__icon"
          />
        </span>
        Attention
      </p>
      <p className="m-0 px-4">
        <b>On December 1, 2023</b>, we will be updating the price of the ELITE
        Fitness Package to <b>$129 per month</b>. Due to rising costs, this
        change is necessary to ensure access to our unbeatable network of
        fitness locations. Helping you stay fit and feel good is our #1
        priority!
      </p>
    </div>
  );
};

export { SpecialPricingBanner };
