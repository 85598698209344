import React from 'react';
import classNames from 'classnames';
import { setPrimarySelectedPackage } from '../../../../context/actions/enrollment';
import { useGlobalState } from '../../../../context/GlobalContext';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';

const DigitalTier = ({ isMobile, isRadioBtnHidden = false }) => {
  const [{ configState, tierState }] = useGlobalState();
  const [{ primarySelectedPackage }, dispatch] = useEnrollmentState();
  const { digitalTier } = tierState;
  const {
    com: {
      attributes: { studioDisplay }
    }
  } = configState;

  return (
    <>
      {digitalTier && (
        <div
          className={classNames(
            'custom-radio-sup card shadow pb-3 custom-radio-inf',
            {
              'd-none': !isMobile,
              'd-lg-flex': !isMobile,
              'd-lg-none d-sm-flex d-xs-flex': isMobile
            }
          )}
          style={{marginTop: '10px'}}

        >
          <label className="col-md-12 w-100">
            <span
              className="custom-span badge badge-primary shadow w-100 position-absolute fixed-top d-block"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '200px',
                textTranform: 'capitalize'
              }}
            >
              Digital Content Only
            </span>
            <input
              className="mx-2 radio-btn-fixed"
              type="radio"
              name="digitalPackage"
              id="digitalPackageSelection"
              value={digitalTier[0].recurringRatePlanChargeId}
              style={{ visibility: isRadioBtnHidden ? "hidden" : "visible" }}                          
              checked={
                primarySelectedPackage &&
                primarySelectedPackage.recurringRatePlanChargeId ===
                  digitalTier[0].recurringRatePlanChargeId
              }
              onChange={() =>
                dispatch(setPrimarySelectedPackage(digitalTier[0]))
              }               
            />
            <div className="d-flex flex-row align-content-center">
              <div className="flex-column px-3">
                <p className="m-0 price h1">{`$${Number(
                  digitalTier[0].recurringPrice
                )}`}</p>
                <p
                  className="per-month mb-0 align-self-center"
                  style={{ marginTop: "-10px", display: "block" }}
                >
                  /month
                </p>
              </div>
              <div className="flex-column align-self-center">
                <p className="m-0 font-weight-bold text-left">
                  Add thousands of on-demand videos and live streams to your
                  plan.
                </p>
                <p className="m-0">
                  Content includes workouts, wellness videos, nutrition
                  tutorials, and more!
                </p>
                {(studioDisplay === 'Both' || studioDisplay === 'Web') && (
                  <>
                    <div className="border my-1" />
                    <p className="font-italic m-0 text-left">Included with purchase</p>
                    <p className="m-0 text-left">
                      <span className="font-weight-bold bg-0">
                        Studio Class Rewards
                      </span>{' '}
                      | 30% off every 10th class
                    </p>
                  </>
                )}
              </div>
            </div>
          </label>
        </div>
      )}
    </>
  );
};

export { DigitalTier };
