import { SET_TIERS, CHOOSE_TIER, SET_LOADING } from '../reducers/tiers';
import { getPackages } from '../../api/ecommerce/GetPackageByClientProduct';

export const getTiers = async (clientCode, programCode, dispatch) => {
  dispatch(setLoading('loadingTiers', true));
  return getPackages(programCode, clientCode)
    .then(data => {
      dispatch(setTiers(data.tiers, data.homeLocationTier, data.digitalTier));
      dispatch(setLoading('loadingTiers', false));
    })
    .catch(error => dispatch({ type: SET_TIERS, tiers: [] }));
};

const setTiers = (tiers, homeLocationTier, digitalTier) => ({
  type: SET_TIERS,
  tiers,
  homeLocationTier,
  digitalTier
});

export const chooseTier = tier => ({ type: CHOOSE_TIER, tier });

export const setLoading = (loadingType, isLoading) => ({
  type: SET_LOADING,
  loadingType,
  isLoading
});
