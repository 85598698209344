function checkBrowser() {
  if (
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0
  ) {
    return 'opera';
  }
  if (typeof InstallTrigger !== 'undefined') {
    return 'firefox';
  }
  if (
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(
      !window['safari'] ||
        (typeof window.safari !== 'undefined' && window.safari.pushNotification)
    )
  ) {
    return 'safari';
  }

  // Internet Explorer 6-11

  if (/*@cc_on!@*/ false || !!document.documentMode) {
    return 'IE';
  }
  // Edge 20+
  if (!(false || !!document.documentMode) && !!window.StyleMedia) {
    return 'Edge';
  }
  if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime)
  ) {
    return 'chrome';
  }
  return 'browser undetected';
}

export { checkBrowser };
