import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { setLoading, setUserInfo } from '../../../context/actions/user';
import { Input, Select } from '../../Global';
import { updatePaymentMethod } from '../../../api/payments';
import { useUserState } from '../../../context/UserContext';
import { toastNotification } from '../../../utilities/toastMessage';
import { useGlobalState } from '../../../context/GlobalContext';

const EditPaymentForm = ({
  chosenPaymentMethod = null,
  closeEditPaymentModal,
  setIsLoading
}) => {
  const [initialValues, setInitialValues] = useState({});

  const [{}, userDispatch] = useUserState();
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  useEffect(() => {
    let initial;
    if (chosenPaymentMethod.paymentType === 'CreditCard') {
      initial = {
        creditCardExpirationMonth: chosenPaymentMethod.expirationMonth,
        creditCardExpirationYear: chosenPaymentMethod.expirationYear,
        creditCardPostalCode: chosenPaymentMethod.creditCardPostalCode,
        creditCardHolderName: chosenPaymentMethod.name
      };
    } else {
      initial = {
        achAccountName: chosenPaymentMethod.name,
        achAccountType: chosenPaymentMethod.eCheckType,
        achBankName: chosenPaymentMethod.eCheckBankName
      };
    }
    setInitialValues(initial);
  }, []);

  const submitPaymentEdit = async values => {
    setIsLoading(true);
    closeEditPaymentModal();
    try {
      await updatePaymentMethod(chosenPaymentMethod, values);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your payment method has been successfully updated.',
        true
      );
    } catch (err) {
      toastNotification('Your payment method could not be updated.', false);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };
  const getYears = () => {
    const start = moment().year();
    const end = start + 20;
    let yearsObj = [];
    for (let i = start; i <= end; i++) {
      yearsObj = [...yearsObj, { year: i }];
    }
    return yearsObj;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        userDispatch(setLoading('loadingAccountSummary', true));
        submitPaymentEdit(values);
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;
        return (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <Input
                type={'text'}
                title={
                  chosenPaymentMethod &&
                  chosenPaymentMethod.paymentType === 'CreditCard'
                    ? 'Cardholder Name'
                    : 'E-Check Account Name'
                }
                name={
                  chosenPaymentMethod &&
                  chosenPaymentMethod.paymentType === 'CreditCard'
                    ? 'creditCardHolderName'
                    : 'achAccountName'
                }
                value={
                  chosenPaymentMethod &&
                  chosenPaymentMethod.paymentType === 'CreditCard'
                    ? values.creditCardHolderName
                    : values.achAccountName
                }
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                addressType="billingAddress"
              />
              {chosenPaymentMethod &&
              chosenPaymentMethod.paymentType === 'CreditCard' ? (
                <>
                  <div className="d-flex w-100">
                    <Select
                      type={'select'}
                      title={'Exp Month'}
                      name={'creditCardExpirationMonth'}
                      value={values.creditCardExpirationMonth}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={[
                        { month: 1 },
                        { month: 2 },
                        { month: 3 },
                        { month: 4 },
                        { month: 5 },
                        { month: 6 },
                        { month: 7 },
                        { month: 8 },
                        { month: 9 },
                        { month: 10 },
                        { month: 11 },
                        { month: 12 }
                      ]}
                      labelField="month"
                      addressType="billingAddress"
                    />
                    <Select
                      type={'select'}
                      title={'Exp Year'}
                      name={'creditCardExpirationYear'}
                      value={values.creditCardExpirationYear}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={getYears()}
                      addressType="billingAddress"
                      labelField="year"
                    />
                  </div>
                  <Input
                    type={'text'}
                    title={'Zip Code'}
                    name={'creditCardPostalCode'}
                    value={values.creditCardPostalCode}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    addressType="billingAddress"
                  />
                </>
              ) : (
                <div className="d-flex w-100">
                  <Select
                    type={'text'}
                    title={'Account Type'}
                    name={'achAccountType'}
                    value={values.achAccountType}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    labelField="accountType"
                    options={[
                      { accountType: 'Saving' },
                      { accountType: 'Checking' },
                      { accountType: 'Business Checking' }
                    ]}
                    addressType="billingAddress"
                  />
                  <Input
                    type={'text'}
                    title={'Bank Name'}
                    name={'achBankName'}
                    value={values.achBankName}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    addressType="billingAddress"
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="outline-primary col"
                onClick={() => closeEditPaymentModal()}
              >
                Cancel
              </Button>
              <button type="submit" className="btn btn-primary col">
                Done
              </button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export { EditPaymentForm };
