import React, { useEffect, useState, useRef } from 'react';
import { useGlobalState } from '../../context/GlobalContext';
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Icon } from '../../assets/Icons';
import 'video.js/dist/video-js.css';
import '@videojs/http-streaming';
import classNames from 'classnames';
import videojs from 'video.js';
import moment from 'moment';
import parseJWT from '../../utilities/parseJWT';
import { getAccessToken } from '../../utilities/tokenHelpers';
import { LoadingSpinner } from '../Global/LoadingSpinner';

const Video = ({ selectedVideo, setSelectedVideo, handleChange }) => {
  const [{ configState }] = useGlobalState();
  const { cms, client } = configState;
  const maxLength = 87;
  const slideIndex = 3;
  const id = selectedVideo;
  const node = useRef(null);
  const playerElement = document.querySelector('#player');
  const videoReportingEndpoint = `/api/${client}/events/v1`;
  const eventNameSpace = 'tivityhealth.com/internal/lesmills';

  //state
  const [programName, setProgram] = useState([]);
  const [programsList, setProgramsList] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(2.75);
  const [categoryList, setCategoryList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [equipmentList, setEquipment] = useState([]);
  const [videoDetails, setVideoDetails] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [player, setPlayer] = useState(null);
  const [isVideoOpen, setIsVideoOpen] = useState();
  const [tivitySub, setTivitySub] = useState(parseJWT(getAccessToken()).sub);
  const [userHasWatchedVideo, setUserHasWatchedVideo] = useState(false);
  const [eventInitiatedSent, setEventInitiatedSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedRuntime, setLastUpdatedRuntime] = useState(0);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
      partialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 10
    }
  };

  useEffect(() => {
    getRelatedVideos();
    window.scrollTo(0, 0);
  }, [selectedVideo]);

  const getRelatedVideos = async () => {
    setIsLoading(true);
    try {
      const videosList = await axios.get('/api/platform/v1/videos');
      setProgramsList(videosList.data.programs);
      setVideoList(videosList.data.videos);

      const videoDetails = await axios.get(`/api/platform/v1/videos/${id}`);
      setProgram(videoDetails.data.video.program);
      setVideoDetails(videoDetails.data.video);
      setCategoryList(videoDetails.data.video.categories);
      setInstructorList(videoDetails.data.video.instructors);
      setEquipment(videoDetails.data.video.equipment);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const eventClientIds = {
    HCSC: '0361DD38DD127FDA138F25259D6D51E8'
  };

  const EventType = {
    EventInitiated: 'EventInitiated',
    EventProgress: 'EventProgress'
  };

  useEffect(() => {
    if (!isLoading) {
      (async () => {
        let videoDetailsEndpoint = `/api/platform/v1/videos/view/${id}?width=640&height=360`;
        const videoUrlCall = await axios.get(videoDetailsEndpoint);
        let props = {
          autoplay: false
        };
        const p = videojs(node.current, props, () => {
          p.src({
            src: videoUrlCall.data.url,
            withCredentials: true,
            loadingSpinner: true
          });
        });

        setPlayer(p);
      })();
    }
    //return () => player?.dispose();
  }, [selectedVideo, isLoading]);

  const isValidPayload = payload => {
    if (
      payload &&
      payload['attributes']['eventId'] &&
      payload['attributes']['tivitySub'] &&
      payload['attributes']['client'] &&
      payload['attributes']['eventDurationSeconds']
    ) {
      return true;
    }

    return false;
  };

  const sendReport = (
    eventType,
    sub,
    fileId,
    timestamp,
    videoIsComplete,
    videoLength
  ) => {
    if (userHasWatchedVideo) return null; //Prevent any more api calls about this video, unless the user refreshes.

    let payload = null;

    switch (eventType) {
      case 'EventInitiated':
        payload = {
          eventDateTime: new Date().toISOString(),
          namespace: eventNameSpace,
          kind: eventType,
          attributes: {
            eventId: fileId,
            tivitySub: sub,
            client: eventClientIds[client.toUpperCase()],
            eventDurationSeconds: videoLength
          }
        };
        break;

      case 'EventProgress':
        payload = {
          eventDateTime: new Date().toISOString(),
          namespace: eventNameSpace,
          kind: eventType,
          attributes: {
            eventId: fileId,
            tivitySub: sub,
            client: eventClientIds[client.toUpperCase()],
            elapsedSeconds: timestamp,
            eventDurationSeconds: videoLength,
            completed: videoIsComplete
          }
        };
        break;
    }

    //Send the payload to the api.
    if (videoReportingEndpoint && payload && isValidPayload(payload)) {
      axios.post(videoReportingEndpoint, payload);
    }
  };

  const onVideoTimeUpdate = () => {
    const playerCurrentTime = Math.ceil(player.currentTime());
    const throttledRuntimeInSeconds = lastUpdatedRuntime + 30;
    if (playerCurrentTime >= throttledRuntimeInSeconds) {
      sendReport(
        EventType.EventProgress,
        tivitySub,
        videoDetails.fileId,
        playerCurrentTime,
        false,
        Math.ceil(player.duration())
      );
      setLastUpdatedRuntime(playerCurrentTime);
    }
  };

  const onVideoPlay = () => {
    //send this once, when the video starts playing. User initiated.
    if (!eventInitiatedSent) {
      sendReport(
        EventType.EventInitiated,
        tivitySub,
        videoDetails.fileId,
        Math.ceil(player.currentTime()),
        false,
        Math.ceil(player.duration())
      );
      setEventInitiatedSent(true);
    }
  };

  const onVideoPause = () => {
    const currentTime = Math.ceil(player.currentTime());
    const duration = Math.ceil(player.duration());

    // Prevent onVideoPause from sending report at the end of the video
    if (currentTime === duration) {
      return;
    }

    sendReport(
      EventType.EventProgress,
      tivitySub,
      videoDetails.fileId,
      currentTime,
      false,
      duration
    );
  };

  const onVideoEnded = () => {
    sendReport(
      EventType.EventProgress,
      tivitySub,
      videoDetails.fileId,
      Math.ceil(player.currentTime()),
      true,
      Math.ceil(player.duration())
    );

    setUserHasWatchedVideo(true);
  };

  const addDefaultSrc = ev => {
    ev.target.src = 'https://via.placeholder.com/395x130/aaaaaa';
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} additionalText="Loading Video" />
      ) : (
        <>
          <div className="video-container">
            <a
              onClick={e => {
                handleChange(false);
              }}
              className="go-back"
            >
              <Icon
                name="chevron"
                width="20px"
                fill={cms.primary_color}
                style={{ transform: 'rotate(180deg)' }}
              />{' '}
              Back
            </a>
            <div className="container">
              <div className="row">
                <div className="col ml-5 pl-0"></div>
                <div className="col-8">
                  <div data-vjs-player>
                    <video
                      ref={node}
                      className="video-js vjs-16-9 vjs-big-play-centered"
                      controls
                      onPlay={onVideoPlay}
                      onPause={onVideoPause}
                      onTimeUpdate={onVideoTimeUpdate}
                      onEnded={onVideoEnded}
                    />
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="video-details-container">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-8">
                  <div className="video-catgory">
                    <div className="video-category-section2">
                      {categoryList &&
                        categoryList.map((cat, x) => {
                          return (
                            <>
                              <div key={x} className="video-categories-tabs">
                                {cat.name}
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <h1 className="video-title">{videoDetails.title}</h1>
                  <div className="row pb-3">
                    <div className="pr-2 mb-2 font-small duration-border">
                      <Icon
                        name="stopwatch"
                        width="15px"
                        height="17px"
                        className="icon-alignment"
                      />{' '}
                      {moment
                        .utc(
                          moment
                            .duration(videoDetails.duration, 'seconds')
                            .as('milliseconds')
                        )
                        .format('mm:ss')}
                    </div>
                    <div className="pr-2 mb-2 font-small duration-border">
                      <Icon
                        name="flame"
                        width="12px"
                        height="16px"
                        className="icon-alignment"
                      />{' '}
                      {videoDetails.intensityType}
                    </div>
                    <div
                      className={classNames('pr-2 mb-2 font-small pb-2', {
                        'duration-border': equipmentList.length > 0
                      })}
                    >
                      <Icon
                        name="user-circle"
                        width="16px"
                        height="17px"
                        className="icon-alignment"
                      />{' '}
                      {instructorList &&
                        instructorList.map((instructor, index) => {
                          return <>{(index ? ', ' : '') + instructor}</>;
                        })}
                    </div>
                    {equipmentList && equipmentList.length > 0 && (
                      <div className="pr-2 mb-2 font-small">
                        <Icon
                          name="dumbbell"
                          width="20px"
                          height="16px"
                          className="icon-alignment"
                        />{' '}
                        {equipmentList.map((equiment, index) => {
                          return <>{(index ? ', ' : '') + equiment}</>;
                        })}
                      </div>
                    )}
                  </div>
                  <p>{videoDetails.descriptionLong}</p>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="video-details-list">
            <div className="container pb-3">
              {programsList &&
                programsList.map((program, e) => {
                  if (programName.name == program.name)
                    return (
                      <>
                        <div className="category-section pt-5" key={e}>
                          <h1 key={program} style={{ fontSize: '32px' }}>
                            {program.name}{' '}
                            <span className="font-weight-normal">
                              ({program.total})
                            </span>
                          </h1>
                          <p className="font-small">{program.description}</p>
                        </div>
                        <Carousel
                          className="mt-4 carousel"
                          showDots={false}
                          responsive={responsive}
                          removeArrowOnDeviceType={['tablet', 'mobile']}
                          //infinite={true}
                          partialVisible={true}
                        >
                          {videoList &&
                            videoList
                              .filter(
                                video =>
                                  program.name == video.video.program.name
                              )
                              .map((video, i) => {
                                if (program.name == video.video.program.name) {
                                  if (program.fileId == video.video.fileId) {
                                    setSelectedVideo(video.video.fileId);
                                  }

                                  return (
                                    <>
                                      <div key={i}>
                                        <div
                                          key={video}
                                          className="col p-0  mt-4"
                                        >
                                          <a
                                            role="button"
                                            onClick={() => {
                                              //setPlaying(true);
                                              setSelectedVideo(
                                                video.video.fileId
                                              );
                                            }}
                                          >
                                            <div
                                              className={classNames(
                                                'video-card',
                                                {
                                                  'sv-border':
                                                    id == video.video.fileId
                                                }
                                              )}
                                            >
                                              <img
                                                src={video.video.thumbnail}
                                                alt={
                                                  video.video.title +
                                                  '_thumbnail'
                                                }
                                                className="img-fluid video-card-img"
                                                onError={addDefaultSrc}
                                              />
                                              <div className="video-catgory">
                                                <div className="video-category-section">
                                                  {moment
                                                    .utc(
                                                      moment
                                                        .duration(
                                                          video.video.duration,
                                                          'seconds'
                                                        )
                                                        .as('milliseconds')
                                                    )
                                                    .format('mm:ss')}
                                                </div>
                                              </div>
                                              <div className="container">
                                                <h5 className="pt-3 title-resp">
                                                  <b>{video.video.title}</b>
                                                </h5>
                                                <div className="pb-3">
                                                  <b>
                                                    <span className="lm-vdlink">
                                                      View Details
                                                      <Icon
                                                        name="chevron"
                                                        width="15px"
                                                        height="15px"
                                                        fill={cms.primary_color}
                                                      />{' '}
                                                    </span>
                                                  </b>
                                                </div>
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              })}
                        </Carousel>
                      </>
                    );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { Video };
