import React, { useState, useEffect } from 'react';
import { Map } from '../../../LocationSearch';
import {
  getLocationCount,
  getStudioCount
} from '../../../../utilities/getNewLocations';
import { useLocation } from 'react-router-dom';
import { LocationSearchInput } from '../../../LocationSearch';
import { useGlobalState } from '../../../../context/GlobalContext';
import {
  getCurrentMapLocation,
  renderLocations,
  setVisibleGyms,
  setVisibleStudios,
  setLoading,
  getStudiosAndGyms
} from '../../../../context/actions/location';
import { SET_GYMS, SET_STUDIOS } from '../../../../context/reducers/location';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { Icon } from '../../../../assets/Icons';

const SelectPackageMap = () => {
  const [{ locationState, configState }, dispatch] = useGlobalState();
  const location = useLocation();
  const {
    chosenLocation,
    currentUserLocation,
    currentMapLocation,
    mapData,
    gymList,
    studioList
  } = locationState;
  const [{ primarySelectedPackage }] = useEnrollmentState();
  const [locationCount, setLocationCount] = useState(0);
  const [loadingLocationCount, setLoadingLocationCount] = useState(false);
  const [studioCount, setStudioCount] = useState(0);

  const {
    client,
    com: {
      attributes: { studioDisplay },
      programCode
    }
  } = configState;

  useEffect(() => {
    getLocationCount(
      programCode,
      client,
      location.selectedTier && location.selectedTier.tier
    )
      .then(count => setLocationCount(count))
      .catch(error => console.log(error));
    getStudioCount(client)
      .then(count => setStudioCount(count))
      .catch(error => console.log(error));
    if (currentUserLocation) {
      dispatch(getCurrentMapLocation(currentUserLocation));
    }
  }, []);

  useEffect(() => {
    dispatch({ type: SET_GYMS, gymList: null });
    dispatch({ type: SET_STUDIOS, studioList: null });
    dispatch(setVisibleGyms(null));
    dispatch(setVisibleStudios(null));
    return () => {
      dispatch({ type: SET_GYMS, gymList: null });
      dispatch({ type: SET_STUDIOS, studioList: null });
      dispatch(setVisibleGyms(null));
      dispatch(setVisibleStudios(null));
    };
  }, []);

  useEffect(() => {
    renderLocations(
      dispatch,
      mapData,
      gymList,
      studioList,
      null,
      null,
      null,
      primarySelectedPackage
    );
  }, [mapData, gymList, studioList, primarySelectedPackage]);

  useEffect(() => {
    if (currentMapLocation && !chosenLocation) {
      getStudiosAndGyms(programCode, client, currentMapLocation)
        .then(list => {
          const { studioList, gymList } = list;
          dispatch({ type: SET_STUDIOS, studioList });
          dispatch({ type: SET_GYMS, gymList });
        })
        .catch(error => {
          dispatch({ type: SET_STUDIOS, studioList: [] });
          dispatch({ type: SET_GYMS, gymList: [] });
        })
        .finally(() => {
          dispatch(setLoading('loadingStudios', false));
        });
    }
  }, [currentMapLocation]);

  useEffect(() => {
    if (primarySelectedPackage) {
      setLoadingLocationCount(true);
      getLocationCount(programCode, client, primarySelectedPackage.tier)
        .then(count => {
          setLocationCount(count);
          setLoadingLocationCount(false);
        })
        .catch(error => {
          console.log(error);
          setLoadingLocationCount(false);
        });
      return;
    }
  }, [primarySelectedPackage]);

  return (
    <div className="col-lg-3 col-xl-3 d-none d-lg-flex d-xl-flex position-relative forced-height p-0">
      <div className="px-2 w-100 position-absolute map-fields__top">
        <LocationSearchInput />
      </div>
      <div
        style={{ zIndex: 1, top: 60, right: '0.5rem' }}
        className="p-2 card position-absolute"
      >
        <p className="text-center m-2 font-weight-bold text-primary text-uppercase h6">
          Showing Nation-Wide Access
        </p>
        <div className="row">
          {primarySelectedPackage !== 'studio' && (
            <div className="align-items-center d-flex flex-column col location-count">
              <p className="m-0 h4 font-weight-normal">
                <div className="d-flex">
                  <Icon
                    name="pin"
                    width="16px"
                    height="18px"
                    className="mt-1"
                  ></Icon>
                  {loadingLocationCount ? 'Loading...' : locationCount}
                </div>
              </p>
              <p className="m-0 text-uppercase text-secondary h6">Gyms </p>
            </div>
          )}
          {(studioDisplay === 'Both' || studioDisplay === 'Web') && (
            <div className="align-items-center d-flex flex-column col location-count">
              <div className="d-flex">
                <Icon
                  name="studio-pin"
                  width="16px"
                  height="18px"
                  className="mt-1"
                ></Icon>
                <p className="m-0 h4 font-weight-normal">{studioCount}</p>
              </div>
              <p className="m-0 text-uppercase text-secondary h6"> Studios</p>
            </div>
          )}
        </div>
      </div>
      <div className="col-md-12 p-0">
        <Map
          locationHovered={() => {}}
          setHoveredLocation={() => {}}
          showName={false}
        />
      </div>
    </div>
  );
};

export { SelectPackageMap };
