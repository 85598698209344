export const initialUserState = {
  isAuthenticated: false,
  firstName: '',
  lastName: '',
  email: '',
  id: null,
  accountId: null,
  personId: null,
  consumerId: null,
  alid: null,
  cardNumber: null,
  tier: null,
  termDate: null,
  dateOfBirth: null,
  dependents: null,
  associatedMembers: null,
  sso: null,
  membership: null,
  accountSummary: null,
  activities: null,
  nextPaymentInfo: null,
  homeGym: null,
  homeGymList: [],
  homeGymCode: null,
  loadingAccountSummary: false,
  loadingAccountActivityLog: false,
  loadingBillingHistory: false,
  billingHistory: null,
  shippingAddress: null,
  billingAddressError: null,
  shippingAddressError: null,
  billingAddress: null,
  mobileNavbarVisible: false,
  eligibilities: null
};
