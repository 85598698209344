import React, { useEffect } from 'react';
import { useGlobalState } from '../../../context/GlobalContext';
import { getNewLocations } from '../../../utilities';
import { SET_GYMS } from '../../../context/reducers/location';
import classNames from 'classnames';

const HorizontalLocationList = ({ numberOfLocationsShown }) => {
  const [
    {
      locationState,
      configState: {
        client,
        com: { programCode }
      }
    },
    dispatch
  ] = useGlobalState();
  const { gymList, currentUserLocation } = locationState;

  useEffect(() => {
    if (currentUserLocation) {
      getNewLocations(programCode, client, currentUserLocation, 10, 4).then(
        locations => {
          dispatch({ type: SET_GYMS, gymList: locations });
        }
      );
      return () => dispatch({ type: SET_GYMS, gymList: null });
    }
  }, []);

  return (
    <div
      className={classNames('row justify-content-center', {
        locations_border: gymList
      })}
    >
      {numberOfLocationsShown &&
        gymList &&
        gymList.length &&
        gymList.slice(0, numberOfLocationsShown).map(item => (
          <div className="col-12 m-0 p-0" key={item.name}>
            <div className="row pb-3 justify-content-center">
              <div className="col-2 m-0 p-0 standard-gyms-pin">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="col-10 m-0 p-0 pl-2">
                <div className="locations_name truncate">
                  {item.name.substring(0, 50 - '...'.length) + '...'}
                </div>
                <div className="locations_distance">
                  {`${Math.round(item.distance * 100) / 100} ${
                    item.distance > 1 ? 'miles' : 'mile'
                  }`}{' '}
                  away
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export { HorizontalLocationList };
