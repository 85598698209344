import React, { useEffect, useState } from 'react';
import { PaymentMethodListItem } from './PaymentMethodListItem';
import { useUserState } from '../../../context/UserContext';

let PaymentMethodList = ({
  changeDefaultPaymentMethod,
  openPaymentMethodEdit,
  openPaymentMethodDelete,
  setChosenPaymentMethod,
  setShowEditPayment
}) => {
  const [userState] = useUserState();
  const { paymentMethods } = userState;
  const [sortedPaymentMethods, setSortedPaymentMethods] = useState([]);

  const toTimestamp = strDate => {
    var datum = Date.parse(strDate);
    return datum / 1000;
  };

  useEffect(() => {
    if (paymentMethods) {
      if (paymentMethods.length === 1) {
        setSortedPaymentMethods(paymentMethods);
        return;
      }

      setSortedPaymentMethods(
        paymentMethods
          .sort(
            (a, b) => toTimestamp(a.createdDate) - toTimestamp(b.createdDate)
          )
          .reverse()
      );
    }
  }, [paymentMethods]);

  return (
    <>
      {paymentMethods && !!paymentMethods.length ? (
        paymentMethods.map((method, i) => (
          <PaymentMethodListItem
            isLastItem={i !== sortedPaymentMethods.length - 1}
            paymentMethod={method}
            isInModal
            showEditPaymentOptions
            openPaymentMethodEdit={openPaymentMethodEdit}
            openPaymentMethodDelete={openPaymentMethodDelete}
            changeDefaultPaymentMethod={changeDefaultPaymentMethod}
            setChosenPaymentMethod={setChosenPaymentMethod}
            setShowEditPayment={setShowEditPayment}
          />
        ))
      ) : (
        <p>
          It seems like you still do not have a payment method on file. Please
          add one.
        </p>
      )}
    </>
  );
};

export { PaymentMethodList };
