import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useUserState } from '../../../context/UserContext';

const TierTab = ({ numInTier, tier, tierSelected }) => {
  const [
    {
      isEnrolledInDefaultProgram,
      defaultProgram,
      isStudioEnrolled,
      isEnrolledInDigitalTier
    }
  ] = useUserState();

  return (
    <div className="col-sm-3 col-md-3 no-margin">
      <div
        className={classNames('card', 'package-card', 'text-center', {
          'package-card-bestvalue-home': tier.isBestValue
        })}
      >
        <div className="package-name">
          <div className="package-name__lp">{tier.name.toUpperCase()}</div>
        </div>
        <div className="row-fluid">
          <div className="package-price">
            <div className="package-price-amount ">
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              })
                .format(tier.recurringPrice)
                .replace(/(\.|,)00$/g, '')}
            </div>
            <div className="package-tag-permonth">
              <p className="small">/month</p>
            </div>
          </div>
        </div>
        <div className="row-fluid text-left ml-3">
          <div dangerouslySetInnerHTML={{ __html: tier.description }} />
          {/* <div className="tier_description"><b>This is how</b> it will look in one line of code.</div> */}
        </div>
        <div className="row-fluid pb-3 mt-3">
          <NavLink
            to={{
              pathname: '/locations',
              selectedTier: tier
            }}
          >
            <span className="package-explore">
              <FontAwesomeIcon icon="search" className="search-icon" /> Explore
              Locations
            </span>
          </NavLink>

          <NavLink
            to={{
              pathname:
                (defaultProgram?.currentSubscription &&
                  !isEnrolledInDefaultProgram) ||
                isStudioEnrolled ||
                isEnrolledInDigitalTier
                  ? '/account/manage-plans'
                  : '/enroll/select-packages',
              selectedTier: tier
            }}
          >
            <button
              type="button"
              className="blue-btn__rounded-home pt-1 pr-5 pb-1 pl-5 font-weight-bold"
            >
              Select <i className="fas fa-chevron-right"></i>
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

TierTab.propTypes = {
  selectTier: PropTypes.func,
  tier: PropTypes.object,
  tierSelected: PropTypes.bool
};

export { TierTab };
