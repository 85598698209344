import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { Icon } from '../../../assets/Icons';
import { EditPaymentForm } from './EditPaymentForm';

let EditPaymentModal = ({
  setShowEditPayment,
  showEditPayment,
  chosenPaymentMethod,
  setChosenPaymentMethod,
  setIsLoading
}) => {
  const closeEditPaymentModal = () => {
    setShowEditPayment(!showEditPayment);
    setChosenPaymentMethod(null);
  };

  return (
    <Modal size="lg" centered isOpen={showEditPayment}>
      <ModalHeader>Edit Payment Method</ModalHeader>
      <span className="modal-close" onClick={() => closeEditPaymentModal()}>
        <Icon name="close-times" width="20px" height="20px" />
      </span>
      <EditPaymentForm
        closeEditPaymentModal={closeEditPaymentModal}
        chosenPaymentMethod={chosenPaymentMethod}
        setIsLoading={setIsLoading}
      />
    </Modal>
  );
};
export { EditPaymentModal };
