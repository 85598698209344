import { Field, FieldArray, Form, Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Input } from '../../../Global';

const AddMemberForm = ({ formRef, submit }) => {
  const [members, setMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(true);

  useEffect(() => {
    setMembers([{ firstName: '', lastName: '', dob: '' }]);
    setLoadingMembers(false);
    return () => {
      setMembers([]);
    };
  }, []);

  return (
    <>
      {loadingMembers ? (
        <div></div>
      ) : (
        <Formik
          initialValues={{ members: members }}
          onSubmit={values => submit(values)}
          innerRef={formRef}
        >
          {({ values }) => (
            <Form>
              <FieldArray name="members">
                {arrayHelpers => (
                  <div>
                    {values.members.map((member, index) => (
                      <div className="py-2 border-bottom" key={index}>
                        <div className="d-flex flex-row">
                          <Field name={`members.${index}.firstName`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta
                            }) => (
                              <Input
                                type={'text'}
                                title={'First Name'}
                                errors={meta.error}
                                touched={meta.touched}
                                handleChange={field.onChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <Field name={`members.${index}.lastName`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta
                            }) => (
                              <Input
                                type={'text'}
                                title={'Last Name'}
                                errors={meta.error}
                                touched={meta.touched}
                                handleChange={field.onChange}
                                {...field}
                              />
                            )}
                          </Field>
                        </div>
                        <Field name={`members.${index}.dob`}>
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta
                          }) => (
                            <Input
                              type={'date'}
                              title={'Date Of Birth'}
                              errors={meta.error}
                              touched={meta.touched}
                              handleChange={field.onChange}
                              {...field}
                            />
                          )}
                        </Field>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          firstName: '',
                          lastName: '',
                          dob: ''
                        })
                      }
                      className="mt-4 btn text-primary"
                    >
                      + Add Another Member
                    </button>
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export { AddMemberForm };
