import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  addDays,
  format,
  isFuture,
  differenceInDays,
  isWithinInterval
} from 'date-fns';
import { UpSellBanner } from './UpSellBanner';
import { DiscardModal } from './DiscardChangeModal';
import { useUserState } from '../../context/UserContext';
import { useGlobalState } from '../../context/GlobalContext';

const AccountUpSell = () => {
  const [userState] = useUserState();
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);
  const [
    {
      configState: { client }
    }
  ] = useGlobalState();
  const {
    isPrimary,
    isStudioEnrolled,
    isEnrolledInDefaultProgram,
    isEnrolledInDigitalTier,
    paymentMethods,
    defaultProgram,
    digitalProgram,
    nextPaymentInfo
  } = userState;

  //console.log('here1 ', JSON.stringify(paymentMethods));

  const displayBanner = (situation, program) => {
    switch (situation) {
      case 're-instate':
        return (
          <>
            {client == 'walmart' ? (
              <UpSellBanner
                title="Its not too late to hang on to your current plan!"
                description="Take advantage of thousands of nationwide gyms and online fitness & wellness classes by remaining enrolled in one of our premier fitness packages today!"
                showLocations={false}
                button={
                  <button
                    className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive"
                    onClick={() => setDiscardModalOpen(true)}
                  >
                    <i className="fas fa-undo mr-2"></i>
                    Discard Cancellation
                  </button>
                }
              />
            ) : (
              <UpSellBanner
                title={
                  isEnrolledInDigitalTier && digitalProgram?.isActive
                    ? `Changed your mind about cancelling your ${defaultProgram?.currentSubscription?.ratePlanName?.toUpperCase()} Fitness package?`
                    : 'Changed your mind about leaving us?'
                }
                description={
                  <>
                    You have
                    <span className="font-weight-bold">
                      {` ${differenceInDays(
                        new Date(program.memberTermDate),
                        new Date()
                      )} days left `}
                    </span>
                    to discard your cancellation, and avoid fees if you want to
                    re-enroll. Your{' '}
                    {`${
                      isEnrolledInDigitalTier && digitalProgram?.isActive
                        ? 'gym'
                        : ''
                    }`}{' '}
                    subscription is set to end effective
                    {` ${format(
                      new Date(program.memberTermDate),
                      'MM/dd/yyyy'
                    )}`}
                    .
                  </>
                }
                showLocations={false}
                button={
                  <button
                    className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive"
                    onClick={() => setDiscardModalOpen(true)}
                  >
                    <i className="fas fa-undo mr-2"></i>
                    Discard Cancellation
                  </button>
                }
              />
            )}
          </>
        );
      case 'cancelled':
        return (
          <>
            {client == 'walmart' ? (
              <UpSellBanner
                title="Solidify your health & fitness routine today!"
                description={
                  <>
                    Take advantage of thousands of nationwide gyms and online
                    fitness & wellness classes when you enroll in one of our
                    premier fitness packages today!
                  </>
                }
                showLocations={false}
                button={
                  <NavLink
                    to={{
                      pathname: '/account/manage-plans',
                      showEditPlan:
                        !paymentMethods || paymentMethods.length !== 0
                    }}
                    exact
                  >
                    <button className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive ml-lg-5">
                      Re-Enroll
                      <span className="enroll-chevron">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </button>
                  </NavLink>
                }
              />
            ) : (
              <UpSellBanner
                title="Go to gyms and fitness studios near home and work"
                description="Fitness program gym packages as low as $19 per month.*"
                showLocations
                button={
                  <NavLink
                    to={{
                      pathname: '/account/manage-plans',
                      showEditPlan:
                        !paymentMethods || paymentMethods.length !== 0
                    }}
                    exact
                  >
                    <button className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive ml-lg-5">
                      Re-Enroll Now
                      <span className="enroll-chevron">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </button>
                  </NavLink>
                }
              />
            )}
          </>
        );
      case 're-enroll':
        return (
          <>
            {client == 'walmart' ? (
              <UpSellBanner
                title="Solidify your health & fitness routine today!"
                description={
                  <>
                    Take advantage of thousands of nationwide gyms and online
                    fitness & wellness classes when you enroll in one of our
                    premier fitness packages today!
                  </>
                }
                showLocations={false}
                button={
                  <NavLink
                    to={{
                      pathname: '/account/manage-plans',
                      showEditPlan:
                        !paymentMethods || paymentMethods.length !== 0
                    }}
                    exact
                  >
                    <button className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive ml-lg-5">
                      Re-Enroll
                      <span className="enroll-chevron">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </button>
                  </NavLink>
                }
              />
            ) : (
              <UpSellBanner
                title="What if we waived enrollment fees? Limited time offer."
                description={
                  <>
                    As a courtesy for being a member, if you re-enroll within
                    the next
                    <span className="font-weight-bold">
                      {` ${differenceInDays(
                        addDays(new Date(program.memberTermDate), 30),
                        new Date()
                      )} days `}
                    </span>
                    we'll waive any enrollment fees. This offer expires on
                    {` ${format(
                      addDays(new Date(program.memberTermDate), 30),
                      'MM/dd/yyyy'
                    )}`}
                    .
                  </>
                }
                showLocations={false}
                button={
                  <NavLink
                    to={{
                      pathname: '/account/manage-plans',
                      showEditPlan:
                        !paymentMethods || paymentMethods.length !== 0
                    }}
                    exact
                  >
                    <button className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive">
                      Re-Enroll Now
                      <span className="enroll-chevron">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </button>
                  </NavLink>
                }
              />
            )}
          </>
        );
      case 'up-sell':
        return (
          <UpSellBanner
            title="Go to gyms and fitness studios near home and work"
            description="Fitness program gym packages as low as $19 per month.*"
            showLocations
            button={
              <NavLink to="/enroll/select-packages" exact>
                <button className="nav-btn-enroll bg-white text-primary blue-btn px-3  font-weight-bold ml-lg-5 text-nowrap">
                  {!isEnrolledInDigitalTier ? 'Enroll Now' : 'See Gym Packages'}
                  <span className="enroll-chevron">
                    <i className="fas fa-chevron-right" />
                  </span>
                </button>
              </NavLink>
            }
          />
        );
      case 'studio':
        return (
          <UpSellBanner
            title="Go to gyms and fitness studios near home and work"
            description="Fitness program gym packages as low as $19 per month.*"
            showLocations
            button={
              <NavLink
                to={{
                  pathname: '/account/manage-plans',
                  showEditPlan: !paymentMethods || paymentMethods.length !== 0
                }}
                exact
              >
                {/* {console.log("here2 ", JSON.stringify(paymentMethods))} */}
                <button className="nav-btn-enroll bg-white text-primary blue-btn px-3 font-weight-bold nowrap-responsive">
                  {!isEnrolledInDigitalTier ? 'Enroll Now' : 'See Gym Packages'}
                  <span className="enroll-chevron">
                    <i className="fas fa-chevron-right" />
                  </span>
                </button>
              </NavLink>
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isPrimary &&
        !nextPaymentInfo?.isBalanceOverdue &&
        // (isEnrolledInDigitalTier ? digitalProgram?.isCancelling : true) &&
        (isEnrolledInDefaultProgram
          ? defaultProgram.isCancelling &&
            displayBanner('re-instate', defaultProgram)
          : defaultProgram?.currentSubscription
          ? defaultProgram?.isCancelled &&
            (isWithinInterval(new Date(), {
              start: new Date(defaultProgram?.memberTermDate),
              end: addDays(new Date(defaultProgram?.memberTermDate), 30)
            })
              ? displayBanner('re-enroll', defaultProgram)
              : displayBanner('cancelled', defaultProgram))
          : isStudioEnrolled
          ? displayBanner('studio', defaultProgram)
          : displayBanner('up-sell', defaultProgram))}

      {isDiscardModalOpen && (
        <DiscardModal
          setDiscardModalOpen={setDiscardModalOpen}
          isDiscardModalOpen={isDiscardModalOpen}
          type={'Discard Cancellation'}
          user={userState}
        />
      )}
    </>
  );
};

export { AccountUpSell };
