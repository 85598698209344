import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

let EnrollmentStep = ({ title, description, children }) => {
  const location = useLocation();
  const isSelectingPackage = location.pathname === '/enroll/select-packages';
  return (
    <div
      className={classNames('mb-5', 'p-0', 'pr-lg-3', {
        'col-xl-6': !isSelectingPackage,
        'col-xl-7': isSelectingPackage,
        'col-lg-7': !isSelectingPackage,
        'col-lg-9': isSelectingPackage
      })}
    >
      {isSelectingPackage ? (
        <h3 className="font-weight-bold pt-2">
          {title}
          <a href="#legal" className="legal-sup">
            <sup>2</sup>
          </a>
        </h3>
      ) : (
        <h3 className="font-weight-bold pt-2">{title}</h3>
      )}
      <p>{description}</p>
      {children}
    </div>
  );
};

export { EnrollmentStep };
