import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { FilterOptionButton } from './';
import CheckboxGroup from 'react-checkbox-group';
import { useGlobalState } from '../../../context/GlobalContext';
import { setFilter } from '../../../context/actions/location';

const AmenitiesFilter = () => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [checkedAmenities, setCheckedAmenities] = useState([]);
  const node = useRef();
  const [{ locationState }, dispatch] = useGlobalState();
  const { amenityCategories, chosenAmenities } = locationState;
  useEffect(() => {
    setCheckedAmenities(chosenAmenities);
  }, [chosenAmenities]);

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setDropDownOpen(false);
  };

  useEffect(() => {
    if (dropDownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownOpen]);

  const toggleDropDown = () => {
    setDropDownOpen(!dropDownOpen);
    setCheckedAmenities(chosenAmenities);
  };

  const applyFilters = () => {
    dispatch(setFilter('chosenAmenities', checkedAmenities));
    toggleDropDown();
  };

  return (
    <div ref={node} className="dropdown ml-2">
      <FilterOptionButton
        toggleFilter={e => toggleDropDown()}
        buttonText={
          chosenAmenities
            ? chosenAmenities.length === 1
              ? chosenAmenities[0]
              : `${chosenAmenities.length} Amenities Chosen`
            : 'Amenities'
        }
      />
      <div
        className={classNames('dropdown-menu', 'shadow', 'px-4', {
          show: dropDownOpen
        })}
      >
        <CheckboxGroup
          value={checkedAmenities ? checkedAmenities : []}
          onChange={setCheckedAmenities}
        >
          {Checkbox => (
            <>
              {amenityCategories.map((amenity, i) => (
                <label
                  key={i}
                  className={classNames(
                    'dropdown-item',
                    'm-0',
                    'px-0',
                    'd-block',
                    {
                      active:
                        checkedAmenities && checkedAmenities.includes(amenity)
                    }
                  )}
                >
                  <Checkbox value={amenity} />
                  {amenity}
                </label>
              ))}
            </>
          )}
        </CheckboxGroup>
        <button
          onClick={() => applyFilters()}
          className="blue-btn mt-2 mb-2 w-100"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export { AmenitiesFilter };
