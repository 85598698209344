import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HomeGymModal } from '../';
import { Icon } from '../../../assets/Icons';
import { useGlobalState } from '../../../context/GlobalContext';
import { LocationAmenities } from './';
import { chooseLocation } from '../../../context/actions/location';
import { WarningSection } from '../../Global/WarningSection';
import { useUserState } from '../../../context/UserContext';

let LocationItemDetails = ({ isModal }) => {
  const [
    { locationState, tierState, configState },
    dispatch
  ] = useGlobalState();
  const [userState] = useUserState();
  const {
    chosenLocation,
    currentUserLocation,
    chosenLocationType
  } = locationState;
  const {
    isEligibleForDefaultProgram,
    isEnrolledInDefaultProgram,
    defaultProgram,
    isPrimary,
    isStudioEnrolled,
    paymentMethods,
    nextPaymentInfo
  } = userState;
  const { homeLocationTier, chosenTier } = tierState;
  const [showHomeGymModal, setShowHomeGymModal] = useState(false);
  const httpRegex = /^(http|https)/;
  const { cms } = configState;
  const getDirections = () => {
    const googleMapDirections = 'https://www.google.com/maps/dir/?api=1';
    if (currentUserLocation) {
      return `${googleMapDirections}&origin=${currentUserLocation.lat},${
        currentUserLocation.lng
      }&destination=${chosenLocation.street.replace(/ /g, '+')}+${
        chosenLocation.city
      }+${chosenLocation.state}`;
    } else {
      return `${googleMapDirections}&destination=${chosenLocation.street.replace(
        / /g,
        '+'
      )}+${chosenLocation.city}+${chosenLocation.state}`;
    }
  };

  return (
    <>
      {!isModal &&
        homeLocationTier &&
        chosenLocation.tierNumber == homeLocationTier.number &&
        isEligibleForDefaultProgram &&
        isEnrolledInDefaultProgram &&
        defaultProgram?.tier == homeLocationTier.number &&
        (!defaultProgram?.currentHomeGym ? (
          <WarningSection type="home location not set" />
        ) : (
          chosenLocation.id !== defaultProgram?.currentHomeGym.id && (
            <WarningSection type="not users current home location" />
          )
        ))}
      {!isModal &&
        isEligibleForDefaultProgram &&
        isEnrolledInDefaultProgram &&
        chosenLocation.tierNumber > defaultProgram?.tier && (
          <WarningSection type="tier upsell" />
        )}
      <div className="px-lg-4 px-2 pt-3">
        <p
          className="go-back d-flex m-0"
          onClick={() => dispatch(chooseLocation(null))}
          style={{ height: 25 }}
        >
          <Icon name="back" width="24px" fill={cms.primary_color} /> Back to
          search results
        </p>
      </div>
      <div
        className="px-lg-4 px-2 py-3 location-list-scroll"
        style={{ overflowY: 'scroll', height: isModal ? '35vh' : 'auto' }}
      >
        <div className="col-sm-12">
          <h3>{chosenLocation.name}</h3>
          {chosenLocation.isHomeLocationTier && (
            <div className="elite-tag-loc">
              <i className="m-0 badge secondary-color p-0 text-right mb-1">
                <Icon
                  name="elite-pin"
                  width="14px"
                  height="14px"
                  className="mr-1"
                ></Icon>
                Elite
              </i>
            </div>
          )}
          {defaultProgram?.currentHomeGym &&
            chosenLocation.id === defaultProgram?.currentHomeGym.id && (
              <div className="home-gym-tag-loc">
                <i className="badge primary-color ">
                  <Icon
                    name="home-gym"
                    width="14px"
                    height="14px"
                    className="mr-1"
                    fill={cms.primary_color}
                  ></Icon>
                  Home Gym
                </i>
              </div>
            )}
          <p className="mt-1 mb-3 font-small text-capitalize">
            {`${chosenLocationType} Location`}
          </p>
          <hr />
          <p
            className="d-flex align-items-center font-small"
            style={{ height: 25 }}
          >
            <Icon name="phone" width="24px" fill={cms.primary_color} />{' '}
            <span className="ml-2">{chosenLocation.phone}</span>
          </p>
          {chosenLocation ? (
            chosenLocation.website ? (
              <p
                className="d-flex align-items-center"
                style={{ maxHeight: 60 }}
              >
                <Icon name="globe" width="24px" fill={cms.primary_color} />
                <button
                  className="ml-2 btn font-weight-normal text-primary p-0 font-small w-100 text-left text-break"
                  onClick={() =>
                    window.open(
                      httpRegex.test(chosenLocation.website)
                        ? chosenLocation.website
                        : `https://${chosenLocation.website}`,
                      '_blank'
                    )
                  }
                >
                  {chosenLocation.website}
                </button>
              </p>
            ) : null
          ) : null}
          <div className="d-flex align-items-center mb-3">
            <Icon
              name="pin"
              width="24px"
              height="24px"
              fill={cms.primary_color}
            />
            <p className="ml-2 font-small m-0">
              {chosenLocation.street}
              <br />
              {`${chosenLocation.city}, ${chosenLocation.state} ${chosenLocation.zip}`}
            </p>
            <p className="font-small text-dark-gray mb-0 ml-auto align-self-start">
              {Math.round(chosenLocation.distance * 100) / 100} Miles
            </p>
          </div>
          {isEligibleForDefaultProgram &&
            isEnrolledInDefaultProgram &&
            //TODO: check term date
            defaultProgram?.tier == chosenLocation.tierNumber &&
            chosenLocation.isHomeLocationTier && (
              <button
                onClick={() => setShowHomeGymModal(true)}
                className="btn btn-primary w-100 mb-2"
                disabled={
                  defaultProgram?.currentHomeGym &&
                  defaultProgram?.currentHomeGym.id === chosenLocation.id
                }
              >
                <Icon
                  name="home-gym-white"
                  width="25px"
                  height="25px"
                  fill={cms.primary_color}
                />{' '}
                Set as Home Location
              </button>
            )}
          {isPrimary &&
            !isModal &&
            !chosenLocation.isStudio &&
            nextPaymentInfo &&
            !nextPaymentInfo.isBalanceOverdue &&
            (isEnrolledInDefaultProgram ? (
              !defaultProgram?.isCancelled ? (
                defaultProgram?.tier < chosenTier.tier && (
                  <NavLink
                    to={{
                      pathname: '/account/manage-plans',
                      showEditPlan:
                        !paymentMethods || paymentMethods.length !== 0,
                      selectedTier: chosenTier
                    }}
                  >
                    <button className="btn btn-primary w-100 mb-2">
                      Upgrade Package
                    </button>
                  </NavLink>
                )
              ) : (
                <NavLink
                  to={{
                    pathname: '/account/manage-plans',
                    showEditPlan:
                      !paymentMethods || paymentMethods.length !== 0,
                    selectedTier: chosenTier
                  }}
                >
                  <button className="btn btn-primary w-100 mb-2">
                    Select Package
                  </button>
                </NavLink>
              )
            ) : (
              <NavLink
                to={{
                  pathname: isStudioEnrolled
                    ? '/account/manage-plans'
                    : '/enroll/select-packages',
                  showEditPlan:
                    isStudioEnrolled &&
                    (!paymentMethods || paymentMethods.length !== 0),
                  selectedTier: chosenTier
                }}
              >
                <button className="btn btn-primary w-100 mb-2">
                  Select Package
                </button>
              </NavLink>
            ))}
          <button
            onClick={() => window.open(getDirections(), '_blank')}
            className="btn btn-outline-primary w-100"
          >
            Get Directions
          </button>
        </div>
        {chosenLocation.amenities && (
          <LocationAmenities amenities={chosenLocation.amenities} />
        )}
        {chosenLocation.marketingNotes ? (
          <div className="col-sm-12">
            <p className="mt-5 mb-3 text-uppercase font-weight-bold text-secondary font-small">
              Additional Information
            </p>
            <hr className="location-amenities-hr" />
            <div className="d-flex align-items-center mt-3 mb-3">
              <ul className="p-0 w-100">{chosenLocation.marketingNotes}</ul>
            </div>
          </div>
        ) : null}
        <HomeGymModal
          showHomeGymModal={showHomeGymModal}
          setShowHomeGymModal={setShowHomeGymModal}
          location={chosenLocation}
          title={
            defaultProgram?.currentHomeGym
              ? 'Update Home Location'
              : 'Confirm Home Location'
          }
        />
      </div>
    </>
  );
};

export { LocationItemDetails };
