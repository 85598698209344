import React, { useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import {
  reinstatePrimary,
  reinstateDigitalPrimary,
  cancelSubscription
} from '../../api/payments';
import { setUserInfo } from '../../context/actions/user';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserState } from '../../context/UserContext';
import { toastNotification } from '../../utilities/toastMessage';
import { updateHomeGym } from '../../api/account';
import { ModalContainer } from '../Global/Modal';
import { useGlobalState } from '../../context/GlobalContext';

const DiscardModal = ({
  user,
  isDiscardModalOpen,
  setDiscardModalOpen,
  isDigitalDiscardModalOpen,
  setDigitalDiscardModalOpen,
  setIsShowing,
  type
}) => {
  const [userState, userDispatch] = useUserState();
  const { digitalProgram, defaultProgram } = user;
  const { accountNumber, consumerId } = userState;
  const [isReinstating, setIsReinstating] = useState(false);
  // const [isShowing, setIsShowing] = useState();
  const history = useHistory();
  const location = useLocation();
  const [showDiscardState, setShowDiscardState] = useState(false);
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();

  const doReinstate = async () => {
    setIsReinstating(true);
    try {
      await reinstatePrimary(userState, user);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your plan cancellation has been successfully discarded.',
        true
      );
      if (location.pathname !== '/account/manage-plans') {
        history.push('/account/manage-plans');
      }
    } catch (error) {
      toastNotification(
        'Your plan cancellation could not be discarded.',
        false
      );
      throw error;
    } finally {
      setDiscardModalOpen(false);
      setIsReinstating(false);
      setIsShowing(false);
    }
  };

  const doDigitalReinstate = async () => {
    setIsReinstating(true);
    try {
      await reinstateDigitalPrimary(userState, user);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your plan cancellation has been successfully discarded.',
        true
      );
      if (location.pathname !== '/account/manage-plans') {
        history.push('/account/manage-plans');
      }
    } catch (error) {
      toastNotification(
        'Your plan cancellation could not be discarded.',
        false
      );
      throw error;
    } finally {
      setDiscardModalOpen(false);
      setIsReinstating(false);
    }
  };

  const handleUpdateHomeGym = async () => {
    if (!defaultProgram?.currentHomeGym.id) return;
    setShowDiscardState(true);
    try {
      await updateHomeGym(defaultProgram?.currentHomeGym.id);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your home gym change has been successfully discarded.',
        true
      );
    } catch (err) {
      toastNotification('Your home gym change could not be discarded.', false);
      throw err;
    } finally {
      setDiscardModalOpen(false);
      setShowDiscardState(false);
    }
  };

  const discardChange = async user => {
    try {
      if (user.isPrimary) {
        await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
          loggedInAccountNumber: accountNumber,
          members: [
            {
              accountNumber: accountNumber,
              consumerId: consumerId,
              isPrimary: true,
              orderActions: [
                {
                  type: 'SubscriptionNew',
                  ratePlanChargeId:
                    defaultProgram?.currentSubscription?.ratePlanChargeId,
                  subscriptionNumber:
                    defaultProgram?.currentSubscription?.subscriptionNumber, 
                  //keepDigital: true,
                }
              ]
            }
          ]
        });
      } else {
        await axios.post('/api/prime/v2/account/submitupdatesubscriptions', {
          loggedInAccountNumber: accountNumber,
          members: [
            {
              accountNumber: user.accountNumber,
              consumerId: user.consumerId,
              isPrimary: false,
              orderActions: [
                {
                  type: 'SubscriptionNew',
                  ratePlanChargeId:
                    user?.defaultProgram?.currentSubscription?.ratePlanChargeId,
                  subscriptionNumber:
                    user?.defaultProgram?.currentSubscription
                      ?.subscriptionNumber, 
                  //keepDigital: true,
                }
              ]
            },
            {
              accountNumber: accountNumber,
              consumerId: consumerId,
              isPrimary: true,
              orderActions: [
                {
                  type: 'NoAction'
                }
              ]
            }
          ]
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const doDiscardChange = async () => {
    setIsReinstating(true);
    try {
      await cancelSubscription(userState, user);
      await discardChange(user);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Your plan change has been successfully discarded.',
        true
      );
      if (location.pathname !== '/account/manage-plans') {
        history.push('/account/manage-plans');
      }
    } catch (error) {
      toastNotification('Your plan change could not be discarded.', false);
      console.log(error);
      throw error;
    } finally {
      setDiscardModalOpen(false);
      setIsReinstating(false);
    }
  };

  const openModal = type => {
    switch (type) {
      case 'Discard Cancellation':
        return (
          <ModalContainer
            isOpen={isDiscardModalOpen}
            setIsOpen={setDiscardModalOpen}
            title="Discard Cancellation?"
            submitText="Yes, Discard Cancellation"
            cancelText="Never Mind"
            submit={doReinstate}
            isLoading={isReinstating}
            isDismissable={false}
          >
            <p>
              Our records indicate you cancelled your current package{' '}
              <b>
                on{' '}
                {moment(
                  defaultProgram?.currentSubscriptionUserCancelDate
                ).format('MM/DD/YY')}
              </b>
              . This change is scheduled to take effect{' '}
              <b>
                on {moment(defaultProgram?.memberTermDate).format('MM/DD/YY')}
              </b>
              . Would you like to discard this cancellation? Your package will
              remain as{' '}
              <b>
                <span className="text-uppercase">
                  {defaultProgram?.currentSubscription?.ratePlanName}{' '}
                </span>
                Fitness
              </b>{' '}
              until further changes are made.
            </p>
          </ModalContainer>
        );
      case 'Discard Digital Cancellation':
        return (
          <ModalContainer
            isOpen={isDiscardModalOpen}
            setIsOpen={setDiscardModalOpen}
            title={
              !defaultProgram?.futureSubscription && digitalProgram?.isActive
                ? 'Discard Downgrade?'
                : 'Discard Cancellation?'
            }
            submitText={
              !defaultProgram?.futureSubscription && digitalProgram?.isActive
                ? 'Yes, Discard Downgrade'
                : 'Yes, Discard Cancellation'
            }
            cancelText="Never Mind"
            submit={doDigitalReinstate}
            isLoading={isReinstating}
          >
            {!defaultProgram?.futureSubscription && digitalProgram?.isActive ? (
              <>
                <p>
                  Our records indicate that you downgraded your package from{' '}
                  {digitalProgram?.currentSubscription?.ratePlanName} to{' '}
                  <b>Digital Only</b>
                  <b>
                    on{' '}
                    {moment(
                      digitalProgram?.currentSubscriptionUserCancelDate
                    ).format('MM/DD/YY')}
                  </b>
                  . The change is scheduled to take place on{' '}
                  {moment(defaultProgram?.memberTermDate).format('MM/DD/YY')}.
                  Would you like to discard this change?
                </p>
              </>
            ) : (
              <>
                <p>
                  Our records indicate you cancelled your current package{' '}
                  <b>
                    on{' '}
                    {moment(
                      digitalProgram?.currentSubscriptionUserCancelDate
                    ).format('MM/DD/YY')}
                  </b>
                  . This change is scheduled to take effect{' '}
                  <b>
                    on{' '}
                    {moment(defaultProgram?.memberTermDate).format('MM/DD/YY')}
                  </b>
                  . Would you like to discard this cancellation? Your package
                  will remain as{' '}
                  <b>
                    <span className="text-uppercase">
                      {digitalProgram?.currentSubscription?.ratePlanName}{' '}
                    </span>
                    Fitness
                  </b>{' '}
                  until further changes are made.
                </p>
              </>
            )}
          </ModalContainer>
        );
      case 'Downgrade Digital Discard Change':
        return (
          <ModalContainer
            isOpen={isDiscardModalOpen}
            setIsOpen={setDiscardModalOpen}
            title="Discard Downgrade?"
            submitText="Yes, Discard Downgrade"
            cancelText="Never Mind"
            submit={doReinstate}
            isLoading={isReinstating}
          >
            <p>
              Our records indicate that you downgraded your package from{' '}
              <b>
                {' '}
                <span className="text-uppercase">
                  {defaultProgram?.currentSubscription?.ratePlanName}
                </span>{' '}
              </b>{' '}
              to <b>Digital Only</b>
              <b>
                {' '}
                on{' '}
                {moment(
                  digitalProgram?.currentSubscriptionUserCancelDate
                ).format('MM/DD/YY')}
              </b>
              . The change is scheduled to take place on{' '}
              <b>{moment(defaultProgram?.memberTermDate).format('MM/DD/YY')}</b>
              . Would you like to discard this change?
            </p>
          </ModalContainer>
        );
      case 'Downgrade Discard Change':
        return (
          <ModalContainer
            isOpen={isDiscardModalOpen}
            setIsOpen={setDiscardModalOpen}
            title="Discard Package Change?"
            cancelText="No, Keep Changes"
            submitText="Yes, Discard Changes"
            submit={doDiscardChange}
            isLoading={isReinstating}
            isDismissable={false}
          >
            {defaultProgram?.currentSubscription &&
              defaultProgram?.futureSubscription && (
                <p>
                  Our records indicate you changed your desired package from
                  <b>
                    {' '}
                    <span className="text-uppercase">
                      {defaultProgram?.currentSubscription?.ratePlanName}
                    </span>{' '}
                  </b>{' '}
                  to
                  <b>
                    {' '}
                    <span className="text-uppercase">
                      {defaultProgram?.futureSubscription.ratePlanName}
                    </span>{' '}
                    on{' '}
                    {moment(
                      defaultProgram?.currentSubscriptionUserCancelDate
                    ).format('MM/DD/YY')}
                  </b>
                  . This change is scheduled to take place on{' '}
                  <b>
                    {moment(
                      defaultProgram?.futureSubscription.startDate
                    ).format('MM/DD/YY')}
                  </b>
                  . Would you like to discard this change? Keep in mind, you
                  will have to call our customer support center in order to
                  change your package in the future.
                </p>
              )}
          </ModalContainer>
        );
      case 'Home Gym Change':
        return (
          <ModalContainer
            isOpen={isDiscardModalOpen}
            setIsOpen={setDiscardModalOpen}
            title=" Discard Home Gym Change?"
            submitText="Yes, Discard Changes"
            cancelText="No, Keep Changes"
            submit={handleUpdateHomeGym}
            isLoading={showDiscardState}
            isDismissable={false}

          >
            {defaultProgram?.currentHomeGym && defaultProgram?.futureHomeGym && (
              <p>
                Our records indicate you changed your home gym to{' '}
                <b>
                  {defaultProgram?.futureHomeGym.name} on{' '}
                  {moment(defaultProgram?.futureHomeGym.createdOnDate).format(
                    'MM/DD/YY'
                  )}
                </b>
                . This change is scheduled to take effect on
                <b>
                  {' '}
                  {moment(
                    defaultProgram?.futureHomeGym.homeGymStartDate
                  ).format('MM/DD/YY')}
                </b>
                . Would you like to discard this change? Your home gym will
                remain at <b>{defaultProgram?.currentHomeGym.name}</b> until
                further changes are made.
              </p>
            )}
          </ModalContainer>
        );
      default:
        return null;
    }
  };

  return <div>{openModal(type)}</div>;
};

export { DiscardModal };
