import React from 'react';
import { LocationSearchInput } from './LocationSearchInput';
import { ModalContainer } from '../Global/Modal';

const GetLocationModal = ({
  showGetLocationModal,
  setShowGetLocationModal
}) => (
  <ModalContainer
    setIsOpen={setShowGetLocationModal}
    isOpen={showGetLocationModal}
  >
    <div className="text-center px-4">
      <h4 className="font-weight-bold text-dark mb-4">
        Members have access to thousands of locations nationwide
      </h4>
      <h4 className="text-secondary mb-4">
        Explore locations near your home, work, or favorite travel destinations.
      </h4>
    </div>
    <div className="mb-4">
      <LocationSearchInput setShowGetLocationModal={setShowGetLocationModal} />
    </div>
  </ModalContainer>
);

export { GetLocationModal };
