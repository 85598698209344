import React from 'react';
import { AddressErrors } from './AddressForm/AddressErrors';

const Input = props => {
  return (
    <div className="form-group col">
      <label htmlFor={props.name} className="form-label">
        <p className=" h6 text-uppercase font-weight-bold text-secondary m-0">
          {props.title}
          {!props.optional && <i className="m-0 text-danger">*</i>}
        </p>
        {props.optional ? (
          <p className=" h6 text-secondary m-0">(Optional)</p>
        ) : null}
      </label>
      <input
        className="form-control"
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        placeholder={props.placeholder}
      />
      <AddressErrors
        touched={props.touched}
        errors={props.errors}
        name={props.name}
        addressType={props.addressType}
      />
    </div>
  );
};

export { Input };
