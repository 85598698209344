import React from 'react';
import { Icon } from '../assets/Icons';
import { getPhoneNumber } from './getPhoneNumber';
const { toast, cssTransition } = require('react-toastify');

const Toast = props => (
  <div className="d-flex flex-row">
    <Icon
      className="m-0 mt-1 align-self-start"
      name={props.success ? 'circle-check-success' : 'exclamation-circle'}
      width={props.success ? '40px' : '30px'}
      height={props.success ? '16px' : '16px'}
    />
    <div className="d-flex flex-column ml-2 flex-fill">
      <p
        className={`m-0 align-self-start Toastify__toast--${
          props.success ? 'success' : 'error'
        }__title`}
      >
        {props.message}
      </p>
      {!props.success && (
        <p className="font-small m-0">
          If this problem persists, please call{' '}
          <a
            className="text-underline-primary"
            href={`tel:${getPhoneNumber().number}`}
          >
            {getPhoneNumber().text}
          </a>
        </p>
      )}
      {props.successMessage && props.successMessage}
      <div className="d-flex flex-row align-self-end">
        <button
          onClick={() => props.closeToast()}
          className={`p-0 border-0 bg-white text-primary text-underline-primary ${props.success &&
            'font-weight-bold'}`}
        >
          Dismiss
        </button>
        {!props.success && (
          <button
            onClick={() => (props.retry ? props.retry() : props.closeToast())}
            className="ml-2 p-0 pl-2 border-0 border-light border-left bg-white text-primary text-underline-primary font-weight-bold"
          >
            Retry
          </button>
        )}
      </div>
    </div>
  </div>
);

export const toastNotification = (
  message,
  success,
  successMessage = null,
  retry = null
) => {
  const options = {
    position: 'bottom-right',
    hideProgressBar: true,
    closeButton: false,
    // autoClose: false,
    autoClose: 5000,
    closeOnClick: false,
    transition: cssTransition({
      enter: 'Toastify__slide-enter--bottom-center',
      exit: 'Toastify__slide-exit--bottom-center'
    })
  };
  success
    ? toast.success(
        <Toast
          message={message}
          successMessage={successMessage}
          success={success}
          retry={retry}
        />,
        options
      )
    : toast.error(
        <Toast message={message} success={success} retry={retry} />,
        options
      );
};
