import axios from 'axios';
import moment from 'moment';

const getActivities = async (client, endDate = null, startDate = null) => {
  const optionalStartDate = moment()
    .add(-1, 'years')
    .format('l');
  const optionalEndDate = moment().format('l');

  try {
    const activityData = await axios.get(
      `/api/${client}/activity/memberactivity?startDate=${
        startDate ? startDate : optionalStartDate
      }&endDate=${endDate ? endDate : optionalEndDate}`
    );
    return activityData.data.data.activity;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getActivities };
