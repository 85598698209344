import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { globalReducer } from './reducers/global';
import { initialGlobalState } from './state/initialGlobalState';
import Localbase from 'localbase';

let db = new Localbase('global');

export const GlobalContext = createContext();

// const globalState = db
//   .collection('globalState')
//   .get()
//   .then(data => data)
//   .catch(error => error);

const globalState = JSON.parse(localStorage.getItem('globalState'));

export const GlobalProvider = ({ children }) => {
  const global = useReducer(globalReducer, globalState || initialGlobalState);

  useEffect(() => {
    // console.log(global);
    // db.collection('globalState').set([global[0]]);
    localStorage.setItem('globalState', JSON.stringify(global[0]));
  }, [global[0]]);

  return (
    <GlobalContext.Provider value={global}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalContext);
