import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { LoadingSpinner } from '../../../../Global/LoadingSpinner';
import { Field, FieldArray, Form, Formik, useFormik, withFormik } from 'formik';
import Input from 'reactstrap/lib/Input';
import { toastNotification } from '../../../../../utilities/toastMessage';
import { useUserState } from '../../../../../context/UserContext';
import { setUserInfo } from '../../../../../context/actions/user';
import { useGlobalState } from '../../../../../context/GlobalContext';
import moment from 'moment';

let EditInfoModal = ({
  isOpen,
  setIsOpen,
  loaderText = '',
  selfClosing = false,
  cancelAction = null,
  size = 'md',
  user,
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const [loading, setLoading] = useState(false);
  const [infoChange, setInfoChange] = useState(null);
  const [
    // { firstName, lastName, dob, personId },
    { personId },
    userDispatch
  ] = useUserState();
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();
  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      dob: moment(user.dateOfBirth).format('YYYY-MM-DD')
    }
  });

  const handleSubmit = async () => {
    setLoading(true);
    let response;
    try {
      response = await axios.put(`/api/prime/v2/account/updateaddress`, {
        personId: user.personId,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        dateOfBirth: moment(formik.values.dob).format()
      });
      if (!response.data.success) throw new Error();
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        'Member information successfully updated.',
        true
      );
    } catch (error) {
      toastNotification('Members information could not be updated.', false);
      throw error;
    } finally {
      setLoading(false);
      setIsOpen(!isOpen)
    }
  };


  return (
    <>
      <Modal
        size={size}
        toggle={!selfClosing && (() => setIsOpen(!isOpen))}
        centered
        isOpen={isOpen}
      >
        <ModalHeader
          className="ml-4 pb-0"
          toggle={!selfClosing ? () => setIsOpen(!isOpen) : null}
        >
          Edit Family Member Info
        </ModalHeader>
        <ModalBody className="pt-0 mt-0">
          {loading ? (
            <LoadingSpinner additionalText={loaderText} />
          ) : (
            <div className="py-2">
              <p className="mx-4">
                Please confirm the name {' & '} date of birth for the current
                family member.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group flex-fill mx-4">
                  <div className="container m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="column m-0 p-0 mr-3">
                        <label id="fitnessPackage pb-3">
                          <p className="m-0 h6 text-uppercase font-weight-bold text-secondary">
                            FIRST NAME
                            <i className="m-0 text-danger">*</i>
                          </p>
                        </label>
                        <Input
                          type={'text'}
                          title={'First Name'}
                          name="firstName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          errors={errors}
                          touched={touched}
                          required
                        />
                        {formik.touched.firstName &&
                          !formik.values.firstName && (
                            <div className="font-small text-danger">
                              First Name is required
                            </div>
                          )}
                      </div>
                      <div className="column m-0 p-0">
                      <label id="fitnessPackage pb-3">
                          <p className="m-0 h6 text-uppercase font-weight-bold text-secondary">
                            LAST NAME
                            <i className="m-0 text-danger">*</i>
                          </p>
                        </label>
                        <Input
                          type={'text'}
                          title={'Last Name'}
                          name="lastName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          errors={errors}
                          touched={touched}
                        />
                        {formik.touched.lastName &&
                          !formik.values.lastName && (
                            <div className="font-small text-danger">
                              Last Name is required
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group flex-fill mx-4">
                  
                  <div className="container m-0 p-0">
                    <div className="row m-0 p-0">
                      
                    </div>
                  </div>
                </div>
                <div className="form-group flex-fill mx-4">
                  <label id="fitnessPackage">
                    <p className="m-0 h6 text-uppercase font-weight-bold text-secondary">
                      DATE OF BIRTH
                      <i className="m-0 text-danger">*</i>
                    </p>
                  </label>
                  <div className="container m-0 p-0">
                    <div className="row m-0 p-0">
                      <div className="column m-0 p-0">
                        <Input
                          type={'date'}
                          title={'Date Of Birth'}
                          name="dob"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dob}
                          errors={errors}
                          touched={touched}
                        />
                        {formik.touched.dob && !formik.values.dob && (
                          <div className="font-small text-danger">
                            Date of Birth is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            <Button
              onClick={
                !cancelAction ? () => setIsOpen(!isOpen) : () => cancelAction()
              }
              className="btn bg-white text-primary col"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              // onClick={formik.handleSubmit}
              className="btn btn-primary col"
              disabled={
                loading ||
                !formik.values.firstName ||
                !formik.values.lastName ||
                !formik.values.dob
              }
            >
              Confirm Member Info
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export { EditInfoModal };
