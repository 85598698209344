import { initialEnrollmentState } from '../state/initialEnrollmentState';

export const SET_LOADING = 'SET_LOADING';
export const SET_CART_SUMMARY = 'SET_CART_SUMMARY';
export const SET_CHOSEN_DEPENDENTS = 'SET_CHOSEN_DEPENDENTS';
export const SET_UNDERAGE_DEPENDENTS = 'SET_UNDERAGE_DEPENDENTS';
export const SET_PRIMARY_SELECTED_PACKAGE = 'SET_PRIMARY_SELECTED_PACKAGE';
export const SET_CHECKED_DEPENDENTS = 'SET_CHECKED_DEPENDENTS';
export const SET_DISABLE_FOOTER_BUTTON = 'SET_DISABLE_FOOTER_BUTTON';
export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';
export const SET_POLICY = 'SET_POLICY';
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';
export const SET_ENROLLMENT_DEFAULT = 'SET_ENROLLMENT_DEFAULT';
export const SET_ADDRESS_VALIDATION_ERRORS = 'SET_ADDRESS_VALIDATION_ERRORS';
export const SET_ADDRESS_SUGGESTIONS = 'SET_ADDRESS_SUGGESTIONS';
export const SET_IS_MAILING_SAME_AS_BILLING = 'SET_IS_MAILING_SAME_AS_BILLING';

const setCartSummary = (cartSummary, state) => ({ ...state, cartSummary });

const setPromoCode = (promoCode, state) => ({
  ...state,
  promoCode
});

const setPrimarySelectedPackage = (primarySelectedPackage, state) => ({
  ...state,
  primarySelectedPackage
});

const setLoading = (loadingType, isLoading, state) => ({
  ...state,
  [loadingType]: isLoading
});

const setPolicy = (policyType, isAccepted, state) => ({
  ...state,
  [policyType]: isAccepted
});

const setChosenDependents = (chosenDependents, state) => ({
  ...state,
  chosenDependents
});

const setCheckedDependents = (checkedDependents, state) => ({
  ...state,
  checkedDependents
});

const setUnderageDependents = (underageDependents, state) => ({
  ...state,
  underageDependents
});

const setDisableFooterButton = (disableFooterButton, state) => ({
  ...state,
  disableFooterButton
});

const setSelectedPaymentMethod = (selectedPaymentMethod, state) => ({
  ...state,
  selectedPaymentMethod
});

const setPaymentToken = (paymentToken, state) => ({
  ...state,
  paymentToken
});

const setAddressValidationErrors = (addressValidationErrors, state) => ({
  ...state,
  addressValidationErrors
});

const setIsMailingSameAsBilling = (isMailingSameAsBilling, state) => ({
  ...state,
  isMailingSameAsBilling
});

export const enrollmentReducer = (state, action) => {
  switch (action.type) {
    case SET_PRIMARY_SELECTED_PACKAGE:
      return setPrimarySelectedPackage(action.primarySelectedPackage, state);
    case SET_CART_SUMMARY:
      return setCartSummary(action.cartSummary, state);
    case SET_CHOSEN_DEPENDENTS:
      return setChosenDependents(action.chosenDependents, state);
    case SET_CHECKED_DEPENDENTS:
      return setCheckedDependents(action.checkedDependents, state);
    case SET_UNDERAGE_DEPENDENTS:
      return setUnderageDependents(action.underageDependents, state);
    case SET_DISABLE_FOOTER_BUTTON:
      return setDisableFooterButton(action.disableFooterButton, state);
    case SET_SELECTED_PAYMENT_METHOD:
      return setSelectedPaymentMethod(action.selectedPaymentMethod, state);
    case SET_POLICY:
      return setPolicy(action.policyType, action.isAccepted, state);
    case SET_PAYMENT_TOKEN:
      return setPaymentToken(action.paymentToken, state);
    case SET_PROMO_CODE:
      return setPromoCode(action.promoCode, state);
    case SET_LOADING:
      return setLoading(action.loadingType, action.isLoading, state);
    case SET_ENROLLMENT_DEFAULT:
      return initialEnrollmentState;
    case SET_ADDRESS_VALIDATION_ERRORS:
      return setAddressValidationErrors(action.addressValidationErrors, state);
    case SET_IS_MAILING_SAME_AS_BILLING:
      return setIsMailingSameAsBilling(action.isMailingSameAsBilling, state);
    default:
      return state;
  }
};
