import React from 'react';
import { ModalContainer } from '../Global/Modal';

export const StudioInformationModal = ({
  setIsStudioModalOpen,
  isStudioModalOpen,
  setShowManagePlanPaymentModal
}) => (
  <ModalContainer
    setIsOpen={setIsStudioModalOpen}
    isOpen={isStudioModalOpen}
    title="Studio Class Rewards"
    cancelText="Check It Out Later"
    submitText="Add Studios to My Plan"
    submit={() => setShowManagePlanPaymentModal(true)}
  >
    <div className="p-0 px-3 d-flex flex-column">
      <p className="m-0">
        Want to do barre on Monday and yoga on Friday, and go to your favorite
        studio every other week? Try our studio class program to receive
        discounts the more classes you attend. It's pay-as-you-go so there's no
        obligation and free to join.
      </p>
      <img
        src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2020/04/punch-card.png"
        className="img-fluid align-self-center"
        alt="studio punch card"
      />
      <p className="m-0">
        Studio classes are separate from gym packages. Class fees are paid
        directly to studios. There are no enrollment or scheduling fees.
      </p>
    </div>
  </ModalContainer>
);
