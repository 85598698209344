export const checkEligibility = (eligibilities, program = null) => {
  if (!eligibilities) return false;

  if (program) {
    const eligibilityItem = eligibilities.find(
      eligibility => eligibility.programCode === program
    );
    return eligibilityItem ? eligibilityItem.isMembershipActive : false;
  }

  return (
    eligibilities.filter(eligibility => eligibility.isMembershipActive).length >
    0
  );
};

export const hasEligibility = (eligibilities, program = null) => {
  if (!eligibilities || eligibilities.length === 0 || !program) return false;

  const eligibilityItem = eligibilities.find(
    eligibility => eligibility.programCode === program
  );
  return eligibilityItem ? true : false;
};
