import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import {
  setCheckedDependents,
  setChosenDependents,
  setPrimarySelectedPackage
} from '../../../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../../../context/EnrollmentContext';
import { useGlobalState } from '../../../../../context/GlobalContext';
import { useUserState } from '../../../../../context/UserContext';
import { Icon } from '../../../../../assets/Icons';
import { isPromise } from 'formik';
import { EditInfoModal } from './EditInfoModal';

const PackageDropdown = ({
  isEditingPlan,
  user,
  selectedGymPackage,
  getOrderPreview,
  loggedInMember
}) => {
  const [showEditInfotModal, setEditInfotModal] = useState(false);
  const formRef = useRef();
  const [
    {
      configState: {
        com: {
          entitlements: { isDependentAccessLinked },
          attributes: { studioDisplay }
        }
      },
      tierState: { digitalTier, tiers }
    }
  ] = useGlobalState();
  const [
    { primarySelectedPackage, chosenDependents },
    dispatch
  ] = useEnrollmentState();
  const [{ enrolledDependents }] = useUserState();

  const getSelectedPackage = id => {
    if (user.isEligibleForDigitalTier) {
      if (id === digitalTier[0].id) return digitalTier[0];
    }
    return id
      ? tiers.find(gymPackage => {
          return gymPackage.id === id;
        })
      : null;
  };

  const handleChangePackage = e => {
    let selectedPackage =
      e.target.value === 'studio'
        ? 'studio'
        : getSelectedPackage(e.target.value);
    if (loggedInMember) {
      dispatch(setPrimarySelectedPackage(selectedPackage));
      getOrderPreview({ primarySelectedPackage: selectedPackage });
    } else {
      const updatedList = chosenDependents.map(member =>
        member.consumerId === user.consumerId
          ? { ...member, selectedGymPackage: selectedPackage }
          : member
      );
      dispatch(setChosenDependents(updatedList));
      getOrderPreview({ chosenDependents: updatedList });
    }
  };

  const handleRemoveMember = member => {
    let updatedChosenDependents = chosenDependents.filter(
      dependent => dependent.consumerId !== member.consumerId
    );
    let updatedChosenDependentIds = updatedChosenDependents.map(
      dependent => dependent.consumerId
    );
    dispatch(setCheckedDependents(updatedChosenDependentIds));
    dispatch(setChosenDependents(updatedChosenDependents));
    getOrderPreview({ chosenDependents: updatedChosenDependents });
  };

  return (
    <>
      <div className="row d-flex justify-content-between list-group-item">
        <form>
          <div className="form-group">
            <label id="fitnessPackage">
              <p className="m-0 h6 text-uppercase font-weight-bold text-secondary">
                Fitness Package 12345(change this)
              </p>
            </label>
            {!loggedInMember && isDependentAccessLinked ? (
              primarySelectedPackage && (
                <p className="m-0 font-weight-bold">
                  {primarySelectedPackage.name.toUpperCase() +
                    `${(studioDisplay === 'Both' || studioDisplay === 'Web') &&
                      (!user.isUnderage ? ' + Studio Class Rewards' : '')}` +
                    `${
                      digitalTier && !user.isUnderage
                        ? ' + Digital Content'
                        : ''
                    }` +
                    ' - $' +
                    primarySelectedPackage.recurringPrice}
                </p>
              )
            ) : (
              <>
                <div className="container m-0 p-0">
                  <div className="row m-0 p-0">
                    <div className="col m-0 p-0">
                      <select
                        className={classNames('form-control', {
                          'is-invalid':
                            !loggedInMember &&
                            !isDependentAccessLinked &&
                            primarySelectedPackage &&
                            selectedGymPackage &&
                            (primarySelectedPackage === 'studio' ||
                              (digitalTier &&
                                primarySelectedPackage.id ===
                                  digitalTier[0].id &&
                                selectedGymPackage.id !== digitalTier[0].id) ||
                              selectedGymPackage.tier >
                                primarySelectedPackage.tier) &&
                            user.isUnderage
                        })}
                        disabled={
                          user?.defaultProgram?.isSuspending ||
                          user?.defaultProgram?.isSuspended ||
                          user?.digitalProgram?.isSuspending ||
                          user?.digitalProgram?.isSuspended
                        }
                        id="fitnessPackage"
                        aria-describedby="fitnessPackageHelp"
                        placeholder="Select Package"
                        onChange={e => handleChangePackage(e)}
                      >
                        {tiers &&
                          tiers.map(gym => {
                            const value =
                              gym.name.toUpperCase() +
                              `${
                                studioDisplay === 'Both' ||
                                studioDisplay === 'Web'
                                  ? !user.isUnderage
                                    ? ' + Studio Class Rewards'
                                    : ' '
                                  : ' '
                              }` +
                              `${
                                digitalTier && !user.isUnderage
                                  ? ' + Digital Content'
                                  : ''
                              }` +
                              ' - $' +
                              gym.recurringPrice;
                            return (
                              <option
                                value={gym.id}
                                id={gym.id}
                                selected={
                                  selectedGymPackage &&
                                  selectedGymPackage.id === gym.id
                                }
                                key={gym.recurringRatePlanChargeId}
                                // style={{color: "#333"}}
                              >
                                {value}
                              </option>
                            );
                          })}
                        {digitalTier && (
                          <option
                            disabled={
                              user.isUnderage
                                ? true
                                : user?.digitalProgram?.isActive &&
                                  (!user.isEnrolledInDefaultProgram ||
                                    user?.defaultProgram?.isCancelling)
                            }
                            value={digitalTier[0].id}
                            id={digitalTier[0].id}
                            selected={
                              selectedGymPackage &&
                              selectedGymPackage.id === digitalTier[0].id
                                ? true
                                : false
                            }
                            key={digitalTier[0].recurringRatePlanChargeId}
                          >
                            {`Digital Content
                    ${
                      studioDisplay === 'Both' || studioDisplay === 'Web'
                        ? !user.isUnderage
                          ? ' + Studio Class Rewards'
                          : ' '
                        : ' '
                    } - $${digitalTier[0].recurringPrice}`}
                          </option>
                        )}
                        {(studioDisplay === 'Both' ||
                          studioDisplay === 'Web') && (
                          <option
                            disabled={
                              isEditingPlan
                                ? !loggedInMember
                                  ? enrolledDependents.filter(
                                      enrolledDependent => {
                                        return (
                                          enrolledDependent.consumerId ===
                                          user.consumerId
                                        );
                                      }
                                    ).length !== 0 || user.isUnderage
                                  : true
                                : !loggedInMember
                                ? user.isUnderage
                                : false
                            }
                            selected={
                              loggedInMember
                                ? primarySelectedPackage === 'studio'
                                : !selectedGymPackage ||
                                  selectedGymPackage === 'studio'
                            }
                            value="studio"
                          >
                            Studio Class Rewards (Only) - Pay as you go
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!loggedInMember && selectedGymPackage === 'studio' && (
              <p className="m-0 my-2 text-primary">
                <span className="font-weight-bold">Please note: </span>
                Family bundle discounts only apply to Gym Packages. Family
                members added to the Studio Class Rewards are added as
                individuals who do not show on your account.
              </p>
            )}
          </div>
        </form>
        <div className="w-100 text-center ml-3 pt-2 d-inline-block align-self-center">
          {/* {!loggedInMember && isDependentAccessLinked && (
            // {!loggedInMember && (
            <button
              onClick={() => setEditInfotModal(true)}
              className="font-weight-bold align-self-center alert alert-primary pl-0 text-nowrap w-100 text-xl-right text-left bg-transparent border-0"
            >
              <Icon
                name="edit-pen"
                width="14px"
                height="14px"
                // fill="#004085"
                className="mr-1 icon-alignment"
              />
              Edit Member Info
            </button>
          )} */}
          {!isEditingPlan
            ? !loggedInMember && (
                <button
                  onClick={() => handleRemoveMember(user)}
                  className="btn align-self-center text-danger pl-0 text-nowrap w-100 text-xl-right text-left bg-transparent"
                >
                  <Icon
                    name="close-times"
                    width="20px"
                    height="20px"
                    fill="#dc3545"
                    className="mr-1"
                  />
                  Remove Member
                </button>
              )
            : !loggedInMember &&
              enrolledDependents.filter(enrolledDependent => {
                return enrolledDependent.consumerId === user.consumerId;
              }).length === 0 && (
                <button
                  onClick={() => handleRemoveMember(user)}
                  className="btn align-self-center text-danger pl-0 text-nowrap w-100 text-xl-right text-left"
                >
                  <Icon
                    name="close-times"
                    width="20px"
                    height="20px"
                    fill="#dc3545"
                    className="mr-1"
                  />
                  Remove from Order
                </button>
              )}
        </div>
      </div>
      {setEditInfotModal && (
        <EditInfoModal
          isOpen={showEditInfotModal}
          setIsOpen={setEditInfotModal}
          user={user}
          formRef={formRef}
        />
      )}
    </>
  );
};
export { PackageDropdown };
