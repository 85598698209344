import React, { useEffect, useState } from 'react';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import axios from 'axios';
import {
  getOrderInformation,
  mapCartSummaryDetails
} from '../../../utilities/getOrderInformation';
import { useUserState } from '../../../context/UserContext';
import { LoadingSpinner } from '../../Global/LoadingSpinner';
import { useGlobalState } from '../../../context/GlobalContext';
import { Icon } from '../../../assets/Icons';
import { PromoCodePill } from './PromoCodePill';

const CurrentPlan = () => {
  const [userState] = useUserState();
  const [enrollmentState] = useEnrollmentState();
  const [currentPlanCartSummary, setCurrentPlanCartSummary] = useState(null);
  const [
    {
      configState: {
        com: {
          programCode,
          entitlements: { isDependentAccessLinked }
        }
      }
    }
  ] = useGlobalState();
  const [accordionState, setAccordionState] = useState({
    showPromoCodes: false,
    showPackages: true,
    showDiscounts: false
  });

  useEffect(() => {
    return () => {
      setCurrentPlanCartSummary(null);
    };
  }, []);

  useEffect(() => {
    if (!currentPlanCartSummary) getCurrentPlan();
  }, [enrollmentState.primarySelectedPackage]);

  const getCurrentPlan = async () => {
    const currentPlan = await processOrderPreview();
    setCurrentPlanCartSummary(currentPlan);
  };

  const processOrderPreview = async () => {
    const url = `/api/prime/v2/account/previewupdatesubscriptions`;

    const requestBody = getOrderInformation(
      isDependentAccessLinked,
      programCode,
      userState,
      enrollmentState,
      true,
      '',
      true
    );

    return axios
      .post(url, requestBody)
      .then(response => {
        if (response.status === 200) {
          return response.data.data;
        } else {
          return { data: 'Server error' };
        }
      })
      .catch(error => {
        return error;
      });
  };

  return (
    <>
      {!currentPlanCartSummary ? (
        <LoadingSpinner additionalText="" />
      ) : (
        <div className="bg-light border border-light p-4">
          <h4 className="font-weight-bold text-secondary">Current Plan</h4>
          {currentPlanCartSummary &&
            currentPlanCartSummary.monthlyPaymentSummary && (
              <div className="ml-2 w-100">
                <div className="mb-3">
                  {mapCartSummaryDetails(
                    currentPlanCartSummary?.monthlyPaymentSummary
                      ?.monthlyPaymentDetails
                  )}
                </div>
                {!!currentPlanCartSummary.monthlyPaymentSummary
                  .monthlyPaymentDiscountAmountWithoutTax && (
                  <>
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <p
                          className="m-0 font-weight-bold"
                          onClick={() =>
                            setAccordionState({
                              ...accordionState,
                              showPromoCodes: !accordionState.showPromoCodes
                            })
                          }
                        >
                          Discount(s) Total{' '}
                          <Icon
                            name="chevron"
                            width="15px"
                            height="15px"
                            className="ml-1"
                            style={{
                              transform: !accordionState.showPromoCodes
                                ? 'rotate(90deg)'
                                : 'rotate(270deg)'
                            }}
                          />
                        </p>
                        <p className="m-0">{`-$${(
                          currentPlanCartSummary.monthlyPaymentSummary
                            .monthlyPaymentDiscountAmountWithoutTax +
                          currentPlanCartSummary.monthlyPaymentSummary
                            .monthlyPaymentDiscountTaxAmount
                        )
                          .toFixed(2)
                          .toString()
                          .replace('-', '')}`}</p>
                      </div>
                      <div
                        className="m-0 mt-2 ml-2"
                        style={{
                          display: !accordionState.showPromoCodes
                            ? 'none'
                            : 'block'
                        }}
                      >
                        {currentPlanCartSummary.monthlyPaymentSummary
                          .detailedPromoCodeDescription?.length > 0 &&
                          currentPlanCartSummary.monthlyPaymentSummary.detailedPromoCodeDescription.map(
                            (code, index) => {
                              if (code.isSystemApplied) {
                                return (
                                  <p className="m-0">{code.description}</p>
                                );
                              }
                            }
                          )}
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-between">
                  <p className="m-0">Taxes</p>
                  <p className="m-0 ml-1">
                    {`$${currentPlanCartSummary.monthlyPaymentSummary.monthlyTotalPaymentTaxAmount.toFixed(
                      2
                    )}`}
                  </p>
                </div>
                <div className="my-2 d-flex justify-content-between">
                  <p className="m-0 font-weight-bold">MONTHLY TOTAL</p>
                  <p className="m-0 font-weight-bold">
                    {currentPlanCartSummary.monthlyPaymentSummary &&
                      currentPlanCartSummary.monthlyPaymentSummary
                        .monthlyTotalPaymentAfterDiscountAmountWithTax &&
                      `$${Number(
                        currentPlanCartSummary.monthlyPaymentSummary
                          .monthlyTotalPaymentAfterDiscountAmountWithTax
                      ).toFixed(2)}`}
                  </p>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export { CurrentPlan };
