import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import exclamation from '../assets/Error.png';
import notEligible from '../assets/notEligible.svg';
import { useGlobalState } from '../context/GlobalContext';
import { getPhoneNumber } from '../utilities/getPhoneNumber';
import { Icon } from '../assets/Icons';
import { useUserState } from '../context/UserContext';
import { CustomerServiceHours } from "../components/Global/CustomerServiceHours"

const Error = ({ isNotEligible, type = null }) => {
  const [error, setError] = useState('');
  const [
    {
      isFWEnrolled,
      isStudioEnrolled,
      isEnrolledInDefaultProgram,
      isEnrolledInDigitalTier
    }
  ] = useUserState();
  const [{ configState }] = useGlobalState();
  const { strings, client, cms } = configState;
  const location = useLocation();
  const query = QueryString.parse(location.search);

  useEffect(() => {
    if (type || (location.state && location.state.error))
      setError(
        location.state && location.state.error
          ? location.state.error
          : type
          ? type
          : ''
      );
    return () => {
      setError('');
    };
  }, []);

  return (
    <div className="container py-4 my-4 d-flex flex-column align-items-center justify-content-start text-center flex-fill">
      {query?.error == 'partner_forward' ? (
        <div className="d-flex flex-column-reverse flex-md-row">
          <div className="d-flex flex-column text-center text-md-left">
            <h1 className="display-1 font-weight-bold">Uh-oh...</h1>
            <p className="">
              It looks like this page could not be reached at this time. Please
              use the following button to return to your account overview.
            </p>
            <NavLink
              to="/account"
              className="btn btn-primary align-self-center align-self-md-start mb-4"
            >
              Account Overview
            </NavLink>
            <p className="m-0">
              <span className="font-weight-bold">Note: </span>If this problem
              persists, please call us at{' '}
              <a
                className="text-underline-primary"
                href={`tel:${getPhoneNumber().number}`}
              >
                {getPhoneNumber().text}
              </a>{' '}
              <CustomerServiceHours />
            </p>
          </div>
          <div className="w-100">
            <Icon name="redirect-image" />
          </div>
        </div>
      ) : (
        <>
          {strings?.path_to_image && (
            <img
              src={strings?.path_to_image}
              className="my-5"
              alt="logo"
              width="300"
              height="40"
            />
          )}
          {error === 'generic' || error === 'orderSubmitFail' ? (
            <img
              src={exclamation}
              alt="error"
              className="mb-5"
              style={{ minHeight: '210px', minWidth: '210px' }}
            />
          ) : isNotEligible || error === 'fwEnrollmentFailed' ? (
            <img
              src={notEligible}
              alt="error"
              className="mb-5"
              style={{ minHeight: '250px', minWidth: '250px' }}
            />
          ) : (
            <Icon name="404" width="325px" fill={cms.primary_color} secondaryFill={cms.secondary_color} />
          )}
          {error === 'generic' || error === 'orderSubmitFail' ? (
            <p className="page-not-found">Something Went Wrong</p>
          ) : isNotEligible || error === 'fwEnrollmentFailed' ? (
            <p className="page-not-found">
              We dropped a piece while building your account
            </p>
          ) : (
            <p className="page-not-found">Something Went Wrong</p>
          )}
          {query?.error_description ? (
            <>
              <p className="w-75 m-0 w-md-100 px-3 px-md-0">
                Sorry, we could not process your sign in at this time.
              </p>
              <p className="w-75 m-0 w-md-100 px-3 px-md-0">
                {query?.error_description}
              </p>
              <p className="w-50 w-md-100 px-3 px-md-0">
                To resolve this issue please call us at:
              </p>
              <div className="my-2">
                <h3 className="font-weight-bold text-primary m-0">
                  <a href={`tel:${getPhoneNumber().number}`}>
                    {getPhoneNumber().text}
                  </a>
                </h3>
                <p><CustomerServiceHours /></p>
              </div>
              <p className="w-50 w-md-100 px-3 px-md-0">
                Our support team can assist you with access to your account.
              </p>
            </>
          ) : error !== 'orderSubmitFail' ? (
            isNotEligible || error === 'fwEnrollmentFailed' ? (
              <>
                <p className="w-75 m-0 w-md-100 px-3 px-md-0">
                  Sorry, we could not process your sign in at this time.
                </p>
                <p className="w-50 w-md-100 px-3 px-md-0">
                  To resolve this issue please call us at:
                </p>
                <div className="my-2">
                  <h3 className="font-weight-bold text-primary m-0">
                    <a href={`tel:${getPhoneNumber().number}`}>
                      {getPhoneNumber().text}
                    </a>
                  </h3>
                  <p><CustomerServiceHours /></p>
                </div>
                <p className="w-50 w-md-100 px-3 px-md-0">
                  Our support team can assist you with access to your account.
                </p>
              </>
            ) : error === 'generic' ? (
              <>
                <p className="px-3 px-md-0" style={{ width: '30%' }}>
                  Sorry, we could not process your request. To resolve this
                  issue please call us at:
                </p>
                <div className="my-2">
                  <h3 className="font-weight-bold text-primary m-0">
                    <a href={`tel:${getPhoneNumber().number}`}>
                      {getPhoneNumber().text}
                    </a>
                  </h3>
                  <p><CustomerServiceHours /></p>
                </div>
              </>
            ) : (
              <>
                <p className="">
                  Sorry, this page could not be found at this time. Please click
                  the button below to return to{' '}
                  {isEnrolledInDefaultProgram ||
                  isStudioEnrolled ||
                  isFWEnrolled ||
                  isEnrolledInDigitalTier
                    ? 'your account page.'
                    : client == 'walmart'
                    ? 'Walton Life Fitness Pass'
                    : 'our home page.'}
                </p>
                {client !== 'walmart' ? (
                  <NavLink
                    to={
                      isEnrolledInDefaultProgram ||
                      isStudioEnrolled ||
                      isFWEnrolled ||
                      isEnrolledInDigitalTier
                        ? '/account'
                        : '/'
                    }
                    className="btn btn-primary mb-4"
                  >
                    {isEnrolledInDefaultProgram ||
                    isStudioEnrolled ||
                    isFWEnrolled ||
                    isEnrolledInDigitalTier
                      ? 'My Account'
                      : 'Home'}
                  </NavLink>
                ) : (
                  <button
                    onClick={() => {
                      window.location =
                        'https://pfedprod.wal-mart.com/idp/startSSO.ping?PartnerSpId=ExtWLFCMFA';
                    }}
                    className="btn btn-primary mb-4"
                  >
                    Return to Walton Life Fitness Pass
                  </button>
                )}
                <p className="m-0 mb-4">
                  <span className="font-weight-bold">Note: </span>If this
                  problem persists, please call us at{' '}
                  <a
                    className="text-underline-primary"
                    href={`tel:${getPhoneNumber().number}`}
                  >
                    {getPhoneNumber().text}
                  </a>{' '}
                  <CustomerServiceHours />
                </p>
              </>
            )
          ) : (
            <>
              <p className="px-3 px-md-0" style={{ width: '30%' }}>
                Sorry, we could not process your request. To resolve this issue
                please call us at:
              </p>
              <div className="my-2">
                <h3 className="font-weight-bold text-primary m-0">
                  <a href={`tel:${getPhoneNumber().number}`}>
                    {getPhoneNumber().text}
                  </a>
                </h3>
                <p><CustomerServiceHours /></p>
              </div>
              <p className="px-3 mb-5" style={{ width: '35%' }}>
                <span className="font-weight-bold">
                  Please do not attempt this action again
                </span>
                , until after speaking to our support team to avoid duplicate
                payments. Once you speak to support, you can return to the home
                page by clicking <NavLink to="/">here.</NavLink>
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export { Error };
