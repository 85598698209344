import React from 'react';
import { useEnrollmentState } from '../../../context/EnrollmentContext';

const AddressErrors = ({ touched, errors, name, addressType }) => {
  let [{ addressValidationErrors }] = useEnrollmentState();

  return (
    <>
      {!addressValidationErrors['invalid'] &&
      addressValidationErrors[addressType] &&
      Object.getOwnPropertyNames(addressValidationErrors[addressType]).length
        ? addressValidationErrors[addressType][name] && (
            <p className="text-danger">
              {addressValidationErrors[addressType][name]}
            </p>
          )
        : errors &&
          errors[name] &&
          touched[name] && <p className="text-danger">{errors[name]}</p>}
    </>
  );
};

export { AddressErrors };
