import React from 'react';
import { useUserState } from '../../context/UserContext';

const Avatar = () => {
  const [userState] = useUserState();
  const { firstName, lastName } = userState;
  return (
    <div className="bg-light d-flex justify-content-center align-items-center avatar shadow">
      <h2 className="m-0 text-primary">
        {firstName.charAt(0).toUpperCase()}
        {lastName.charAt(0).toUpperCase()}
      </h2>
    </div>
  );
};

export { Avatar };
