import React from 'react';

const Loading = () => {
  return (
    <>
      <section className="section-nav">
        <nav className="bg-white navbar-expand-lg pl-lg-4  align-items-center d-flex bg-white justify-content-between nav-container">
          <div
            className="nav-link pt-20 bg-secondary loading-animation"
            style={{ width: '170px', height: '30px' }}
          ></div>
          <div className="navbar-brand bg-white p-4 mr-5">
            <div style={{ width: '170px', height: '30px' }} />
          </div>
          <div />
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarContent"
          >
            <ul className="navbar-nav float-right">
              <li className="nav-item">
                <div
                  className="nav-link pt-20 bg-secondary loading-animation"
                  style={{ width: '100px' }}
                ></div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link pt-20 bg-secondary loading-animation"
                  style={{ width: '100px' }}
                ></div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link pt-20 bg-secondary loading-animation"
                  style={{ width: '100px' }}
                ></div>
              </li>
            </ul>
          </div>
        </nav>
      </section>
      <section className="lp">
        <section>
          <div
            className="row bg-secondary loading-animation"
            style={{ height: '600px', position: 'relative' }}
          ></div>
        </section>
      </section>
    </>
  );
};

export { Loading };
