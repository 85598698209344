import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import classNames from 'classnames';
import { setEnrollmentDefault } from '../../../context/actions/enrollment';
import { useUserState } from '../../../context/UserContext';
import { useGlobalState } from '../../../context/GlobalContext';

let EnrollmentFooter = ({
  step,
  setShowManagePlanPaymentModal,
  continueStep,
  isEditingPlan,
  setShowEditPlan,
  validatingAddress = false
}) => {
  const [
    { disableFooterButton, acceptedProgramPolicy },
    dispatch
  ] = useEnrollmentState();
  const [userState] = useUserState();
  const { loadingAccountSummary } = userState;
  const history = useHistory();
  const location = useLocation();
  const [{ configState }] = useGlobalState();
  const {
    com: { uiConfig }
  } = configState;
  const { hasLandingPage } = uiConfig || {};

  const handleCancel = () => {
    dispatch(setEnrollmentDefault());
    if (isEditingPlan) {
      setShowEditPlan(prevState => !prevState);
    } else {
      history.push('/');
    }
  };

  const handleSaveAndProceed = e => {
    e.preventDefault();
    if (location.pathname === '/account/manage-plans') {
      setShowManagePlanPaymentModal(true);
      return;
    } else if (step === 4) {
      if (acceptedProgramPolicy) {
        window.Z.submit();
      }
    } else {
      continueStep();
    }
  };

  return (
    <div
      className={classNames(
        'bg-white',
        'shadow',
        'border-top',
        'align-items-center',
        'd-flex',
        {
          'sign-up-footer': !isEditingPlan,
          'edit-plans-footer': isEditingPlan
        }
      )}
    >
      <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
        {hasLandingPage ? (
          <div className="d-flex col-xl-7 col-lg-6" />
        ) : (
          <div className="d-flex col-xl-10 col-lg-6" />
        )}
        {hasLandingPage && (
          <button
            type="button"
            className="btn btn-outline-primary col ml-lg-2"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        )}
        <button
          type="button"
          id="enrollmentproceedbutton"
          className="mr-3 btn btn-primary col ml-1"
          disabled={disableFooterButton}
          onClick={e => (!disableFooterButton ? handleSaveAndProceed(e) : null)}
        >
          {isEditingPlan
            ? 'Confirm Update'
            : step === 1
            ? 'Save & Proceed'
            : step !== 4
            ? disableFooterButton && validatingAddress
              ? 'Validating your Address'
              : 'Continue'
            : 'Complete Checkout'}
        </button>
      </div>
    </div>
  );
};

export { EnrollmentFooter };
