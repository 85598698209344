import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { Icon } from '../../../assets/Icons';

const ActivityPagination = ({
  activities,
  currentPageNum,
  setCurrentPageNum
}) => {
  let pageNumbers = [];
  let perPage = activities ? Math.ceil(activities.length / 10) : null;

  for (let i = 1; i <= perPage; i++) {
    pageNumbers.push(i);
  }
  const handlePageNumClick = number => {
    setCurrentPageNum(number);
  };

  const handlePreviousNextClick = number => {
    localStorage.setItem('currentActivityPage', currentPageNum + number);
    if (number === -1) {
      if (currentPageNum > 1) setCurrentPageNum(currentPageNum + number);
    } else {
      if (currentPageNum < perPage) setCurrentPageNum(currentPageNum + number);
    }
  };

  return (
    <>
      <div className="d-md-flex d-none align-items-end">
        <div className="d-flex justify-content-end p-0 align-items-baseline pagination mt-3">
          <p className="m-0">
            Showing {10 * (currentPageNum - 1) + 1}-
            {activities &&
              activities.slice(10 * (currentPageNum - 1), 10 * currentPageNum)
                .length +
                10 * (currentPageNum - 1)}{' '}
            of {activities && activities.length}
          </p>
          <a
            className={classNames({ 'd-none': currentPageNum === 1 }, 'mx-2')}
            onClick={() => handlePreviousNextClick(-1)}
          >
            <i className="fas fa-chevron-left text-secondary" />
          </a>
          {pageNumbers.map(number => {
            if (
              (currentPageNum <= number && currentPageNum + 2 >= number) ||
              currentPageNum === number
            ) {
              return (
                <p
                  onClick={() => handlePageNumClick(number)}
                  className={
                    'm-0 ' +
                    'mx-2 ' +
                    (currentPageNum === number
                      ? 'text-primary font-weight-bold'
                      : null)
                  }
                >
                  {number}
                </p>
              );
            } else if (
              currentPageNum >= number &&
              currentPageNum - 2 <= number
            ) {
              return (
                <p
                  onClick={() => handlePageNumClick(number)}
                  className={
                    'm-0 ' +
                    'mx-2 ' +
                    (currentPageNum === number
                      ? 'text-primary font-weight-bold'
                      : null)
                  }
                >
                  {number}
                </p>
              );
            }
          })}
          <a
            className={classNames({
              'd-none': currentPageNum === perPage
            })}
            onClick={() => handlePreviousNextClick(1)}
          >
            <i className={classNames('fas', 'fa-chevron-right')} />
          </a>
        </div>
      </div>
      <div className="container d-flex d-md-none flex-column align-items-center justify-content-center">
        <p className="m-0">
          Showing {10 * (currentPageNum - 1) + 1}-
          {activities &&
            activities.slice(10 * (currentPageNum - 1), 10 * currentPageNum)
              .length +
              10 * (currentPageNum - 1)}{' '}
          of {activities && activities.length}
        </p>
        <div>
          <a
            className={classNames({ 'd-none': currentPageNum === 1 })}
            onClick={() => handlePreviousNextClick(-1)}
          >
            <i className="fas fa-chevron-left text-secondary" />
          </a>
          {pageNumbers.map(number => (
            <p
              onClick={() => handlePageNumClick(number)}
              className={
                'm-0 ' +
                (currentPageNum === number
                  ? 'text-primary font-weight-bold ml-3'
                  : null)
              }
            >
              {number}
            </p>
          ))}
          <a
            className={classNames({
              'd-none': currentPageNum === perPage
            })}
            onClick={() => handlePreviousNextClick(1)}
          >
            <i className={classNames('fas', 'fa-chevron-right')} />
          </a>
        </div>
      </div>
    </>
  );
};

export { ActivityPagination };
