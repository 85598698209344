import React, { useState, useEffect } from 'react';
import { MemberPackageBox } from './MemberPackageBox/MemberPackageBox';
import { AddMemberModal } from './AddMemberModal';
import { useGlobalState } from '../../../../context/GlobalContext';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import {
  setDisableFooterButton,
  setChosenDependents,
  setCheckedDependents,
  setPolicy
} from '../../../../context/actions/enrollment';
import {
  validateMemberEmail,
  isDependentTierInvalid
} from '../../../../utilities/enrollmentValidation';
import { useUserState } from '../../../../context/UserContext';
import { Checkbox } from '../../../Global/Checkbox';

const MemberSummary = ({ isEditingPlan, getOrderPreview }) => {
  const [{ tierState, configState }] = useGlobalState();
  const [userState] = useUserState();
  const [
    {
      chosenDependents,
      primarySelectedPackage,
      acceptedProgramPolicy,
      acceptedTermsPolicy
    },
    enrollmentDispatch
  ] = useEnrollmentState();
  const {
    firstName,
    lastName,
    defaultProgram,
    email,
    enrolledDependents,
    isPrimary
  } = userState;
  const { com } = configState;
  const [showFamilyModal, setShowFamilyModal] = useState(false);
  const {
    entitlements: { isDependentAccessLinked, familyMembership },
    uiConfig: { canEnrollDependents }
  } = com;
  const {
    com: {
      attributes: { studioDisplay }
    }
  } = configState;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (enrolledDependents && enrolledDependents.length) {
      const updatedEnrolledDependents = enrolledDependents.map(dependent => {
        if (dependent?.defaultProgram?.tier) {
          dependent.selectedGymPackage = tierState.tiers.find(
            item => item.tier == dependent?.defaultProgram?.tier
          );
        } else if (tierState.digitalTier && dependent.isEnrolledInDigitalTier) {
          dependent.selectedGymPackage = tierState.digitalTier[0];
        }
        return dependent;
      });
      const updated = updatedEnrolledDependents.filter(
        dependent =>
          dependent.isDependentToMember &&
          ((dependent.isEnrolledInDefaultProgram &&
            !(
              dependent?.defaultProgram?.isCancelling ||
              dependent?.defaultProgram?.isCancelled
            )) ||
            (dependent.isEnrolledInDigitalTier &&
              !(
                dependent?.digitalTier?.isCancelling ||
                dependent?.digitalTier?.isCancelled
              ))) &&
          dependent
      );
      enrollmentDispatch(setChosenDependents(updated));
      enrollmentDispatch(
        setCheckedDependents(
          updatedEnrolledDependents.map(dependent => dependent.consumerId)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!isEditingPlan) {
      if (acceptedProgramPolicy && acceptedTermsPolicy) {
        if (!chosenDependents) {
          enrollmentDispatch(
            setDisableFooterButton(
              !validateMemberEmail(email, chosenDependents)
            )
          );
        } else {
          enrollmentDispatch(
            setDisableFooterButton(
              !(
                validateMemberEmail(email, chosenDependents) &&
                !isDependentTierInvalid(
                  chosenDependents,
                  primarySelectedPackage,
                  tierState.digitalTier
                )
              )
            )
          );
        }
      } else {
        enrollmentDispatch(setDisableFooterButton(true));
      }
    }
  }, [
    email,
    chosenDependents,
    primarySelectedPackage,
    acceptedProgramPolicy,
    acceptedTermsPolicy
  ]);

  const handleBlur = e => {
    const targetName = e.target.name;
    if (!e.target.checked) {
      setErrors(prevState => ({
        ...prevState,
        [targetName]: `${targetName} needs to read and accepted`
      }));
    } else {
      let errorsCopy = { ...errors };
      delete errorsCopy[targetName];
      setErrors(errorsCopy);
    }
  };

  return (
    <>
      <AddMemberModal
        showFamilyModal={showFamilyModal}
        setShowFamilyModal={() => setShowFamilyModal(!showFamilyModal)}
        isEditingPlan={isEditingPlan}
        getOrderPreview={getOrderPreview}
      />
      {isEditingPlan && <h3 className="my-1">Current Members</h3>}
      <MemberPackageBox
        getOrderPreview={getOrderPreview}
        isEditingPlan={isEditingPlan}
        selectedGymPackage={
          isEditingPlan && !primarySelectedPackage
            ? defaultProgram?.tier &&
              tierState.tiers.find(item => item.tier == defaultProgram?.tier)
            : primarySelectedPackage
        }
        loggedInMember
        user={userState}
      />
      {chosenDependents &&
        chosenDependents.map(
          (member, index) =>
            !member.canBeEnrolled && (
              <MemberPackageBox
                key={member.id}
                getOrderPreview={getOrderPreview}
                isEditingPlan={isEditingPlan}
                selectedGymPackage={
                  isDependentAccessLinked ? null : member.selectedGymPackage
                }
                user={member}
              />
            )
        )}
      {isEditingPlan &&
        chosenDependents &&
        chosenDependents.filter(member => member.canBeEnrolled).length !==
          0 && (
          <>
            <h3 className="my-1">Newly Added Members</h3>
            <p>
              By providing your email address and/or any other personal
              information, as defined under applicable law, you acknowledge that
              you are agreeing to our use of your information as provided in our{' '}
              <a href="/terms-of-use">Terms of Use</a> and{' '}
              <a href="/privacy-policy">Privacy Policy.</a>
            </p>
          </>
        )}
      {chosenDependents &&
        chosenDependents.map(
          (member, index) =>
            member.canBeEnrolled && (
              <MemberPackageBox
                key={member.id}
                getOrderPreview={getOrderPreview}
                isEditingPlan={isEditingPlan}
                selectedGymPackage={member.selectedGymPackage}
                user={member}
              />
            )
        )}
      {familyMembership && canEnrollDependents && (
        <>
          <div className="position-relative d-none d-lg-flex">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => setShowFamilyModal(!showFamilyModal)}
            >
              Add Family Members
            </button>
          </div>
          <div className="position-relative d-lg-none d-sm-flex mt-2 mb-2">
            <button
              type="button"
              className="btn btn-outline-primary mt-2 mb-2 w-100"
              onClick={() => setShowFamilyModal(!showFamilyModal)}
            >
              Add Family Members
            </button>
          </div>
        </>
      )}
      {!isEditingPlan && (
        <>
          <h3 className="font-weight-bold mb-2 mt-4">Agree to Our Terms</h3>
          <div className="card shadow p-4 w-100">
            <Checkbox
              onChange={e => {
                handleBlur(e);
                enrollmentDispatch(
                  setPolicy('acceptedTermsPolicy', e.target.checked)
                );
              }}
              checked={acceptedTermsPolicy}
              text="By checking this box, I acknowledge that I have read and agree to comply with the terms and conditions of the "
              hyperLink="Privacy Policy"
              hyperLink2="Terms of Use"
              hyperLinkValue="/privacy-policy"
              hyperLinkValue2="/terms-of-use"
              name="Privacy Policy and Terms of Use"
              htmlFor="Privacy Policy and Terms of Use"
            />
            {errors && errors['Terms Policy'] && (
              <p style={{ color: 'red' }} className="m-0 pl-4">
                {errors['Terms Policy']}
              </p>
            )}
            <Checkbox
              onChange={e => {
                handleBlur(e);
                enrollmentDispatch(
                  setPolicy('acceptedProgramPolicy', e.target.checked)
                );
              }}
              checked={acceptedProgramPolicy}
              text="By checking this box, I acknowledge that I have read and agree to comply with the terms and conditions of the"
              hyperLink="Fitness Program Agreement"
              hyperLinkValue="/fitness-program-agreement"
              name="Fitness Program Agreement"
              htmlFor="Fitness Program Agreement"
            />
            {errors && errors['Program Policy'] && (
              <p style={{ color: 'red' }} className="m-0 pl-4">
                {errors['Program Policy']}
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export { MemberSummary };
