import axios from 'axios';

export const pauseCallRecording = async (
  inContact,
  mask,
  setInContactError
) => {
  const { baseURI, sessionId, contactId, inContactToken } = inContact;

  if (
    baseURI === '' ||
    !baseURI ||
    sessionId === '' ||
    !sessionId ||
    contactId === '' ||
    !contactId ||
    inContactToken === '' ||
    !inContactToken
  ) {
    setInContactError(true);
    throw new Error();
  }
  try {
    const inContactAPI = axios.create();
    inContactAPI.post(
      `${baseURI}services/v13.0/agent-sessions/${encodeURIComponent(
        sessionId
      )}/interactions/${encodeURIComponent(contactId)}/${
        mask ? 'mask' : 'unmask'
      }`,
      {},
      {
        headers: {
          Authorization: 'bearer ' + inContactToken,
          'content-Type': 'application/json'
        }
      }
    );
  } catch (error) {
    console.log(error);
    setInContactError(true);
    throw error;
  }
};
