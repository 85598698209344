import axios from 'axios';

function apiWrapper(url, body, type = 'POST') {
  const options = {
    method: type,
    data: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    url: type === 'GET' ? (url += body) : url
  };

  return axios(options)
    .then(response => {
      if (response.status === 200) {
        return response.data;
      } else {
        return { data: 'Server error' };
      }
    })
    .catch(error => error);
}

export { apiWrapper };
