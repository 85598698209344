import classNames from 'classnames';
import React from 'react';
import { setPrimarySelectedPackage } from '../../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { checkBrowser } from '../../../../utilities/checkBrowser';

const SelectPackageTierBox = ({ gym, isMobile }) => {
  const [{ primarySelectedPackage }, dispatch] = useEnrollmentState();

  return (
    <>
      <label
        key={gym.recurringRatePlanChargeId}
        className={classNames(
          'custom-radio-sup card col p-0 shadow form-check form-check-inlined-flex transparent-border',
          {
            'pt-3': !isMobile,
            'pt-4': isMobile,
            'best-value-card': gym.isBestValue,
            active:
              primarySelectedPackage &&
              primarySelectedPackage.recurringRatePlanChargeId ===
                gym.recurringRatePlanChargeId,
            'h-100': checkBrowser() !== 'IE' && checkBrowser() !== 'Edge'
          }
        )}
      >
        <div className={classNames('card-body p-0 w-100')}>
          <input
            className="form-check-input ml-1"
            type="radio"
            name="fitnessPackage"
            id="fitnessPackage"
            value={gym.recurringRatePlanChargeId}
            checked={
              primarySelectedPackage &&
              primarySelectedPackage.recurringRatePlanChargeId ===
                gym.recurringRatePlanChargeId
            }
            onChange={() => dispatch(setPrimarySelectedPackage(gym))}
          />
          <div className="w-100">
            <span
              className="custom-span badge badge-primary shadow w-100 position-absolute fixed-top d-block"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              {gym.name}
            </span>
            <div className="center m-neg">
              <p className="m-0 price h1">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })
                  .format(gym.recurringPrice)
                  .replace(/(\.|,)00$/g, '')}
              </p>
              <p
                className="per-month mb-0 align-self-center"
                style={{ margin: '-10px' }}
              >
                /month
              </p>
              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: gym.description }}
              />
            </div>
          </div>
        </div>
      </label>
    </>
  );
};

export { SelectPackageTierBox };
