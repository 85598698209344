import React, { useState, useEffect } from 'react';
import { getPaymentMethods } from '../../../../api/payments';
import { useEnrollmentState } from '../../../../context/EnrollmentContext';
import { setSelectedPaymentMethod } from '../../../../context/actions/enrollment';

const PaymentMethod = () => {
  const [{ selectedPaymentMethod }, dispatch] = useEnrollmentState();
  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    getPaymentMethods()
      .then(data => {
        setPaymentMethods(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const updateSelectedPaymentMethod = selectedMethod =>
    dispatch(setSelectedPaymentMethod(selectedMethod));

  return (
    <React.Fragment>
      <p className="m-0 h6 text-uppercase font-weight-bold text-secondary">
        Payment Method <i className="m-0 text-danger">*</i>
      </p>
      <form>
        <div className="form-group m-0">
          {paymentMethods && (
            <select
              value={selectedPaymentMethod && selectedPaymentMethod}
              onChange={e => {
                updateSelectedPaymentMethod(e.target.value);
              }}
              className="form-control"
              id="paymentMethod"
            >
              <option disabled selected key="" value="">
                Choose one here (e.g. Credit Card, etc.)
              </option>
              {paymentMethods &&
                paymentMethods.length &&
                paymentMethods.map(item => {
                  return (
                    <option value={item.pageId} key={item.pageId}>
                      {item.pageType}
                    </option>
                  );
                })}
            </select>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export { PaymentMethod };
