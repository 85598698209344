export const initialEnrollmentState = {
  cartSummary: null,
  loadingCartSummary: false,
  chosenDependents: null,
  checkedDependents: null,
  underageDependents: null,
  acceptedTermsPolicy: false,
  acceptedProgramPolicy: false,
  primarySelectedPackage: null,
  disableFooterButton: true,
  selectedPaymentMethod: null,
  paymentToken: null,
  promoCode: [],
  addressValidationErrors: { shippingAddress: {}, billingAddress: {} },
  isMailingSameAsBilling: true
};
