import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LocationAmenities = ({ amenities }) => (
  <React.Fragment>
    {amenities ? (
      <div className="col-sm-12">
        <a
          className="btn btn-link mt-3 text-uppercase font-weight-bold text-secondary font-small p-0 pb-1"
          data-toggle="collapse"
          href="#collapseChevron"
          role="button"
          aria-expanded="false"
          aria-controls="collapseChevron"
        >
          <span className="position-relative">Fitness Amenities</span>
          <span className="amenities-chevron">
            <FontAwesomeIcon icon="angle-down" className="arrow-icon" />
          </span>
        </a>
        <p className="mt-1 pr-4 font-small">
          Actual amenities may differ and may require additional charge
        </p>
        <hr className="location-amenities-hr" />
        <div className="collapse" id="collapseChevron">
          <div className="d-flex align-items-center mt-3 mb-3 amenities-list">
            <ul className=" p-0 w-100">
              {amenities.map((amenity, i) => {
                return !amenity.additionalCost ? (
                  <Fragment>
                    <li>{amenity.name}</li>
                  </Fragment>
                ) : null;
              })}
            </ul>
          </div>
        </div>
      </div>
    ) : null}
    {amenities.additionalCost ? (
      <div className="col-sm-12">
        <a
          className="btn btn-link mt-3 text-uppercase font-weight-bold text-secondary font-small p-0 pb-1"
          data-toggle="collapse"
          href="#collapseChevron2"
          role="button"
          aria-expanded="false"
          aria-controls="collapseChevron"
        >
          <span className="position-relative">Additional Cost Amenities</span>
          <span className="amenities-chevron">
            <FontAwesomeIcon icon="angle-down" className="arrow-icon" />
          </span>
        </a>
        <hr className="location-amenities-hr" />
        <div className="collapse" id="collapseChevron2">
          <div className="collapse d-flex align-items-center mt-3 mb-3 amenities-list">
            <ul className="p-0 w-100">
              {amenities.map((amenity, i) => {
                return amenity.additionalCost ? (
                  <Fragment>
                    <li>{amenity.name}</li>
                  </Fragment>
                ) : null;
              })}
            </ul>
          </div>
        </div>
      </div>
    ) : null}
  </React.Fragment>
);

export { LocationAmenities };
