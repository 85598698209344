import React from 'react';
import { Icon } from '../../../assets/Icons';
import { useUserState } from '../../../context/UserContext';

const Confirmation = (fillColor) => {
  const [userState] = useUserState();
  const {
    defaultProgram: { futureHomeGym }
  } = userState;

  return (
    <>
      <div className="card-body text-center">
        <Icon name="circle-check-blue" width="40px" height="40px" fill={fillColor} />
      </div>
      <div className="card-body text-center">
        <p className="font-weight-bold">Home Gym Set!</p>
        <p>
          You can visit location beginning{' '}
          {futureHomeGym ? 'the first of next month' : 'today'}. Be sure to
          bring your member ID card with you and present to the front desk when
          you arrive.
        </p>
      </div>
    </>
  );
};

export { Confirmation };
