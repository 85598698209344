import React from 'react';
import { ProgressBar } from './ProgressBar';
import { ModalContainer } from './Modal';

const LoadingScreen = ({ isLoading }) => {
  return (
    <ModalContainer 
      selfClosing isOpen={isLoading}
      isDismissable={false}
      loaderText='' //set this to empty because this loader text already says "do not refresh page". 
    >
      <div className="bg-white p-5 text-center">
        <ProgressBar
          title="Hang Tight!"
          subtitle="We're processing your request."
          dynamicTextArray={[
            'Reviewing your profile...',
            'Confirming your selection(s)...',
            'Compiling your account information...',
            'Wrapping up...'
          ]}
        />
        <div className="mt-3">
          <p>
            Please <b>do not refresh the page.</b>
          </p>
        </div>
      </div>
    </ModalContainer>
  );
};

export { LoadingScreen };
