import React, { useState } from 'react';
import { useGlobalState } from '../../../context/GlobalContext';
import { checkBrowser } from '../../../utilities/checkBrowser';
import { checkIfMobile } from '../../../utilities/checkIfMobile';
import { checkOS } from '../../../utilities/checkOS';
import classNames from 'classnames';
import { Icon } from '../../../assets/Icons';
import { useUserState } from '../../../context/UserContext';
import { getPhoneNumber } from '../../../utilities/getPhoneNumber';
import { SelfHelpPanel } from '../AccountSections/SelfHelpPanel';
import { parseJSON } from 'date-fns';
import { CustomerServiceHours } from '../../Global/CustomerServiceHours';

let Help = () => {
  const [{ configState }] = useGlobalState();
  const [userState] = useUserState();
  const [selfHelp, setSelfHelp] = useState('');
  const { strings } = configState;

  const { firstName, lastName, cardNumber } = userState;
  const [emailDetails, setEmailDetails] = useState({
    issue: '',
    body: ''
  });
  const [errors, setErrors] = useState({});
  const [
    {
      configState: { com, client, cms }
    }
  ] = useGlobalState();
  const { uiConfig } = com || {};
  const { emailSentFrom } = uiConfig || {};
  if (strings.selfHelp) {
    setSelfHelp(JSON.parse(strings.help));
  }

  const handleChange = e => {
    let emailDetailsField = e.target.name;
    let emailDetailsValue = e.target.value.trim();

    setEmailDetails(prevState => ({
      ...prevState,
      [emailDetailsField]: emailDetailsValue.trim()
    }));
  };

  const getSubjectLine = () => {
    switch (client) {
      case 'walmart':
        return 'Walton Life Fitness Pass';
      case 'hcsc':
        return 'Well OnTarget Fitness Program';
      default:
        return '';
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (emailDetails.issue && emailDetails.body) {
      window.location = `mailto:${emailSentFrom}?body=We're sorry to hear you are experiencing an issue.
    The information contained in this email will help our customer service easily locate your profile and assist you.
  Please do not delete any of the information contained below.%0D%0A%0D%0AAdditional Details%3A (If additional details are needed, please add them here)
         %0D%0AMessage%3A ${emailDetails.body} %0D%0AIssue Category%3A ${
        emailDetails.issue
      }%0D%0AFirstname%3A${' ' + firstName}%0D%0ALastname%3A${' ' +
        lastName}%0D%0ABrowser%3A${' ' + checkBrowser()}%0D%0ADevice%3A${
        ' ' + checkBrowser ? checkOS() : checkIfMobile()
      }%0D%0AClient%3A${' ' +
        configState.client}%0D%0ATivity Card Number%3A${' ' +
        cardNumber}&subject=Help Request - ${getSubjectLine()}`;
    } else {
      return;
    }
  };
  const handleBlur = e => {
    const targetName = e.target.name;
    const errorMessages = {
      issue: 'Please select a issue from our dropdown below',
      body: 'Please add additional information about your issue'
    };
    if (!emailDetails[targetName]) {
      setErrors(prevState => ({
        ...prevState,
        [targetName]: errorMessages[targetName]
      }));
    } else {
      let errorsCopy = { ...errors };
      delete errorsCopy[targetName];
      setErrors(errorsCopy);
    }
  };
  return (
    <div className="my-3 mx-4 d-flex p-4 bg-white rounded-sm shadow flex-grow-1 flex-column">
      <form onSubmit={handleSubmit}>
        <h5>How can we help?</h5>
        <p className="text-sm">
          We're sorry to hear you are experiencing an issue. Please try some of
          the following options for assistance.
        </p>
        <div className="p-3 border border-primary bg-light rounded w-75 mb-3">
          <h5 className="m-0 mb-1">For immediate assistance, call us:</h5>
          <p className="m-0 ml-3 mb-1">
            <span className="font-weight-bold">English: </span>
            <a
              className="text-underline-primary"
              href={`tel:${getPhoneNumber().number}`}
            >
              {getPhoneNumber().text}
            </a>
            <br></br>
            <span className="font-weight-bold">Spanish: </span>
            <a className="text-underline-primary" href={`tel:1-877-614-6316`}>
              1-877-614-6316
            </a>
          </p>
          <p className="m-0 ml-3 mb-1">
            <span className="font-weight-bold">Hours:</span>
            <CustomerServiceHours />
          </p>
        </div>
        <div className="border-bottom w-50 mb-3">
          <p className="ml-2 text-lg">For general questions:</p>
          <p className="text-sm ml-4">
            Check out our{' '}
            <a target="_blank" href="/faq">
              Frequently Asked Questions
            </a>
          </p>
        </div>
        <p className="text-lg mb-2">
          Or, to report an issue (via email), fill out the form below:
        </p>
        <div className="form-group m-0">
          <label for="subject">
            <p className="h6 text-uppercase font-weight-bold text-secondary m-0">
              Issue Category
            </p>
          </label>

          <h6 className="mb-1 ml-2 font-weight-normal mt-2 text-sm">
            I am having an issue with...
          </h6>

          <select
            name="issue"
            className={classNames('form-control', 'ml-2', 'col-sm-6', {
              'text-dark-gray': !emailDetails.issue
            })}
            placeholder="Choose a category"
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {Object.entries(ReportIssueOptions).map(([key, value]) => {
              return (
                <option value={key} key={key}>
                  {value}
                </option>
              );
            })}
          </select>
          <br />

          <SelfHelpPanel
            isVisible={emailDetails.issue}
            category={emailDetails.issue}
            bodyText={SetSelfHelpContent(
              selfHelp?.panelContent,
              emailDetails.issue
            )}
            primaryColor={cms.primary_color}
          />

          {errors && errors['issue'] && (
            <p className="m-0 ml-2" style={{ color: 'red' }}>
              {errors['issue']}
            </p>
          )}
        </div>

        <div className="form-group m-0">
          <label for="cc">
            <p className="h6 text-uppercase font-weight-bold text-secondary m-0 mt-4">
              Details{' '}
            </p>
          </label>
          <div className="ml-2">
            <h6 className="mb-1 font-weight-normal mt-2 text-sm">
              Describe below the issue you are having:
            </h6>
            <div className="ml-2">
              <h6 className="mb-1 font-weight-normal mt-2 text-sm">
                1. What were you <b>doing</b>?
              </h6>
              <h6 className="mb-1 font-weight-normal mt-2 text-sm">
                2. What <b>happened?</b> (error, nothing, etc.)
              </h6>
              <h6 className="mb-1 font-weight-normal mt-2 text-sm">
                3. What did you <b>expect</b> to happen?
              </h6>
            </div>
            <textarea
              name="body"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="The more details the better"
              className="form-control height-150 col-sm-6 mt-2"
            ></textarea>
          </div>
          {errors && errors['body'] && (
            <p className="m-0 ml-2" style={{ color: 'red' }}>
              {errors['body']}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="btn-primary align-self-end btn my-3 px-4 py-2"
        >
          Review Email{' '}
          <Icon
            name="external-link"
            width="20px"
            height="20px"
            className="ml-2 fill-white"
          ></Icon>
        </button>
      </form>
    </div>
  );
};
export { Help };

const ReportIssueOptions = {
  '': 'Choose a category',
  'Billing/Payment': 'Billing/Payment',
  'Fitness Location (Studio/Gym)': 'Fitness Location (Studio/Gym)',
  'Managing My Plan': 'Managing My Plan',
  'Mobile App': 'Mobile App',
  'Technical Error': 'Technical Error',
  'Username/Password': 'Username/Password',
  other: 'Other'
};

const SetSelfHelpContent = (content, key) => {
  if (!content || !key) return '';
  return content[key] ?? null;
};
