import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import {
  setChosenDependents,
  setDisableFooterButton
} from '../../../../../context/actions/enrollment';
import { setEmail } from '../../../../../context/actions/user';
import { useEnrollmentState } from '../../../../../context/EnrollmentContext';
import { useUserState } from '../../../../../context/UserContext';
import { validateEmail } from '../../../../../utilities/validateEmail';
import { useLocation } from 'react-router-dom';

const EmailField = ({ user, loggedInMember, isEditingPlan }) => {
  const [userState, userDispatch] = useUserState();
  const [values, setValues] = useState('');
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const [
    { chosenDependents, primarySelectedPackage, disableFooterButton },
    dispatch
  ] = useEnrollmentState();

  useEffect(() => {
    user.email ? setValues(user.email.trim()) : (user.email = '');
  }, []);

  const items = (() => {
    const fieldValue = sessionStorage.getItem('persist.user');
    return fieldValue === null ? [] : JSON.parse(fieldValue);
  })();

  const handleBlur = e => {
    const value = e.target.value;
    if (!value) {
      return setErrors({ email: 'Please provide a valid email.' });
    } else {
      setErrors(prevState => {
        let prevStateCopy = { ...prevState };
        delete prevStateCopy.email;
        return prevStateCopy;
      });
    }
    if (!validateEmail(value)) {
      return setErrors(prevState => ({
        ...prevState,
        email: 'Please provide a valid email.'
      }));
    } else {
      setErrors(prevState => {
        let prevStateCopy = { ...prevState };
        delete prevStateCopy.email;
        return prevStateCopy;
      });
    }
    items.push({ name: user.firstName, email: user.email });
    sessionStorage.setItem('persist.user', JSON.stringify(items));
  };

  const handleEmail = e => {
    setValues(e.target.value.trim());

    if (loggedInMember) {
      userDispatch(setEmail(e.target.value.trim()));
    } else {
      const updatedDependents = chosenDependents.map(member => {
        if (member.consumerId === user.consumerId) {
          member.email = e.target.value.trim();
          return member;
        }
        return member;
      });
      dispatch(setChosenDependents(updatedDependents));
    }
  };

  ///debug info.
  // const tmp = JSON.parse(data);

  // console.info("storageKey: ", storageKey());s
  // if(tmp)
  //   console.info("Stored value: ", tmp.value);

  // console.info("Raw Data: ", data);

  // if(tmp)
  //   console.info("Is email valid? : ", validateEmail(tmp.value));
  //end

  return (
    <>
      {(loggedInMember || !user.isUnderage) && (
        <div className="form-group flex-fill">
          <label id="fitnessPackage">
            <p className="m-0 h6 text-uppercase font-weight-bold text-secondary">
              EMAIL ADDRESS
              <i className="m-0 text-danger">*</i>
            </p>
          </label>
          <DebounceInput
            autoComplete="new-password"
            minLength={2}
            disabled={isEditingPlan && loggedInMember}
            debounceTimeout={500}
            onChange={handleEmail}
            className="form-control"
            placeholder="Enter Email Here"
            onBlur={handleBlur}
            value={values || user.email}
          />
          {errors && errors['email'] && (
            <p className="text-danger ml-1">{errors['email']}</p>
          )}
        </div>
      )}
    </>
  );
};

export { EmailField };
