import {
  SET_FILTER,
  CLEAR_FILTERS,
  CHOOSE_LOCATION,
  CHOOSE_LOCATION_TYPE,
  SET_LOADING,
  SET_STUDIOS,
  SET_GYMS,
  SET_CURRENT_USER_LOCATION,
  SET_CURRENT_MAP_LOCATION,
  SET_MAP_DATA,
  SET_VISIBLE_GYMS,
  SET_VISIBLE_STUDIOS
} from '../reducers/location';
import {
  getLocations,
  getNewGymsAndStudios,
  getEliteGyms
} from '../../utilities/getNewLocations';
import { getCurrentLocationFromNavigator } from '../../utilities';
import classifyPoint from 'robust-point-in-polygon';

export const chooseLocationType = chosenLocationType => ({
  type: CHOOSE_LOCATION_TYPE,
  chosenLocationType
});

export const setFilter = (filterType, filterValue) => ({
  type: SET_FILTER,
  filterType,
  filterValue
});

export const clearFilters = () => ({ type: CLEAR_FILTERS });

export const setLoading = (loadingType, isLoading) => ({
  type: SET_LOADING,
  loadingType,
  isLoading
});

export const chooseLocation = location => ({ type: CHOOSE_LOCATION, location });

export const getGyms = async (
  programCode,
  client,
  locationType,
  location,
  chosenStudioType = ''
) => {
  try {
    const locations = await getLocations(
      programCode,
      client,
      locationType,
      location,
      chosenStudioType
    );
    return locations.list;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStudioList = (programCode, client, location, dispatch) => {
  dispatch(setLoading('loadingStudios', true));
  getLocations(programCode, client, 'studio', location)
    .then(data => {
      dispatch({ type: SET_STUDIOS, studioList: data.list });
      dispatch(setLoading('loadingStudios', false));
    })
    .catch(error => {
      console.log(error);
      dispatch({ type: SET_STUDIOS, studioList: [] });
      dispatch(setLoading('loadingStudios', false));
    });
};

export const getStudiosAndGyms = async (programCode, client, location) => {
  try {
    const list = await getNewGymsAndStudios(programCode, client, location);
    return list;
  } catch (error) {
    return error;
  }
};

export const getEliteGymList = (programCode, client, location, dispatch) => {
  getEliteGyms(programCode, client, location)
    .then(data => {
      dispatch({ type: SET_GYMS, gymList: data });
      dispatch(setLoading('loadingLocations', false));
    })
    .catch(error => {
      dispatch({ type: SET_GYMS, gymList: [] });
      dispatch(setLoading('loadingLocations', false));
    });
};

export const getCurrentMapLocation = location => ({
  type: SET_CURRENT_MAP_LOCATION,
  location
});

export const getCurrentUserLocation = dispatch => {
  getCurrentLocationFromNavigator().then(data =>
    dispatch({
      type: SET_CURRENT_USER_LOCATION,
      location: data.currentLocation
    })
  );
};

export const setMapData = mapData => ({ type: SET_MAP_DATA, mapData });

export const setVisibleGyms = visibleGyms => ({
  type: SET_VISIBLE_GYMS,
  visibleGyms
});

export const setVisibleStudios = visibleStudios => ({
  type: SET_VISIBLE_STUDIOS,
  visibleStudios
});

export const renderLocations = (
  dispatch,
  mapData,
  gymList,
  studioList = null,
  chosenAmenities = null,
  chosenDistance = null,
  chosenGender = null,
  chosenTier = null,
  chosenLocationType = null
) => {
  if (!mapData) {
    dispatch(setVisibleGyms(gymList));
    if (studioList) {
      dispatch(setVisibleStudios(studioList));
    }
    return;
  }
  //else if (!mapData.bounds.ne.lat) {
  //   dispatch(setVisibleGyms(gymList));
  //   if (studioList) {
  //     dispatch(setVisibleStudios(studioList));
  //   }
  //   return;
  // }

  // Bounds not set in mobile vew because map is hidden.
  const isWithinDistance = location =>
    chosenDistance ? location.distance < chosenDistance : true;

  const isWithinTier = location =>
    chosenTier ? chosenTier.tier >= location.tierNumber : true;

  const hasAmenities = location =>
    location.isStudio
      ? true
      : chosenAmenities
      ? chosenAmenities.every(amenity =>
          location.amenityCategories.includes(amenity)
        )
      : true;

  const hasGender = location =>
    chosenGender ? chosenGender === location.genderSpecific : true;
  //REFACTORED FOR MOBILE VIEW
  let bounds = [];
  let inboundLocations = [];
  if (mapData.bounds.ne.lat) {
    bounds = [
      [mapData.bounds.nw.lat, mapData.bounds.nw.lng],
      [mapData.bounds.ne.lat, mapData.bounds.ne.lng],
      [mapData.bounds.se.lat, mapData.bounds.se.lng],
      [mapData.bounds.sw.lat, mapData.bounds.sw.lng]
    ];

    inboundLocations = gymList
      ? typeof gymList == Array &&
        gymList.filter(
          location =>
            classifyPoint(bounds, [location.latitude, location.longitude]) ===
            -1
        )
      : [];
  }
  let filteredLocations;

  if (inboundLocations.length > 0) {
    filteredLocations =
      chosenAmenities || chosenDistance || chosenGender
        ? inboundLocations.filter(
            location =>
              hasAmenities(location) &&
              isWithinDistance(location) &&
              hasGender(location) &&
              isWithinTier(location)
          )
        : chosenTier
        ? inboundLocations.filter(location => isWithinTier(location))
        : inboundLocations;
  } else {
    if (!gymList) {
      //it gives a error because on render gymList is null but after the async call it resolves as a array full of locations
      gymList = [];
    }
    filteredLocations =
      chosenAmenities || chosenDistance || chosenGender
        ? gymList.filter(
            location =>
              hasAmenities(location) &&
              isWithinDistance(location) &&
              hasGender(location) &&
              isWithinTier(location)
          )
        : chosenTier
        ? gymList.filter(location => isWithinTier(location))
        : gymList;
  }
  dispatch(setVisibleGyms(filteredLocations));
  if (studioList) {
    let inboundStudios = gymList
      ? studioList.filter(
          studio =>
            classifyPoint(bounds, [studio.latitude, studio.longitude]) === -1
        )
      : [];
    dispatch(setVisibleStudios(inboundStudios));
  }
};
