import React from 'react';
import {
  LocationOptionButton,
  LocationItemDetails,
  TierList,
  LocationList,
  LocationSearchInput
} from './';
import classNames from 'classnames';
import { useGlobalState } from '../../context/GlobalContext';
import { chooseLocationType } from '../../context/actions/location';
import { Icon } from '../../assets/Icons';
import { LoadingSpinner } from '../Global/LoadingSpinner';

let LocationSection = ({
  showTiers,
  hoveredLocation,
  setHoveredLocation,
  isModal,
  studioLimitReached,
  getLocations
}) => {
  const [{ locationState, tierState }, dispatch] = useGlobalState();
  const {
    locationTypes,
    chosenLocationType,
    chosenLocation,
    loadingLocations,
    visibleGyms
  } = locationState;
  const { loadingTiers, chosenTier, tiers } = tierState;

  return (
    <div
      className={classNames(
        'location-search__sidebar',
        {
          'col-lg-4': !isModal,
          'col-xl-4': !isModal,
          'col-lg-5': isModal,
          'col-xl-5': isModal
        },
        'p-0 border-right border-primary'
      )}
    >
      {loadingLocations ? (
        <LoadingSpinner additionalText="" />
      ) : (
        <>
          {!chosenLocation ? (
            <>
              <div
                className={classNames('bg-white locations-sticky__search', {
                  shadow: !isModal
                })}
              >
                <div className="px-4 pt-3" style={{ position: 'relative' }}>
                  <LocationSearchInput />
                </div>
                {!isModal && (
                  <div className="d-flex">
                    {locationTypes.map((option, i) => {
                      return (
                        <LocationOptionButton
                          key={i}
                          selectGymOption={() =>
                            dispatch(chooseLocationType(option))
                          }
                          locationOption={option}
                          active={chosenLocationType === option}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                className="px-lg-4 px-2 py-3"
                // style={{ height: !isModal && '800px' }}
              >
                {showTiers && tiers && <TierList />}
                {studioLimitReached ? (
                  <div className="d-flex flex-column text-center align-items-center">
                    <p className="m-0">
                      Sorry we could not load any nearby fitness studios at this
                      time.
                    </p>
                    <button
                      onClick={() => getLocations()}
                      className="d-flex align-items-center btn bg-none border-0 text-primary"
                    >
                      <Icon name="re-do" width="12px" height="12px" />
                      Try again
                    </button>
                  </div>
                ) : (
                  tiers && (
                    <LocationList
                      isModal={isModal}
                      list={visibleGyms}
                      setHoveredLocation={setHoveredLocation}
                      hoveredLocation={hoveredLocation}
                      headerText={
                        visibleGyms &&
                        (locationTypes && !isModal
                          ? `${visibleGyms.length}  ${chosenLocationType &&
                              chosenLocationType.replace(/^\w/, letter =>
                                letter.toUpperCase()
                              )} Locations Nearby`
                          : `${visibleGyms.length} Nearby Elite Locations`)
                      }
                      tierDescription={
                        showTiers &&
                        !loadingTiers &&
                        chosenTier &&
                        `${chosenTier.name.replace(/^\w/, letter =>
                          letter.toUpperCase()
                        )} Tier has access to all following locations`
                      }
                      scrollLocationList
                      chosenLocationType={chosenLocationType}
                    />
                  )
                )}
              </div>
            </>
          ) : (
            <LocationItemDetails isModal={isModal} />
          )}
        </>
      )}
    </div>
  );
};

export { LocationSection };
