import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import {
  AccountNavigation,
  AccountOverview,
  ActivityLog,
  BillingAndPayment,
  EditProfile,
  ManagePlans,
  UpcomingPayment,
  EliteLocationSearchModal,
  Help,
  WholeHealthLiving
} from '../components';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { isUnderage } from '../utilities/checkIfUnderage';
import { LegacyPrompt } from '../components/Account';
import { AccountUpSell } from '../components/Account/AccountUpSell';
import { CancelledDependent } from '../components/Account/CancelledDependent';
import { SuspensionWarning } from '../components/Global/SuspensionWarning';
import { AlertMessage } from '../components/Global/AlertMessage';
import { useUserState } from '../context/UserContext';
import { CreditCardExpiredAlertMessage } from '../components/Global/CreditCardExpiredAlertMessage';
import { isAfter } from 'date-fns';
import { useGlobalState } from '../context/GlobalContext';
import { SpecialPricingBanner } from '../components/Global/SpecialPricingBanner';

const Account = () => {
  const [userState] = useUserState();
  const [{ configState }] = useGlobalState();
  const {
    nextPaymentInfo,
    isEnrolledInDefaultProgram,
    isStudioEnrolled,
    isPrimary,
    paymentMethods,
    isEnrolledInDigitalTier,
    defaultProgram,
    digitalProgram
  } = userState;
  const [showLocationSearchModal, setShowLocationSearchModal] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);
  const currentDate = new Date();
  const specialBannerDate = new Date('December 01, 2023 00:00:00');

  const location = useLocation();
  const { com } = configState;

  useEffect(() => {
    if (location.showEditPlan) setShowEditPlan(location.showEditPlan);
  }, []);

  const primaryMethod =
    paymentMethods &&
    paymentMethods.find(paymentMethod => paymentMethod.isDefault);

  return (
    <div className="d-lg-flex">
      <AccountNavigation />
      <div className="flex-grow-1 d-flex flex-column w-75 w-lg-100">
        {/* <button onClick={() => toastNotification('Success message here', true)}>
          Success
        </button>
        <button onClick={() => toastNotification('Error message here', false)}>
          Error
        </button> */}
        {!isPrimary &&
          (defaultProgram?.isCancelling || digitalProgram?.isCancelling) && (
            <CancelledDependent
              program={
                defaultProgram?.isCancelling
                  ? defaultProgram
                  : digitalProgram?.isCancelling && digitalProgram
              }
            />
          )}
        {typeof defaultProgram.memberTermDate !== 'undefined' && (
          <AccountUpSell />
        )}
        {(defaultProgram?.isSuspending ||
          defaultProgram?.isSuspended ||
          digitalProgram?.isSuspending ||
          digitalProgram?.isSuspended) && (
          <SuspensionWarning whereToShow="banner" user={userState} />
        )}

        {location.pathname === '/account/edit-profile' ? null : (
          <>
            {nextPaymentInfo &&
            nextPaymentInfo.isBalanceOverdue &&
            paymentMethods.length !== 0 &&
            isAfter(
              new Date(),
              new Date(
                primaryMethod.expirationYear,
                primaryMethod.expirationMonth,
                1
              )
            ) ? (
              <CreditCardExpiredAlertMessage />
            ) : (
              nextPaymentInfo &&
              nextPaymentInfo.isBalanceOverdue && <AlertMessage />
            )}
          </>
        )}
        {/* Remove following section after dec 1st */}
        {(location.pathname === '/account' &&
          currentDate < specialBannerDate) ||
        (location.pathname === '/account/manage-plans' &&
          currentDate < specialBannerDate) ? (
          <SpecialPricingBanner />
        ) : (
          ''
        )}
        {isPrimary &&
          location.pathname !== '/account/help' &&
          !showEditPlan &&
          nextPaymentInfo &&
          !(
            (defaultProgram?.isCancelling || digitalProgram?.isCancelling) &&
            nextPaymentInfo.amount === 'No Payment Due'
          ) && (
            <div className="my-lg-3 mx-4 d-flex justify-content-between align-items-center">
              <UpcomingPayment />
            </div>
          )}
        <Route exact path="/account/">
          <AccountOverview
            setShowLocationSearchModal={setShowLocationSearchModal}
            showLocationSearchModal={showLocationSearchModal}
          />
        </Route>
        <Route exact path="/account/edit-profile">
          <EditProfile />
        </Route>
        {(isEnrolledInDefaultProgram ||
          defaultProgram?.currentSubscription ||
          isStudioEnrolled ||
          isEnrolledInDigitalTier) && (
          <Route exact path="/account/manage-plans">
            <ManagePlans
              setShowLocationSearchModal={setShowLocationSearchModal}
              showLocationSearchModal={showLocationSearchModal}
              showEditPlan={showEditPlan}
              setShowEditPlan={setShowEditPlan}
            />
          </Route>
        )}
        {(userState.isPrimary || !isUnderage(userState.dateOfBirth)) &&
          (isEnrolledInDefaultProgram ||
            defaultProgram?.currentSubscription ||
            isStudioEnrolled ||
            isEnrolledInDigitalTier) && (
            <Route exact path="/account/billing-and-payment">
              <BillingAndPayment />
            </Route>
          )}
        <Route exact path="/account/activity">
          <ActivityLog />
        </Route>
        <Route exact path="/account/help">
          <Help />
        </Route>
        {com.entitlements.wholeHealth && <WholeHealthLiving />}
      </div>
      {showLocationSearchModal && (
        <EliteLocationSearchModal
          setShowLocationSearchModal={setShowLocationSearchModal}
          showLocationSearchModal={showLocationSearchModal}
        />
      )}
      <LegacyPrompt />
      <ToastContainer />
    </div>
  );
};

export { Account };
