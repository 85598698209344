import React, { useState } from 'react';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import { setPromoCode } from '../../../context/actions/enrollment';

const PromoCodes = ({ getOrderPreview }) => {
  const [promoCodeValue, setPromoCodeValue] = useState();
  const [enrollmentState, dispatch] = useEnrollmentState();
  const { promoCode } = enrollmentState;

  const onChangePromoCode = e => {
    setPromoCodeValue(e.target.value);
  };

  const submitPromoCode = e => {
    setPromoCodeValue('');
    if (promoCode.includes(promoCodeValue.toUpperCase())) {
      return;
    } else {
      getOrderPreview({
        promoCode: [...promoCode, promoCodeValue.toUpperCase()]
      });
      dispatch(setPromoCode([...promoCode, promoCodeValue.toUpperCase()]));
    }
  };

  return (
    <>
      <p className="font-weight-bold text-uppercase text-secondary mb-0 small">
        Do you have a promotional code?
      </p>
      {promoCodeValue && promoCode.includes(promoCodeValue.toUpperCase()) && (
        <p className="text-danger">You've already tried that promo code</p>
      )}
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter Code Here"
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
          onChange={e => onChangePromoCode(e)}
        />
        <div className="input-group-append">
          <button
            className="btn btn-primary"
            type="button"
            id="button-addon2"
            disabled={!promoCodeValue}
            onClick={e => submitPromoCode(e)}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export { PromoCodes };
