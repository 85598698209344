import { locationReducer } from './location';
import { tiersReducer } from './tiers';
import { configReducer } from './config';
export const SET_GLOBAL_STATE = 'SET_GLOBAL_STATE';

export const globalReducer = (
  { locationState, tierState, configState },
  action
) => ({
  locationState: locationReducer(locationState, action),
  tierState: tiersReducer(tierState, action),
  configState: configReducer(configState, action)
});
