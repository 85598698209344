import React, { useEffect } from 'react';
import { setDisableFooterButton } from '../../../context/actions/enrollment';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import classNames from 'classnames';
import { CurrentPlan } from './CurrentPlan';
import {
  validateMemberEmail,
  isDependentTierInvalid
} from '../../../utilities/enrollmentValidation';
import { useUserState } from '../../../context/UserContext';
import { LoadingSpinner } from '../../Global/LoadingSpinner';
import { useGlobalState } from '../../../context/GlobalContext';
import { CartSummarySection } from './CartSummarySection';

const CartSummary = ({ isEditingPlan, getOrderPreview }) => {
  const [userState] = useUserState();
  const [enrollmentState, dispatch] = useEnrollmentState();
  const [{ tierState }] = useGlobalState();
  const { digitalTier } = tierState;

  const { defaultProgram, isPrimary, isEnrolledInDefaultProgram } = userState;
  const {
    cartSummary,
    chosenDependents,
    primarySelectedPackage,
    loadingCartSummary,
    acceptedProgramPolicy,
    acceptedTermsPolicy
  } = enrollmentState;

  const comparePackages = () => {
    if (
      isPrimary &&
      isEnrolledInDefaultProgram &&
      primarySelectedPackage?.tier ==
        defaultProgram?.currentSubscription.tier &&
      primarySelectedPackage?.name ==
        defaultProgram?.currentSubscription?.ratePlanName
    ) {
      if (!chosenDependents || chosenDependents.length === 0) {
        dispatch(setDisableFooterButton(true));
      } else {
        if (
          chosenDependents.filter(
            dependent =>
              dependent?.isEnrolledInDefaultProgram &&
              dependent?.selectedGymPackage?.tier ==
                dependent?.defaultProgram?.currentSubscription.tier
          ).length === chosenDependents.length
        ) {
          dispatch(setDisableFooterButton(true));
        } else {
          dispatch(setDisableFooterButton(false));
        }
      }
    } else {
      dispatch(setDisableFooterButton(false));
    }
  };

  useEffect(() => {
    if (loadingCartSummary) {
      dispatch(setDisableFooterButton(true));
    } else {
      if (cartSummary) {
        if (
          validateMemberEmail(userState.email, chosenDependents) &&
          !isDependentTierInvalid(
            chosenDependents,
            primarySelectedPackage,
            digitalTier
          ) &&
          (!isEditingPlan ? acceptedProgramPolicy && acceptedTermsPolicy : true)
        ) {
          if (
            (!chosenDependents || chosenDependents.length === 0) &&
            primarySelectedPackage === 'studio' &&
            (defaultProgram.isCancelled || defaultProgram.isCancelling)
          ) {
            dispatch(setDisableFooterButton(true));
          } else {
            comparePackages();
          }
        }
      } else {
        dispatch(setDisableFooterButton(true));
      }
    }
  }, [primarySelectedPackage, loadingCartSummary, chosenDependents]);

  return (
    <div
      className={classNames(
        'col-lg-5 col-xl-4 m-0 position-relative forced-height'
      )}
    >
      {isEditingPlan && <CurrentPlan />}
      {loadingCartSummary ? (
        <LoadingSpinner additionalText="" />
      ) : (
        <>
          {
            <>
              {isEditingPlan &&
                cartSummary?.monthlyPaymentSummary?.monthlyPaymentDetails
                  ?.length > 0 && (
                  <CartSummarySection
                    isEditingPlan={isEditingPlan}
                    getOrderPreview={getOrderPreview}
                    details={cartSummary?.monthlyPaymentSummary}
                  />
                )}
              {cartSummary?.immediatePaymentSummary?.immediatePaymentDetails
                ?.length > 0 && (
                <CartSummarySection
                  isEditingPlan={isEditingPlan}
                  getOrderPreview={getOrderPreview}
                  details={cartSummary}
                  promoCodeErrors={cartSummary?.promoCodeError}
                  dueNow
                />
              )}
            </>
          }
        </>
      )}
    </div>
  );
};

export { CartSummary };
