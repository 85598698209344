import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useGlobalState } from '../../context/GlobalContext';
import { useUserState } from '../../context/UserContext';
import { Icon } from '../../assets/Icons';
import axios from 'axios';
import { setLoading } from '../../context/actions/user';
import { LoadingSpinner } from '../Global/LoadingSpinner';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Video } from './Video';
import moment from 'moment';
import NoImage from '../../assets/images/no-image.png';

const VideoList = item => {
  const [{ configState }] = useGlobalState();
  const { cms } = configState;
  const [isLoadingVideos, setIsLoadingVideos] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [programsList, setPrograms] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState();
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    getVideosList();
  }, []);

  const getVideosList = async () => {
    setIsLoadingVideos(true);
    try {
      const videosList = await axios.get('/api/platform/v1/videos');
      await setPrograms(videosList.data.programs);
      await setVideoList(videosList.data.videos);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setIsLoadingVideos(false);
    }
  };

  const handleChange = show => {
    setPlaying(show);
  };

  const addDefaultSrc = ev => {
    ev.target.src = 'https://via.placeholder.com/395x130/aaaaaa';
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 60
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 60
    }
  };

  return (
    <>
      {playing ? (
        <>
          <Video
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            handleChange={handleChange}
          />
        </>
      ) : (
        <>
          <div className="bg-col">
            <div className="lesmills-lp">
              <div className="lm-back">
                <img
                  src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2021/08/lesMills_Hero.png"
                  alt="LesMills background"
                  className="img-fluid"
                />
              </div>
              <div className="ml-2 lm-text">
                <div className="row pt-4">
                  <div className="col pt-4">
                    <img
                      src="https://d1okn44hi4vvnd.cloudfront.net/wp-content/uploads/2021/06/LesMills-logo.png"
                      alt="LesMills logo"
                      className="lm-logo"
                    />
                    <h1 className="pt-3">
                      Take your workouts to <br />
                      the next level
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="lesmills-lp-bg">
              <div className="mx-2">
                <div className="row"></div>
                <div className="col">
                  {isLoadingVideos ? (
                    <LoadingSpinner isLoading={isLoadingVideos} />
                  ) : (
                    programsList &&
                    programsList.map((program, e) => {
                      return (
                        <>
                          <div className="category-section pt-5" key={e}>
                            <h1 key={program} style={{ fontSize: '32px' }}>
                              {program.name}{' '}
                              <span className="font-weight-normal">
                                ({program.total})
                              </span>
                            </h1>
                            <p className="font-small">{program.description}</p>
                          </div>

                          <Carousel
                            className="mt-4 carousel"
                            showDots={false}
                            responsive={responsive}
                            removeArrowOnDeviceType={['tablet', 'mobile']}
                            //infinite={true}
                            partialVisible={true}
                            //ref={carouselRef}
                          >
                            {videoList &&
                              videoList
                                .filter(
                                  video =>
                                    program.name == video.video.program.name
                                )
                                .map((video, i) => {
                                  if (program.name == video.video.program.name)
                                    return (
                                      <>
                                        <div key={i}>
                                          <div
                                            key={video}
                                            className="col p-0 slides mt-4"
                                          >
                                            <a
                                              className="its-a-link"
                                              onClick={() => {
                                                setPlaying(true);
                                                setSelectedVideo(
                                                  video.video.fileId
                                                );
                                              }}
                                            >
                                              <div className="video-card">
                                                <img
                                                  src={video.video.thumbnail}
                                                  //src="https://via.placeholder.com/380x130/aaaaaa"
                                                  alt={
                                                    video.video.title +
                                                    '_thumbnail'
                                                  }
                                                  onError={addDefaultSrc}
                                                  className="img-fluid video-card-img"
                                                />
                                                <div className="video-catgory">
                                                  <div className="video-category-section">
                                                    {moment
                                                      .utc(
                                                        moment
                                                          .duration(
                                                            video.video
                                                              .duration,
                                                            'seconds'
                                                          )
                                                          .as('milliseconds')
                                                      )
                                                      .format('mm:ss')}
                                                  </div>
                                                </div>
                                                <div className="container">
                                                  <div className="row">
                                                    <h5 className="pt-3 title-resp">
                                                      <b>{video.video.title}</b>
                                                    </h5>
                                                  </div>
                                                  <div className="pb-3">
                                                    <b>
                                                      <span className="lm-vdlink">
                                                        View Details
                                                        <Icon
                                                          name="chevron"
                                                          width="15px"
                                                          height="15px"
                                                          fill={
                                                            cms.primary_color
                                                          }
                                                        />{' '}
                                                      </span>
                                                    </b>
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </>
                                    );
                                })}
                          </Carousel>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { VideoList };
