import { initialUserState } from '../state/initialUserState';

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_DEPENDENTS = 'SET_DEPENDENTS';
export const SET_ENROLLED_DEPENDENTS = 'SET_ENROLLED_DEPENDENTS';
export const SET_ACCOUNT_SUMMARY = 'SET_ACCOUNT_SUMMARY';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const SET_SSO = 'SET_SSO';
export const SET_UPCOMING_PAYMENT = 'SET_UPCOMING_PAYMENT';
export const SET_LOADING = 'SET_LOADING';
export const SET_HOME_GYM = 'SET_HOME_GYM';
export const SET_HOME_GYM_LIST = 'SET_HOME_GYM_LIST';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP';
export const SET_USER = 'SET_USER';
export const SET_MOBILENAVBARVISIBLE = 'SET_MOBILENAVBARVISIBLE';
export const LOG_OUT = 'LOG_OUT';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ADDRESS_ERROR = 'SET_ADDRESS_ERROR';

const setDependents = (dependents, state) => ({ ...state, dependents });

const setEnrolledDependents = (enrolledDependents, state) => ({
  ...state,
  enrolledDependents
});

const setAccountSummary = (accountSummary, state) => ({
  ...state,
  accountSummary
});

const setActivities = (activities, state) => ({
  ...state,
  activities
});

const setSSO = (sso, state) => ({
  ...state,
  sso
});

const setEmail = (email, state) => ({
  ...state,
  email
});

const setMembership = (membership, state) => ({
  ...state,
  membership
});

const setUpcomingPayment = (nextPaymentInfo, state) => ({
  ...state,
  nextPaymentInfo
});

const setIsAuthenticated = (isAuthenticated, state) => ({
  ...state,
  isAuthenticated
});

const setUserInfo = (userInfo, state) => ({
  ...state,
  ...userInfo
});

const setHomeGym = (homeGymCode, state) => ({
  ...state,
  homeGymCode
});

const setHomeGymList = (homeGymList, state) => ({
  ...state,
  homeGymList
});

const setLoading = (loadingType, isLoading, state) => ({
  ...state,
  [loadingType]: isLoading
});

const setAddress = (addressType, address, state) => ({
  ...state,
  [addressType]: address
});

const setAddressError = (addressType, error, state) => ({
  ...state,
  [addressType]: error
});

const setMobileNavbarVisible = (mobileNavbarVisible, state) => ({
  ...state,
  mobileNavbarVisible
});

export const userReducer = (state, action) => {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return setIsAuthenticated(action.isAuthenticated, state);
    case SET_USER_INFO:
      return setUserInfo(action.userInfo, state);
    case SET_DEPENDENTS:
      return setDependents(action.dependents, state);
    case SET_ENROLLED_DEPENDENTS:
      return setEnrolledDependents(action.enrolledDependents, state);
    case SET_ACCOUNT_SUMMARY:
      return setAccountSummary(action.accountSummary, state);
    case SET_ACTIVITIES:
      return setActivities(action.activities, state);
    case SET_UPCOMING_PAYMENT:
      return setUpcomingPayment(action.nextPaymentInfo, state);
    case SET_SSO:
      return setSSO(action.sso, state);
    case SET_EMAIL:
      return setEmail(action.email, state);
    case SET_HOME_GYM:
      return setHomeGym(action.homeGymCode, state);
    case SET_HOME_GYM_LIST:
      return setHomeGymList(action.homeGymList, state);
    case SET_MEMBERSHIP:
      return setMembership(action.membership, state);
    case SET_USER:
      return { ...state, ...action.user };
    case SET_LOADING:
      return setLoading(action.loadingType, action.isLoading, state);
    case SET_ADDRESS:
      return setAddress(action.addressType, action.address, state);
    case SET_ADDRESS_ERROR:
      return setAddressError(action.addressType, action.error, state);
    case SET_MOBILENAVBARVISIBLE:
      return setMobileNavbarVisible(action.mobileNavbarVisible, state);
    case LOG_OUT:
      return initialUserState;
    default:
      return state;
  }
};
