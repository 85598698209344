function mutateHeaderLinks(links, pathname) {
  if (!links) {
    return;
  }
  const newCopyOfHeaderLinks = links.map(linkItem => {
    let link = { ...linkItem };
    if (link.key === 'learn' && pathname === '/') {
      link.props = {
        ...link.props,
        className: 'nav-item font-weight-bold'
      };
    } else if (link.key === 'locations' && pathname === '/locations') {
      link.props = {
        ...link.props,
        className: 'nav-item font-weight-bold'
      };
    } else if (link.key === 'account' && pathname === '/account') {
      link.props = {
        ...link.props,
        className: 'nav-item font-weight-bold'
      };
    } else if (link.key === 'faq' && pathname === '/faq') {
      link.props = {
        ...link.props,
        className: 'nav-item font-weight-bold'
      };
    } else {
      link.props = { ...link.props, className: 'nav-item' };
    }
    return link;
  });
  return newCopyOfHeaderLinks;
}

export { mutateHeaderLinks };
