import React from 'react';
import { TierTab } from '..';
import { HomeLocationTierTab } from '..';
import { EliteHorizontalLocationList } from '..';
import { useGlobalState } from '../../../context/GlobalContext';
import { NavLink } from 'react-router-dom';
import { DigitalTier } from '../../../components/Enrollment/EnrollmentSteps/SelectPackages/DigitalTier';

const TierTabs = ({ children }) => {
    const [{ tierState }] = useGlobalState();
    const { tiers, homeLocationTier } = tierState;

    return (
        <React.Fragment>
            <section className="extra-padding-bottom overlap-img text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 extra-margin-top">
                            {/*<div className="extra-margin-top locations text-center">*/}
                            {/*  <h1>Gyms Near You</h1>*/}
                            {/*  <div className="row d-none d-md-flex">*/}
                            {/*    <div className="col">*/}
                            {/*      <div className="m-3 standard-gyms-pin">*/}
                            {/*        <i*/}
                            {/*          className="fas fa-map-marker-alt"*/}
                            {/*          style={{ width: 40, height: 40 }}*/}
                            {/*        />*/}
                            {/*      </div>*/}
                            {/*      <p*/}
                            {/*        className="font-weight-bold"*/}
                            {/*        style={{ fontSize: '17px' }}*/}
                            {/*      >*/}
                            {/*        {' '}*/}
                            {/*        Standard*/}
                            {/*      </p>*/}
                            {/*      <p>Gyms with basic amenities</p>*/}
                            {/*      {children}*/}
                            {/*    </div>*/}
                            {/*    <div className="col">*/}
                            {/*      {homeLocationTier && (*/}
                            {/*        <>*/}
                            {/*          <div className="m-3 elite-gyms-pin">*/}
                            {/*            <i*/}
                            {/*              className="fas fa-map-marker-alt"*/}
                            {/*              style={{ width: 40, height: 40 }}*/}
                            {/*            />*/}
                            {/*          </div>*/}
                            {/*          <p*/}
                            {/*            className="font-weight-bold"*/}
                            {/*            style={{ fontSize: '17px' }}*/}
                            {/*          >*/}
                            {/*            {' '}*/}
                            {/*            Elite*/}
                            {/*          </p>*/}
                            {/*          <p>Exclusive clubs with luxury amenities</p>*/}
                            {/*          <EliteHorizontalLocationList*/}
                            {/*            numberOfLocationsShown={3}*/}
                            {/*          />*/}
                            {/*        </>*/}
                            {/*      )}*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            {/*<div className="d-sm-block d-md-none">*/}
                            {/*  <div className="m-3 standard-gyms-pin">*/}
                            {/*    <i*/}
                            {/*      className="fas fa-map-marker-alt"*/}
                            {/*      style={{ width: 40, height: 40 }}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*  <p className="font-weight-bold" style={{ fontSize: '18px' }}>*/}
                            {/*    {' '}*/}
                            {/*    Standard*/}
                            {/*  </p>*/}
                            {/*  <p>Gyms with basic amenities.</p>*/}
                            {/*  {children}*/}
                            {/*  {homeLocationTier && (*/}
                            {/*    <>*/}
                            {/*      <div className="m-3 elite-gyms-pin">*/}
                            {/*        <i*/}
                            {/*          className="fas fa-map-marker-alt"*/}
                            {/*          style={{ width: 40, height: 40 }}*/}
                            {/*        />*/}
                            {/*      </div>*/}
                            {/*      <p*/}
                            {/*        className="font-weight-bold"*/}
                            {/*        style={{ fontSize: '18px' }}*/}
                            {/*      >*/}
                            {/*        {' '}*/}
                            {/*        Elite*/}
                            {/*      </p>*/}
                            {/*      <p>Exclusive clubs with luxury amenities.</p>*/}
                            {/*      <EliteHorizontalLocationList numberOfLocationsShown={3} />*/}
                            {/*    </>*/}
                            {/*  )}*/}
                            {/*</div>*/}
                            {/*<div className="container extra-padding-bottom mt-3 center-btn">*/}
                            {/*  <NavLink to="/locations">*/}
                            {/*    <button type="button" className="font-weight-bold white-btn">*/}
                            {/*      Explore All Gyms <i className="fas fa-chevron-right"></i>*/}
                            {/*    </button>*/}
                            {/*  </NavLink>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
            <section className="gray-bg extra-padding-bottom text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 extra-margin-top">
                            <h1>
                                Gym Packages
                                <a href="#legal" className="legal-sup">
                                    <sup>1</sup>
                                </a>
                            </h1>
                            <h3 className="font-italic">
                                No long-term commitments.
                             </h3>
                            <h3 className="font-italic">Your workout, your way - close to home and nationwide</h3>
                            <h3 className="font-italic pb-4">
                                *One-time enrollment fee of $19.
                            </h3>
                            <div className="row justify-content-md-center lp-std-gym">
                                {/* gym packages for fyw */}
                                {tiers &&
                                    tiers.map((tier, i) => {
                                        if (
                                            (window.location.href.includes('fyw') &&
                                                tier.name === 'Base')
                                            /*||  tier.name === 'Elite'*/
                                        ) {
                                        } else {
                                            return (
                                                <TierTab key={i} tier={tier} selectTier={() => { }} />
                                            );
                                        }
                                    })}
                                <div>
                                    <h1>
                                        Digital Only Package                                            
                                    </h1>
                                    <h3 className="font-italic">
                                        Work out whenever you want, wherever you are.
                                    </h3>                                    
                                    <h3 className="font-italic pb-4">
                                        *No enrollments fee for digital only
                                    </h3>
                                </div>
                                <DigitalTier isRadioBtnHidden={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export { TierTabs };
