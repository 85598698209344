import React, { useState } from 'react';
import moment from 'moment';
import { Icon } from '../../assets/Icons';
import { ManagePlanPaymentModal } from '../Account/ManagePlanPaymentModal';
import { ManagePaymentMethodModal } from '../Account/ManagePaymentMethods/ManagePaymentMethodModal';
import { EditAddressModal } from '../Account/EditAddressModal';
import axios from 'axios';
import { setUserInfo } from '../../context/actions/user';
import { LoadingScreen } from './LoadingScreen';
import { useUserState } from '../../context/UserContext';
import { toastNotification } from '../../utilities/toastMessage';
import { useGlobalState } from '../../context/GlobalContext';
import { getPhoneNumber } from '../../utilities/getPhoneNumber';
import { CustomerServiceHours } from './CustomerServiceHours';

const AlertMessage = () => {
  const [userState, userDispatch] = useUserState();
  const [showManagePlanPaymentModal, setShowManagePlanPaymentModal] = useState(
    false
  );
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { nextPaymentInfo } = userState;
  const [
    {
      configState: {
        client,
        com: { programCode }
      }
    }
  ] = useGlobalState();
  const [{ configState }] = useGlobalState();
  const {
    com: {
      attributes: { studioDisplay }
    }
  } = configState;

  const payOffBalance = async () => {
    setIsLoading(true);
    try {
      await axios.post('/api/prime/v2/account/createpayment');
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        `Your payment of $${Number(nextPaymentInfo.amount).toFixed(
          2
        )} has been accepted.`,
        true
      );
    } catch (err) {
      // history.push('/error', { error: 'orderSubmitFail' });
      toastNotification(
        `Your payment could not be processed at this time.`,
        false
      );
      console.log(err);
      throw err;
    } finally {
      setShowManagePlanPaymentModal(!showManagePlanPaymentModal);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="my-lg-3 mx-4 p-3 d-flex flex-column rounded danger-alert border shadow">
        <div className="d-flex flex-row">
          <span className="">
            <Icon name="exclamation-circle" width="19px" height="17px" />
          </span>
          <p
            className="font-weight-bold text-lg ml-1 m-0"
            style={{ color: '#a02020' }}
          >
            Notice
          </p>
        </div>
        <p className="font-small m-0">
          Your account shows a past due balance of
          <span className="font-weight-bold">
            {` $${Number(nextPaymentInfo.amount).toFixed(
              2
            )}, which was due on ${moment(
              nextPaymentInfo.nextPaymentDate
            ).format('MM/DD/YY')}`}
            .
          </span>{' '}
          In order to avoid any further late fees or account deactivation, we
          require payment immediately. If you feel you have reached this balance
          summarization in error, please call us at{' '}
          <a href={`tel:${getPhoneNumber().number}`}>{getPhoneNumber().text}</a>
          <CustomerServiceHours />.
        </p>
        <button
          onClick={() => setShowManagePlanPaymentModal(true)}
          className="btn align-self-end text-primary text-underline-primary"
        >
          Pay Off Existing Balance
        </button>
      </div>
      <ManagePlanPaymentModal
        showManagePlanPaymentModal={showManagePlanPaymentModal}
        setShowManagePlanPaymentModal={setShowManagePlanPaymentModal}
        setIsLoading={setIsLoading}
        setShowPaymentModal={setShowPaymentModal}
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
        submit={payOffBalance}
        title={`Checkout - $${Number(nextPaymentInfo.amount).toFixed(2)}`}
      />
      <ManagePaymentMethodModal
        setIsManagePaymentMethodModalOpen={setShowPaymentModal}
        isManagePaymentMethodModalOpen={showPaymentModal}
      />
      <EditAddressModal
        isEditAddressModalOpen={isEditAddressModalOpen}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
      />
      <LoadingScreen isLoading={isLoading} />
    </>
  );
};

export { AlertMessage };
