import React, { useEffect } from 'react';
import { useGlobalState } from '../context/GlobalContext';
import { useLocation, NavLink } from 'react-router-dom';
import { useUserState } from '../context/UserContext';

const StaticContent = () => {
  const [{ configState }] = useGlobalState();
  const [userState] = useUserState();
  const { strings } = configState;
  const {
    isEligibleForDefaultProgram,
    isEnrolledInDefaultProgram,
    isFWEnrolled,
    defaultProgram,
    paymentMethods
  } = userState;
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML =
      'var acc = document.getElementsByClassName("question"); var i; for (i = 0; i < acc.length; i++) {acc[i].addEventListener("click", function() {this.classList.toggle("active");var panel = this.nextElementSibling;if (panel.style.display === "block") {panel.style.display = "none";} else {panel.style.display = "block";}});}';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="container">
        <nav aria-label="breadcrumb" className="d-lg-block">
          <ol className="breadcrumb px-3 mb-0 bg-white d-flex  align-items-center">
            <li className="breadcrumb-item text-underline-primary active">
              <NavLink to="/" exact>
                Home
              </NavLink>
            </li>
            <li className="breadcrumb-item">
              {location.pathname === '/faq'
                ? 'Frequently Asked Questions'
                : location.pathname === '/fitness-program-agreement'
                ? !isFWEnrolled && isEligibleForDefaultProgram
                  ? 'Fitness Program Agreement'
                  : 'Fitness Program Agreement'
                : null}
              {location.pathname === '/terms-of-use' && 'Terms of Use'}
              {location.pathname === '/privacy-policy' && 'Privacy Policy'}
            </li>
          </ol>
        </nav>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html:
            location.pathname === '/faq'
              ? strings.faq
              : location.pathname === '/fitness-program-agreement'
              ? strings.program_policy
              : location.pathname === '/terms-of-use'
              ? strings.terms_of_use
              : location.pathname === '/privacy-policy'
              ? strings.privacy_policy
              : location.pathname === '/privacy-policy'
              ? strings.terms_of_use_and_privacy_policy_for_studios
              : location.pathname === '/privacy-policy-app'
              ? strings.privacy_policy_for_app
              : null
        }}
      />
      {isEligibleForDefaultProgram && !isEnrolledInDefaultProgram && (
        <div className="hero-s1-button-div">
          <NavLink
            to={{
              pathname:
                defaultProgram?.currentSubscription &&
                defaultProgram?.isCancelled
                  ? '/account/manage-plans'
                  : '/enroll/select-packages',
              showEditPlan:
                !isEnrolledInDefaultProgram &&
                !(!paymentMethods || paymentMethods.length == 0)
            }}
            exact
          >
            <button
              type="button"
              className="blue-btn hero-s1-button m-0 font-weight-bold"
            >
              Enroll Now{' '}
              <span className="enroll-chevron">
                <i className="fas fa-chevron-right" />
              </span>
            </button>
          </NavLink>
        </div>
      )}
    </React.Fragment>
  );
};

export { StaticContent };
