import React, { useState } from 'react';
import { fullCancelSubscription } from '../../api/payments';
import { setUserInfo } from '../../context/actions/user';
import { useUserState } from '../../context/UserContext';
import { toastNotification } from '../../utilities/toastMessage';
import { ModalContainer } from '../Global/Modal';
import { useGlobalState } from '../../context/GlobalContext';
import moment from 'moment';

export const DeleteMemberModal = ({ user, isOpen, setIsOpen, date }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userState, userDispatch] = useUserState();
  const [
    {
      configState: {
        client,
        com: {
          programCode,
          entitlements: { isDependentAccessLinked }
        }
      }
    }
  ] = useGlobalState();

  const handleDeleteDependent = async () => {
    setIsLoading(true);
    try {
      await fullCancelSubscription(userState, user);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        `The subscription for ${user.firstName} ${user.lastName} has been successfully cancelled.`,
        true
      );
    } catch (error) {
      toastNotification(
        `The subscription for ${user.firstName} ${user.lastName} could not be cancelled.`,
        false
      );
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  const handleDeletePrimary = async () => {
    setIsLoading(true);
    try {
      await fullCancelSubscription(userState, user);
      await setUserInfo(programCode, client, userDispatch);
      toastNotification(
        `The subscription for ${user.firstName} ${user.lastName} has been successfully cancelled.`,
        true
      );
    } catch (error) {
      toastNotification(
        `The subscription for ${user.firstName} ${user.lastName} could not be cancelled.`,
        false
      );
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {user?.isPrimary ? (
        <ModalContainer
          setIsOpen={setIsOpen}
          title="Confirm Cancellation"
          isOpen={isOpen}
          cancelText="Discard Cancellation"
          submitText="Yes, Cancel Subscription"
          submit={handleDeletePrimary}
          isLoading={isLoading}
          isDismissable={false}
          
        >
          <div className="px-3">
            <p className="p-0">
              Would you like to permanently cancel the gym subscription of{' '}
              <b>{user && `${user.firstName} ${user.lastName}`}</b>? Cancellation
              will become effective at the end of this billing period.
            </p>
            {isDependentAccessLinked ? (
              <p>
                <b>Please note:</b> If family members have been added to your
                gym subscription, canceling your subscription as the primary
                member will cancel all family member subscriptions.
              </p>
            ) : (
              <p>
                <b>Please note:</b> If family members have been added to your
                gym subscription, canceling your subscription as the primary
                member will not affect their subscriptions.
              </p>
            )}
          </div>
        </ModalContainer>
      ) : (
        <ModalContainer
          setIsOpen={setIsOpen}
          title="Confirm Cancellation"
          isOpen={isOpen}
          cancelText="Discard Cancellation"
          submitText="Yes, Cancel Subscription"
          submit={handleDeleteDependent}
          isLoading={isLoading}
          isDismissable={false}
          // loaderText={`Removing ${dependent && dependent.firstName} ${dependent &&
          //   dependent.lastName} has been successfully cancelled.`}
        >
          <div className="mt-4 px-4">
            <p className="p-0">
              Would you like to permanently cancel the gym subscription of{' '}
              <b>{user && `${user.firstName} ${user.lastName}`}</b>? Canceling a
              subscription will remove this member from your family bundle,
              effective the end of this billing period.
            </p>
          </div>
        </ModalContainer>
      )}
    </>
  );
};
