export const initialLocationState = {
  studioTypes: [
    'Aerial',
    'Barre',
    'Bootcamp',
    'Boxing/Kickboxing',
    'Circuit Training',
    'Crossfit',
    'Cycling',
    'Dance',
    'Gymnastics',
    'Interval Training',
    'Martial Arts',
    'Outdoor',
    'Personal Training',
    'Pilates',
    'Pole Fitness',
    'Rock Climbing',
    'Sports',
    'Tai Chi',
    'Weight Training',
    'Yoga'
  ],
  amenityCategories: [
    'Cardiovascular Equipment',
    'Resistance Equipment',
    'Pool',
    'Hot Tub/Whirlpool',
    'Group Exercise',
    'Recreational Activities'
  ],
  genders: ["Women's", 'Coed'],
  locationTypes: ['gym', 'studio'],
  chosenLocationType: null,
  chosenStudioType: null,
  chosenGender: null,
  chosenAmenities: null,
  chosenDistance: 20,
  chosenLocation: null,
  gymList: null,
  loadingLocations: false,
  studioList: null,
  loadingStudios: false,
  visibleGyms: null,
  visibleStudios: null,
  currentMapLocation: null,
  currentUserLocation: null,
  mapData: null
};
