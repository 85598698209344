import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useGlobalState } from '../../../context/GlobalContext';
import { chooseLocation } from '../../../context/actions/location';
import { Icon } from '../../../assets/Icons';
import { useUserState } from '../../../context/UserContext';

const LocationListItem = ({
  item,
  setHoveredLocation,
  locationHovered,
  name,
  address1,
  city,
  state,
  zipCode,
  mileDistance
}) => {
  const [{}, dispatch] = useGlobalState();
  const [userState] = useUserState();
  const {
    defaultProgram: { currentHomeGym }
  } = userState;
  const [{ configState }] = useGlobalState();
  const { cms } = configState;


  const handleClick = () => {
    window.scrollTo(0, 0);
    dispatch(chooseLocation(item));
  };

  return (
    <div
      className={classNames('mt-2', 'card', 'card--shadow', {
        'card-hovered': locationHovered
      })}
      onMouseEnter={() =>
        setHoveredLocation ? setHoveredLocation(item) : null
      }
      onMouseLeave={() => (setHoveredLocation ? setHoveredLocation({}) : null)}
      onClick={() => handleClick()}
    >
      <div className="card-body p-2">
        <div className="d-flex justify-content-between align-middle mb-1">
          <h6 className="card-title m-0">{name}</h6>
          <div className="d-flex flex-column">
            {item.isHomeLocationTier && (
              <i className="m-0 badge secondary-color p-0 text-right mb-1">
                <Icon
                  name="elite-pin"
                  width="14px"
                  height="14px"
                  className="mr-1"
                  fill={cms.secondary_color}
                ></Icon>
                Elite
              </i>
            )}
            {currentHomeGym && currentHomeGym.id === item.id && (
              <div className="home-gym-tag-loc">
                <i className="badge primary-color ">
                  <Icon
                    name="home-gym"
                    width="14px"
                    height="14px"
                    className="mr-1"
                    fill={cms.primary_color}
                  ></Icon>
                  Home Gym
                </i>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <p className="font-small m-0">
            {`${address1}`} <br />
            {`${city}, ${state} ${zipCode}`}
          </p>
          <p className="font-small text-muted m-0">
            {Math.round(mileDistance * 100) / 100} Miles
          </p>
        </div>
      </div>
    </div>
  );
};

LocationListItem.propTypes = {
  chooseLocation: PropTypes.func,
  item: PropTypes.object
};

export { LocationListItem };
