import React from 'react';
import classNames from 'classnames';

const AddressSuggestions = ({
  isOpen,
  validField,
  getItemProps,
  highlightedIndex,
  addressType,
  smartyStreetsSuggestions
}) => {
  return (
    <>
      {isOpen && (
        <div
          className="autocomplete-dropdown-container shadow"
          style={{ left: '15px' }}
        >
          {smartyStreetsSuggestions &&
            smartyStreetsSuggestions.length > 0 &&
            smartyStreetsSuggestions.map((item, index) => (
              <div
                {...getItemProps({ item: item })}
                key={item.streetLine}
                className={classNames({
                  'suggestion-item--active': highlightedIndex === index,
                  'suggestion-item': highlightedIndex !== index
                })}
              >
                {`${item.streetLine} ${item.secondary} ${item.city} ${item.state} ${item.zipcode} `}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export { AddressSuggestions };
