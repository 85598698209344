export const initialConfigState = {
  client: null,
  com: null,
  strings: null,
  loadingCom: false,
  loadingCMS: false,
  loadingSite: true,
  authUrl: null
  // test: {
  //   clientName: 'Health Care Service Corporation',
  //   rules: {
  //     age: {
  //       buyerMember: 16,
  //       consumerNonbuyer: 13,
  //       familyMember: 16
  //     },
  //     familyMembership: true,
  //     gyms: true,
  //     studios: true,
  //     homeGymSelection: true,
  //     wholeHealth: false
  //   },
  //   pageConfigurations: {
  //     pages: [
  //       { name: 'Landing Page', component: 'Home', path: '/' },
  //       { name: 'Location Search', component: 'Location', path: '/locations' },
  //       { name: 'Enrollment', component: 'SignUp', path: '/enroll' },
  //       { name: 'Account', component: 'Account', path: '/account' }
  //     ],
  //     enrollmentFlow: {
  //       pages: [
  //         {
  //           name: 'Select Packages',
  //           components: 'SelectPackages',
  //           path: '/enroll/select-packages'
  //         },
  //         {
  //           name: 'SigUp FitnessWorks',
  //           components: 'SignUpFW',
  //           path: '/enroll'
  //         },
  //         {
  //           name: 'Confirmation',
  //           components: 'Confirmation',
  //           path: '/enroll/confirmation'
  //         },
  //         {
  //           name: 'Cart Summary',
  //           components: 'TwoColumnSummary',
  //           path: '/enroll/:subpage'
  //         },
  //         {
  //           name: '',
  //           component: 'MemberSummary',
  //           path: '/enroll/cart-summary'
  //         },
  //         {
  //           name: 'Billing Details',
  //           component: 'BillingDetails',
  //           path: '/enroll/billing-details'
  //         },
  //         {
  //           name: 'Payment',
  //           component: 'Payment',
  //           path: '/enroll/payment-method'
  //         }
  //       ],
  //       pageOrder: [
  //         'Select Package',
  //         'Member Summary',
  //         'Billing Info',
  //         'Payment'
  //       ],
  //       fields: {}
  //     },
  //     account: {
  //       pages: [
  //         { name: 'Overview', component: 'AccountOverview', path: '/account/' },
  //         {
  //           name: 'Edit Profile',
  //           component: 'EditProfile',
  //           path: '/account/edit-profile'
  //         },
  //         {
  //           name: 'Manage Plans',
  //           component: 'ManagePlans',
  //           path: '/account/manage-plans'
  //         },
  //         {
  //           name: 'Billing and Payment',
  //           component: 'BillingAndPayment',
  //           path: '/account/billing-and-payment'
  //         },
  //         {
  //           name: 'Activity Log',
  //           component: 'ActivityLog',
  //           path: '/account/activity'
  //         },
  //         {
  //           name: 'Referrals',
  //           component: 'Referrals',
  //           path: '/account/referrals'
  //         }
  //       ]
  //     }
  //   }
  // }
};
