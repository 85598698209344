import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  MemberSummary,
  BillingDetails,
  Payment,
  SelectPackages
} from './EnrollmentSteps';
import { EnrollmentFooter, EnrollmentNavBar } from './EnrollmentNav';
import { CartSummary } from './CartSummary';
import {
  isDependentTierInvalid,
  validateMemberEmail
} from '../../utilities/enrollmentValidation';
import { useEnrollmentState } from '../../context/EnrollmentContext';
import { useGlobalState } from '../../context/GlobalContext';
import { EnrollmentStep } from './EnrollmentStep';
import { SelectPackageMap } from './EnrollmentSteps/SelectPackages';
import {
  setCartSummary,
  setDisableFooterButton,
  setLoading
} from '../../context/actions/enrollment';
import { validateAddress } from '../../utilities';
import { processOrder } from '../../api/payments';
import { useUserState } from '../../context/UserContext';
import { publishEvent } from '@tivityhealth/events-core';

const TwoColumnSummary = () => {
  const location = useLocation();
  const history = useHistory();
  const [{ configState, tierState }] = useGlobalState();
  const [userState] = useUserState();
  const [enrollmentState, enrollmentDispatch] = useEnrollmentState();
  const {
    chosenDependents,
    primarySelectedPackage,
    acceptedProgramPolicy,
    acceptedTermsPolicy
  } = enrollmentState;

  const [step, setStep] = useState(1);
  const [validatingAddress, setValidatingAddress] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [shippingAddressError, setShippingAddressError] = useState({});
  const [billingAddressError, setBillingAddressError] = useState({});
  const [isMailingSameAsBilling, setIsMailingSameAsBilling] = useState(true);
  const [
    isAddressValidationErrorOpen,
    setIsAddressValidationErrorOpen
  ] = useState(false);
  const { digitalTier } = tierState;

  const {
    client,
    com: {
      entitlements: { familyMembership, isDependentAccessLinked },
      programCode
    }
  } = configState;

  const enrollmentSteps = [
    {
      step: 1,
      name: 'Package',
      link: 'select-packages',
      validation: true
    },
    {
      step: 2,
      name: 'Members',
      link: 'cart-summary',
      validation: primarySelectedPackage
    },
    {
      step: 3,
      name: 'Address',
      link: 'billing-details',
      validation:
        primarySelectedPackage &&
        acceptedProgramPolicy &&
        acceptedTermsPolicy &&
        validateMemberEmail(userState.email, chosenDependents) &&
        (isDependentAccessLinked
          ? true
          : !isDependentTierInvalid(
              chosenDependents,
              primarySelectedPackage,
              digitalTier
            ))
    },
    {
      step: 4,
      name: 'Checkout',
      link: 'payment-method',
      validation:
        primarySelectedPackage &&
        acceptedProgramPolicy &&
        acceptedTermsPolicy &&
        validateMemberEmail(userState.email, chosenDependents) &&
        (isDependentAccessLinked
          ? true
          : !isDependentTierInvalid(
              chosenDependents,
              primarySelectedPackage,
              digitalTier
            )) &&
        !!shippingAddress &&
        !shippingAddressError &&
        !!billingAddress &&
        !billingAddressError
    }
  ];

  useEffect(() => {
    setStep(getStep());
  }, [location.pathname]);

  const getStep = () => {
    switch (location.pathname) {
      case '/enroll/select-packages':
        return 1;
      case '/enroll/cart-summary':
        return 2;
      case '/enroll/billing-details':
        return 3;
      case '/enroll/payment-method':
        return 4;
      default:
        return 1;
    }
  };

  const redirectStep = () => {
    switch (step) {
      case 1:
        return 1;
      case 2:
        return primarySelectedPackage ? 2 : 1;
      case 3:
        return primarySelectedPackage &&
          acceptedProgramPolicy &&
          acceptedTermsPolicy &&
          validateMemberEmail(userState.email, chosenDependents) &&
          (isDependentAccessLinked
            ? true
            : !isDependentTierInvalid(
                chosenDependents,
                primarySelectedPackage,
                digitalTier
              ))
          ? 3
          : 2;
      case 4:
        return primarySelectedPackage &&
          acceptedProgramPolicy &&
          acceptedTermsPolicy &&
          validateMemberEmail(userState.email, chosenDependents) &&
          (isDependentAccessLinked
            ? true
            : !isDependentTierInvalid(
                chosenDependents,
                primarySelectedPackage,
                digitalTier
              )) &&
          !!shippingAddress &&
          !shippingAddressError &&
          !!billingAddress &&
          !billingAddressError
          ? 4
          : 3;
      default:
        return 1;
    }
  };

  const getOrderPreview = async (itemChanged = null) => {
    try {
      enrollmentDispatch(setLoading('loadingCartSummary', true));
      const cartSummaryData = await processOrder(
        isDependentAccessLinked,
        programCode,
        enrollmentDispatch,
        {
          ...userState,
          ...(shippingAddress?.zip !== '' && { shippingAddress }),
          ...(billingAddress?.zip !== '' && { billingAddress })
        },
        itemChanged ? { ...enrollmentState, ...itemChanged } : enrollmentState,
        false,
        false,
        ''
      );
      // console.log(cartSummaryData);
      if (cartSummaryData) {
        if (cartSummaryData.cartSummary) {
          enrollmentDispatch(setCartSummary(cartSummaryData.cartSummary));
          enrollmentDispatch(setLoading('loadingCartSummary', false));
        } else {
          enrollmentDispatch(setCartSummary(cartSummaryData));
          enrollmentDispatch(setLoading('loadingCartSummary', false));
        }
      }
    } catch (error) {
      enrollmentDispatch(setCartSummary(null));
      enrollmentDispatch(setLoading('loadingCartSummary', false));
      console.log(error);
      throw error;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <EnrollmentStep
            title="What fitness package are you interested in?"
            description="Please confirm your selection below."
          >
            <SelectPackages getOrderPreview={getOrderPreview} />
          </EnrollmentStep>
        );
      case 2:
        return (
          <>
            {client === 'walmart' ? (
              <EnrollmentStep
                title="Members & Emails"
                description={[
                  'Please confirm your email address on file and add any family members over the age of 18. ',
                  <br />,
                  <b>Note:</b>,
                  ' You must enter an email address for each family member.'
                ]}
              >
                <MemberSummary
                  isEditingPlan={false}
                  getOrderPreview={getOrderPreview}
                />
              </EnrollmentStep>
            ) : (
              <EnrollmentStep
                title="Members & Emails"
                description={
                  familyMembership
                    ? 'Please confirm the email address on file for the primary member and add any family members. Note: You must enter an email address for each family member. Remember, gym package family bundles unlock special discounts!'
                    : 'Please confirm your email address'
                }
              >
                <MemberSummary
                  isEditingPlan={false}
                  getOrderPreview={getOrderPreview}
                />
              </EnrollmentStep>
            )}
          </>
        );
      case 3:
        return (
          <>
            {client === 'walmart' ? (
              <EnrollmentStep
                title="Account Address"
                description="Please enter your address below to complete your account setup."
              >
                <BillingDetails
                  skipValidation={skipValidation}
                  validatingAddress={validatingAddress}
                  isAddressValidationErrorOpen={isAddressValidationErrorOpen}
                  setIsAddressValidationErrorOpen={
                    setIsAddressValidationErrorOpen
                  }
                  shippingAddress={shippingAddress}
                  billingAddress={billingAddress}
                  setBillingAddress={setBillingAddress}
                  setShippingAddress={setShippingAddress}
                  shippingAddressError={shippingAddressError}
                  billingAddressError={billingAddressError}
                  isMailingSameAsBilling={isMailingSameAsBilling}
                  setIsMailingSameAsBilling={setIsMailingSameAsBilling}
                  onAddressChange={onAddressChange}
                />
              </EnrollmentStep>
            ) : (
              <EnrollmentStep
                title="Home Address"
                description="Please enter your address below to complete your account setup. If you're signing up for studio access only, there's no enrollment fee, but we'll need your payment information and billing details for the pay-as-you-go classes."
              >
                <BillingDetails
                  skipValidation={skipValidation}
                  validatingAddress={validatingAddress}
                  isAddressValidationErrorOpen={isAddressValidationErrorOpen}
                  setIsAddressValidationErrorOpen={
                    setIsAddressValidationErrorOpen
                  }
                  shippingAddress={shippingAddress}
                  billingAddress={billingAddress}
                  setBillingAddress={setBillingAddress}
                  setShippingAddress={setShippingAddress}
                  shippingAddressError={shippingAddressError}
                  billingAddressError={billingAddressError}
                  isMailingSameAsBilling={isMailingSameAsBilling}
                  setIsMailingSameAsBilling={setIsMailingSameAsBilling}
                  onAddressChange={onAddressChange}
                />
              </EnrollmentStep>
            )}
          </>
        );
      case 4:
        return (
          <EnrollmentStep
            title="Add Payment Method "
            description={
              primarySelectedPackage === 'studio'
                ? 'No charges will be applied at this time. Entering your payment method now is required simply to make it more convenient for you to pay when you book a class.'
                : 'How would you like to pay? Choose your preferred monthly payment method.'
            }
          >
            <Payment
              shippingAddress={shippingAddress}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              setShippingAddress={setShippingAddress}
            />
          </EnrollmentStep>
        );
      default:
        return (
          <EnrollmentStep
            title="What fitness package are you interested in?"
            description="Please confirm your selection below."
          >
            <SelectPackages getOrderPreview={getOrderPreview} />
          </EnrollmentStep>
        );
    }
  };

  const publishEmailCapture = async email => {
    // let productType;
    // if (primarySelectedPackage &&
    //     primarySelectedPackage.recurringRatePlanChargeId ===
    //     digitalTier[0].recurringRatePlanChargeId)
    //     {
    //       productType = "digital";
    //     }
    // else {
    //       productType = "gym";
    //     }
    try {
      publishEvent({
        namespace: 'tivityhealth.com/consumer',
        kind: 'EligibilityConfirmed',
        attributes: {
          email: email,
          client: client,
          // program: programCode
          product: 'gym'
        }
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const continueStep = async () => {
    if (primarySelectedPackage) {
      if (step === 1) {
        setStep(step + 1);
        history.push('/enroll/cart-summary');
      } else if (step === 2) {
        if (
          validateMemberEmail(userState.email, chosenDependents) &&
          (isDependentAccessLinked
            ? true
            : !isDependentTierInvalid(
                chosenDependents,
                primarySelectedPackage,
                digitalTier
              ))
        ) {
          publishEmailCapture(userState.email);
          setStep(step + 1);
          history.push('/enroll/billing-details');
        }
      } else if (step === 3) {
        validateAddress(
          isMailingSameAsBilling,
          setValidatingAddress,
          enrollmentDispatch,
          shippingAddress,
          billingAddress,
          setStep,
          history,
          getOrderPreview,
          setIsAddressValidationErrorOpen
        );
      }
    }
  };

  const compareAddressToDefault = address => {
    if (!address) return false;
    return (
      Object.entries(address).toString() ===
      Object.entries({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      }).toString()
    );
  };

  useEffect(() => {
    if (
      !compareAddressToDefault(shippingAddress) &&
      (!isMailingSameAsBilling
        ? !compareAddressToDefault(billingAddress)
        : true)
    ) {
      if (
        Object.keys(shippingAddressError).length > 0 ||
        (!isMailingSameAsBilling && Object.keys(billingAddressError).length > 0)
      ) {
        enrollmentDispatch(setDisableFooterButton(true));
      } else {
        enrollmentDispatch(setDisableFooterButton(false));
      }
    }
  }, [
    shippingAddress,
    shippingAddressError,
    billingAddress,
    billingAddressError
  ]);

  const onAddressChange = addressData => {
    const { values, touched, errors, addressType } = addressData;

    if (isMailingSameAsBilling) {
      if (values) {
        setShippingAddress(values);
        setBillingAddress(values);
      }
      if (Object.keys(errors).length > 0) {
        setShippingAddressError(errors);
        setBillingAddressError(errors);
      } else if (
        Object.keys(errors).length === 0 &&
        Object.keys(touched).length > 0
      ) {
        setShippingAddressError({});
        setBillingAddressError({});
      }
    } else {
      if (values) {
        if (addressType === 'shippingAddress') {
          setShippingAddress(values);
        } else if (addressType === 'billingAddress') {
          setBillingAddress(values);
        }
      }
      if (Object.keys(errors).length > 0) {
        if (addressType === 'shippingAddress') {
          setShippingAddressError(errors);
        } else if (addressType === 'billingAddress') {
          setBillingAddressError(errors);
        }
      } else {
        if (addressType === 'shippingAddress') {
          setShippingAddressError({});
        } else if (addressType === 'billingAddress') {
          setBillingAddressError({});
        }
      }
    }
  };

  const skipValidation = async () => {
    try {
      await getOrderPreview();
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setValidatingAddress(false);
      setStep(prevState => prevState++);
      history.push('/enroll/payment-method');
    }
  };

  return (
    <>
      <EnrollmentNavBar enrollmentSteps={enrollmentSteps} step={step} />
      <div className="container-fluid d-lg-flex">
        <div className="col-xl-1 d-none d-xl-block" />
        {renderStep()}

        {location.pathname !== '/enroll/select-packages' ? (
          <CartSummary
            getOrderPreview={getOrderPreview}
            isEditingPlan={false}
          />
        ) : (
          <SelectPackageMap />
        )}

        <div className="col-xl-1 d-none d-xl-block" />
      </div>
      <EnrollmentFooter
        step={step}
        continueStep={continueStep}
        validatingAddress={validatingAddress}
      />
    </>
  );
};

export { TwoColumnSummary };
