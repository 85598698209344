import React, { useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { useGlobalState } from '../../../context/GlobalContext';

const ActivityFilters = ({
  selectedEndDate,
  setSelectedEndDate,
  selectedStartDate,
  setSelectedStartDate,
  applyFilters,
  clearFilter,
  activityFilterType,
  setActivityFilterType
}) => {
  const [
    {
      configState: {
        com: {
          attributes: { studioDisplay }
        }
      }
    }
  ] = useGlobalState();

  const handleChangeStartDate = e => {
    if (!e) {
      e = moment().format('l');
    }
    setSelectedStartDate(moment(e).format('l'));
  };

  const handleChangeEndDate = e => {
    if (!e) {
      e = moment()
        .add(-1, 'years')
        .format('l');
    }
    setSelectedEndDate(moment(e).format('l'));
  };

  const handleFilterActivity = type => {
    setActivityFilterType(type);
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between mb-3">
        <p className="mb-3 m-md-0 align-self-start align-self-md-center">
          Filter by
        </p>
        <p className="font-weight-bold align-self-start align-self-md-center mb-3 m-md-0">
          Date Range
        </p>
        <div className="d-flex flex-column align-self-start align-self-md-center mb-3 m-md-0">
          <p className="text-uppercase text-secondary font-weight-bold font-small m-0">
            From
          </p>
          <DatePicker
            value={new Date(selectedStartDate)}
            onChange={e => handleChangeStartDate(e)}
          />
        </div>
        <div className="d-flex flex-column align-self-start align-self-md-center mb-3 m-md-0">
          <p className="text-uppercase text-secondary font-weight-bold font-small m-0">
            To
          </p>
          <DatePicker
            value={new Date(selectedEndDate)}
            onChange={e => handleChangeEndDate(e)}
          />
        </div>
        <div className="dropdown align-self-start align-self-md-center mb-3 m-md-0">
          <button
            className="btn btn-sm btn-primary dropdown-toggle text-nowrap rounded-pill align-self-start align-self-md-center"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {activityFilterType
              ? activityFilterType === 'PL'
                ? 'Fitness Location'
                : activityFilterType
              : 'Type'}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <p
              onClick={() =>
                handleFilterActivity(activityFilterType === 'PL' ? '' : 'PL')
              }
              className={classNames('dropdown-item', 'm-0', {
                active: activityFilterType === 'PL'
              })}
            >
              Fitness Location
            </p>
            {(studioDisplay === 'Both' || studioDisplay === 'Web') && (
              <p
                onClick={() =>
                  handleFilterActivity(
                    activityFilterType === 'Studio' ? '' : 'Studio'
                  )
                }
                className={classNames('dropdown-item', 'm-0', {
                  active: activityFilterType === 'Studio'
                })}
              >
                Studio Class
              </p>
            )}
          </div>
        </div>
        <button
          className="btn btn-outline-secondary align-self-center mb-3 m-md-0"
          onClick={() => {
            applyFilters();
          }}
        >
          Apply Filter
        </button>
        <button
          className="btn btn-outline-secondary align-self-center mb-3 m-md-0"
          onClick={() => clearFilter()}
        >
          Clear Filter
        </button>
      </div>
    </>
  );
};

export { ActivityFilters };
