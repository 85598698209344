import React from 'react';
import { Icon } from '../../assets/Icons';

const WholeHealthLiving = () => {
  return (
    <div className="my-3 mx-4 d-flex">
      <div className="d-flex w-100 bg-primary rounded flex-md-row flex-column">
        <div className="d-flex align-items-center justify-content-center w-50 w-md-100">
          <Icon
            name="whole-health-living"
            width="279px"
            height="61px"
            fill="#fff"
            className="mx-md-5 mx-3 my-3 mr-2 my-md-0 my-4 fill-white"
          ></Icon>
        </div>
        <div className="d-flex flex-column white px-4 px-md-0 w-50 w-md-100">
          <h5 className="font-weight-bold mt-md-5 mb-3 white w-100">
            Get Wellness Discounts
          </h5>
          <p className="mb-2 w-100">
            Complement your fitness routine with discounts on wellness products
            and services offered by WholeHealth Living Choices.
          </p>
          <a
            target="_blank"
            href="https://www.whlchoices.com/#/register?referUrl=https://www.bcbsilforyourhealth.com/"
          >
            <button
              type="submit"
              className="btn-outline-primary align-self-start btn my-3 px-4 py-2 rounded-pill"
            >
              Learn More
              <Icon
                name="external-link"
                width="20px"
                height="20px"
                className="ml-2 mb-1 fill-primary"
              ></Icon>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export { WholeHealthLiving };
