import axios from 'axios';

const getPackages = async (programCode, clientCode) => {
  try {
    const commercePackages = await axios.get(`/api/prime/v2/enrollment`);
    const networkPackages = await axios.get(
      `/api/${clientCode}/network/programs/${programCode}`
    );
    if (commercePackages && networkPackages) {
      const commerce = commercePackages.data.data.products;

      const tiers = commerce.find(
        product => product.programCode === programCode
      ).productRatePlans;

      const digitalTier = commerce.find(
        product => product.programCode === 'prime_digital'
      );

      const network = networkPackages.data.data.tiers;
      const homeLocationTier =
        network && network.length > 0
          ? network.filter(tier => tier.isHomeLocationTier)
          : null;

      return {
        tiers,
        digitalTier: digitalTier && digitalTier.productRatePlans,
        homeLocationTier: homeLocationTier && homeLocationTier[0]
      };
    }
  } catch (error) {
    throw error;
  }
};

export { getPackages };
