import React from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

const ActivityListItem = ({ activity }) => (
  <>
    <div className="d-md-flex d-none align-items-center bg-light rounded-pill p-1 justify-content-between mb-1">
      <p className="m-0 ml-5 p-0 col">{activity.location}</p>
      <p className="m-0 p-0 col">{activity.class ? activity.class : ''}</p>
      <p className="m-0 p-0 col">
        {moment(activity.activityDate).format('MM/DD/YYYY')}
      </p>
      <p className="m-0 p-0 col">
        {moment(activity.activityTime, 'HH:mm:ss').format('LT')}
      </p>
      <p className="m-0 p-0 col">{activity.type}</p>
      <div className="mini-avatar d-flex justify-content-center align-items-center" />
    </div>
    <div className="d-flex my-4 py-4 bg-light d-md-none flex-column rounded shadow">
      <div className="d-flex flex-row justify-content-between">
        <p className="pl-4 font-weight-bold">Location:</p>
        <p className="pr-4">{activity.location}</p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <p className="pl-4 font-weight-bold">Class:</p>
        <p className="pr-4">{activity.class ? activity.class : 'Not found'}</p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <p className="pl-4 font-weight-bold">Date:</p>
        <p className="pr-4">
          {moment(activity.activityDate).format('MM/DD/YYYY')}
        </p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <p className="pl-4 font-weight-bold">Time:</p>

        <p className="pr-4">
          {moment(activity.activityTime, 'HH:mm:ss').format('LT')}
        </p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <p className="pl-4 font-weight-bold">Type</p>
        <p className="pr-4">{activity.type}</p>
      </div>
    </div>
  </>
);

export { ActivityListItem };
