import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from '../../assets/Icons';
import { useGlobalState } from '../../context/GlobalContext';
import { setMobileNavbarVisible } from '../../context/actions/user';
import { daysDifference } from '../../utilities/daysDifference';
import { mutateHeaderLinks } from '../../utilities/mutateHeaderLinks';
import { checkEligibility } from '../../utilities/checkEligibility';
import { isPast } from 'date-fns/esm';
import { useUserState } from '../../context/UserContext';
import moment from 'moment';
import classNames from 'classnames';

const Header = ({ banner }) => {
  const [headerData, setHeaderData] = useState({});
  const [{ configState }] = useGlobalState();
  const [userState, userDispatch] = useUserState();
  const { strings } = configState;
  const [bannerData, setBannerData] = useState({
    renderBanner: false,
    bannerString: null
  });
  const {
    isAuthenticated,
    termDate,
    mobileNavbarVisible,
    eligibilities,
    isEligibleForDefaultProgram,
    isEnrolledInDefaultProgram,
    isStudioEnrolled,
    defaultProgram,
    isPrimary,
    isFWEnrolled,
    paymentMethods,
    isEnrolledInDigitalTier
  } = userState;
  let location = useLocation();
  const {
    com: { uiConfig }
  } = configState;
  const { hasLandingPage } = uiConfig || {};

  useEffect(() => {
    loadLinks()
      .then(data => {
        setHeaderData({
          imgSource: data.imgSource,
          links: mutateHeaderLinks(data.returnMenu, location.pathname)
        });
      })
      .catch(error => console.log(error));
  }, [strings, userState]);

  useEffect(() => {
    setBannerData(daysDifference(termDate));
  }, []);

  useEffect(() => {
    let newCopyOfHeaderLinks = mutateHeaderLinks(
      headerData.links,
      location.pathname
    );
    setHeaderData(prevState => ({
      ...prevState,
      links: newCopyOfHeaderLinks
    }));
  }, [location.pathname]);

  const loadLinks = async () => {
    let imgSource = strings.path_to_image;
    let returnMenu = [];
    if (strings.header_menu_links) {
      let menuItems = JSON.parse(strings.header_menu_links);
      let menuOrder = menuItems.desktopOrder.split(',');

      for (var menuCategory of menuOrder) {
        if (
          (isAuthenticated && menuItems[menuCategory].desktop.isVisibleAuth) ||
          (!isAuthenticated && menuItems[menuCategory].desktop.isVisiblePublic)
        ) {
          if (
            (menuItems[menuCategory].desktop.value !== 'Learn More' &&
              checkEligibility(eligibilities)) ||
            (menuItems[menuCategory].desktop.value !== 'My Account' &&
              !checkEligibility(eligibilities))
          ) {
            returnMenu.push(
              <li key={menuCategory} className="nav-item">
                <NavLink
                  to={menuItems[menuCategory].desktop.link}
                  exact
                  className="nav-link"
                  // target="_self"
                >
                  {menuCategory === 'home' ? (
                    <img src={imgSource} />
                  ) : (
                    menuItems[menuCategory].desktop.value
                  )}
                </NavLink>
              </li>
            );
          }
        }
      }
      return { imgSource: imgSource, returnMenu: returnMenu };
    }
    return { imgSource, returnMenu: null };
  };
  return (
    <Fragment>
      {/*TODO: check term end dates 
        strings.banner_show == 'true' &&
        isEligibleForDefaultProgram &&
        !isEnrolledInDefaultProgram && (
          <div
            dangerouslySetInnerHTML={{
              __html: strings.banner
            }}
          />
        )}
      {/*TODO: check term end dates for countdown banner reenroll checkEligibility(
        eligibilities
      ) &&
        bannerData.renderBanner && (
          <div className="banner text-center">
            <p>{bannerData.bannerString}</p>
          </div>
        )*/}
      {/* remove banner variable after dec 1st */}
      {banner && strings.banner_show == 'true' && (
        <div
          dangerouslySetInnerHTML={{
            __html: strings.banner
          }}
        />
      )}

      <section className="sticky-top section-nav">
        <nav
          className="bg-white navbar-expand-lg border-bottom pl-lg-4 align-items-center d-flex bg-white nav-container"
          style={{ height: '80px' }}
        >
          <button
            className="navbar-toggler p-2 pl-4 text-nowrap"
            type="button"
            onClick={() =>
              userDispatch(setMobileNavbarVisible(!mobileNavbarVisible))
            }
            // disabled by carlo
            // data-toggle="collapse"
            // data-target="#navbarContent"
            // aria-controls="navbarContent"
            // aria-expanded="false"
            // aria-label="Toggle navigation"
          >
            {location.pathname !== '/lesmills' && (
              <div className="primary">
                {mobileNavbarVisible ? (
                  <>
                    <span className="fill-primary">
                      <Icon width="22px" height="22px" name="close-times" />{' '}
                      Close
                    </span>
                  </>
                ) : (
                  <>
                    <Icon width="18px" height="18px" name="nav" /> Menu
                  </>
                )}
              </div>
            )}
          </button>
          <NavLink
            to="/"
            exact
            className={classNames('p-4 mr-1', {
              'float-left': location.pathname == '/lesmills',
              'ml-auto': location.pathname !== '/lesmills'
            })}
          >
            {headerData.imgSource && (
              <img
                src={headerData.imgSource}
                // style={{ width: '170px', height: '30px' }}
                className="img-fluid"
              />
            )}
          </NavLink>
          <div />
          {location.pathname !== '/lesmills' && (
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarContent"
            >
              <ul className="navbar-nav float-right mr-4">
                {(isEnrolledInDefaultProgram ||
                  defaultProgram?.currentSubscription ||
                  isStudioEnrolled ||
                  isFWEnrolled) && (
                  <li className="nav-item">
                    <NavLink to="/account" exact className="nav-link">
                      My Account
                    </NavLink>
                  </li>
                )}
                {hasLandingPage && isPrimary && !isEnrolledInDefaultProgram && (
                  <li className="nav-item">
                    <NavLink to="/learn-more" exact className="nav-link">
                      Learn More
                    </NavLink>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink to="/locations" exact className="nav-link">
                    Locations
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/faq" exact className="nav-link">
                    FAQs
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                <NavLink to="/lesMills" exact className="nav-link">
                  Les Mills
                </NavLink>
              </li> */}
              </ul>
              {isPrimary &&
                !isEnrolledInDefaultProgram &&
                !isEnrolledInDigitalTier &&
                !(
                  location.pathname === '/enroll/select-packages' ||
                  location.pathname === '/enroll/cart-summary' ||
                  location.pathname === '/enroll/billing-details' ||
                  location.pathname === '/enroll/payment-method' ||
                  location.pathname === '/enroll/confirmation'
                ) && (
                  <div className="slanted-background d-flex justify-content-center pr-2">
                    <ul
                      className="navbar-nav py-2 pl-0"
                      style={{ zIndex: 9999 }}
                    >
                      <li className="nav-item">
                        <NavLink
                          to={{
                            pathname:
                              // defaultProgram?.currentSubscription &&
                              // defaultProgram?.isCancelled
                              //   ? '/account/manage-plans'
                              //   : '/enroll/select-packages',
                              defaultProgram?.currentSubscription
                                ? defaultProgram?.isCancelled
                                  ? '/account/manage-plans'
                                  : defaultProgram.isCancelling ==
                                      moment().format() &&
                                    '/enroll/select-packages'
                                : '/enroll/select-packages',
                            showEditPlan:
                              !isEnrolledInDefaultProgram &&
                              !(!paymentMethods || paymentMethods.length == 0)
                          }}
                          exact
                          className="nav-link nav-cta-enroll pl-0"
                        >
                          <button className="nav-btn-enroll blue-btn  px-3 font-weight-bold text-nowrap">
                            {/* {defaultProgram?.currentSubscription &&
                          defaultProgram?.isCancelled
                            ? 'Re-Enroll Now'
                            : 'Enroll Now'} */}
                            {defaultProgram?.currentSubscription
                              ? defaultProgram?.isCancelled
                                ? 'Re-Enroll Now'
                                : defaultProgram.isCancelling ==
                                    moment().format() && 'Enroll Now'
                              : 'Enroll Now'}
                            <span className="enroll-chevron">
                              <i className="fas fa-chevron-right" />
                            </span>
                          </button>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              {/* commented out for now */}
              {/* {(isEligibleForDefaultProgram && isEnrolledInDefaultProgram) ||
              (isStudioEligible &&
                isStudioEnrolled &&
                currentSubscriptionStatus &&
                currentSubscriptionStatus == 'Cancelled' && (
                  <div className="slanted-background d-flex justify-content-center pr-2">
                    <ul
                      className="navbar-nav py-2 pl-0"
                      style={{ zIndex: 9999 }}
                    >
                      <li className="nav-item">
                        <NavLink
                          // to={}
                          to={{
                            pathname:
                              currentSubscriptionStatus &&
                              isEnrolledInDefaultProgram
                                ? '/account/manage-plans'
                                : '/enroll/select-packages',
                            showEditPlan:
                              currentSubscriptionStatus &&
                              isEnrolledInDefaultProgram &&
                              (!paymentMethods || paymentMethods.length != 0)
                          }}
                          exact
                          className="nav-link nav-cta-enroll pl-0"
                        >
                          <button className="nav-btn-enroll blue-btn px-3 font-weight-bold text-nowrap">
                            Re-Enroll Now
                            <span className="enroll-chevron">
                              <i className="fas fa-chevron-right" />
                            </span>
                          </button>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                ))} */}
            </div>
          )}
        </nav>
      </section>
    </Fragment>
  );
};

export { Header };
