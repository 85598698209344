import React, { useEffect } from 'react';
import { LandingPageLocationSection } from '../components';
import { StudioPackageSection } from '../components/Home';
import { useGlobalState } from '../context/GlobalContext';
import { useUserState } from '../context/UserContext';

const Home = () => {
  const [{ configState }] = useGlobalState();
  const [
    {
      isEligibleForDefaultProgram,
      isPrimary,
      defaultProgram,
      isEnrolledInDefaultProgram,
      isStudioEnrolled,
      isEnrolledInDigitalTier
    }
  ] = useUserState();
  const { strings, com } = configState;

  useEffect(() => {
    [].forEach.call(document.querySelectorAll('#enrollButton'), function(a) {
      if (!isPrimary) {
        a.remove();
      } else if (
        (defaultProgram?.currentSubscription && !isEnrolledInDefaultProgram) ||
        isStudioEnrolled ||
        isEnrolledInDigitalTier
      ) {
        a.href = '/account/manage-plans';
      }
    });
    // let enrollButton = document.getElementById('enrollButton');
    // if (enrollButton) {
    //   if (!isPrimary) {
    //     enrollButton.remove();
    //   } else if (
    //     (defaultProgram?.currentSubscription && !isEnrolledInDefaultProgram) ||
    //     isStudioEnrolled ||
    //     isEnrolledInDigitalTier
    //   ) {
    //     enrollButton.href = '/account/manage-plans';
    //   }
    // }
  }, []);

  return (
      <>         
      <div dangerouslySetInnerHTML={{ __html: strings?.lp_topContent }}></div>
      {isEligibleForDefaultProgram && (
          <>
          <StudioPackageSection />                     
        </>
          )}
      {isEligibleForDefaultProgram && com.entitlements.wholeHealth && (
              <div
                  dangerouslySetInnerHTML={{
                      __html: strings?.lp_wellness_discounts
                  }}
              ></div>
          )}
          {isEligibleForDefaultProgram && (
              <>                  
                  <LandingPageLocationSection />
              </>
          )}
      <div
        dangerouslySetInnerHTML={{
          __html: strings?.lp_middle_content
        }}
      ></div>
      
      <div
        dangerouslySetInnerHTML={{
          __html: strings?.lp_bottomContent
        }}
      ></div>
    </>
  );
};

export { Home };
