import React from 'react';
import { Icon } from '../../assets/Icons';
import { downloadInvoice } from '../../api/account';
import { useGlobalState } from '../../context/GlobalContext';

const BillingHistoryItem = ({ history }) => {
  const [
    {
      configState: { client, cms }
    }
  ] = useGlobalState();
  

  return (
    <>
      <div className="d-sm-flex d-none flex-column w-100 calc-fontsize bg-light p-1 mb-1 py-2">
        <div className="d-flex w-100 justify-content-between mb-2 mt-2">
          <div className="d-flex col-12 text-center">
            <p className="m-0 p-0 col-2">
              {history.isLegacyOrder
                ? history.legacyOrderDate.split('-')[1]
                : history.invoiceDate.split('-')[1]}
              /
              {history.isLegacyOrder
                ? history.legacyOrderDate.split('-')[2]
                : history.invoiceDate.split('-')[2]}
              /
              {history.isLegacyOrder
                ? history.legacyOrderDate.split('-')[0].slice(-2)
                : history.invoiceDate.split('-')[0].slice(-2)}
            </p>
            <p className="m-0 p-0 col-4">{history.productType}</p>
            <p className="m-0 p-0 col-2">{history.paymentStatus}</p>
            <p className="m-0 p-0 col-2">${history.amount.toFixed(2)}</p>
            <div
              className="m-0 p-0 col-2 pointer"
              onClick={() =>
                downloadInvoice(
                  client,
                  history.isLegacyOrder ? history.legacyOrderId : history.fileId
                )
              }
            >
              <span className="text-primary font-weight-bold">
                <Icon
                  width="16px"
                  name="download"
                  height="26px"
                  fill='#000'
                />{' '}
                Download
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column my-4 py-4 bg-light d-sm-none justify-content-between rounded shadow">
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Date:</p>
          <p className="pr-4">
            {' '}
            {history.isLegacyOrder
              ? history.legacyOrderDate.split('-')[1]
              : history.invoiceDate.split('-')[1]}
            /
            {history.isLegacyOrder
              ? history.legacyOrderDate.split('-')[2]
              : history.invoiceDate.split('-')[2]}
            /
            {history.isLegacyOrder
              ? history.legacyOrderDate.split('-')[0].slice(-2)
              : history.invoiceDate.split('-')[0].slice(-2)}
          </p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Description:</p>
          <p className="pr-4">{history.productType}</p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Status:</p>
          <p className="pr-4">{history.paymentStatus}</p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Amount:</p>
          <p className="pr-4">${history.amount.toFixed(2)}</p>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <p className="pl-4 font-weight-bold">Invoice doc (PDF)</p>
          <div
            className="m-0 p-0 pointer pr-4"
            onClick={() =>
              downloadInvoice(
                client,
                history.isLegacyOrder ? history.legacyOrderId : history.fileId
              )
            }
          >
            <span className="text-primary font-weight-bold">
              <Icon width="16px" name="download" height="26px" fill={cms.primary_color} />{' '}
              Download
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export { BillingHistoryItem };
