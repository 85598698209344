import React, { createContext, useContext, useReducer } from 'react';
import { userReducer } from './reducers/user';
import { initialUserState } from './state/initialUserState';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const user = useReducer(userReducer, initialUserState);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUserState = () => useContext(UserContext);
