import React from 'react';

const ManageStudioProgram = ({ user }) => {
  const { firstName, lastName } = user;

  return (
    <>
      <div className="list-group-item w-100 d-xl-flex d-inline-block flex-xl-row flex-column">
        <div className="align-self-center pr-4">
          <h5 className="m-0 font-weight-bold">{`${firstName}`}</h5>
          <h5 className="m-0 font-weight-bold">{`${lastName}`}</h5>
        </div>
        <div className="align-self-center pr-4">
          <div className="align-self-center px-xl-3 border-xl-left">
            <p className="font-weight-bold font-small text-uppercase text-secondary m-0">
              Current Package
            </p>
            <h5 className="m-0 font-weight-bold nowrap-responsive">
              Studio Class Loyalty Program
            </h5>
            <p className="m-0 font-small text-dark-gray">Pay-as-you-go</p>
          </div>
        </div>
      </div>
    </>
  );
};

export { ManageStudioProgram };
