import React, { useState, useEffect } from 'react';
import { getIframe } from '../../api/payments';
import { useEnrollmentState } from '../../context/EnrollmentContext';
import { useUserState } from '../../context/UserContext';
import { LoadingSpinner } from './LoadingSpinner';
import { ModalContainer } from './Modal';

const PaymentIframe = ({
  showPaymentIframe,
  toggleShowPaymentIframe,
  toggleProcessingPayment,
  setPaymentToken,
  isProcessing,
  billingAddress
}) => {
  const [{ selectedPaymentMethod }] = useEnrollmentState();
  const [userState] = useUserState();
  const { accountId, firstName, lastName } = userState;
  const [openModal, setOpenModal] = useState(false);

  let prePopulatedFields = {
    creditCardAddress1: (billingAddress && billingAddress.address1) || '',
    creditCardAddress2: (billingAddress && billingAddress.address2) || '',
    creditCardState: (billingAddress && billingAddress.state) || '',
    creditCardCity: (billingAddress && billingAddress.city) || '',
    creditCardPostalCode: (billingAddress && billingAddress.zip) || '',
    creditCardHolderName:
      (firstName && lastName && `${firstName} ${lastName}`) || ''
  };

  let props = {
    tenantId: 30222,
    id: null,
    token: null,
    signature: null,
    style: 'inline',
    key: null,
    submitEnabled: true,
    locale: 'English (en)',
    param_supportedTypes: 'AmericanExpress,Visa,MasterCard,Discover',
    url: null,
    paymentGateway: null,
    field_accountId: accountId && accountId
  };

  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    if (!iframeLoading) {
      setIframeLoading(true);
    }
    getIframe(selectedPaymentMethod)
      .then(data => {
        let newProps = { ...props, ...data };
        window.Z.render(newProps, prePopulatedFields, callback);
        if (!showPaymentIframe) toggleShowPaymentIframe(true);
        setIframeLoading(false);
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }, [selectedPaymentMethod]);

  const forwardCallbackURL = response => {
    var callbackUrl = window.location.origin;
    for (var id in response) {
      callbackUrl =
        callbackUrl + id + '=' + encodeURIComponent(response[id]) + '&';
    }
    window.location.replace(callbackUrl);
  };

  const callback = response => {
    if (!response.success) {
      // toastNotification('Your payment method could not be added.', false);
      forwardCallbackURL(response);
    } else {
      setPaymentToken(response.refId);
      // toastNotification(
      //   `Your payment method has been successfully added.`,
      //   true
      // );
      toggleProcessingPayment(true);
    }
  };
  // console.log(iframeLoading);

  const ZuoraSumbit = () => {
    window.open('https://www.zuora.com/', '_blank');
    setOpenModal(false);
  };

  return (
    <>
      <div id="stopRecording">
        <div
          style={
            iframeLoading || isProcessing
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          <LoadingSpinner />
        </div>
        <div
          style={
            iframeLoading || isProcessing
              ? { display: 'none' }
              : { display: '' }
          }
        >
          <div id="zuora_payment" style={{ paddingTop: '10px' }}></div>
          <p className="m-0 p-0">
            Powered by{' '}
            <button className="zuoraButton" onClick={() => setOpenModal(true)}>
              Zuora
            </button>
          </p>
        </div>
      </div>
      {openModal && (
        <ModalContainer
          title="You’re heading to Zuora.com"
          setIsOpen={setOpenModal}
          isOpen={openModal}
          cancelText="Stay Here"
          submitText="Visit Zuora"
          submit={ZuoraSumbit}
          zuora={false}
        >
          <h3 className="zuoraTitle">
            <b>You're heading to Zuora.com</b>{' '}
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setOpenModal(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </h3>
          <div className="zuoraBorderTop"></div>
          <p className="zuoraBody">
            You’re leaving Well onTarget Fitness Program and being redirected to
            our friends at Zuora.com
          </p>
          <div />
          <p className="zuoraLegal">
            Please note that Zuora.com is not owned, operated or controlled by
            Tivity Health, inc. Or it’s affiliates. By visiting Zuora.com, you
            will be subject to their terms of use and privacy policy
          </p>
          <div className="zuoraBorderBottom"></div>
        </ModalContainer>
      )}
    </>
  );
};

export { PaymentIframe };
