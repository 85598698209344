import React from 'react';
import moment from 'moment';
import { Icon } from '../../assets/Icons';
import { getPhoneNumber } from '../../utilities/getPhoneNumber';
import { useUserState } from '../../context/UserContext';

const CancelledDependent = ({ program }) => {
  const [
    { isEnrolledInDigitalTier, defaultProgram, digitalProgram }
  ] = useUserState();
  return (
    <div
      className={
        'd-flex shadow card mb-2 p-3 border-0 my-lg-3 mx-4 justify-content-between suspended-warning py-4'
      }
    >
      <p className="suspended-warning__title px-4">
        <span className="suspended-warning__icon">
          <Icon
            name="exclamation-triangle"
            width="19px"
            height="17px"
            className="suspended-warning__icon"
          />
        </span>
        Attention
      </p>
      <p className="font-small m-0 px-4">
        You will no longer have access to{' '}
        {!defaultProgram.isActive &&
        (isEnrolledInDigitalTier ? digitalProgram?.isActive : false)
          ? 'your gym package'
          : 'the program'}{' '}
        effective{' '}
        <b>{`${moment(program?.memberTermDate).format('MM/DD/YY')}`}</b>, as it
        appears your subscription was cancelled by the primary member in your
        family bundle.{' '}
        {!defaultProgram.isActive &&
        (isEnrolledInDigitalTier ? digitalProgram?.isActive : false)
          ? 'You still have access to our Digital Content. '
          : 'You may re-enroll in the program as a primary member after this effective date. '}
        If you have any questions, please call{' '}
        <a href={`tel:${getPhoneNumber().number}`}>{getPhoneNumber().text}</a>.
      </p>
    </div>
  );
};

export { CancelledDependent };
