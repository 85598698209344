import React, { useState } from 'react';
import { Icon } from '../../../assets/Icons';
import { downloadCard, printCard } from '../../../api/account';
import { ManageHomeGym } from './ManageHomeGym';
import { useGlobalState } from '../../../context/GlobalContext';
import { useUserState } from '../../../context/UserContext';
import { DiscardModal } from '../DiscardChangeModal';
import { ManageDefaultProgram } from './ManageDefaultProgram';
import { ManageDigitalProgram } from './ManageDigitalProgram';
import { ManageStudioProgram } from './ManageStudioProgram';

const ManagePlanFitnessPackage = ({
  user,
  setIsCancelSubscriptionModalOpen,
  setSelectedUser,
  setShowLocationSearchModal,
  showLocationSearchModal,
  loggedInUser
}) => {
  const {
    isStudioEnrolled,
    isDependentToMember,
    consumerId,
    isEnrolledInDigitalTier,
    defaultProgram,
    digitalProgram,
    isEnrolledInDefaultProgram
  } = user;
  const [{ tierState, configState }] = useGlobalState();
  const { com, cms } = configState;
  const [userState] = useUserState();
  const [{ state }] = useUserState();
  const { homeLocationTier } = tierState;
  const { nextPaymentInfo, isPrimary } = userState;
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false);
  const [isDigitalDiscardModalOpen, setDigitalDiscardModalOpen] = useState(
    false
  );
  const [isDowngradeDiscardModalOpen, setDowngradeDiscardModalOpen] = useState(
    false
  );
  const {
    com: {
      attributes: { studioDisplay }
    }
  } = configState;

  const handleDownload = () => {
    downloadCard(consumerId);
  };

  const handlePrint = () => {
    printCard(consumerId);
  };

  const showSelfCancellationButton =
    (com?.statesAllowingSelfCancellation?.includes(state?.toUpperCase()) &&
      com?.entitlements?.allowMemberSelfCancellation) ??
    false;

  return (
    <>
      <div className="my-3 list-group">
        {!defaultProgram?.isCancelled && defaultProgram?.currentSubscription ? (
          <>
            <ManageDefaultProgram
              loggedInUser={loggedInUser}
              user={user}
              setDowngradeDiscardModalOpen={setDowngradeDiscardModalOpen}
              setDiscardModalOpen={setDiscardModalOpen}
              setDigitalDiscardModalOpen
            />

            {(isPrimary || loggedInUser) &&
              homeLocationTier &&
              defaultProgram?.tier == homeLocationTier.number && (
                <ManageHomeGym
                  setShowLocationSearchModal={setShowLocationSearchModal}
                  showLocationSearchModal={showLocationSearchModal}
                  user={user}
                />
              )}

            {isEnrolledInDigitalTier && (
              <ManageDigitalProgram
                isDigitalOnly={false}
                user={user}
                setDigitalDiscardModalOpen={setDigitalDiscardModalOpen}
                loggedInUser={loggedInUser}
              />
            )}
          </>
        ) : isStudioEnrolled && !isEnrolledInDigitalTier ? (
          <ManageStudioProgram user={user} />
        ) : (
          isEnrolledInDigitalTier && (
            <ManageDigitalProgram
              isDigitalOnly={true}
              user={user}
              setDigitalDiscardModalOpen={setDigitalDiscardModalOpen}
              loggedInUser={loggedInUser}
            />
          )
        )}
        {(isEnrolledInDefaultProgram || isEnrolledInDigitalTier) && (
          <>
            <div className="list-group-item w-100 bg-light d-xl-flex d-inline-block flex-lg-row flex-column">
              {defaultProgram?.isActive && (
                <>
                  <button
                    className="btn btn-outline-secondary align-self-center mr-4 px-3 mb-2"
                    onClick={handleDownload}
                  >
                    <span className="fill-primary">
                      <Icon
                        width="16px"
                        name="download"
                        height="16px"
                        className="mr-2"
                        fill={cms.primary_color}
                      />
                    </span>
                    Download Card
                  </button>
                  <button
                    className="btn btn-outline-secondary align-self-center px-4 mb-2"
                    onClick={handlePrint}
                  >
                    <span className="fill-primary">
                      <Icon
                        name="print"
                        width="16px"
                        height="16px"
                        className="mr-2"
                      />
                    </span>
                    Print Card
                  </button>
                </>
              )}
              {isPrimary &&
                ((!loggedInUser && isDependentToMember) ||
                  (loggedInUser && showSelfCancellationButton)) &&
                (isEnrolledInDefaultProgram
                  ? !defaultProgram?.isCancelling
                  : true) &&
                (isEnrolledInDigitalTier
                  ? !digitalProgram?.isCancelling
                  : true) &&
                nextPaymentInfo &&
                !nextPaymentInfo.isBalanceOverdue && (
                  <button
                    className="btn align-self-center text-danger px-lg-3"
                    onClick={() => {
                      setIsCancelSubscriptionModalOpen(true);
                      setSelectedUser(user);
                    }}
                  >
                    <Icon
                      name="close-times"
                      width="17px"
                      height="17px"
                      className="mr-2"
                      fill="#DC3545"
                    ></Icon>
                    Cancel Subscription
                  </button>
                )}
            </div>
          </>
        )}
      </div>

      {isDiscardModalOpen && (
        <DiscardModal
          setDiscardModalOpen={setDiscardModalOpen}
          isDiscardModalOpen={isDiscardModalOpen}
          type={
            !defaultProgram?.futureSubscription && digitalProgram?.isActive
              ? 'Downgrade Digital Discard Change'
              : 'Discard Cancellation'
          }
          user={user}
        />
      )}
      {isDigitalDiscardModalOpen && (
        <DiscardModal
          setDiscardModalOpen={setDigitalDiscardModalOpen}
          isDiscardModalOpen={isDigitalDiscardModalOpen}
          type={'Discard Digital Cancellation'}
          user={user}
        />
      )}
      {/* {isDigitalDiscardModalOpen && (
        <DiscardModal
          setDiscardModalOpen={setDigitalDiscardModalOpen}
          isDiscardModalOpen={isDigitalDiscardModalOpen}
          type={'Downgrade Digital Discard Change'}
          user={user}
        />
      )} */}
      {isDowngradeDiscardModalOpen && (
        <DiscardModal
          setDiscardModalOpen={setDowngradeDiscardModalOpen}
          isDiscardModalOpen={isDowngradeDiscardModalOpen}
          type={'Downgrade Discard Change'}
          user={user}
        />
      )}
    </>
  );
};

export { ManagePlanFitnessPackage };
