import React from 'react';
import classNames from 'classnames';
import { Icon } from '../../../assets/Icons';

const PromoCodePill = ({ promoCode, error, removePromoCode, index }) => {
  return (
    <div
      className={classNames(
        'border rounded-pill d-flex flex-row p-1 justify-content-between mb-2',
        { 'border-primary': !error, 'border-danger': error }
      )}
    >
      <div className="d-flex align-items-center">
        {error ? (
          <span className="fas fa-exclamation-circle text-danger" />
        ) : (
          <span className="fas fa-check-circle" />
        )}
        <p className="m-0">&nbsp;{promoCode.description}</p>
      </div>
      {!promoCode.isSystemApplied && !error && (
        <button
          type="button"
          className="border border-0 mr-2 p-0 m-0 bg-transparent"
          onClick={e => removePromoCode(promoCode)}
        >
          <Icon name="trash-can" width="20px" height="20px" />
        </button>
      )}
    </div>
  );
};

export { PromoCodePill };
