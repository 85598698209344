import {
  SET_LOADING,
  SET_CLIENT,
  SET_COM,
  SET_CMS,
  SET_AUTH_URL,
  SET_IDP_CLIENT_ID,
  IS_REFRESHING_TOKEN,
  SET_CLASSESWIDGETURL,
  SET_ANALYTICS,
  SET_IN_CONTACT,
  SET_STRINGS
} from '../reducers/config';
import axios from 'axios';
import { logOut } from './user';
import { setEnrollmentDefault } from './enrollment';
import { clearToken } from '../../utilities/tokenHelpers';

export const setLoading = (loadingType, isLoading) => ({
  type: SET_LOADING,
  loadingType,
  isLoading
});

export const setClient = client => ({ type: SET_CLIENT, client });

export const setAnalytics = analytics => ({ type: SET_ANALYTICS, analytics });

export const setClassesWidgetUrl = classesWidgetUrl => ({
  type: SET_CLASSESWIDGETURL,
  classesWidgetUrl
});

export const setCom = com => ({ type: SET_COM, com });

export const setInContact = inContact => ({ type: SET_IN_CONTACT, inContact });

export const setCMS = cms => ({ type: SET_CMS, cms });

export const setStrings = strings => ({ type: SET_STRINGS, strings });

export const getCMS = async (url, client, dispatch) => {
  dispatch(setLoading('loadingCMS', true));
  try {
    const cmsData = await axios.get(`${url}${client}/acf/v3/pages/`);
    const cms = await cmsData.data.find(item => item.acf.clientName === client);
    dispatch(setCMS(cms.acf));
    dispatch(setStrings(cms.acf));
    return { cms: cms.acf };
  } catch (error) {
    throw error;
  } finally {
    dispatch(setLoading('loadingCMS', false));
  }
};

export const setAuthUrl = authUrl => ({ type: SET_AUTH_URL, authUrl });

export const setIdpClientId = idpClientId => ({
  type: SET_IDP_CLIENT_ID,
  idpClientId
});

export const clearState = (userDispatch, enrollmentDispatch) => {
  userDispatch(logOut());
  enrollmentDispatch(setEnrollmentDefault());
  clearToken();
};

export const setIsRefreshingToken = isRefreshingToken => ({
  isRefreshingToken,
  type: IS_REFRESHING_TOKEN
});
