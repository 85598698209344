import React from 'react';
import { TierTabs, HorizontalLocationList } from './';

const LandingPageLocationSection = () => (
  <div className="landing-page-location-section">
    <TierTabs>
      <HorizontalLocationList numberOfLocationsShown={3} />
    </TierTabs>
  </div>
);

export { LandingPageLocationSection };
