import axios from 'axios';

const setToken = tokenObj => {
  localStorage.setItem('accessToken', tokenObj.accessToken);
  localStorage.setItem('refreshToken', tokenObj.refreshToken);
  localStorage.setItem('expiresAt', tokenObj.expiresAt);
};

export const getAccessToken = () => localStorage.getItem('accessToken');

const getRefreshToken = () => localStorage.getItem('refreshToken');

const getTokenExpiration = () => localStorage.getItem('expiresAt');

export const clearToken = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expiresAt');
};

const getToken = async (config, history, dispatch) => {
  try {
    const tokenData = await axios.post('/api/prime/v2/state/token', config);
    await setToken(tokenData.data.data.authToken);
    return tokenData.data.data.authToken;
  } catch (error) {
    return error;
  }
};

const refreshToken = async () => {
  try {
    const refreshAxios = axios.create({
      headers: { Authorization: `Bearer ${getAccessToken()}` }
    });
    const newToken = await refreshAxios.put('/api/prime/v2/state/token');
    const token = newToken.data.data.authToken;
    return token;
  } catch (error) {
    return error.response;
  }
};

export {
  getToken,
  refreshToken,
  getTokenExpiration,
  setToken,
  getRefreshToken
};
