import React, { useRef, useState } from 'react';
import Downshift, { resetIdCounter } from 'downshift';
import { useEnrollmentState } from '../../../context/EnrollmentContext';
import { AddressSuggestions } from './AddressSuggestions';
import { DebounceInput } from 'react-debounce-input';
import { smartyStreetsAutoComplete } from '../../../utilities';
import { AddressErrors } from './AddressErrors';
import { setAddress } from '../../../context/actions/user';
import { setAddressValidationErrors } from '../../../context/actions/enrollment';
import { useUserState } from '../../../context/UserContext';

const AddressInput = ({
  addressType,
  name,
  title,
  optional,
  type,
  value,
  handleBlur,
  handleChange,
  errors,
  placeholder,
  touched,
  setValues
}) => {
  const inputRef = useRef();
  const [smartyStreetsSuggestions, setSmartyStreetsSuggestions] = useState([]);

  const handleAddressSelect = item => {
    const address = {
      address1: item.streetLine,
      address2: item.secondary,
      city: item.city,
      state: item.state,
      zip: item.zipcode
    };
    setValues(address);
    inputRef.current.blur();
  };

  const handleSuggestionsChange = async e => {
    let inputValue = e.target.value;
    handleChange(e);
    let res = await smartyStreetsAutoComplete(inputValue);
    setSmartyStreetsSuggestions(res ? res : smartyStreetsSuggestions);
  };

  resetIdCounter();

  const isAddress1 = name === 'address1';

  return (
    <div className="form-group col">
      <Downshift onChange={handleAddressSelect}>
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex
        }) => (
          <div>
            <label htmlFor={name} className="form-label">
              <p className=" h6 text-uppercase font-weight-bold text-secondary m-0">
                {title}
                {!optional && <i className="m-0 text-danger">*</i>}
              </p>
              {optional ? (
                <p className=" h6 text-secondary m-0">(Optional)</p>
              ) : null}
            </label>
            <DebounceInput
              {...getInputProps({
                className: 'form-control',
                id: name,
                name: name,
                type: type,
                value: value,
                onBlur: handleBlur,
                onChange: e =>
                  isAddress1 ? handleSuggestionsChange(e) : handleChange(e),
                placeholder: placeholder,
                autoComplete: 'new-password'
              })}
              inputRef={inputRef}
              debounceTimeout={isAddress1 ? 700 : 0}
            />
            <AddressSuggestions
              isOpen={isOpen}
              smartyStreetsSuggestions={smartyStreetsSuggestions}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              addressType={addressType}
            />
            {!isOpen && (
              <AddressErrors
                touched={touched}
                errors={errors}
                name={name}
                addressType={addressType}
              />
            )}
          </div>
        )}
      </Downshift>
    </div>
  );
};
export { AddressInput };
