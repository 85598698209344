import { validateEmail } from './validateEmail';

const isDependentTierInvalid = (
  chosenDependents,
  primarySelectedTier,
  digitalTier
) => {
  if (!primarySelectedTier) return false;
  if (!chosenDependents || chosenDependents.length === 0) return false;
  if (primarySelectedTier === 'studio') {
    return (
      chosenDependents.filter(dependent => !dependent.isUnderage).length !==
      chosenDependents.length
    );
  }

  if (digitalTier && primarySelectedTier.id === digitalTier[0].id) {
    return (
      chosenDependents.filter(dependent =>
        dependent.isUnderage
          ? dependent.selectedGymPackage.id === digitalTier[0].id
          : dependent
      ).length !== chosenDependents.length
    );
  }

  return (
    chosenDependents.filter(dependent =>
      dependent.isUnderage
        ? dependent.selectedGymPackage.tier <= primarySelectedTier.tier
        : dependent
    ).length !== chosenDependents.length
  );
};

const validateMemberEmail = (primaryEmail, dependents) => {
  if (dependents && dependents.length) {
    return (
      validateEmail(primaryEmail) &&
      dependents.filter(dependent => {
        return dependent.isUnderage
          ? dependent
          : validateEmail(dependent.email);
      }).length === dependents.length
    );
  }
  return validateEmail(primaryEmail);
};

export { validateMemberEmail, isDependentTierInvalid };
