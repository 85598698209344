export const getPhoneNumber = () => {
  let state = localStorage.getItem('globalState');
  if (state) {
    let client = JSON.parse(state).configState.client;

    if (client === 'hcsc') {
      return { number: '1-888-313-5643', text: '1-888-313-5643' };
    } else if (client === 'walmart') {
      return {
        number: '1-833-236-0181',
        text: '1-833-236-0181'
      };
    } else {
      return { number: '', text: '' };
    }
  } else {
    return { number: '', text: '' };
  }
};
