import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const UpSellBanner = ({ title, description, showLocations, button }) => (
  <div className="my-lg-3 mx-4 d-flex">
    <div className="card w-100 flex-row primary-bg p-4 justify-content-between">
      <div className="align-self-center col-6 ">
        <h3 className="text-white font-weight-bold">{title}</h3>
        <p className="text-white m-0 font-italic">{description}</p>
      </div>
      <div className="align-self-center">
        {showLocations && (
          <div className="d-inline-flex">
            <NavLink to="/locations" exact>
              <div className="ml-1 p-2 text-white d-flex  w-100 align-items-center ">
                <span className="mr-3">
                  <FontAwesomeIcon icon="search" />
                </span>
                <p className="text-white m-0">Explore Locations</p>
              </div>
            </NavLink>
          </div>
        )}
        <div className="d-inline-flex">{button}</div>
      </div>
    </div>
  </div>
);

export { UpSellBanner };
